import { FC, useEffect } from 'react';
import { Mno } from '../../../../entity/mno';
import {
  UsimInfo,
  UsimInfoDetails,
  UsimStatus,
} from '../../types/mobile-plan-operator-admin-types';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import {
  ESIM_SELECT_ITEM_LIST,
  USIM_SELECT_ITEM_LIST,
} from './constants/selectItemList';
import { ConditionalRenderByFeeStatus } from './ConditionalRenderByFeeStatus';

type RemoveFeeStatus<T> = {
  [K in keyof T as K extends `${infer Prefix}FeeStatus` ? Prefix : never]: T[K];
};

export type SimFeeStatusKeyType = keyof RemoveFeeStatus<UsimInfoDetails>;

interface Props {
  mno: Mno;
  label: string;
  simFeeStatusKey: SimFeeStatusKeyType;
  defaultValue: { fee: number | null; feeStatus: UsimStatus };
}

export const MvnoInfoDeliveryFeeStatus: FC<Props> = ({
  mno,
  label,
  simFeeStatusKey,
  defaultValue,
}) => {
  const { setValue, control, watch } = useFormContext<UsimInfo>();
  const deliveryFeeKey = `${mno}.${simFeeStatusKey}Fee` as const;
  const deliveryFeeStatusKey = `${mno}.${simFeeStatusKey}FeeStatus` as const;
  const deliveryFeeStatus = watch(deliveryFeeStatusKey);

  useEffect(() => {
    switch (deliveryFeeStatus) {
      case 'FREE':
        setValue(deliveryFeeKey, null);
        break;
      case 'FREE_ON_ACTIVATION':
        setValue(deliveryFeeKey, null);
        break;
      case 'PAID':
        break;
      case 'UNSUPPORTED':
        setValue(deliveryFeeKey, null);
        break;
    }
  }, [deliveryFeeStatus]);

  return (
    <FormControl fullWidth>
      <Stack flexDirection="column" gap="8px">
        <Controller
          control={control}
          name={deliveryFeeStatusKey}
          defaultValue={defaultValue.feeStatus}
          render={({ field }) => (
            <>
              <InputLabel id={`${deliveryFeeStatusKey}-label`}>
                {label}
              </InputLabel>
              <Select key={field.value} {...field} label={label}>
                {(simFeeStatusKey === 'esim'
                  ? ESIM_SELECT_ITEM_LIST
                  : USIM_SELECT_ITEM_LIST
                ).map((item, index) => (
                  <MenuItem key={`${item.value}`} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
        />
        <ConditionalRenderByFeeStatus
          mno={mno}
          simFeeStatusKey={simFeeStatusKey}
          defaultValue={defaultValue.fee}
        />
      </Stack>
    </FormControl>
  );
};
