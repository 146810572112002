import { Controller, useFormContext } from 'react-hook-form';
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField,
} from '@mui/material';

import { MobilePlanOrder } from '../../../entity/order';

interface CardInfoProps {
  order: MobilePlanOrder;
}

export function CardInfo({ order }: CardInfoProps) {
  const { control, register, watch, setValue } =
    useFormContext<MobilePlanOrder>();
  const watchPaymentMethod = watch('detail.paymentMethod');

  if (watchPaymentMethod !== 'card') {
    return null;
  }

  const handleCardNumber = (value: string) => {
    const cardNumber = value.replace(/\D/g, '');
    setValue('detail.cardNumber', cardNumber);
  };

  return (
    <TableRow>
      <TableCell>카드번호</TableCell>
      <TableCell>
        <TextField
          {...register('detail.cardNumber')}
          onChange={(e) => handleCardNumber(e.target.value)}
        />
      </TableCell>
      <TableCell>유효기간</TableCell>
      <TableCell>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <FormControl>
            <Controller
              name="detail.cardExpiresMonth"
              control={control}
              defaultValue={order?.detail?.cardExpiresMonth ?? ''}
              render={({ field }) => (
                <Select {...field} size="small">
                  <MenuItem value="01">01</MenuItem>
                  <MenuItem value="02">02</MenuItem>
                  <MenuItem value="03">03</MenuItem>
                  <MenuItem value="04">04</MenuItem>
                  <MenuItem value="05">05</MenuItem>
                  <MenuItem value="06">06</MenuItem>
                  <MenuItem value="07">07</MenuItem>
                  <MenuItem value="08">08</MenuItem>
                  <MenuItem value="09">09</MenuItem>
                  <MenuItem value="10">10</MenuItem>
                  <MenuItem value="11">11</MenuItem>
                  <MenuItem value="12">12</MenuItem>
                </Select>
              )}
            />
          </FormControl>
          <FormControl
            sx={{
              ml: 1,
            }}
          >
            <Controller
              name="detail.cardExpiresYear"
              control={control}
              defaultValue={order?.detail?.cardExpiresYear ?? ''}
              render={({ field }) => (
                <Select {...field} size="small">
                  <MenuItem value="22">2022</MenuItem>
                  <MenuItem value="23">2023</MenuItem>
                  <MenuItem value="24">2024</MenuItem>
                  <MenuItem value="25">2025</MenuItem>
                  <MenuItem value="26">2026</MenuItem>
                  <MenuItem value="27">2027</MenuItem>
                  <MenuItem value="28">2028</MenuItem>
                  <MenuItem value="29">2029</MenuItem>
                  <MenuItem value="30">2030</MenuItem>
                  <MenuItem value="31">2031</MenuItem>
                  <MenuItem value="32">2032</MenuItem>
                </Select>
              )}
            />
          </FormControl>
        </Box>
      </TableCell>
    </TableRow>
  );
}
