import { AxiosResponse } from 'axios';
import { orderApi } from '../common/http_util';
import {
  OrderProgressDetail,
  OrderProgressDetailList,
} from '../entity/order-progress';
import qs from 'query-string';
import { ApiResponse } from '../entity/api';

class OrderProgressApiService {
  getList(
    data: any,
    page: number,
    size: number,
    sortField?: string,
    sortDirection?: string,
  ): Promise<AxiosResponse<ApiResponse<OrderProgressDetailList>>> {
    let queryParams: any = {
      page,
      size,
    };

    if (sortField && sortDirection) {
      queryParams = {
        ...queryParams,
        sort: `${sortField},${sortDirection}`,
      };
    }

    return orderApi.post(`progress?${qs.stringify(queryParams)}`, data);
  }

  get(id: string): Promise<AxiosResponse<ApiResponse<OrderProgressDetail>>> {
    return orderApi.get(`progress/${id}`);
  }
}

export const orderProgressApiService = new OrderProgressApiService();
