import { Grid } from '@mui/material';
import { styled, Theme } from '@mui/material/styles';
import { FC, useEffect } from 'react';
import {
  useSearchPagination,
  useSearchParamsData,
  useSearchSection,
} from '../../../SearchData/hooks';
import useInternetConsultSearch from '../../hooks/useInternetConsultSearch';

const _Button = styled('button')<{ $active?: boolean }>(
  ({ theme, $active }: { theme: Theme; $active?: boolean }) => ({
    padding: '12px 36px',
    borderRadius: '40px',
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    ...(() => {
      if ($active) {
        return {
          background:
            theme.palette.mode === 'dark'
              ? 'rgba(25, 118, 210, 0.32)'
              : 'rgba(25, 118, 210, 0.08)',
          color:
            theme.palette.mode === 'dark' ? 'rgb(255,255,255)' : 'rgb(0, 0, 0)',
        };
      }
      return {
        color:
          theme.palette.mode === 'dark'
            ? 'rgb(255,255,255)'
            : 'rgba(0, 0, 0, 0.6)',
        ':hover': {
          background:
            theme.palette.mode === 'dark'
              ? 'rgba(25, 118, 210, 0.16)'
              : 'rgba(25, 118, 210, 0.04)',
        },
      };
    })(),
  }),
);

interface Props {
  internetCount: number;
  internetIptvCount: number;
}

const InternetConsultTableTab: FC<Props> = ({
  internetCount,
  internetIptvCount,
}) => {
  const { updateSearchParams } = useSearchParamsData();
  const { searchState, searchHandler } = useSearchSection();
  const { paginationHandler } = useSearchPagination();

  const onClickIptvBundle = () => {
    searchHandler.setIptvBundle(true);
    searchParamsHandler(true);
  };

  const onClickIsNotTvBundle = () => {
    searchHandler.setIptvBundle(false);
    searchParamsHandler(false);
  };

  const searchParamsHandler = (iptvBundle: boolean) => {
    paginationHandler.setPage(0);
    const searchSectionData = searchHandler.getSearchParamsData();
    updateSearchParams({
      ...searchSectionData,
      iptvBundle: iptvBundle.toString(),
      page: '0',
    });
  };

  return (
    <Grid container columnSpacing={2}>
      <Grid item>
        <_Button
          $active={!searchState.iptvBundle}
          onClick={onClickIsNotTvBundle}
        >
          인터넷 ({internetCount})
        </_Button>
      </Grid>
      <Grid item>
        <_Button $active={!!searchState.iptvBundle} onClick={onClickIptvBundle}>
          인터넷 + TV ({internetIptvCount})
        </_Button>
      </Grid>
    </Grid>
  );
};

export default InternetConsultTableTab;
