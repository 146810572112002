import styled from '@emotion/styled';
import { Alert, Button, Snackbar, Typography } from '@mui/material';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/UserContext';
import { useSecureSession } from '../../hooks/useSecureSession';
import pbl from '../../pbl/pbl-service/pbl-service';
import getRedirectQueryString from '../../utils/getRedirectQueryString';

export function SessionTimer() {
  const { logoutUser } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [showTimeRemainingToast, setShowTimeRemainingToast] = useState(false);
  const currentPath = location.pathname;
  const redirectQueryString = getRedirectQueryString({
    redirectUrl: currentPath,
    includeQuestionMark: true,
  });

  const closeTimeRemainingToast = () => {
    setShowTimeRemainingToast(false);
  };

  const openTimeRemainingToast = () => {
    setShowTimeRemainingToast(true);
  };

  const handleSessionExpired = () => {
    pbl('click', 'timer', 'here', {
      object: {
        id: 'session_expired',
        section: 'GNB',
      },
    });
    logoutUser();
    navigate(`/login${redirectQueryString}`);
  };

  const { timeRemaining, extendSession } = useSecureSession({
    sessionDuration: 60,
    warningTime: 5,
    onSessionExpired: handleSessionExpired,
    onWarning: openTimeRemainingToast,
  });

  const handleReset = () => {
    extendSession();
  };

  return (
    <>
      <TimerContainer>
        <Typography>남은 로그인 유지시간: {timeRemaining} 분</Typography>
        <Button variant="text" size="medium" onClick={handleReset}>
          초기화
        </Button>
      </TimerContainer>
      <Snackbar
        open={showTimeRemainingToast}
        autoHideDuration={5000}
        onClose={closeTimeRemainingToast}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert
          severity="success"
          variant="filled"
          onClose={closeTimeRemainingToast}
        >
          {timeRemaining}분 뒤에 세션이 만료됩니다.
        </Alert>
      </Snackbar>
    </>
  );
}

const TimerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 12px;
  padding-right: 12px;
`;
