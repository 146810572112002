import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react';
import PortalRender from './portalRender';

interface UsePortalControllerContext<T> {
  close: (data?: T) => void;
  dismiss: () => void;
  setCloseHandler: (fn: (data?: T) => void) => void;
}

const PortalControllerContext = createContext<UsePortalControllerContext<any>>({
  close: () => {},
  dismiss: () => {},
  setCloseHandler: () => {},
});

const usePortalContextController = <T,>(
  dismissHandler?: () => void,
): UsePortalControllerContext<T> => {
  const closeHandlerRef = useRef<(data?: T) => void>();
  const setCloseHandler = (fn: (data?: T) => void) => {
    closeHandlerRef.current = fn;
  };

  const close = useCallback((data?: T) => {
    closeHandlerRef.current?.(data);
  }, []);

  const dismiss = () => {
    dismissHandler?.();
  };

  return { close, setCloseHandler, dismiss };
};

export const usePortalController = <T,>() => {
  const { close: contextClose, dismiss: contextDismiss } = useContext<
    UsePortalControllerContext<T>
  >(PortalControllerContext);

  const close = (data?: T) => {
    contextClose(data);
  };

  const dismiss = () => {
    contextDismiss();
  };

  return { close, dismiss };
};

export const usePortal = <T,>() => {
  const portalRenderRef = useRef<PortalRender>();
  const renderDisposeRef = useRef<() => void>(() => {});
  const portalContextController = usePortalContextController<T>(() => {
    renderDisposeRef.current && renderDisposeRef.current();
  });

  useEffect(() => {
    portalRenderRef.current = new PortalRender();

    return () => {
      renderDisposeRef.current && renderDisposeRef.current();
    };
  }, []);

  const open = useCallback(
    (component: ReactNode, preventScroll?: boolean) => {
      return new Promise<T | undefined>((resolved) => {
        portalContextController.setCloseHandler(resolved);

        renderDisposeRef.current =
          portalRenderRef.current?.render(
            <PortalControllerContext.Provider value={portalContextController}>
              {component}
            </PortalControllerContext.Provider>,
            preventScroll,
          ) || (() => {});
      });
    },
    [portalContextController],
  );

  const dispose = useCallback(() => {
    renderDisposeRef.current && renderDisposeRef.current();
  }, []);

  return {
    open,
    dispose,
  };
};
