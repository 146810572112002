import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Switch,
} from '@mui/material';
import { useCallback, useState } from 'react';
import { orderApiService } from '../../api/order';
import { useLoading } from '../../context/LoadingContext';
import pbl from '../../pbl/pbl-service/pbl-service';
import { download, makeMetadataForDownloading } from '../../domains/file';
import { convertAxiosResponseToFetchResponse } from '../../converters/network/response';
import { useRole } from '../../router/useRole';
import { usePermission } from '../../router/usePermission';

type Props = {
  filterData?: any;
  totalCount: number;
  open: boolean;
  exportType?: string;
  onClose: () => void;
};

export default function ExcelDownloadPopup({
  filterData,
  totalCount,
  open,
  exportType,
  onClose,
}: Props) {
  const [excludeUsim, setExcludeUsim] = useState(false);
  const [includeMoyoProcessingOrder, setIncludeMoyoProcessingOrder] =
    useState(false);
  const { setLoading } = useLoading();
  const { roles } = useRole();
  const { isAuthorityEnableDownloadMoyoProcessingOrder } = usePermission();

  const onClickDownload = useCallback(async () => {
    pbl('click', 'modal_button', 'here', {
      object: {
        id: 'export_excel',
        section: 'action_menu',
      },
      eventProperties: {
        filterData,
        order_count: totalCount,
        excludeUsim,
        includeMoyoProcessingOrder,
        activeRole: roles,
      },
    });
    setLoading(true);

    try {
      const isEsim = excludeUsim ? false : filterData.isEsim;
      if (exportType === 'delayOrder') {
        const res = await orderApiService.exportDelayExcel({
          ...filterData,
          excludeUsim,
          isEsim,
          includeMoyoProcessing: includeMoyoProcessingOrder,
        });
        setLoading(false);
        download(
          await makeMetadataForDownloading(
            convertAxiosResponseToFetchResponse(res),
          ),
        );
      } else {
        const res = await orderApiService.exportToExcel({
          ...filterData,
          excludeUsim,
          isEsim,
          includeMoyoProcessing: includeMoyoProcessingOrder,
        });
        setLoading(false);
        download(
          await makeMetadataForDownloading(
            convertAxiosResponseToFetchResponse(res),
          ),
        );
      }
    } catch (err) {
      alert('엑셀 파일 다운로드에 실패했습니다. 잠시 후 다시 시도해주세요.');
    } finally {
      setLoading(false);
      onClose();
    }
  }, [filterData, excludeUsim, totalCount, includeMoyoProcessingOrder]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>엑셀로 추출하기</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {`${totalCount}개의 주문을 엑셀로 추출합니다.`}
        </DialogContentText>
        <FormGroup>
          <FormControlLabel
            label={'유심 보유 고객 제외하기'}
            control={
              <Switch
                checked={excludeUsim}
                onChange={(e) => {
                  setExcludeUsim(e.target.checked);
                }}
              />
            }
            sx={{
              mt: '16px',
            }}
          />
          {isAuthorityEnableDownloadMoyoProcessingOrder && (
            <FormControlLabel
              label="모요가 처리중인 신청 포함하기"
              control={
                <Switch
                  disabled={!isAuthorityEnableDownloadMoyoProcessingOrder}
                  checked={includeMoyoProcessingOrder}
                  onChange={(e) => {
                    setIncludeMoyoProcessingOrder(e.target.checked);
                  }}
                />
              }
            />
          )}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClickDownload}>추출하기</Button>
      </DialogActions>
    </Dialog>
  );
}
