import axios from 'axios';
import { PlanMetaAdmin } from '../entity/plan';
import { CreateMoyoPlanTypes } from '../components/NewMoyoOnlyPage/type';
import { comma } from '../hooks/string-util';

// #notify-moyo_only_생성요청 slack webhook url
const SLACK_WEBHOOK_URL =
  'https://hooks.slack.com/services/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';

const sendChangeDetailInfoToSlack = async (
  exists: {
    fee: number;
    feeAfterDiscount: number | undefined;
    promotionPeriod: number | undefined;
  },
  date: string,
  isReservation: boolean,
  previewFee: number,
  guardRailFee: number,
  guardRailPlanId: number | undefined,
  responseId?: number,
  plan?: PlanMetaAdmin,
  url: string = SLACK_WEBHOOK_URL,
) => {
  const alarm = `*모요ONLY 요금제 수정 ${isReservation ? '[예약]' : ''}*`;
  const message = `${alarm}

${isReservation ? `예약시점 : ${date.replace('T', ' ')}` : ''}

통신사 : ${plan?.mvno}
요금제명 : ${plan?.name}
할인 시 가격 : ${comma(exists.fee)}원 -> ${comma(plan?.fee)}원
할인 종료 후 가격 : ${comma(exists.feeAfterDiscount)}원 -> ${comma(
    plan?.feeAfterDiscount,
  )}원
할인기간 : ${
    exists.promotionPeriod === undefined
      ? '할인기간이 없었어요'
      : `${exists.promotionPeriod}개월동안`
  } -> ${plan?.promotionPeriod}개월동안

모요온리 12개월치 요금 : ${comma(previewFee)}원
비교 요금제 12개월치 요금: ${
    guardRailFee === 0 ? '비교 요금제가 없습니다' : `${comma(guardRailFee)}원`
  }

 - 모요 링크 : ${`https://www.moyoplan.com/plans/${responseId}`}
 - 비교 요금제: ${
   guardRailPlanId
     ? `https://www.moyoplan.com/plans/${guardRailPlanId}`
     : '비교 요금제가 없습니다'
 }
 - 파트너 링크 : ${plan?.partnerSignupUrl}
`;

  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
  };

  const metaData = {
    text: message,
  };
  const data = JSON.stringify(metaData);

  try {
    await axios({
      method: 'post',
      url,
      data,
      headers: headers,
    });
  } catch (error) {
    console.warn(error);
  }
};

const sendNewPlan = async (
  plan: CreateMoyoPlanTypes | undefined,
  guardRailPlanId: number | undefined,
  date: string,
  name: string,
  isReservation: boolean,
  previewFee: number,
  guardRailFee: number,
  responseId: number,
  url: string = SLACK_WEBHOOK_URL,
) => {
  try {
    const alarm = `*${
      isReservation
        ? '모요ONLY 요금제 신규 등록 [예약]'
        : '모요ONLY 요금제 신규 등록'
    }*`;
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
    };
    const message = `${alarm}

${isReservation ? `예약시점 : ${date.replace('T', ' ')}` : ''}

통신사: ${plan?.carrier}
요금제명: ${name}
할인시 가격: ${comma(plan?.fee)}원
할인종료 후 가격: ${comma(plan?.feeAfterDiscount)}원
할인기간: ${plan?.promotionPeriod}개월동안
KT에 허가된 요금제: ${plan?.mno === 'KT' ? ' 네' : ''}


모요온리 12개월치 요금: ${comma(previewFee)}원
비교 요금제 12개월치 요금: ${
      guardRailFee === 0 ? '비교 요금제가 없습니다' : `${comma(guardRailFee)}원`
    }

 - 모요 링크: ${
   responseId === 0
     ? '생성 예약했습니다'
     : `https://www.moyoplan.com/plans/${responseId}`
 }
 - 비교 요금제: ${
   guardRailPlanId && guardRailFee !== 0
     ? `https://www.moyoplan.com/plans/${guardRailPlanId}`
     : '비교 요금제가 없습니다'
 }
 - 파트너 링크: ${plan?.partnerSignupUrl}
 `;

    const metaData = {
      text: message,
    };

    const data = JSON.stringify(metaData);

    return await axios({
      method: 'post',
      url,
      data,
      headers: headers,
    });
  } catch (error) {
    console.log(error);
  }
};

export default {
  sendNewPlan,
  sendChangeDetailInfoToSlack,
};
