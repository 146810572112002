import { ReleaseNoteItem } from './releaseNotes';
import ReleaseStatusType from './ReleaseStatusType';

const Release_2_2_2: ReleaseNoteItem = {
  version: '2.2.2',
  date: '2023-07-05',
  title: '파트너스 사용자 가이드 추가',
  details: [
    {
      title: '파트너스 사용자 가이드 추가',
      contents: [
        {
          content: (
            <>
              모요 파트너스를 통해 요금제 접수, 요금제 관리, 파트너스 문의 등
              사용하는 방법에 대한 가이드가 추가되었습니다.
            </>
          ),
          status: ReleaseStatusType.NEW,
        },
      ],
    },
  ],
};

export default Release_2_2_2;
