import styled from '@emotion/styled';
import {
  Alert,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import orderDetailOneTongApi, {
  NewPhoneNumberItem,
} from '../../../api/orderDetailOneTongApi';

const PhoneNumberSelect = styled(Select)`
  .optional {
    display: none;
  }
`;

interface PhoneNumberSelectorProps {
  wishNumber1?: string;
  wishNumber2?: string;
  orderPhoneNumber?: string;
  enableOrderPhoneNumber?: boolean;
  mvno: string;
  onChange: (phoneNumber: string, encryptedPhoneNumber?: string) => void;
  orderId: number;
}

const PhoneNumberSelector: FC<PhoneNumberSelectorProps> = ({
  wishNumber1,
  wishNumber2,
  orderPhoneNumber,
  enableOrderPhoneNumber,
  mvno,
  onChange,
  orderId,
}) => {
  const [wishNumber, setWishNumber] = useState<string>(
    wishNumber1 || wishNumber2 || '',
  );
  const [phoneNumberList, setPhoneNumberList] = useState<NewPhoneNumberItem[]>(
    [],
  );
  const [error, setError] = useState<string>();

  useEffect(() => {
    orderPhoneNumber && onChange(orderPhoneNumber);
  }, []);

  useEffect(() => {
    if (enableOrderPhoneNumber) return;
    if (wishNumber.length === 4) {
      updatePhoneNumberList(wishNumber);
    }
  }, [wishNumber, enableOrderPhoneNumber]);

  useEffect(() => {
    if (enableOrderPhoneNumber) return;
    onChange('');
  }, [phoneNumberList]);

  const updatePhoneNumberList = async (_wishNumber: string) => {
    try {
      const rs = await orderDetailOneTongApi.getNewPhoneNumber(
        wishNumber,
        orderId,
      );
      if (rs.data.resultType === 'FAIL') {
        // 1순위가 조회 안되고, 2순위가 있으면 2순위로 업데이트
        if (
          _wishNumber === wishNumber1 &&
          wishNumber2 &&
          wishNumber1 !== wishNumber2
        ) {
          setWishNumber(wishNumber2);
          return;
        }
        setError(rs.data.error);
        setPhoneNumberList([]);
      } else {
        setPhoneNumberList(rs.data.result?.newPhoneNumberList || []);
      }
    } catch (_: any) {
      setError('알 수 없는 문제가 발생하였습니다.');
      setPhoneNumberList([]);
    }
  };

  const onChangeWishNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    setError(undefined);
    if (isNaN(Number(value)) || 4 < value.length) {
      setPhoneNumberList([]);
      return;
    }
    setWishNumber(e.currentTarget.value);
  };

  const onClickMenuHandlerClosure = (
    phoneNumber: string,
    encryptedPhoneNumber?: string,
  ) => {
    return () => {
      onChange(phoneNumber, encryptedPhoneNumber);
    };
  };

  if (enableOrderPhoneNumber) {
    return (
      <Alert style={{ display: 'inline-flex', minWidth: 320 }} severity="info">
        희망 개통 번호 :{' '}
        {orderPhoneNumber?.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')}
      </Alert>
    );
  }
  return (
    <div>
      <TextField
        onChange={onChangeWishNumber}
        value={wishNumber}
        label="희망 번호"
      />
      {error ? (
        <Alert
          style={{
            marginLeft: 16,
            marginTop: 4,
            display: 'inline-flex',
            minWidth: 220,
          }}
          severity="error"
        >
          {error}
        </Alert>
      ) : (
        phoneNumberList.length > 0 && (
          <FormControl style={{ marginLeft: 16 }}>
            <InputLabel id="phone-number">개통 번호</InputLabel>
            <PhoneNumberSelect
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
              }}
              style={{ width: 220 }}
              labelId="phone-number"
              label="개통 번호"
            >
              {phoneNumberList.map(
                ({ number, 해지일자, encryptedPhoneNumber }) => {
                  return (
                    <MenuItem
                      onClick={onClickMenuHandlerClosure(
                        number,
                        encryptedPhoneNumber,
                      )}
                      key={number}
                      value={number}
                    >
                      <Typography variant="body1" fontSize={16}>
                        {number.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')}
                      </Typography>
                      <Typography
                        className="optional"
                        variant="body1"
                        fontSize={12}
                        color="rgba(0, 0, 0, 0.6)"
                        marginLeft="24px"
                      >
                        {해지일자.replace(/(\d{4})(\d{2})(\d{2})/, '$1.$2.$3')}{' '}
                        해지
                      </Typography>
                    </MenuItem>
                  );
                },
              )}
            </PhoneNumberSelect>
          </FormControl>
        )
      )}
    </div>
  );
};

export default PhoneNumberSelector;
