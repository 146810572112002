import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { companyApiService } from '../../api/company';

type AuthRouteProps = {
  children: JSX.Element;
};

export default function CompanyRoute({ children }: AuthRouteProps) {
  const navigate = useNavigate();

  async function fetchCompanyInfo() {
    const response = await companyApiService.getInfo();

    const mvnos = response.data.result?.mvnos;
    const internets = response.data.result?.internets;

    if (mvnos == null && internets != null) {
      navigate('/orders/internet');
    }
  }

  useEffect(() => {
    fetchCompanyInfo();
  }, []);

  return children;
}
