import { Controller, useFormContext } from 'react-hook-form';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  TableCell,
  TextField,
  Typography,
} from '@mui/material';

import { MobilePlanOrder } from '../../../entity/order';

interface LicenseInfoProps {
  order: MobilePlanOrder;
}

const YYYYMMDD_REGEX = /^(\d{4})(\d{2})(\d{2})$/;
const licenseTypeErrorMessage = '신분증 종류를 선택해 주세요.';

export function LicenseInfo({ order }: LicenseInfoProps) {
  const {
    register,
    control,
    watch,
    formState: { errors },
  } = useFormContext<MobilePlanOrder>();
  const watchLicenseType = watch('detail.licenseType');
  const licenseIssuedAtValidate = (value?: string) => {
    if (!value) return '발급일자를 입력해 주세요.';

    const matches = YYYYMMDD_REGEX.exec(value);
    if (!matches) return '발급일자를 올바르게 입력해주세요.';

    const yyyy = Number(matches[1]);
    const mm = Number(matches[2]);
    const dd = Number(matches[3]);
    const date = new Date(`${yyyy}-${mm}-${dd}`);
    if (isNaN(date.getTime())) return '발급일자를 올바르게 입력해주세요.';

    return true;
  };

  return (
    <>
      <TableCell>실명확인자료</TableCell>
      <TableCell>
        <FormControl>
          <Controller
            name="detail.licenseType"
            rules={{ required: licenseTypeErrorMessage }}
            control={control}
            defaultValue={order.detail?.licenseType ?? ''}
            render={({ field }) => (
              <RadioGroup {...field} row>
                <FormControlLabel
                  value="registration"
                  control={<Radio />}
                  label="주민등록증"
                  disabled={order.detail?.licenseType === 'registration'}
                  aria-readonly={order.detail?.licenseType === 'registration'}
                />
                <FormControlLabel
                  value="driver"
                  control={<Radio />}
                  label="운전면허증"
                  disabled={order.detail?.licenseType === 'registration'}
                  aria-readonly={order.detail?.licenseType === 'registration'}
                />
              </RadioGroup>
            )}
          />
        </FormControl>
        <FormHelperText error={!!errors.detail?.licenseType}>
          {errors.detail?.licenseType?.message}
        </FormHelperText>
      </TableCell>
      <TableCell>신분증 정보</TableCell>
      <TableCell>
        {watchLicenseType === 'registration' ? (
          <TextField
            {...register('detail.licenseIssuedAt', {
              validate: licenseIssuedAtValidate,
            })}
            error={!!errors.detail?.licenseIssuedAt}
            helperText={errors.detail?.licenseIssuedAt?.message}
            size="small"
            placeholder="YYYYMMDD"
          />
        ) : (
          <>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <TextField
                {...register('detail.driverLicense1')}
                size="small"
                sx={{
                  flex: 1,
                  flexBasis: '15%',
                }}
                disabled
                aria-readonly
              />
              <Typography
                sx={{
                  flex: '0 0 auto',
                  padding: '0 5px',
                }}
              >
                -
              </Typography>
              <TextField
                {...register('detail.driverLicense2')}
                size="small"
                sx={{
                  flex: 1,
                  flexBasis: '15%',
                }}
                disabled
                aria-readonly
              />
              <Typography
                sx={{
                  flex: '0 0 auto',
                  padding: '0 5px',
                }}
              >
                -
              </Typography>
              <TextField
                {...register('detail.driverLicense3')}
                size="small"
                sx={{
                  flex: 1,
                  flexBasis: '25%',
                }}
                disabled
                aria-readonly
              />
              <Typography
                sx={{
                  flex: '0 0 auto',
                  padding: '0 5px',
                }}
              >
                -
              </Typography>
              <TextField
                {...register('detail.driverLicense4')}
                size="small"
                sx={{
                  flex: 1,
                  flexBasis: '15%',
                }}
                disabled
                aria-readonly
              />
            </Box>
            <TextField
              {...register('detail.licenseIssuedAt', {
                validate: licenseIssuedAtValidate,
              })}
              error={!!errors.detail?.licenseIssuedAt}
              helperText={errors.detail?.licenseIssuedAt?.message}
              size="small"
              placeholder="발급일자"
              sx={{
                mt: 2,
              }}
            />
          </>
        )}
      </TableCell>
    </>
  );
}
