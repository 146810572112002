import { GridPaginationModel, GridRowSelectionModel } from '@mui/x-data-grid';
import { IBehaviorLogObject } from '../pbl-service/create-pbl-service';
import pbl from '../pbl-service/pbl-service';

export const pblDatagridPagination = (
  model: GridPaginationModel,
  beforePage: number,
  beforePageSize: number,
  options?: {
    object?: IBehaviorLogObject;
  },
) => {
  pbl('click', 'pagination', 'here', {
    object: {
      ...options?.object,
    },
    eventProperties: {
      page: model.page,
      pageSize: model.pageSize,
      beforePage,
      beforePageSize,
    },
  });
};
