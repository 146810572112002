import { Typography } from '@mui/material';

import { numberCommaFormatter } from '../../../utils/valueFormatter';

import {
  MvnoInfoItemBody,
  MvnoInfoItemRow,
} from '../../MvnoInfoPage/Section/Common/MvnoInfoItemFrame';
import MvnoInfoItem from '../../MvnoInfoPage/Section/Common/MvnoInfoItem';
import { PlanMetaV2 } from '../../../entity/plan-v2';

type PlanDetailInfoComponentProps = {
  planMeta: PlanMetaV2;
};

const PlanDetailInfoComponent = ({
  planMeta,
}: PlanDetailInfoComponentProps) => {
  return (
    <>
      <MvnoInfoItemBody gap={4}>
        <Typography variant="h6">요금 정보</Typography>
        <MvnoInfoItemRow numberOfRow={3} gap={3}>
          <MvnoInfoItem
            title="요금제 코드"
            content={planMeta.code ? planMeta.code : '없음'}
          />
          <MvnoInfoItem
            title="약정 기간"
            content={
              planMeta.agreementPeriod
                ? `${planMeta.agreementPeriod}${
                    planMeta.agreementType === 'day'
                      ? '일'
                      : planMeta.agreementType === 'month'
                      ? '월'
                      : ''
                  }`
                : '없음'
            }
          />
        </MvnoInfoItemRow>
        <MvnoInfoItemRow numberOfRow={3} gap={3}>
          <MvnoInfoItem
            title="할인 요금"
            content={
              planMeta.fee == null
                ? '없음'
                : `${numberCommaFormatter(planMeta.fee)}원`
            }
          />
          <MvnoInfoItem
            title="할인 종료 후 요금"
            content={
              planMeta.할인끝난후_금액
                ? `${numberCommaFormatter(planMeta.할인끝난후_금액)}원`
                : 0
            }
          />
          <MvnoInfoItem
            title="할인 기간"
            content={
              planMeta.할인적용_개월수 === null
                ? '없음'
                : planMeta.할인적용_개월수 === 0
                ? '평생'
                : `${numberCommaFormatter(planMeta.할인적용_개월수)}개월`
            }
          />
        </MvnoInfoItemRow>
      </MvnoInfoItemBody>
    </>
  );
};

export default PlanDetailInfoComponent;
