// NOTE: Mno 통신망
export const MnoList = [
  { key: 'all', value: '', text: '전체' },
  { key: 'sk', value: 'SKT', text: 'SKT망' },
  { key: 'kt', value: 'KT', text: 'KT망' },
  { key: 'lg', value: 'LGU', text: 'LG망' },
  { key: 'skt-mvno', value: 'SKT-MVNO', text: 'SKT-MVNO망' },
  { key: 'kt-mvno', value: 'KT-MVNO', text: 'KT-MVNO망' },
  { key: 'lg-mvno', value: 'LGU-MVNO', text: 'LGU-MVNO망' },
];

// NOTE: 인터넷 통신망
export const InternetMnoList = [
  { key: 'all', value: '', text: '전체' },
  { key: 'sk', value: 'SKT', text: 'SKT망' },
  { key: 'kt', value: 'KT', text: 'KT망' },
  { key: 'lg', value: 'LGU', text: 'LG망' },
];
