import { Grid, MenuItem, Select, Typography } from '@mui/material';

import { useSearchSection } from '../hooks';

export function MnoSection() {
  const { searchState, searchHandler } = useSearchSection();

  const { mno } = searchState;
  const { setMno } = searchHandler;

  return (
    <Grid item xs={12} md={6}>
      <Typography mb={1}>통신망</Typography>
      <Select
        fullWidth
        defaultValue=""
        value={mno}
        onChange={(event) => setMno(event.target.value)}
        size="small"
        displayEmpty
      >
        <MenuItem key={'all'} value="">
          전체
        </MenuItem>
        <MenuItem key={'sk'} value="SKT">
          SKT망
        </MenuItem>
        <MenuItem key={'kt'} value="KT">
          KT망
        </MenuItem>
        <MenuItem key={'lg'} value="LGU">
          LG망
        </MenuItem>
      </Select>
    </Grid>
  );
}
