import { Box, Button, Typography } from '@mui/material';
import { FC } from 'react';
import { usePermission } from '../../../router/usePermission';

const InternetConsultDetailHeader: FC = () => {
  const { isInternetPermission } = usePermission();
  const onClickEdit = () => {};

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography variant="h5" my={3}>
        인터넷 정찰제 접수
      </Typography>
      {isInternetPermission && <Button onClick={onClickEdit}>수정</Button>}
    </Box>
  );
};

export default InternetConsultDetailHeader;
