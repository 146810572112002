import { styled, Box as MaterialBox, Modal } from '@mui/material';
import { CSSProperties, FC, SyntheticEvent, useRef, useState } from 'react';
import MaterialDownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import fileService from '../../api/fileService';

const Box = styled(MaterialBox)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  background: theme.palette.background.default,
  borderRadius: 12,
  border: `2px solid ${theme.palette.action.selected}`,
  outline: 'none',
  boxShadow:
    'rgb(0 0 0 / 20%) 0px 11px 15px -7px, rgb(0 0 0 / 14%) 0px 24px 38px 3px, rgb(0 0 0 / 12%) 0px 9px 46px 8px;',
  '> div': {
    background: theme.palette.action.hover,
    padding: 32,
  },
  img: {
    objectFit: 'contain',
  },
}));

const Download = styled('button')(({ theme }) => ({
  background:
    theme.palette.mode === 'dark'
      ? 'rgba(0, 0, 0, 0.6)'
      : 'rgba(255, 255, 255, 0.6)',
  position: 'absolute',
  width: 'calc(100% - 64px)',
  height: 'calc(100% - 67px)',
  border: 'none',
  outline: 'none',
  padding: 0,
  margin: 0,
  left: 32,
  top: 32,
  opacity: 0,
  transition: 'opacity 0.3s',
  cursor: 'pointer',
  ':hover': {
    opacity: 1,
  },
}));

const DownloadOutlinedIcon = styled(MaterialDownloadOutlinedIcon)(
  ({ theme }) => ({
    fill: theme.palette.text.primary,
  }),
);

interface ImageDialogProps {
  src?: string;
  alt?: string;
  fit?: boolean;
  open: boolean;
  download?: boolean;
  onClose: () => void;
}

const ImageDialog: FC<ImageDialogProps> = ({
  src,
  alt,
  fit,
  open,
  download,
  onClose,
}) => {
  const [imgStyle, setImgStyle] = useState<CSSProperties>();
  const imgRef = useRef<HTMLImageElement>(null);

  const onLoadHandler = (e: SyntheticEvent<HTMLImageElement, Event>) => {
    const isVertical =
      e.currentTarget.naturalWidth / e.currentTarget.naturalHeight <=
      window.innerWidth / window.innerHeight;
    const height = 'calc(90vh - 64px)';
    const width = 'calc(80vw - 64px)';
    const style: CSSProperties = isVertical
      ? fit
        ? { maxHeight: height }
        : { height }
      : fit
      ? { maxWidth: width }
      : { width };
    setImgStyle(style);
  };

  const onClickDownload = async () => {
    if (!src) return;
    var xhr = new XMLHttpRequest();
    xhr.open('GET', fileService.getImageUrl(src), true);
    xhr.responseType = 'blob';
    xhr.onload = function () {
      var urlCreator = window.URL || window.webkitURL;
      var imageUrl = urlCreator.createObjectURL(this.response);
      var tag = document.createElement('a');
      tag.href = imageUrl;
      tag.download = fileService.getFileNameFromUUIDPath(src);
      document.body.appendChild(tag);
      tag.click();
      document.body.removeChild(tag);
    };
    xhr.send();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box>
        <div>
          <img
            alt={alt}
            style={imgStyle}
            ref={imgRef}
            onLoad={onLoadHandler}
            src={src}
          />
          {download && (
            <Download onClick={onClickDownload}>
              <DownloadOutlinedIcon fontSize="large" />
            </Download>
          )}
        </div>
      </Box>
    </Modal>
  );
};

export default ImageDialog;
