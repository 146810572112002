import { Grid, TextField, Typography } from '@mui/material';

import { useSearchSection } from '../hooks';

export function CustomerNameSection() {
  const { searchState, searchHandler } = useSearchSection();

  const { customerName } = searchState;
  const { setCustomerName } = searchHandler;

  return (
    <Grid item xs={6} md={3}>
      <Typography mb={1}>고객 이름</Typography>
      <TextField
        label="고객 이름"
        value={customerName}
        onChange={(event) => setCustomerName(event.target.value)}
        size="small"
        fullWidth
      />
    </Grid>
  );
}
