import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box, Button, Container, Typography } from '@mui/material';
import {
  DataGrid,
  GridRowSelectionModel,
  GridSortModel,
} from '@mui/x-data-grid';
import { orderApiService } from '../../api/order';
import { handleApi } from '../../common/http_util';
import { MobilePlanOrder, OrderStatus } from '../../entity/order';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import { useLoading } from '../../context/LoadingContext';
import { useOptions } from '../../context/OptionsContext';
import OrderSearchSection from '../OrderListPage/OrderSearchSection';
import ExcelDownloadPopup from '../OrderListPage/ExcelDownloadPopup';
import { OrderSelfListColumns } from './GridHeader';
import pbl from '../../pbl/pbl-service/pbl-service';
import {
  pblResetSearchOrderFilter,
  pblSearchOrderFilter,
} from '../../pbl/pbl-common-service/pbl-search-order-filter';
import { pblDatagridCheckbox } from '../../pbl/pbl-common-service/pbl-datagrid-checkbox';
import { pblDatagridPagination } from '../../pbl/pbl-common-service/pbl-datagrid-pagination';
import { DEFAULT_DATE_FOR_SEARCH } from '../OrderListPage';
import { usePermission } from '../../router/usePermission';

const rows: Array<MobilePlanOrder> = [];

export default function OrderSelfListPage() {
  const { isExcelPermission } = usePermission();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { setLoading } = useLoading();

  const [orders, setOrders] = useState<Array<MobilePlanOrder>>(rows);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(25);
  const [sortModel, setSortModel] = useState<GridSortModel>([]);

  const [dateStandard, setDateStandard] = useState<string>('createdAt');
  const [startDate, setStartDate] = useState<DateTime | undefined | null>(
    DateTime.fromISO(DEFAULT_DATE_FOR_SEARCH.from ?? ''),
  );
  const [endDate, setEndDate] = useState<DateTime | undefined | null>(
    DateTime.fromISO(DEFAULT_DATE_FOR_SEARCH.to ?? ''),
  );
  const [customerName, setCustomerName] = useState<string>('');
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState<string>('');
  const [planName, setPlanName] = useState<string>('');
  const [mno, setMno] = useState<string>('');
  const [status, setStatus] = useState<OrderStatus | null>(null);
  const [birthday, setBirthday] = useState<string>('');
  const [registerType, setRegisterType] = useState<string>('');
  const [isChangePlanRequest, setIsChangePlanRequest] =
    useState<boolean>(false);
  const [memo, setMemo] = useState<string>();
  const [hasUsim, setHasUsim] = useState<string>('');
  const [isEsim, setIsEsim] = useState<string>();
  const [willPurchaseUsim, setWillPurchaseUsim] = useState<string>();

  const [selectedIds, setSelectedIds] = useState<GridRowSelectionModel>([]);

  const { adminUser, statusList } = useOptions();

  const columnVisibilityModel = useMemo(() => {
    const showDeliveryStatus =
      adminUser?.authorizedMvnos?.includes('all') === true;

    return {
      deliveryStatus: showDeliveryStatus,
    };
  }, [adminUser]);

  const filterData = useMemo(() => {
    const startDateParam = searchParams.get('startDate');
    let startDate;
    if (startDateParam != null) {
      startDate = DateTime.fromISO(startDateParam);
    }

    const endDateParam = searchParams.get('endDate');
    let endDate;
    if (endDateParam != null) {
      endDate = DateTime.fromISO(endDateParam);
    }

    return {
      customerName: searchParams.get('customerName'),
      customerPhoneNumber: searchParams
        .get('customerPhoneNumber')
        ?.replaceAll('-', ''),
      planName: searchParams.get('planName'),
      mno: searchParams.get('mno'),
      dateStandard: searchParams.get('dateStandard'),
      orderDateTimeFrom: startDate?.toISODate() ?? DEFAULT_DATE_FOR_SEARCH.from,
      orderDateTimeTo: endDate?.toISODate() ?? DEFAULT_DATE_FOR_SEARCH.from,
      birthday: searchParams.get('birthday') || undefined,
      status: 'SELF_COMPLETE', // 셀프개통 완료 건만 나온다
      registerType: searchParams.get('registerType'),
      hasUsim: searchParams.get('hasUsim'),
      isEsim: searchParams.get('isEsim'),
      willPurchaseUsim: searchParams.get('willPurchaseUsim'),
      // TODO willPurchase 보기
      memo: searchParams.get('memo'),
      orderType: 'self',
    };
  }, [searchParams]);

  const fetchOrders = useCallback(async () => {
    setLoading(true);

    let page = 0;
    const pageParams = parseInt(searchParams.get('page') ?? '');
    if (!isNaN(pageParams)) page = pageParams;

    let pageSize = 25;
    const pageSizeParams = parseInt(searchParams.get('pageSize') ?? '');
    if (!isNaN(pageSizeParams)) pageSize = pageSizeParams;

    const sortField = searchParams.get('sortField') ?? 'createdAt';
    const sortDirection = searchParams.get('sortDirection');
    let sort: 'asc' | 'desc' | null = 'desc';
    if (sortDirection === 'asc' || sortDirection === 'desc') {
      sort = sortDirection;
    }

    const orderList = await handleApi(
      () =>
        orderApiService.getList(
          filterData,
          page,
          pageSize,
          sortField,
          sort ?? undefined,
        ),
      navigate,
    );

    setLoading(false);

    if (!orderList) return;

    setTotalCount(orderList.totalCount);
    setOrders(orderList.list);
  }, [searchParams, filterData]);

  useEffect(() => {
    if (!totalCount) return;
    pbl('pageview', 'none', 'orders_self_complete', {
      eventProperties: {
        total_count: totalCount,
      },
    });
  }, [totalCount]);

  useEffect(() => {
    fetchOrders();
  }, [searchParams]);

  useEffect(() => {
    const startDateParam = searchParams.get('startDate');
    if (startDateParam != null) setStartDate(DateTime.fromISO(startDateParam));

    const endDateParam = searchParams.get('endDate');
    if (endDateParam != null) setEndDate(DateTime.fromISO(endDateParam));

    setDateStandard(searchParams.get('dateStandard') ?? 'createdAt');
    setCustomerName(searchParams.get('customerName') ?? '');
    setCustomerPhoneNumber(
      searchParams.get('customerPhoneNumber')?.replaceAll('-', '') ?? '',
    );
    setPlanName(searchParams.get('planName') ?? '');
    setMno(searchParams.get('mno') ?? '');
    setRegisterType(searchParams.get('registerType') ?? '');
    setBirthday(searchParams.get('birthday') ?? '');
    setHasUsim(searchParams.get('hasUsim') ?? '');
    setIsEsim(searchParams.get('isEsim') ?? undefined);
    setWillPurchaseUsim(searchParams.get('willPurchaseUsim') ?? undefined);
    setMemo(searchParams.get('memo') ?? '');
    setIsChangePlanRequest(searchParams.get('isChangePlanRequest') === 'true');

    const status = searchParams.get('status');
    if (status) {
      const newStatus = statusList.find((s: OrderStatus) => s.key === status);
      if (newStatus) {
        setStatus(newStatus);
      }
    }

    const pageParams = searchParams.get('page');
    const page = parseInt(pageParams ?? '');
    if (!isNaN(page)) {
      setPage(page);
    }

    const pageSizeParams = parseInt(searchParams.get('pageSize') ?? '');
    if (!isNaN(pageSizeParams)) {
      setPageSize(pageSizeParams);
    }

    const sortField = searchParams.get('sortField');
    const sortDirection = searchParams.get('sortDirection');
    if (sortField) {
      let sort: 'asc' | 'desc' | null = null;
      if (sortDirection === 'asc' || sortDirection === 'desc')
        sort = sortDirection;
      setSortModel([{ field: sortField, sort }]);
    }
  }, [searchParams, statusList]);

  const updateSearchParams = (data: {
    [key: string]: string | undefined | null;
  }) => {
    for (const [key, value] of Object.entries(data)) {
      if (value == null) {
        searchParams.delete(key);
      } else {
        searchParams.set(key, value);
      }
    }

    setSearchParams(searchParams);
  };

  const onClickSearch = () => {
    const searchData = {
      startDate: startDate?.toSQLDate(),
      endDate: endDate?.toSQLDate(),
      dateStandard,
      customerName,
      customerPhoneNumber,
      planName,
      mno,
      hasUsim,
      willPurchaseUsim,
      birthday,
      isEsim,
      status: status?.key,
      registerType,
      memo,
      isChangePlanRequest: isChangePlanRequest?.toString(),
    };
    updateSearchParams(searchData);
    pblSearchOrderFilter(searchData);
  };

  const onClickReset = () => {
    navigate(window.location.pathname);
    setDateStandard('createdAt');
    setStartDate(null);
    setEndDate(null);
    setCustomerName('');
    setCustomerPhoneNumber('');
    setPlanName('');
    setMno('');
    setStatus(null);
    setHasUsim('');
    setIsEsim(undefined);
    setWillPurchaseUsim(undefined);
    setRegisterType('');
    setBirthday('');
    setMemo('');
    setIsChangePlanRequest(false);
    pblResetSearchOrderFilter();
  };

  const onStartDateChange = (date: DateTime | null | undefined) => {
    if (date == null) return;

    setStartDate(date);
  };

  const onEndDateChange = (date: DateTime | null | undefined) => {
    if (date == null) return;
    setEndDate(date);
  };

  const onCustomerPhoneNumberChange = (phoneNumber: string) => {
    setCustomerPhoneNumber(phoneNumber.replace('-', ''));
  };

  const onPageChange = (page: number) => {
    updateSearchParams({ page: page.toString() });
  };

  const onPageSizeChange = (pageSize: number) => {
    updateSearchParams({ pageSize: pageSize.toString() });
  };

  const onIsChangePlanRequestChange = (data: boolean | null) => {
    if (data == null) return;
    setIsChangePlanRequest(data);
  };

  const onSortModelChange = (newModel: GridSortModel) => {
    if (newModel.length <= 0) return;

    let field = newModel[0].field;
    if (field === 'isDuplicate') {
      field = 'duplicate';
    }

    updateSearchParams({
      sortField: field,
      sortDirection: newModel[0].sort?.toString(),
    });
  };

  const [showExcelDownloadPopup, setShowExcelDownloadPopup] = useState(false);

  const onClickExcelDownload = () => {
    setShowExcelDownloadPopup(true);
  };

  return (
    <>
      <Container sx={{ display: 'grid', mt: 3 }} maxWidth="xl">
        <Typography variant="h5" fontWeight={'bold'}>
          셀프개통 완료
        </Typography>
        <OrderSearchSection
          dateStandard={dateStandard}
          onDateStandardChanged={setDateStandard}
          startDate={startDate}
          onStartDateChanged={onStartDateChange}
          endDate={endDate}
          onEndDateChanged={onEndDateChange}
          customerName={customerName}
          onCustomerNameChanged={setCustomerName}
          customerPhoneNumber={customerPhoneNumber}
          onCustomerPhoneNumberChanged={onCustomerPhoneNumberChange}
          planName={planName}
          onPlanNameChanged={setPlanName}
          mno={mno}
          onMnoChanged={setMno}
          status={status}
          onStatusChanged={setStatus}
          statusList={statusList.filter((s) => s.orderType === 'self')}
          registerType={registerType}
          onRegisterTypeChanged={setRegisterType}
          onHasUsimChanged={(value) => {
            console.log('value :', value);
            if (value === 'eSIM') {
              setIsEsim('true');
              setWillPurchaseUsim(undefined);
              setHasUsim('');
            } else if (value === 'willPurchaseUsim') {
              setIsEsim(undefined);
              setWillPurchaseUsim('true');
              setHasUsim('');
            } else if (value === 'true') {
              setIsEsim(undefined);
              setWillPurchaseUsim(undefined);
              setHasUsim('true');
            } else if (value === 'false') {
              setIsEsim('false');
              setWillPurchaseUsim('false');
              setHasUsim('false');
            } else {
              setIsEsim(undefined);
              setWillPurchaseUsim(undefined);
              setHasUsim('');
            }
          }}
          hasUsim={hasUsim}
          isEsim={isEsim}
          willPurchaseUsim={willPurchaseUsim}
          birthday={birthday}
          onBirthdayChanged={setBirthday}
          memo={memo}
          isChangePlanRequest={isChangePlanRequest}
          onMemoChanged={setMemo}
          onSearch={onClickSearch}
          onIsChangePlanRequestChange={onIsChangePlanRequestChange}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mt: 1,
            mb: 1,
          }}
        >
          <Button onClick={onClickSearch} variant="contained" sx={{ mr: 1 }}>
            검색
          </Button>
          <Button onClick={onClickReset} variant="outlined">
            검색 초기화
          </Button>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h5" mt={3}>
            셀프 개통 접수 리스트
          </Typography>
          {isExcelPermission && (
            <Box
              sx={{
                display: 'inline-block',
              }}
            >
              <Button onClick={onClickExcelDownload}>엑셀로 추출하기</Button>
            </Box>
          )}
        </Box>
        <DataGrid
          sx={{
            mt: 3,
            '& .row-duplicate': {
              bgcolor: '#fff5f5',
            },
            '& .MuiDataGrid-columnSeparator': {
              visibility: 'visible',
            },
          }}
          rows={orders}
          columns={OrderSelfListColumns}
          autoHeight
          rowCount={totalCount}
          pagination
          paginationMode="server"
          paginationModel={{ page, pageSize }}
          onPaginationModelChange={(newModel) => {
            pblDatagridPagination(newModel, page, pageSize, {
              object: {
                section: 'order_list',
              },
            });
            onPageChange(newModel.page);
            onPageSizeChange(newModel.pageSize);
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={onSortModelChange}
          checkboxSelection
          onRowSelectionModelChange={(newSelectionModel) => {
            pblDatagridCheckbox(selectedIds, newSelectionModel, {
              object: {
                section: 'order_list',
              },
            });
            setSelectedIds(newSelectionModel);
          }}
          rowSelectionModel={selectedIds}
          columnVisibilityModel={columnVisibilityModel}
        />
      </Container>
      <ExcelDownloadPopup
        filterData={filterData}
        open={showExcelDownloadPopup}
        onClose={() => setShowExcelDownloadPopup(false)}
        totalCount={totalCount}
      />
    </>
  );
}
