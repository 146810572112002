import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  pblResetSearchOrderFilter,
  pblSearchInternetConsultOrderFilter,
} from '../../../pbl/pbl-common-service/pbl-search-order-filter';
import pbl from '../../../pbl/pbl-service/pbl-service';
import {
  useSearchPagination,
  useSearchParamsData,
  useSearchSection,
} from '../../SearchData/hooks';

const useInternetConsultSearch = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { searchState, searchHandler } = useSearchSection();
  const { paginationHandler } = useSearchPagination();
  const { updateSearchParams } = useSearchParamsData();

  const handleClickSearch = () => {
    const searchSectionData = searchHandler.getSearchParamsData();
    updateSearchParams(searchSectionData);
    pblSearchInternetConsultOrderFilter(searchSectionData);
  };

  const handleClickReset = () => {
    navigate(`${window.location.pathname}?version=${Date.now()}`);
    searchHandler.resetSearchData();
    paginationHandler.resetPaginationData();
    pblResetSearchOrderFilter();
  };

  return {
    handleClickSearch,
    handleClickReset,
    searchParams,
    searchState,
    searchHandler,
    paginationHandler,
  };
};

export default useInternetConsultSearch;
