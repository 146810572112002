import { ReactNode } from 'react';
import ReactDOM from 'react-dom';

class PortalRender {
  private _body: HTMLElement;
  private _container: HTMLDivElement;
  private _prevOverflow = '';
  private _isRendered = false;

  constructor() {
    this._body = document.body;
    this._container = document.createElement('div');
    this._container.classList.add('root-portal-render');
  }

  private dispose = () => {
    this._body.style.overflow = this._prevOverflow;
    if (this._isRendered) {
      this._body.removeChild(this._container);
      this._isRendered = false;
    }
  };

  render = (element: ReactNode, preventScroll?: boolean) => {
    if (preventScroll) {
      this._prevOverflow = this._body.style.overflow;
      this._body.style.overflow = 'hidden';
    }
    this._isRendered = true;
    const root = ReactDOM.createPortal(element, this._container);
    this._body.appendChild(this._container);
    ReactDOM.render(root, this._container);

    return () => {
      ReactDOM.unmountComponentAtNode(this._container);
      this.dispose();
    };
  };
}

export default PortalRender;
