export enum MobilePlanOperatorId {
  슈가모바일 = 1,
  KG모바일 = 2,
  KT엠모바일 = 3,
  LG_U_PLUS = 4,
  LG헬로모바일 = 5,
  SKT = 6,
  SK7모바일 = 7,
  고고모바일 = 8,
  아이플러스유 = 9,
  드림라인_모바일 = 10,
  마블링 = 11,
  마이월드 = 12,
  쉐이크모바일 = 13,
  U_PLUS_유모바일 = 14,
  사람과연결 = 15,
  서경모바일 = 16,
  스노우맨 = 17,
  스마텔 = 18,
  KT스카이라이프 = 19,
  핀다이렉트 = 20,
  아이즈모바일 = 21,
  앤텔레콤 = 22,
  에이모바일 = 23,
  에르엘 = 24,
  에스원안심모바일 = 25,
  아시아모바일 = 26,
  오파스모바일 = 27,
  여유알뜰모바일 = 28,
  위너스텔 = 29,
  모빙 = 30,
  인스모바일 = 31,
  KCTV알뜰폰 = 32,
  이지모바일 = 33,
  이야기모바일 = 34,
  토스모바일 = 35,
  포인트플러스모바일 = 36,
  프리티 = 37,
  티플러스 = 38,
  밸류컴 = 39,
  한패스모바일 = 40,
  셀모바일 = 41,
  kcn휴대폰 = 42,
  M_FUN = 43,
  푸른방송 = 44,
  JCN알뜰폰 = 45,
  EMART_24_모바일 = 46,
  대성홀딩스 = 47,
  메리큐 = 48,
  파인드라이브 = 49,
  에스로밍 = 50,
  KT파워텔 = 51,
  플래시모바일 = 52,
  이비카드 = 53,
  리브엠 = 56,
  조이텔 = 57,
  엔페이넷 = 59,
  엔티온텔레콤 = 60,
  원텔레콤 = 61,
  온국민폰 = 62,
  코인샷모바일 = 63,
  퍼스트모바일 = 64,
  니즈텔레콤 = 65,
  모바일_아시아 = 66,
  장성모바일 = 67,
  W_MVNO = 68,
  모나 = 69,
  KT = 70,
  찬스모바일 = 71,
}
