import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { TextField } from '@mui/material';
import {
  UsimInfo,
  UsimInfoDetails,
} from '../../../types/mobile-plan-operator-admin-types';
import { Mno } from '../../../../../entity/mno';
import NumericFormatComponent from '../../../../../shared/components/custom-mui/NumericFormatComponent';

interface Props {
  mno: Mno;
  deliveryFeeKey: keyof Pick<
    UsimInfoDetails,
    'usimDeliveryFee' | 'nfcUsimDeliveryFee' | 'esimFee'
  >;
  defaultValue: number | null;
}

export const MvnoInfoDeliveryFee: FC<Props> = ({
  mno,
  deliveryFeeKey,
  defaultValue,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<UsimInfo>();

  const registerKey = `${mno}.${deliveryFeeKey}` as const;

  return (
    <>
      <Controller
        control={control}
        name={registerKey}
        defaultValue={defaultValue}
        rules={{
          required: '유심비용을 입력해 주세요.',
          min: {
            value: 1,
            message: '1원 이상 입력해 주세요.',
          },
          max: {
            value: 100000,
            message: '10만원 이하로 설정해 주세요.',
          },
        }}
        render={({ field }) => (
          <TextField
            placeholder="유심비용 - 숫자만 입력"
            {...field}
            inputMode="numeric"
            error={!!errors[mno]?.[deliveryFeeKey]?.message}
            helperText={errors[mno]?.[deliveryFeeKey]?.message}
            InputProps={{
              endAdornment: '원',
              inputComponent: NumericFormatComponent,
            }}
          />
        )}
      />
    </>
  );
};
