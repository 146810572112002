import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { usePortal, usePortalController } from '../../common/usePortal';
import { ThemeControlProvider } from '../../context/ThemeControlContext';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReleaseNotes, {
  ReleaseNoteItem,
  ReleaseStatus,
} from '../../static/releaseNotes';

const ReleaseNoteController = (() => {
  const VIEW_RELEASE_NOTE_VERSION_LIST = 'view_release_note_version_list';
  let viewReleaseNoteVersionListCache: string[] | undefined;

  const _updateViewReleaseNoteVersionListCache = () => {
    const viewReleaseNoteVersionListStr = localStorage.getItem(
      VIEW_RELEASE_NOTE_VERSION_LIST,
    );
    if (!viewReleaseNoteVersionListStr) return;
    try {
      viewReleaseNoteVersionListCache = JSON.parse(
        viewReleaseNoteVersionListStr,
      );
    } catch (_: any) {
      viewReleaseNoteVersionListCache = [];
    }
  };

  const _getViewReleaseNoteVersionList = (): string[] | undefined => {
    if (!viewReleaseNoteVersionListCache) {
      _updateViewReleaseNoteVersionListCache();
    }
    return viewReleaseNoteVersionListCache;
  };

  const _setViewReleaseNoteVersionList = (versionList: string[]) => {
    try {
      localStorage.setItem(
        VIEW_RELEASE_NOTE_VERSION_LIST,
        JSON.stringify(Array.from(new Set(versionList))),
      );
      _updateViewReleaseNoteVersionListCache();
    } catch (error) {
      console.log('storage error- _setViewReleaseNoteVersionList', error);
    }
  };

  const initViewReleaseNoteVersionList = () => {
    const viewReleaseNoteVersionList = _getViewReleaseNoteVersionList();
    if (!viewReleaseNoteVersionList) {
      _setViewReleaseNoteVersionList([]);
    }
  };

  const addViewReleaseNoteVersion = (version: string) => {
    const viewReleaseNoteVersionList = _getViewReleaseNoteVersionList() || [];
    viewReleaseNoteVersionList.push(version);
    _setViewReleaseNoteVersionList(viewReleaseNoteVersionList);

    return viewReleaseNoteVersionList;
  };

  const getBeforeViewReleaseNoteVersionList = (authorizedMvnos?: string[]) => {
    const viewReleaseNoteVersionList = _getViewReleaseNoteVersionList();
    if (!viewReleaseNoteVersionList) return [];

    const beforeViewReleaseNoteVersionList = getReleaseNoteVersions(
      authorizedMvnos,
    ).filter(
      (releaseNoteVersion) =>
        !viewReleaseNoteVersionList.includes(releaseNoteVersion),
    );

    return beforeViewReleaseNoteVersionList;
  };

  const isNewReleaseNote = (version: string) => {
    const viewReleaseNoteVersionList = _getViewReleaseNoteVersionList();
    return !viewReleaseNoteVersionList?.includes(version);
  };

  const getReleaseNotes = (authorizedMvnos?: string[]) => {
    if (!authorizedMvnos) return [];
    if (authorizedMvnos.includes('all')) return ReleaseNotes;

    return ReleaseNotes.filter((releaseNote) => {
      if (!releaseNote.authorizedMvnos) return true;
      return !!releaseNote.authorizedMvnos.filter((authorizedMvno) =>
        authorizedMvnos.includes(authorizedMvno),
      ).length;
    });
  };

  const getReleaseNoteVersions = (authorizedMvnos?: string[]) => {
    if (!authorizedMvnos) return [];
    return getReleaseNotes(authorizedMvnos).map(
      (releaseNote) => releaseNote.version,
    );
  };

  return {
    initViewReleaseNoteVersionList,
    addViewReleaseNoteVersion,
    getBeforeViewReleaseNoteVersionList,
    isNewReleaseNote,
    getReleaseNotes,
  };
})();

const ReleaseStatusColorMap = {
  [ReleaseStatus.NEW]: 'success',
  [ReleaseStatus.FEATURE]: 'info',
  [ReleaseStatus.CHANGE]: 'secondary',
  [ReleaseStatus.FIXED]: 'warning',
  [ReleaseStatus.DEPRECATED]: 'error',
} as const;

const ReleaseStatusBadge: FC<{ status: ReleaseStatus }> = ({ status }) => {
  return (
    <Chip
      style={{ width: 112 }}
      size="small"
      variant="outlined"
      color={ReleaseStatusColorMap[status]}
      label={status}
    />
  );
};

const ReleaseAccordion: FC<{
  releaseNote: ReleaseNoteItem;
  isNew?: boolean;
}> = ({ releaseNote, isNew: _isNew }) => {
  const [isNew, setIsNew] = useState(_isNew);

  const onChangeAccordion = useCallback(() => {
    if (isNew) {
      setIsNew(false);
      ReleaseNoteController.addViewReleaseNoteVersion(releaseNote.version);
    }
  }, [isNew]);

  return (
    <Accordion onChange={onChangeAccordion}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography fontWeight={700} display="inline-block" marginRight={1}>
          {releaseNote.title}
        </Typography>
        <Typography fontWeight={400} display="inline-block" marginRight={1}>
          v.{releaseNote.version}
        </Typography>
        <Typography
          variant="body2"
          fontWeight={500}
          paddingTop="1px"
          display="inline-block"
          marginRight={1}
        >
          ({releaseNote.date})
        </Typography>
        {isNew && <Chip size="small" color="success" label="NEW" />}
      </AccordionSummary>
      <AccordionDetails>
        {releaseNote.details.map((detail) => (
          <Box marginBottom={2}>
            {releaseNote.details.length > 1 && (
              <Typography variant="subtitle1" fontWeight={700} marginBottom={1}>
                {detail.title}
              </Typography>
            )}
            {detail.contents.map((content) => (
              <Box
                display="flex"
                alignItems="flex-start"
                gap="8px"
                marginBottom="8px"
              >
                <ReleaseStatusBadge status={content.status} />
                <Typography
                  variant="subtitle2"
                  marginTop={'2px'}
                  whiteSpace="pre-wrap"
                >
                  {content.content}
                </Typography>
              </Box>
            ))}
          </Box>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

const ReleaseModal: FC<{ authorizedMvnos?: string[] }> = ({
  authorizedMvnos,
}) => {
  const { close, dismiss } = usePortalController();
  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (open) return;
    onCloseDialogHandler();
  }, [open]);

  const onCloseHandler = () => {
    setOpen(false);
    close(false);
  };

  const onCloseDialogHandler = () => {
    setTimeout(() => {
      dismiss();
    }, 500);
  };

  return (
    <Dialog open={open} fullWidth onClose={onCloseHandler} closeAfterTransition>
      <DialogTitle fontWeight="bold">Release Notes</DialogTitle>
      <DialogContent>
        {ReleaseNoteController.getReleaseNotes(authorizedMvnos).map(
          (releaseNote) => (
            <ReleaseAccordion
              isNew={ReleaseNoteController.isNewReleaseNote(
                releaseNote.version,
              )}
              releaseNote={releaseNote}
            />
          ),
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseHandler}>확인</Button>
      </DialogActions>
    </Dialog>
  );
};

const useReleaseModal = (authorizedMvnos?: string[]) => {
  const portal = usePortal<boolean>();
  const [newUpdate, setNewUpdate] = useState(0);

  useEffect(() => {
    ReleaseNoteController.initViewReleaseNoteVersionList();
  }, []);

  useEffect(() => {
    syncNewUpdate();
  }, [authorizedMvnos]);

  const syncNewUpdate = () => {
    const beforeViewReleaseNoteVersionList =
      ReleaseNoteController.getBeforeViewReleaseNoteVersionList(
        authorizedMvnos,
      );
    setNewUpdate(beforeViewReleaseNoteVersionList.length);
  };

  const open = () => {
    return portal
      .open(
        <ThemeControlProvider>
          <ReleaseModal authorizedMvnos={authorizedMvnos} />
        </ThemeControlProvider>,
      )
      .then(syncNewUpdate);
  };

  return { open, newUpdate };
};

export default useReleaseModal;
