import { sendPBL } from '../pbl-api-service';
import createPblService, { IBehaviorLogOptions } from './create-pbl-service';
import { PblCategoryTypes } from '../pbl-types/pbl-category-types';
import { PblObjectTypes } from '../pbl-types/pbl-object-types';
import { PblNavigationTypes } from '../pbl-types/pbl-navigation-types';
import { sendPblToEventTracker } from './pbl-event-tracker';

const pbl = (
  category: PblCategoryTypes,
  objectType: PblObjectTypes,
  navigation: PblNavigationTypes,
  options?: IBehaviorLogOptions,
) => {
  const pblData = createPblService(category, objectType, navigation, options);

  sendPblToEventTracker(pblData);
  sendPBL(pblData);
};

export default pbl;
