import { CSSProperties, FC } from 'react';
import { TableCell } from '@mui/material';

interface Props {
  value: string | undefined;
  suffix: string;
}

const cellWidth = '12.5%';

const styles: Record<string, CSSProperties> = {
  error: {
    background: '#FDEDED',
    color: 'error.main',
  },
};

export const FallbackValueTableCell: FC<Props> = ({ value, suffix }) => {
  const isValueUndefined = value === undefined;

  return (
    <TableCell
      sx={{
        width: cellWidth,
        ...(isValueUndefined && styles.error),
      }}
    >
      {isValueUndefined ? '입력 필요' : value.concat(suffix)}
    </TableCell>
  );
};
