import {
  Box,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { DateTime } from 'luxon';
import { InternetOrderStatus } from '../../entity/internet-order';
import { DatePicker } from '@mui/x-date-pickers';

type OrderSearchProps = {
  dateStandard?: string;
  onDateStandardChanged: (s: string) => void;
  startDate: DateTime | undefined | null;
  onStartDateChanged: (date: DateTime | undefined | null) => void;
  endDate: DateTime | undefined | null;
  onEndDateChanged: (date: DateTime | undefined | null) => void;
  customerName?: string;
  onCustomerNameChanged: (name: string) => void;
  customerPhoneNumber?: string;
  onCustomerPhoneNumberChanged: (number: string) => void;
  planName?: string;
  onPlanNameChanged: (name: string) => void;
  mno?: string;
  onMnoChanged: (name: string) => void;
  status: InternetOrderStatus | null;
  onStatusChanged: (status: InternetOrderStatus | null) => void;
  statusList: Array<InternetOrderStatus>;
  onSearch?: () => void;
};

export default function InternetOrderSearchSection({
  dateStandard,
  onDateStandardChanged,
  startDate,
  onStartDateChanged,
  endDate,
  onEndDateChanged,
  customerName,
  onCustomerNameChanged,
  customerPhoneNumber,
  onCustomerPhoneNumberChanged,
  planName,
  onPlanNameChanged,
  mno,
  onMnoChanged,
  status,
  onStatusChanged,
  statusList,
  onSearch,
}: OrderSearchProps) {
  const onStatusCheck = (value: InternetOrderStatus | string) => {
    const newStatus = statusList.find(
      (s: InternetOrderStatus) => s.key === value,
    );
    onStatusChanged(newStatus ?? null);
  };

  const onSubmit = (e: any) => {
    e.preventDefault();

    if (onSearch) onSearch();
  };

  return (
    <Box>
      <form onSubmit={(e) => onSubmit(e)}>
        <button type="submit" hidden />
        <Grid container rowSpacing={2} columnSpacing={6} mt={2}>
          <Grid item xs={12} md={6}>
            <Box>
              <Typography mb={1}>날짜</Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Select
                  size="small"
                  sx={{
                    marginRight: '1rem',
                  }}
                  defaultValue="createdAt"
                  value={dateStandard}
                  onChange={(e) => onDateStandardChanged(e.target.value)}
                >
                  <MenuItem value="createdAt">신청일</MenuItem>
                  <MenuItem value="completedAt">개통일</MenuItem>
                </Select>
                <div
                  style={{
                    display: 'inline-block',
                    marginRight: '1rem',
                  }}
                >
                  <DatePicker
                    label="시작일"
                    value={startDate}
                    onChange={(newValue) => {
                      onStartDateChanged(newValue);
                    }}
                    slotProps={{ textField: { size: 'small' } }}
                  />
                </div>
                <DatePicker
                  label="종료일"
                  value={endDate}
                  onChange={(newValue) => {
                    onEndDateChanged(newValue);
                  }}
                  slotProps={{ textField: { size: 'small' } }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography mb={1}>고객 이름</Typography>
            <TextField
              label="고객 성함"
              value={customerName}
              onChange={(e) => onCustomerNameChanged(e.target.value)}
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography mb={1}>전화번호</Typography>
            <TextField
              label=" - 없이 전화번호를 입력해주세요 ( ex: 01012345678 )"
              value={customerPhoneNumber}
              onChange={(e) => onCustomerPhoneNumberChanged(e.target.value)}
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography mb={1}>요금제 이름</Typography>
            <TextField
              label="요금제 이름"
              value={planName}
              onChange={(e) => onPlanNameChanged(e.target.value)}
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography mb={1}>상태값</Typography>
            <Select
              fullWidth
              defaultValue="all"
              value={status?.key ?? 'all'}
              onChange={(e) => onStatusCheck(e.target.value)}
              size="small"
            >
              <MenuItem key={'all'} value="all">
                전체
              </MenuItem>
              {statusList.map((status) => (
                <MenuItem key={status.key} value={status.key}>
                  {status.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography mb={1}>통신망</Typography>
            <Select
              fullWidth
              defaultValue=""
              value={mno}
              onChange={(e) => onMnoChanged(e.target.value)}
              size="small"
              displayEmpty
            >
              <MenuItem key={'all'} value="">
                전체
              </MenuItem>
              <MenuItem key={'sk'} value="SKT">
                SKT망
              </MenuItem>
              <MenuItem key={'kt'} value="KT">
                KT망
              </MenuItem>
              <MenuItem key={'lg'} value="LGU">
                LG망
              </MenuItem>
            </Select>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}
