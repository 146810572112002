import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { CreateMoyoPlanTypes } from '../type';
import { FormControl, Grid, TextField, Typography } from '@mui/material';

export const PartnersInfo: FC = () => {
  const { control, register } = useFormContext<CreateMoyoPlanTypes>();
  return (
    <Grid item xs={12} md={6}>
      <Typography variant="h6" my={2} mt={'30px'}>
        파트너스 링크
      </Typography>
      <Grid display={'flex'} gap={'64px'}>
        <FormControl fullWidth>
          <Controller
            control={control}
            name="partnerSignupUrl"
            render={({ field }) => (
              <TextField
                required
                {...field}
                fullWidth
                type="string"
                label={'가입 신청 링크'}
                variant="outlined"
              />
            )}
          />
        </FormControl>
        <FormControl fullWidth>
          <Controller
            control={control}
            name="code"
            render={({ field }) => (
              <TextField
                required
                {...field}
                fullWidth
                type="string"
                label="요금제 코드"
                variant="outlined"
              />
            )}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};
