import { Box, TextField, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { MobilePlanOrder } from '../../../entity/order';

interface OrderInformationProps {
  order?: MobilePlanOrder;
}

export function OrderInformation({ order }: OrderInformationProps) {
  const { register } = useFormContext<MobilePlanOrder>();

  if (!order) {
    return <></>;
  }

  return (
    <>
      <Typography variant="h6" my={2}>
        접수 정보
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 1,
        }}
      >
        <TextField
          label="개통번호"
          {...register('orderPhoneNumber')}
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label="접수번호"
          {...register('partnerOrderId')}
          size="small"
          InputLabelProps={{ shrink: true }}
        />
      </Box>
    </>
  );
}
