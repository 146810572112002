import { FC } from 'react';
import SwitchEditableMvnoInfo from '../../SwitchEditableMvnoInfo/SwitchEditableMvnoInfo';
import MvnoInfoUsimSupportDetail from './MvnoInfoUsimSupportDetail';
import MvnoInfoUsimSupportEdit from './MvnoInfoUsimSupportEdit';
import { UsimInfo } from '../../types/mobile-plan-operator-admin-types';
import mvnoInfoApiService, {
  MvnoInfoPutFunction,
} from '../../api/mvno-info-api-service';
import { useMobilePlanOperatorAdmin } from '../../hooks/useMobilePlanOperatorAdmin';
import { useRole } from '../../../../router/useRole';

interface Props {
  usimInfo: UsimInfo;
  mobilePlanOperatorId: number;
}

const MvnoInfoUsimSupport: FC<Props> = ({ usimInfo, mobilePlanOperatorId }) => {
  const { update } = useMobilePlanOperatorAdmin(mobilePlanOperatorId);
  const { isMoyoRole } = useRole();

  const put: MvnoInfoPutFunction<UsimInfo> = async (data: UsimInfo) => {
    const response = await mvnoInfoApiService.putUsimInfo(
      mobilePlanOperatorId,
      data,
    );
    if (response.data.result) {
      update({
        usimInfoList: response.data.result,
      });
    }
    return response;
  };

  const enableEdit = isMoyoRole;

  return (
    <SwitchEditableMvnoInfo
      title="망별 유심 지원"
      subTitle="수정은 모요에 요청해 주세요"
      detail={<MvnoInfoUsimSupportDetail {...usimInfo} />}
      edit={<MvnoInfoUsimSupportEdit {...usimInfo} />}
      put={put}
      enableEdit={enableEdit}
    />
  );
};

export default MvnoInfoUsimSupport;
