import {
  Button,
  colors,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useCallback, useEffect } from 'react';
import { IBehaviorLogOptions } from '../../../pbl/pbl-service/create-pbl-service';
import pbl from '../../../pbl/pbl-service/pbl-service';

type PopupProps = {
  loading: boolean;
  logParams: IBehaviorLogOptions;
  orderCountMoyoIsHandling: number;
  onClickSubmitAll: () => void;
  onClickExcludeOrdersMoyoIsHandling: () => void;
};

export function CheckOrdersMoyoIsHandling({
  logParams,
  loading: isLoading,
  orderCountMoyoIsHandling,
  onClickSubmitAll,
  onClickExcludeOrdersMoyoIsHandling,
}: PopupProps) {
  useEffect(() => {
    pbl('impression', 'modal', 'here', {
      object: {
        id: 'change_bulk_status_you_have_orders_moyo_is_handling',
        section: 'action_menu',
      },
      eventProperties: {
        ...logParams,
      },
    });
  }, []);

  const handleClickSubmitAll = useCallback(async () => {
    pbl('click', 'modal_button', 'here', {
      object: {
        id: 'change_bulk_status_submit_all',
        section: 'action_menu',
      },
      eventProperties: {
        ...logParams,
      },
    });

    onClickSubmitAll();
  }, [logParams, onClickSubmitAll]);

  const handleClickExcludeOrdersMoyoIsHandling = useCallback(async () => {
    pbl('click', 'modal_button', 'here', {
      object: {
        id: 'change_bulk_status_exclude_orders_moyo_is_handling',
        section: 'action_menu',
      },
      eventProperties: {
        ...logParams,
      },
    });

    onClickExcludeOrdersMoyoIsHandling();
  }, [logParams, onClickExcludeOrdersMoyoIsHandling]);

  return (
    <>
      <img
        src="https://assets.moyoplan.com/image/partners/popup_header_warning.png"
        alt=""
      />
      <DialogTitle>
        상태값을 수정하면 안되는
        <br />
        주문이 {orderCountMoyoIsHandling}건 있어요
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: '16px' }}>
          모요 시스템이 자동 처리할 주문들이에요. 고객님 요청이 있지 않는 한
          수정을 자제해주세요.{' '}
          <a
            href="https://moyoplan.notion.site/21a7d2015eda477cb8aaccb425816705?pvs=4"
            target="_blank"
            rel="noreferrer"
          >
            (더 자세한 설명)
          </a>
        </DialogContentText>
        <img
          src="https://assets.moyoplan.com/image/partners/example_order_status_moyo_is_handling.png"
          alt=""
          style={{ width: '100%' }}
        />
      </DialogContent>
      <DialogActions style={{ marginBottom: '12px', marginRight: '24px' }}>
        <Button
          color="error"
          onClick={handleClickSubmitAll}
          disabled={isLoading}
          style={{ color: colors.grey[400] }}
        >
          그래도 수정
        </Button>
        <Button
          onClick={handleClickExcludeOrdersMoyoIsHandling}
          disabled={isLoading}
        >
          제외하고 나머지만 수정
        </Button>
      </DialogActions>
    </>
  );
}
