import { Grid, MenuItem, Select, Typography } from '@mui/material';

import { OrderStatus } from '../../../entity/order';
import { useSearchSection } from '../hooks';
import { useOptions } from '../../../context/OptionsContext';

export function StatusSection() {
  const { statusList } = useOptions();
  const { searchState, searchHandler } = useSearchSection();

  // TODO : statusList.filter((s) => orderTypes?.includes(s.orderType))
  // status list가 이렇게 동작하는데 이 부분을 반영해주기
  const { status } = searchState;
  const { setStatus } = searchHandler;

  const onStatusCheck = (value: OrderStatus | string) => {
    const newStatus = statusList.find((s: OrderStatus) => s.key === value);
    setStatus(newStatus ?? null);
  };

  return (
    <Grid item xs={12} md={6}>
      <Typography mb={1}>상태값</Typography>
      <Select
        fullWidth
        defaultValue="all"
        value={status?.key ?? 'all'}
        onChange={(event) => onStatusCheck(event.target.value)}
        size="small"
      >
        <MenuItem key={'all'} value="all">
          전체
        </MenuItem>
        {statusList.map((status) => (
          <MenuItem key={status.key} value={status.key}>
            {status.name}
          </MenuItem>
        ))}
      </Select>
    </Grid>
  );
}
