import { FC } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  TableContainer,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { MobilePlanOrder } from '../../../entity/order';
import {
  idRenderCell,
  registerTypeRenderCell,
} from '../../../utils/gridRenderer';
import * as GridPreset from '../../../utils/gridPreset';

// 신청일	종류	가입유형	상태	통신사	통신망
const columns: GridColDef[] = [
  { ...GridPreset.createdAt_신청일, width: 150 },
  GridPreset.status_상태,
  {
    ...GridPreset.status_상태,
    renderCell: idRenderCell('/orders'),
  },
  {
    ...GridPreset.registerType_신청유형,
    width: 80,
  },
  GridPreset.customerPhoneNumber_신청전화번호,
  GridPreset.orderPhoneNumber_개통전화번호,
  { ...GridPreset.mno_통신망, width: 120 },
  { ...GridPreset.planName_요금제, width: 220 },
];

interface BeforeOrderInfoTableProps {
  userOrderList: MobilePlanOrder[];
  open: boolean;
  onClose: () => void;
}

export const BeforeOrderInfoTableDialog: FC<BeforeOrderInfoTableProps> = ({
  userOrderList,
  open,
  onClose,
}) => {
  if (!userOrderList?.length) return <></>;
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>이전 신청 내역</DialogTitle>
      <DialogContent>
        <TableContainer component={Paper} elevation={5}>
          <DataGrid
            sx={{
              '& .MuiDataGrid-columnSeparator': {
                visibility: 'visible',
              },
            }}
            rows={userOrderList}
            columns={columns}
            autoHeight
          />
        </TableContainer>
      </DialogContent>
      <DialogActions sx={{ padding: '16px 24px' }}>
        <Button variant="outlined" onClick={onClose}>
          닫기
        </Button>
      </DialogActions>
    </Dialog>
  );
};
