export const isEmptyString = (str: string | undefined | null) => {
  return str === '' || !str;
};

export const stringToNumber = (
  str: string | undefined | null,
): number | null => {
  return isEmptyString(str) ? null : Number(str);
};

export const stringToBoolean = (
  str: string | undefined | null,
): boolean | null => {
  return isEmptyString(str) ? null : str === 'true';
};
