import pbl from '../pbl-service/pbl-service';
import { PblObjectTypes } from '../pbl-types/pbl-object-types';

const removeSensitiveInformation = (object: object) => {
  const { customerName, birthday, customerPhoneNumber, ...rest } =
    object as Record<string, unknown>;
  return rest;
};

const _pblSearchCommonFilter = (
  searchData: object,
  objectType: PblObjectTypes,
) => {
  const filteredSearchData = removeSensitiveInformation(searchData);
  const appliedFilters = Object.entries(searchData)
    .filter(([_, value]) => !!value)
    .map(([key, _]) => key);
  pbl('search', objectType, 'here', {
    object: {
      section: 'filter',
    },
    eventProperties: {
      ...filteredSearchData,
      appliedFilters,
    },
  });
};

export const pblSearchOrderFilter = (searchData: object) => {
  _pblSearchCommonFilter(searchData, 'order');
};

export const pblSearchInternetOrderFilter = (searchData: object) => {
  _pblSearchCommonFilter(searchData, 'internet_order');
};

export const pblSearchInternetConsultOrderFilter = (searchData: object) => {
  _pblSearchCommonFilter(searchData, 'internet_consult_order');
};

export const pblResetSearchOrderFilter = () => {
  pbl('click', 'button', 'here', {
    object: {
      id: 'search_clear',
      name: '검색 초기화',
      section: 'filter',
    },
  });
};
