import { AxiosResponse } from 'axios';
import { moyoApi } from '../common/http_util';
import { ApiResponse } from '../entity/api';
import { Mno } from '../entity/mno';
import { Mvno } from '../entity/mvno';

export interface MvnoPolicy {
  mvno: Mvno;
  mno: Mno;
  isSupport: boolean;
  isSupportAutoValidation: boolean;
  isSupportAutoActivation: boolean;
  isEsim: boolean;
  isSupportEsimSelfActivation: boolean;
  isBlockNewRegisterType: boolean;
  isSupportPartnersActivation: boolean;
  isDeletePersonalInfo: boolean;
}

export function getMvnoPolicyList(): Promise<
  AxiosResponse<
    ApiResponse<{
      policyList: MvnoPolicy[];
    }>
  >
> {
  return moyoApi.get('/self-activation-support-policy');
}
