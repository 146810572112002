import { Grid, MenuItem, Select, Tooltip, Typography } from '@mui/material';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

import { useSearchSection } from '../hooks';

export function UsimAfterDeliverySection() {
  const { searchState, searchHandler } = useSearchSection();

  const { 유심도착후_진행되지않은일자 } = searchState;
  const { set유심도착후_진행되지않은일자 } = searchHandler;

  return (
    <Grid item xs={12} md={6}>
      <Typography mb={1}>
        유심 배송 완료 후 N일 경과
        <Tooltip
          placement="top"
          title={
            <>
              유심 배송 완료 후 N일이 지나도
              <br /> 개통을 진행하지 않고 있는고객을 검색할 수 있어요.
            </>
          }
        >
          <HelpOutlineOutlinedIcon
            fontSize="small"
            sx={{ verticalAlign: 'text-top', marginLeft: 0.5 }}
          />
        </Tooltip>
      </Typography>
      <Select
        fullWidth
        defaultValue=""
        value={유심도착후_진행되지않은일자 || ''}
        onChange={(event) => set유심도착후_진행되지않은일자(event.target.value)}
        size="small"
        displayEmpty
      >
        <MenuItem key={''} value="">
          없음
        </MenuItem>
        <MenuItem key={2} value={2}>
          2일 이상
        </MenuItem>
        <MenuItem key={1} value={1}>
          1일 이상
        </MenuItem>
      </Select>
    </Grid>
  );
}
