import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { MobilePlanOrderHistory } from '../../../entity/order';
import { dateUtils } from '../../../utils';

interface OrderHistorySectionProps {
  historyList?: MobilePlanOrderHistory[];
}

export function OrderHistoryList({
  historyList = [],
}: OrderHistorySectionProps) {
  return (
    <TableContainer component={Paper} elevation={5} sx={{ mt: 3 }}>
      <Table
        sx={{
          td: {
            backgroundColor: 'transparent',
            fontWeight: 'normal',
          },
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                minWidth: '90px',
              }}
            >
              등록일
            </TableCell>
            <TableCell
              sx={{
                minWidth: '54px',
              }}
            >
              상태
            </TableCell>
            <TableCell
              sx={{
                minWidth: '54px',
              }}
            >
              상세 사유
            </TableCell>
            <TableCell
              sx={{
                minWidth: '38px',
              }}
            >
              상담사
            </TableCell>
            <TableCell>상담 메모</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {historyList.map((history: MobilePlanOrderHistory, index) => {
            const historyDate = dateUtils.dateToLocaleStringSplitDateAndTime(
              history.createdAt,
            );
            return (
              <TableRow key={`history-${index}`}>
                <TableCell>
                  <Typography fontSize={14}>
                    {historyDate?.dateString}
                  </Typography>
                  <Typography fontSize={14}>
                    {historyDate?.timeString}
                  </Typography>
                </TableCell>
                <TableCell>{history.status}</TableCell>
                <TableCell>{history.statusDetail}</TableCell>
                <TableCell>{history.adminUserName}</TableCell>
                <TableCell sx={{ whiteSpace: 'pre-line' }}>
                  {history.memo}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
