import { GridValueFormatterParams } from '@mui/x-data-grid';
import { DateTime, DateTimeFormatOptions } from 'luxon';
import { numberCommaFormatter } from './valueFormatter';

const phoneNumberReplaceRegex = /(\d{3})(\d{4})(\d{4})/;
const phoneNumberReplaceFormat = '$1-$2-$3';

/**
 *
 * @param dateTimeFormat - 기본은 DATETIME_SHORT를 제공함. 필요시 함수 호출할때 파라미터로 전달
 * @returns
 */
export const dateFormatter =
  (dateTimeFormat: DateTimeFormatOptions = DateTime.DATETIME_SHORT) =>
  (params: GridValueFormatterParams) => {
    if (!params.value) return '';

    const millis = params.value as number;
    return DateTime.fromMillis(millis).toLocaleString(dateTimeFormat);
  };

// TODO: 두가지가 같은 케이스로 보이는데 어느쪽에 맞출지 정리하기
export const phoneNumberFormatter = (params: GridValueFormatterParams) =>
  params.value.replace(phoneNumberReplaceRegex, phoneNumberReplaceFormat);

export const orderPhoneNumberFormatter = (params: GridValueFormatterParams) => {
  // @ts-ignore
  if (params.value == null || typeof params.value != 'string') return '';

  return params.value?.replace(
    phoneNumberReplaceRegex,
    phoneNumberReplaceFormat,
  );
};

export const gridNumberFormatter = (params: GridValueFormatterParams) =>
  numberCommaFormatter(params.value);
