import React, { FC } from 'react';
import {
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import {
  ExceedRateList,
  RateDetails,
} from '../../types/mobile-plan-operator-admin-types';
import { useThemeControl } from '../../../../context/ThemeControlContext';
import { comma } from '../../../../common/string_util';
import { convertStringToMno } from '../../../../common/order-util';
import { MvnoInfoItemBody } from '../Common/MvnoInfoItemFrame';
import { FallbackValueTableCell } from './FallbackValueTableCell';

const applyCommaToRateDetails = (rateDetails: RateDetails) => {
  return Object.entries(rateDetails).reduce((acc, [key, value]) => {
    acc[key] = comma(value);
    return acc;
  }, {} as Record<string, string | undefined>);
};

const MvnoInfoExceedRateDetailSection: FC<{
  mno: string;
  rateDetails: RateDetails;
}> = ({ mno, rateDetails }) => {
  const title = convertStringToMno(mno);
  const themeControl = useThemeControl();
  const formattedRateDetails = applyCommaToRateDetails(rateDetails);
  const cellWidth = '12.5%';

  return (
    <MvnoInfoItemBody gap={3}>
      <Box>
        <Typography variant="body1" color="text.primary" fontWeight={700}>
          {title}망
        </Typography>
      </Box>
      <Table>
        <TableHead>
          <TableRow
            sx={{
              background: themeControl.theme.palette.neutral[2],
            }}
          >
            <TableCell sx={{ width: cellWidth }}>데이터</TableCell>
            <TableCell sx={{ width: cellWidth }}>음성</TableCell>
            <TableCell sx={{ width: cellWidth }}>영상통화</TableCell>
            <TableCell sx={{ width: cellWidth }}>SMS</TableCell>
            <TableCell sx={{ width: cellWidth }}>LMS</TableCell>
            <TableCell sx={{ width: cellWidth }}>MMS(문자)</TableCell>
            <TableCell sx={{ width: cellWidth }}>MMS(사진)</TableCell>
            <TableCell sx={{ width: cellWidth }}>MMS(영상)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow hover>
            <FallbackValueTableCell
              value={formattedRateDetails.dataRate}
              suffix={'원/mb'}
            />
            <FallbackValueTableCell
              value={formattedRateDetails.voiceCallRate}
              suffix={'원/초'}
            />
            <FallbackValueTableCell
              value={formattedRateDetails.mvoipCallRate}
              suffix={'원/초'}
            />
            <FallbackValueTableCell
              value={formattedRateDetails.smsRate}
              suffix={'원/건'}
            />
            <FallbackValueTableCell
              value={formattedRateDetails.lmsRate}
              suffix={'원/건'}
            />
            <FallbackValueTableCell
              value={formattedRateDetails.mmsTextRate}
              suffix={'원/건'}
            />
            <FallbackValueTableCell
              value={formattedRateDetails.mmsPictureRate}
              suffix={'원/건'}
            />
            <FallbackValueTableCell
              value={formattedRateDetails.mmsVideoRate}
              suffix={'원/건'}
            />
          </TableRow>
        </TableBody>
      </Table>
    </MvnoInfoItemBody>
  );
};

const MvnoInfoExceedRateDetail: FC<ExceedRateList> = (exceedRate) => {
  return (
    <MvnoInfoItemBody gap={6}>
      {Object.entries(exceedRate).map(([key, rateDetails]) => (
        <MvnoInfoExceedRateDetailSection
          key={key}
          mno={key}
          rateDetails={rateDetails as RateDetails}
        />
      ))}
    </MvnoInfoItemBody>
  );
};

export default MvnoInfoExceedRateDetail;
