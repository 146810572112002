import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import { AutoActivateStates, OrderTypes } from '../types';

interface UseSearchSectionWithParamsProps {
  initStatus?: string;
  autoActivateStates?: AutoActivateStates[];
  orderTypes?: OrderTypes[];
}

const paramsToBoolean = (
  value: string | null | undefined,
): boolean | undefined => {
  if (value == null) return undefined;
  return value === 'true';
};

const paramsToNumber = (
  value: string | null | undefined,
): number | undefined => {
  if (value == null) return undefined;
  return Number(value);
};

export function useSearchParamsData({
  initStatus,
  autoActivateStates,
  orderTypes,
}: UseSearchSectionWithParamsProps = {}) {
  const [searchParams, setSearchParams] = useSearchParams();

  const filterData = useMemo(() => {
    const startDateParam = searchParams.get('startDate');
    let startDate;
    if (startDateParam != null) {
      startDate = DateTime.fromISO(startDateParam);
    }

    const endDateParam = searchParams.get('endDate');
    let endDate;
    if (endDateParam != null) {
      endDate = DateTime.fromISO(endDateParam);
    }

    return {
      customerName: searchParams.get('customerName'),
      customerPhoneNumber: searchParams
        .get('customerPhoneNumber')
        ?.replaceAll('-', ''),
      planName: searchParams.get('planName'),
      mno: searchParams.get('mno'),
      mvno: searchParams.get('mvno'),
      dateStandard: searchParams.get('dateStandard'),
      orderDateTimeFrom: startDate?.toISODate(),
      orderDateTimeTo: endDate?.toISODate(),
      applicationStartedTsFrom: startDate?.toISODate(),
      applicationStartedTsTo: endDate?.toISODate(),
      registerType: searchParams.get('registerType'),
      memo: searchParams.get('memo'),
      hasUsim: searchParams.get('hasUsim'),
      isEsim: searchParams.get('isEsim'),
      birthday: searchParams.get('birthday') || undefined,
      유심도착후_진행되지않은일자:
        searchParams.get('유심도착후_진행되지않은일자'),
      status: initStatus || searchParams.get('status'),
      internetStatus: initStatus || searchParams.get('internetStatus'),
      orderTypes,
      autoActivateStates,
      addedByMvno: true,
      iptvBundle: searchParams.get('iptvBundle') === 'true',
      currentMno: searchParams.get('currentMno'),
      orderHandlerId: paramsToNumber(searchParams.get('orderHandlerId')),
      hasOrderHandler: paramsToBoolean(searchParams.get('hasOrderHandler')),
    };
  }, [searchParams, orderTypes, initStatus, autoActivateStates]);

  const updateSearchParams = useCallback(
    (data: { [key: string]: string | undefined | null }) => {
      for (const [key, value] of Object.entries(data)) {
        if (value == null) {
          searchParams.delete(key);
        } else {
          searchParams.set(key, value);
        }
      }
      searchParams.set('version', `${Date.now()}`);

      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  return {
    filterData,
    updateSearchParams,
  };
}
