import { makeObservable, observable } from 'mobx';
import orderDetailOneTongApi from '../../../api/orderDetailOneTongApi';
import { asyncPoll } from '../../../common/async-poll';
import { type PARTNERS_ADMIN_API_ERROR_CODE } from '../../../entity/api';

const StageNameToIdForPbl: Record<string, string> = {
  '고객 정보 인증': 'id_validation',
  '사전 동의 요청': 'pre_agree',
  '번호 이동 인증': 'change_bill',
  '개통 정보 등록': 'validate_usim',
  '해지/개통': 'activate',
  '번호 예약': 'new_number',
  개통: 'new_activate',
};

class OneTongStage {
  @observable private _isActive: boolean = false;
  @observable status?: 'success' | 'error';
  @observable canSendToMno?: boolean;
  @observable errorCode?: PARTNERS_ADMIN_API_ERROR_CODE;
  @observable errorInfo?: string;
  @observable info?: string;
  name: string;
  stageId: string;
  subStages: string[];
  pollingPath?: string;
  private _orderId: string;
  private _apiPath: string;
  private _next: OneTongStage | undefined;
  private _activeCheckIntervalId?: NodeJS.Timer;

  constructor(
    orderId: string,
    name: string,
    subStages: string[],
    apiPath: string,
    pollingPath?: string,
  ) {
    makeObservable(this);
    this._orderId = orderId;
    this.name = name;
    this.stageId = StageNameToIdForPbl[name] ?? '';
    this.subStages = subStages;
    this._apiPath = apiPath;
    this.pollingPath = pollingPath;
  }

  get next() {
    return this._next;
  }
  get isActive() {
    return this._isActive;
  }

  private successHandler = () => {
    this._isActive = false;
    this.status = 'success';
    this.errorInfo = undefined;
    this._next?.setActive();
  };

  private failHandler = (
    errorInfo?: any,
    errorCode?: PARTNERS_ADMIN_API_ERROR_CODE,
    canSendToMno?: boolean,
  ) => {
    this.status = 'error';
    this.errorCode = errorCode;
    this.errorInfo = errorInfo || 'U Cube 또는 KOS로 등록해주세요.';
    this.canSendToMno = canSendToMno;
  };

  setActive = () => {
    this._isActive = true;
  };

  setNext = (oneTongStage: OneTongStage) => {
    this._next = oneTongStage;
  };

  request = async (
    endPoint: 'activation' | 'order',
    data?: any,
    onSuccess?: (info?: string) => void,
    onFail?: (errorInfo?: string, errorCode?: string) => void,
  ) => {
    try {
      const rs = await orderDetailOneTongApi.postSelfActivationOneTong(
        endPoint,
        this._apiPath,
        this._orderId,
        '파트너스',
        data,
      );

      if (rs.data.resultType === 'SUCCESS') {
        if (this.pollingPath !== undefined) {
          console.log('polling - ' + this.pollingPath);
          await this._runActiveCheck(onSuccess, onFail);
        } else {
          this.successHandler();
          this.info = rs.data.result?.message;
          onSuccess?.(this.info);
        }
      } else {
        this.failHandler(
          rs.data.error,
          rs.data.errorCode,
          rs.data.canSendToMno,
        );
        onFail?.(rs.data.error, rs.data.errorCode?.toString());
      }
    } catch (err: any) {
      this.failHandler(err.code);
      onFail?.(undefined, err.code);
    }
  };

  clear = () => {
    this._isActive = false;
    this.status = undefined;
    this.errorInfo = undefined;
    this.info = undefined;
    this._activeCheckIntervalId && clearInterval(this._activeCheckIntervalId);
  };

  getValidPollingPath = () => {
    if (this.pollingPath == null) {
      throw new Error('pollingPath is null');
    } else {
      return this.pollingPath as string;
    }
  };

  /**
   * 개통 결과 polling 방식으로 기다림
   */
  private _runActiveCheck = async (
    onSuccess?: (info?: string) => void,
    onFail?: (
      errorInfo?: string,
      errorCode?: string,
      canSendToMno?: boolean,
    ) => void,
  ) => {
    try {
      const pollingPath = this.getValidPollingPath();

      const response = await asyncPoll(
        () =>
          orderDetailOneTongApi.postValidationMoveActivate(
            this._orderId,
            pollingPath,
          ),
        (response) => {
          // 인터페이스와 다르게 result 없는 경우 있음
          const status = response.data.result?.status;
          return (
            response.data.resultType === 'FAIL' ||
            status === 'FAILED' ||
            status === 'FINISHED'
          );
        },
        5000,
      );

      if (response.data.result?.status === 'FINISHED') {
        this.successHandler();
        onSuccess?.();
      } else {
        this.failHandler(
          response.data.error,
          response.data.errorCode,
          response.data.canSendToMno,
        );
        onFail?.(response.data.error, response.data.errorCode?.toString());
      }
    } catch (error: any) {
      this.failHandler(error.code);
      onFail?.(undefined, error.code);
    }
  };
}

export default OneTongStage;
