import { ReleaseNoteItem } from './releaseNotes';
import ReleaseStatusType from './ReleaseStatusType';

const Release_2_8_0: ReleaseNoteItem = {
  version: '2.8.0',
  date: '2024-03-08',
  title: '로그인시 첫 진입 탭 변경',
  details: [
    {
      title: '로그인시 전체탭에서 간편신청 접수탭으로 첫 진입 탭 변경',
      contents: [
        {
          content: (
            <>
              모요가 진행하는 자동화프로그램(자동검증, 자동개통 등) 대상 신청
              건이 포함되어 노출됩니다.
              <br />위 신청 건들을 대상으로 작업을 하시게되면 이후
              자동화프로그램이 정상동작하지 않아 불필요하게 개통실분들의{' '}
              작업량이 늘어날 수 있습니다.
              <br />
              때문에, 명확히 개통실분들의 도움을 필요로하는 신청건들이 모여있는{' '}
              간편신청 접수 페이지를 조금 더 쉽게 접근하실 수 있도록 업데이트{' '}
              하였습니다.
            </>
          ),
          status: ReleaseStatusType.CHANGE,
        },
      ],
    },
  ],
};

export default Release_2_8_0;
