import { useEffect, useState } from 'react';

// TODO: 약관 version이 늘어나면 확장성있는 구조로 개편
const CURRENT_VERSION = 'v1';
export default function PrivacyPolicyPage() {
  const [content, setContent] = useState('');
  useEffect(() => {
    fetch(`/partners-privacy-policy-${CURRENT_VERSION}.html`)
      .then((res) => res.text())
      .then((text) => setContent(text));
  }, []);
  return (
    <div
      style={{
        maxWidth: '600px',
        margin: '0 auto',
      }}
      dangerouslySetInnerHTML={{
        __html: content,
      }}
    ></div>
  );
}
