import { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import { useImmer } from 'use-immer';

import {
  SearchState,
  PaginationState,
  initialSearchState,
  initialPaginationState,
  GridUpdateState,
} from '../types';

function useProviderValue() {
  const [searchState, setSearchState] =
    useImmer<SearchState>(initialSearchState);

  const [paginationState, setPaginationState] = useImmer<PaginationState>(
    initialPaginationState,
  );

  const [gridUpdateState, setGridUpdateState] =
    useImmer<GridUpdateState | null>(null);

  const value = useMemo(
    () => ({
      searchState,
      setSearchState,
      paginationState,
      setPaginationState,
      gridUpdateState,
      setGridUpdateState,
    }),
    [
      searchState,
      setSearchState,
      paginationState,
      setPaginationState,
      gridUpdateState,
      setGridUpdateState,
    ],
  );

  return value;
}

const SearchDataContext = createContext<
  ReturnType<typeof useProviderValue> | undefined
>(undefined);

export function SearchDataContextProvider(props: PropsWithChildren<{}>) {
  const value = useProviderValue();
  return <SearchDataContext.Provider value={value} {...props} />;
}

export function useSearchDataContext() {
  const context = useContext(SearchDataContext);
  if (!context) {
    throw new Error('SearchDataContext is not provided');
  }

  return context;
}
