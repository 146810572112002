import { useMemo } from 'react';
import {
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';

import { toChunkArray } from '../../../common/array_util';
import { MobilePlanOrder } from '../../../entity/order';
import { VerticalColorTable } from '../../../shared/components/coloredTable';
import {
  MainAccordion,
  MainAccordionDetails,
  MainAccordionSummary,
} from '../../../shared/components/defaultAccordian';

type Props = {
  order: MobilePlanOrder;
};

export function OrderAgreementsSection({ order }: Props) {
  const agreementCells = useMemo(() => {
    if (!order?.agreements) return null;

    const chunks = toChunkArray(order.agreements, 2);

    return (
      <>
        {chunks.map((chunk) => {
          return (
            <TableRow key={chunk[0].termId}>
              {chunk.map((agreement) => {
                return (
                  <>
                    <TableCell>{agreement.title}</TableCell>
                    <TableCell>
                      {agreement.isAgreed === true ? '동의' : '미동의'}
                    </TableCell>
                  </>
                );
              })}
            </TableRow>
          );
        })}
      </>
    );
  }, [order]);

  if (!order.agreements) {
    return null;
  }

  return (
    <MainAccordion>
      <MainAccordionSummary>
        <Typography variant="h6">약관 동의</Typography>
      </MainAccordionSummary>
      <MainAccordionDetails>
        <div style={{ padding: '12px 0' }}>
          <TableContainer component={Paper} elevation={5}>
            <VerticalColorTable>
              <colgroup>
                <col style={{ width: '30%' }} />
                <col style={{ width: '20%' }} />
                <col style={{ width: '30%' }} />
                <col style={{ width: '20%' }} />
              </colgroup>
              <TableBody>{agreementCells}</TableBody>
            </VerticalColorTable>
          </TableContainer>
        </div>
      </MainAccordionDetails>
    </MainAccordion>
  );
}
