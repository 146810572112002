import { Box, Button, Typography } from '@mui/material';

import { useState } from 'react';
import orderDetailOneTongApi from '../../../api/orderDetailOneTongApi';
import useMoyoActiveCollection from '../../../common/moyoActivationCollection';
import { useAuth } from '../../../context/UserContext';
import { MobilePlanOrder } from '../../../entity/order';
import pbl from '../../../pbl/pbl-service/pbl-service';
import { usePermission } from '../../../router/usePermission';
import { useRole } from '../../../router/useRole';
import AutoActivateStateChip from '../../../shared/components/autoActivateStateChip';
import { FailedToSendToMnoPopup } from '../OrderDetailOneTong/FailedToSendToMnoPopup';
import { SendToMnoButton } from '../OrderDetailOneTong/SendToMnoButton';

interface RegistAutoActivateProps {
  title: string;
  order?: MobilePlanOrder;
  onClickEdit: () => void;
  onClickRestore: () => void;
}

export function RegistAutoActivate({
  title,
  order,
  onClickEdit,
  onClickRestore,
}: RegistAutoActivateProps) {
  const { isMoyoRole } = useRole();
  const { isEditPermission } = usePermission();
  const { isSelfMoyoActive: showActivateState } = useMoyoActiveCollection();
  const [loading, setLoading] = useState<boolean>(false);
  const [isFailedToSendToMnoPopupOpen, setIsFailedToSendToMnoPopupOpen] =
    useState(false);
  const [failedToSendToMnoReason, setFailedToSendToMnoReason] = useState<
    string | undefined
  >();
  const { getUserMvno, user } = useAuth();

  const commonLogParams = {
    user: user?.username ?? '',
    mvno: getUserMvno(),
  };

  const sendOrderToMno = async () => {
    if (order == null) {
      return;
    }
    setLoading(true);
    try {
      const response = await orderDetailOneTongApi.postSendOrderToMno(order.id);
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
      if (response.data.resultType !== 'SUCCESS') {
        throw new Error(`${response.data.error}`);
      }
      pbl('server', 'success', 'here', {
        object: {
          id: 'send_to_mno_success',
          name: '온라인 서식 전송 성공',
          section: 'top_menu',
        },
        eventProperties: {
          ...commonLogParams,
        },
      });
      window.alert('전산에 보내는 데 성공했어요');
      window.location.reload();
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : JSON.stringify(error);

      pbl('server', 'fail', 'here', {
        object: {
          id: 'send_to_mno_fail',
          name: '온라인 서식 전송 실패',
          section: 'top_menu',
        },
        eventProperties: {
          ...commonLogParams,
          error: errorMessage,
        },
      });
      setFailedToSendToMnoReason(errorMessage);
      setIsFailedToSendToMnoPopupOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const onClickSendToMno = async () => {
    pbl('click', 'button', 'here', {
      object: {
        id: 'send_to_mno_button',
        name: '전산에 보내기 버튼',
        section: 'top_menu',
      },
      eventProperties: {
        canSendToMno: order?.canSendToMno,
      },
    });

    await sendOrderToMno();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography variant="h5" my={3}>
        {title}
        {showActivateState && order?.autoActivateState && (
          <AutoActivateStateChip
            sx={{ marginLeft: 2, verticalAlign: 'text-top' }}
            size="small"
            state={order.autoActivateState}
          />
        )}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          marginLeft: 'auto',
          marginRight: '0',
          gap: 1,
        }}
      >
        {order?.canSendToMno && (
          <SendToMnoButton
            loading={loading}
            onClick={onClickSendToMno}
            loggingParams={{
              ...commonLogParams,
              section: 'top_menu',
              canSendToMno: order?.canSendToMno?.toString(),
            }}
          />
        )}
        {isMoyoRole && (
          <Button color="warning" variant={'outlined'} onClick={onClickRestore}>
            복구
          </Button>
        )}
        {isEditPermission && (
          <Button onClick={onClickEdit} variant="outlined">
            수정
          </Button>
        )}
        <FailedToSendToMnoPopup
          logParams={{
            ...commonLogParams,
            section: 'top_menu',
            error: failedToSendToMnoReason,
          }}
          open={isFailedToSendToMnoPopupOpen}
          onClose={() => setIsFailedToSendToMnoPopupOpen(false)}
          errorMessage={failedToSendToMnoReason}
        />
      </Box>
    </Box>
  );
}
