import { Box, Button, Chip, Typography } from '@mui/material';
import { FC } from 'react';
import { useThemeControl } from '../../../../context/ThemeControlContext';
import { ApplicationMethodList } from '../../types/mobile-plan-operator-admin-types';
import MvnoInfoSignupSupportMnoItem from './MvnoInfoSignupSupportMnoItem';
import { Mno } from '../../../../entity/mno';
import PermissionRender from '../../../../shared/components/PermissionRender/PermissionRender';

interface Props {
  isMoyoPartner: boolean;
  mobilePlanOperatorMetaId: number;
  brandName: string;
  applicationMethodList: ApplicationMethodList;
  logoUrl: string;
}

const MvnoInfoSignupSupport: FC<Props> = ({
  isMoyoPartner,
  applicationMethodList,
  brandName,
  mobilePlanOperatorMetaId,
  logoUrl,
}) => {
  const themeControl = useThemeControl();

  return (
    <Box
      sx={{
        py: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div>
          <img
            src={logoUrl}
            alt="logo"
            height={20}
            style={{ marginBottom: '8px' }}
          />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <Typography variant="h6">
              [{mobilePlanOperatorMetaId}] {brandName}
            </Typography>
            <Chip
              variant="filled"
              label={isMoyoPartner ? '제휴' : '비제휴'}
              size="small"
              color={isMoyoPartner ? 'primary' : 'default'}
            />
          </Box>
        </div>
        <PermissionRender isMoyoAdminOnly permissionErrorFallback={<></>}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
            }}
          >
            <Button variant="outlined">가입 방식 설정</Button>
          </Box>
        </PermissionRender>
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: 3,
          borderRadius: 2,
          paddingX: 4,
          paddingY: 2,
          backgroundColor: themeControl.theme.palette.neutral[2],
          border: `1px solid ${themeControl.theme.palette.neutral[3]}`,
        }}
      >
        {Object.entries(applicationMethodList).map(
          ([mno, applicationMethod]) => (
            <MvnoInfoSignupSupportMnoItem
              key={mno}
              mno={mno as Mno}
              newApplicationMethod={applicationMethod.newApplicationMethod}
              changeApplicationMethod={
                applicationMethod.changeApplicationMethod
              }
            />
          ),
        )}
      </Box>
    </Box>
  );
};

export default MvnoInfoSignupSupport;
