import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import { comma } from '../../../common/string_util';
import { makeTetheringDesc } from '../../../helpers/plan.helper';
import { PlanV2 } from '../../../entity/plan-v2';

interface Props {
  onCancel?: () => void;
  plan: PlanV2 | undefined;
  isOpen: boolean;
}

export const PlanDetailDialog: FC<Props> = (props) => {
  const { onCancel, isOpen, plan } = props;

  return (
    <Dialog open={isOpen}>
      <DialogTitle>
        <Typography
          variant="h6"
          component={'p'}
          style={{ paddingRight: '400px' }}
        >
          상세
        </Typography>
      </DialogTitle>
      <DialogContent>
        <ul>
          <li>
            <DialogContentText color={'rgba(0, 0, 0, 0.87)'}>
              요금제 이름 : {plan?.meta.name}
            </DialogContentText>
          </li>
          <li>
            <DialogContentText color={'rgba(0, 0, 0, 0.87)'}>
              망 : {plan?.meta.mno}
            </DialogContentText>
          </li>
          <li>
            <DialogContentText color={'rgba(0, 0, 0, 0.87)'}>
              네트워크 : {plan?.meta.net}
            </DialogContentText>
          </li>
          <li>
            <DialogContentText color={'rgba(0, 0, 0, 0.87)'}>
              통화 :{' '}
              {plan?.meta.mobileVoice === 9999
                ? '무제한'
                : `${plan?.meta.mobileData}분`}
            </DialogContentText>
          </li>
          <li>
            <DialogContentText color={'rgba(0, 0, 0, 0.87)'}>
              문자 :{' '}
              {plan?.meta.mobileMessage === 9999
                ? '무제한'
                : `${plan?.meta.mobileMessage}분`}
            </DialogContentText>
          </li>
          <li>
            <DialogContentText color={'rgba(0, 0, 0, 0.87)'}>
              월 기본 데이터 : {plan?.meta.mobileData}GB
            </DialogContentText>
          </li>
          <li>
            <DialogContentText color={'rgba(0, 0, 0, 0.87)'}>
              일 데이터 : {plan?.meta.mobileData2}GB
            </DialogContentText>
          </li>
          <li>
            <DialogContentText color={'rgba(0, 0, 0, 0.87)'}>
              소진 시 속도 :{' '}
              {plan?.meta.speedWhenExhausted
                ? plan.meta.speedWhenExhausted / 1000
                : '없음'}
              Mbps
            </DialogContentText>
          </li>
          <br />
          <li>
            <DialogContentText color={'rgba(0, 0, 0, 0.87)'}>
              할인 시 가격 : {comma(plan?.meta.fee)}원
            </DialogContentText>
          </li>
          <li>
            <DialogContentText color={'rgba(0, 0, 0, 0.87)'}>
              할인 기간 : {plan?.meta.할인적용_개월수}
            </DialogContentText>
          </li>
          <li>
            <DialogContentText color={'rgba(0, 0, 0, 0.87)'}>
              할인 종료 후 가격 : {comma(plan?.meta.할인끝난후_금액)}원
            </DialogContentText>
          </li>
          <br />
          <li>
            <DialogContentText color={'rgba(0, 0, 0, 0.87)'}>
              부가통화 제공량 :{' '}
              {plan?.meta.additionalCall
                ? plan.meta.additionalCall
                : '제공안함'}
            </DialogContentText>
          </li>
          <li>
            <DialogContentText color={'rgba(0, 0, 0, 0.87)'}>
              핫스팟 제공량 :{' '}
              {plan &&
                makeTetheringDesc({
                  tethering: plan?.meta.tethering,
                })}
            </DialogContentText>
          </li>
          <li>
            <DialogContentText color={'rgba(0, 0, 0, 0.87)'}>
              특수 요금제 :{' '}
              {plan?.meta.isSpecialCase
                ? plan?.meta.specialPlanCategory
                : '해당없음'}
            </DialogContentText>
          </li>
        </ul>
      </DialogContent>
      <DialogActions>
        {onCancel && <Button onClick={() => onCancel()}>확인</Button>}
      </DialogActions>
    </Dialog>
  );
};
