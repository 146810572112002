import { DateTime } from 'luxon';

import { OrderStatus } from '../../../entity/order';
import { InternetOrderStatus } from '../../../entity/internet-order';

export interface SearchState {
  dateStandard: string;
  startDate?: DateTime | null;
  endDate?: DateTime | null;
  customerName: string;
  birthday: string;
  customerPhoneNumber: string;
  planName: string;
  status: OrderStatus | null;
  internetStatus: InternetOrderStatus | null;
  mno: string;
  registerType: string;
  memo: string;
  hasUsim: string;
  isEsim?: string;
  willPurchaseUsim?: string;
  mvno: string;
  유심도착후_진행되지않은일자: string;
  isChangePlanRequest: boolean;
  currentMno: string;
  iptvBundle?: boolean | null;
  orderHandlerId?: number;
  hasOrderHandler: boolean | null;
}

export const initialSearchState: SearchState = {
  dateStandard: 'createdAt',
  customerName: '',
  startDate: null,
  endDate: null,
  customerPhoneNumber: '',
  planName: '',
  mno: '',
  status: null,
  internetStatus: null,
  registerType: '',
  hasUsim: '',
  isEsim: '',
  mvno: '',
  유심도착후_진행되지않은일자: '',
  isChangePlanRequest: false,
  birthday: '',
  memo: '',
  currentMno: '',
  iptvBundle: null,
  hasOrderHandler: null,
};
