import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { FC, useEffect } from 'react';
import type {
  MnoOperationPolicyByMoyoList,
  OperationPolicy,
} from '../../types/mobile-plan-operator-admin-types';
import { Controller, useFormContext } from 'react-hook-form';
import CustomMvnoInfoToggleButtonGroup from '../Common/CustomMvnoInfoToggleButtonGroup';
import NumericFormatComponent from '../../../../shared/components/custom-mui/NumericFormatComponent';
import { MvnoInfoItemBody, MvnoInfoItemRow } from '../Common/MvnoInfoItemFrame';
import { Mno } from '../../../../entity/mno';
import { convertStringToMno } from '../../../../common/order-util';

const menuProps = {
  PaperProps: {
    style: {
      maxHeight: 200,
      overflow: 'auto',
    },
  },
};

const PaymentDateSelectItems = [
  {
    label: '1일',
    value: 1,
  },
  {
    label: '2일',
    value: 2,
  },
  {
    label: '3일',
    value: 3,
  },
  {
    label: '4일',
    value: 4,
  },
  {
    label: '5일',
    value: 5,
  },
  {
    label: '6일',
    value: 6,
  },
  {
    label: '7일',
    value: 7,
  },
  {
    label: '8일',
    value: 8,
  },
  {
    label: '9일',
    value: 9,
  },
  {
    label: '10일',
    value: 10,
  },
  {
    label: '11일',
    value: 11,
  },
  {
    label: '12일',
    value: 12,
  },
  {
    label: '13일',
    value: 13,
  },
  {
    label: '14일',
    value: 14,
  },
  {
    label: '15일',
    value: 15,
  },
  {
    label: '16일',
    value: 16,
  },
  {
    label: '17일',
    value: 17,
  },
  {
    label: '18일',
    value: 18,
  },
  {
    label: '19일',
    value: 19,
  },
  {
    label: '20일',
    value: 20,
  },
  {
    label: '21일',
    value: 21,
  },
  {
    label: '22일',
    value: 22,
  },
  {
    label: '23일',
    value: 23,
  },
  {
    label: '24일',
    value: 24,
  },
  {
    label: '25일',
    value: 25,
  },
  {
    label: '26일',
    value: 26,
  },
  {
    label: '27일',
    value: 27,
  },
  {
    label: '28일',
    value: 28,
  },
];

const MnoInfoOperationPolicyEdit: FC<{
  mno: Mno;
  operationPolicy: OperationPolicy;
}> = ({ mno, operationPolicy }) => {
  const { control } = useFormContext<MnoOperationPolicyByMoyoList>();
  const title = convertStringToMno(mno);

  return (
    <MvnoInfoItemBody gap={3}>
      <Box>
        <Typography variant="body1" color="text.primary" fontWeight={700}>
          {title}망
        </Typography>
      </Box>
      <MvnoInfoItemBody gap={7}>
        <MvnoInfoItemRow gap={3}>
          <Controller
            control={control}
            defaultValue={operationPolicy.postActivation}
            name={`${mno}.postActivation`}
            render={({ field }) => (
              <CustomMvnoInfoToggleButtonGroup
                label="신규가입 개통 방식"
                items={[
                  {
                    label: '선개통',
                    value: false,
                  },
                  {
                    label: '후개통',
                    value: true,
                  },
                ]}
                toggleButtonGroupProps={{ ...field }}
              />
            )}
          />
          <Controller
            control={control}
            defaultValue={operationPolicy.dailyDiscount}
            name={`${mno}.dailyDiscount`}
            render={({ field }) => (
              <CustomMvnoInfoToggleButtonGroup
                label="요금제 할인기간 계산 방법"
                items={[
                  {
                    label: '일할',
                    value: true,
                  },
                  {
                    label: '월할',
                    value: false,
                  },
                ]}
                toggleButtonGroupProps={{ ...field }}
              />
            )}
          />
          <Controller
            control={control}
            defaultValue={operationPolicy.allowPaymentByOther}
            name={`${mno}.allowPaymentByOther`}
            render={({ field }) => (
              <CustomMvnoInfoToggleButtonGroup
                label="타인납부"
                items={[
                  {
                    label: '지원',
                    value: true,
                  },
                  {
                    label: '미지원',
                    value: false,
                  },
                ]}
                toggleButtonGroupProps={{ ...field }}
              />
            )}
          />
        </MvnoInfoItemRow>
        <MvnoInfoItemRow gap={3}>
          <Controller
            control={control}
            name={`${mno}.registerChangeFee`}
            defaultValue={operationPolicy.registerChangeFee}
            render={({ field }) => (
              <TextField
                required
                InputLabelProps={{
                  required: false,
                }}
                {...field}
                fullWidth
                inputMode="numeric"
                label="번호이동 수수료"
                InputProps={{
                  endAdornment: '원',
                  inputComponent: NumericFormatComponent,
                }}
              />
            )}
          />
          <Controller
            control={control}
            name={`${mno}.billingDateList.card.billingDay`}
            defaultValue={operationPolicy.billingDateList?.card.billingDay}
            render={({ field }) => (
              <FormControl fullWidth>
                <InputLabel id="card-billingDay">카드 결제일</InputLabel>
                <Select label="카드 결제일" MenuProps={menuProps} {...field}>
                  {PaymentDateSelectItems?.map(({ label, value }) => (
                    <MenuItem key={label} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
          <Controller
            control={control}
            name={`${mno}.billingDateList.account.billingDay`}
            defaultValue={operationPolicy.billingDateList?.account.billingDay}
            render={({ field }) => (
              <FormControl fullWidth>
                <InputLabel id="account-billingDay">자동이체 결제일</InputLabel>
                <Select
                  MenuProps={menuProps}
                  label="자동이체 결제일"
                  {...field}
                >
                  {PaymentDateSelectItems?.map(({ label, value }) => (
                    <MenuItem key={label} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </MvnoInfoItemRow>
      </MvnoInfoItemBody>
    </MvnoInfoItemBody>
  );
};

const MvnoInfoOperationPolicyByMoyoEdit: FC<MnoOperationPolicyByMoyoList> = (
  mnoOperationPolicyByMoyoList,
) => {
  const { setValue, reset } = useFormContext<MnoOperationPolicyByMoyoList>();

  useEffect(() => {
    initialize(mnoOperationPolicyByMoyoList);
    return () => {
      reset();
    };
  }, []);

  const initialize = (
    _mnoOperationPolicyByMoyoList: MnoOperationPolicyByMoyoList,
  ) => {
    Object.entries(mnoOperationPolicyByMoyoList).forEach(([key, value]) => {
      setValue(key as keyof MnoOperationPolicyByMoyoList, value);
    });
  };

  return (
    <MvnoInfoItemBody gap={6}>
      {Object.entries(mnoOperationPolicyByMoyoList).map(
        ([key, mnoOperationPolicyByMoyo]) => (
          <MnoInfoOperationPolicyEdit
            operationPolicy={mnoOperationPolicyByMoyo}
            key={key}
            mno={key as Mno}
          />
        ),
      )}
    </MvnoInfoItemBody>
  );
};

export default MvnoInfoOperationPolicyByMoyoEdit;
