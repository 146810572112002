import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import { ChangeEvent, FC, KeyboardEvent, useState } from 'react';
import { authApiService } from '../../api/auth';
import { useLoading } from '../../context/LoadingContext';

interface ResetPasswordModalProps {
  open: boolean;
  onClose: () => void;
}

const ResetPasswordModal: FC<ResetPasswordModalProps> = ({ open, onClose }) => {
  const [errorText, setErrorText] = useState<string>('');
  const [username, setUsername] = useState<string>('');
  const [curPassword, setCurPassword] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [password2, setPassword2] = useState<string>('');
  const { setLoading } = useLoading();

  const onSubmitResetPassword = async () => {
    setErrorText('');
    if (password !== password2) {
      setErrorText('비밀번호를 확인해주세요.');
      return;
    }

    setLoading(true);

    try {
      await authApiService.resetPassword(username, curPassword, password);
      alert('비밀번호가 변경되었습니다.');
      onCloseHandler();
    } catch (_: any) {
      const errorMessage =
        _?.response?.data?.error ?? '알 수 없는 오류가 발생했습니다.';
      setErrorText(errorMessage.toString());
    } finally {
      setLoading(false);
    }
  };

  const onCloseHandler = () => {
    setErrorText('');
    setPassword('');
    setPassword2('');
    onClose();
  };

  const onChangeUsernameHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setUsername(e.currentTarget.value);
  };

  const onChangeCurPasswordHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setCurPassword(e.currentTarget.value);
  };

  const onChangePasswordHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.currentTarget.value);
  };

  const onChangePassword2Handler = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword2(e.currentTarget.value);
  };

  const onKeyPressEnterHandler = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSubmitResetPassword();
    }
  };

  return (
    <Dialog open={open} onClose={onCloseHandler}>
      <DialogTitle>비밀번호 변경</DialogTitle>
      <DialogContent>
        <DialogContentText>변경하실 비밀번호를 입력해주세요.</DialogContentText>
        <TextField
          error={!!errorText}
          autoFocus
          margin="normal"
          id="userId"
          label="사용자 이름"
          fullWidth
          variant="outlined"
          onKeyPress={onKeyPressEnterHandler}
          onChange={onChangeUsernameHandler}
          value={username}
        />
        <TextField
          error={!!errorText}
          margin="normal"
          id="cur_password"
          label="현재 비밀번호"
          type="password"
          fullWidth
          variant="outlined"
          onKeyPress={onKeyPressEnterHandler}
          onChange={onChangeCurPasswordHandler}
          value={curPassword}
        />
        <TextField
          error={!!errorText}
          margin="normal"
          id="new_password"
          label="새 비밀번호"
          type="password"
          fullWidth
          variant="outlined"
          onKeyPress={onKeyPressEnterHandler}
          onChange={onChangePasswordHandler}
          value={password}
        />
        <TextField
          error={!!errorText}
          margin="normal"
          id="new_password2"
          label="새 비밀번호 확인"
          type="password"
          fullWidth
          variant="outlined"
          onKeyPress={onKeyPressEnterHandler}
          onChange={onChangePassword2Handler}
          value={password2}
          helperText={errorText || undefined}
        />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="outlined" onClick={onCloseHandler}>
          닫기
        </Button>
        <Button variant="contained" onClick={onSubmitResetPassword}>
          변경하기
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ResetPasswordModal;
