import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { FC } from 'react';
import { InternetPlanConsultHistory } from '../../../entity/internet-plan/internet-plan-consult-history';

interface Props {
  list: InternetPlanConsultHistory[];
}

const InternetConsultDetailHistory: FC<Props> = ({ list }) => {
  return (
    <TableContainer component={Paper} elevation={5} sx={{ mt: 3 }}>
      <Table
        sx={{
          td: {
            backgroundColor: 'transparent',
            fontWeight: 'normal',
          },
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell>등록일</TableCell>
            <TableCell>상태</TableCell>
            <TableCell>상세 사유</TableCell>
            <TableCell>상담사</TableCell>
            <TableCell>상담 메모</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((history) => (
            <TableRow key={history.id}>
              <TableCell>{history.regTs}</TableCell>
              <TableCell>{history.status}</TableCell>
              <TableCell>{history.statusDetail ?? ''}</TableCell>
              <TableCell>{history.operatorName}</TableCell>
              <TableCell>{history.memo}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InternetConsultDetailHistory;
