import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  Input,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { orderApiService } from '../../api/order';
import { useLoading } from '../../context/LoadingContext';
import pbl from '../../pbl/pbl-service/pbl-service';
import InfoIcon from '@mui/icons-material/Info';
import { useThemeControl } from '../../context/ThemeControlContext';

type PopupProps = {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
};

const CheckBoxLabelSx = {
  margin: 0,
  padding: 1.5,
  borderRadius: 1,
  width: '100%',
  boxSizing: 'border-box',
  bgcolor: 'background.default',
};

export default function UsimOrDeliveryExcelUploadPopup({
  open,
  onClose,
  onSuccess,
}: PopupProps) {
  const [file, setFile] = useState<File>();
  const [uploadTypes, setUploadTypes] = useState<('USIM' | 'DELIVERY')[]>([]);
  const { setLoading } = useLoading();
  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const file = e.target.files[0];

    if (file) setFile(file);
  };

  const uploadFile = async () => {
    if (!file) {
      alert('파일을 업로드해주세요');
      return;
    }
    pbl('click', 'modal_button', 'here', {
      object: {
        id: 'upload_usim_or_delivery_excel',
        section: 'action_menu',
      },
    });
    try {
      setLoading(true);

      const formData = new FormData();
      formData.append('file', file);

      const res = await orderApiService.uploadUsimOrDeliveryInfoExcel(
        formData,
        uploadTypes.join(','),
      );

      if (res.data.result === true) {
        alert('업로드가 완료되었습니다');
        onClose();
        onSuccess();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>유심 정보 업로드</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ marginBottom: 2 }}>
          정보를 업데이트할 주문만 엑셀 파일에 담아서 올려주세요.
        </DialogContentText>
        <Grid container spacing={1} direction="column">
          <Grid item>
            <Box>
              <FormControlLabel
                label="유심 정보 업로드"
                sx={CheckBoxLabelSx}
                control={
                  <Checkbox
                    sx={{
                      padding: 0,
                    }}
                    checked={uploadTypes.includes('USIM')}
                    onChange={() => {
                      setUploadTypes((prev) =>
                        prev.includes('USIM')
                          ? prev.filter((type) => type !== 'USIM')
                          : [...prev, 'USIM'],
                      );
                    }}
                  />
                }
              />
            </Box>
          </Grid>
          <Grid item>
            <Box>
              <FormControlLabel
                label="택배 송장 업로드"
                sx={CheckBoxLabelSx}
                control={
                  <Checkbox
                    sx={{
                      padding: 0,
                    }}
                    checked={uploadTypes.includes('DELIVERY')}
                    onChange={() => {
                      setUploadTypes((prev) =>
                        prev.includes('DELIVERY')
                          ? prev.filter((type) => type !== 'DELIVERY')
                          : [...prev, 'DELIVERY'],
                      );
                    }}
                  />
                }
              />
            </Box>
          </Grid>
          {uploadTypes.length > 0 && (
            <Grid item container alignItems="center">
              <InfoIcon
                fontSize="small"
                color="primary"
                sx={{ marginRight: 1 }}
              />
              <Typography variant="body2">
                모든 주문의 상태값이{' '}
                <Typography
                  variant="body2"
                  component="span"
                  fontWeight={700}
                  color="primary.main"
                >
                  {uploadTypes.includes('DELIVERY')
                    ? '"유심 배송중"'
                    : '"유심 배송요청"'}
                </Typography>
                으로 일괄 변경됩니다.
              </Typography>
            </Grid>
          )}
          <Grid item>
            <Input
              type="file"
              onChange={handleFileChange}
              inputProps={{
                accept:
                  'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={uploadTypes.length === 0 || !file}
          onClick={uploadFile}
        >
          업로드하기
        </Button>
      </DialogActions>
    </Dialog>
  );
}
