import { MobilePlanOperatorId } from '../common/mobile-plan-operator-id';
import {
  BasicInfoDto,
  ExceedRateList,
  PlanManagementMethodType,
  UsimInfo,
} from '../components/MvnoInfoPage/types/mobile-plan-operator-admin-types';

export enum ReservationStatus {
  CHANGE_RESERVED = 'CHANGE_RESERVED',
  CLOSE_RESERVED = 'CLOSE_RESERVED',
  OPEN_RESERVED = 'OPEN_RESERVED',
}

export type PlanMetaHistory = {
  id: number;
  planMetaId: number;
  name: string;
  mvno: string;
  mno: string;
  net: string;
  mobileData?: number;
  mobileData2?: number;
  speedWhenExhausted?: number;
  mobileVoice: number;
  mobileMessage: number;
  할인끝난후_금액?: number;
  할인적용_개월수?: number;
  fee: number;
  code?: string;
};

export type SimplePlanMeta = {
  id: number;
  name: string;
  mvno: string;
  mno: string;
  net: string;
  fee: number;
  isDeleted: boolean;
  partnerSignupUrl?: string;
  partnerSignupUrlForNewPhoneNumber?: string;
};

export type PlanMetaAdmin = {
  id: number;
  name: string;
  mvno: string;
  mno: string;
  net: string;
  code: string;
  fee: number;
  state: PlanState;
  planManagementType: PlanManagementMethodType; // 요금제 등록(관리) 방식
  feeAfterDiscount?: number;
  promotionPeriod?: number;
  numberOfMonthsForDiscount?: number;
  partnerSignupUrl?: string;
  partnerSignupUrlForNewPhoneNumber?: string;
  isDeleted: boolean;
  nextReservedUpdateTime?: number[];
  display: boolean;
  reservationStatus: ReservationStatus;
  manualIsDeleted: boolean;
  updatedAt: string;
};

export interface ICreatePlanDto {
  /** 기본정보 */
  mobilePlanOperatorId: number; // 통신사
  name: string; // 요금제 이름
  code: string; // 요금제 코드
  signupUrl: string; // 파트너사 가입 URL
  mobileSignupUrl: string; //파트너사 모바일 가입 URL
  reserveTime: string; // 예약 시간

  /** 요금제 스펙 */
  specialGroupName: String; // 특별 그룹 이름. null이면 특별 그룹 아님
  network: string; // 네트워크 유형
  mno: string; // mno
  basicData: number | null; // 월 데이터 제공량
  basicDataUnit: string | null; // 월 데이터 제공량 단위
  dailyData: number | null; // 일 데이터 제공량
  dailyDataUnit: string | null; // 일 데이터 제공량 단위
  qos: number | null; // 데이터 소진 시 속도
  qosUnit: string | null; // 데이터 소진 시 속도 단위
  message: number | null; // 문자 메시지 제공량
  voice: number | null; // 음성통화 제공량 (분)

  /** 요금 정보 */
  agreementPeriod: number | null; // 약정 기간 - 단위: 일
  discountFee: number | null; // 할인 가격
  originalFee: number | null; // 기본 가격 (할인 후 가격)
  discountPeriod: number | null; // 할인 기간

  /** 부가정보 */
  dataSharingSupported: boolean | null; // 데이터 쉐어링 지원 여부
  microPaymentSupported: boolean | null; // 소액 결제 지원 여부
  internetCombineSupported: boolean | null; // 인터넷 결합 지원 여부
  additionalCall: number | null; // 부가통화 null: 통신사에 문의 / -1: 제공안함 / 0: 통화량에서 차감

  tetheringData?: number; // 테더링(핫스팟) 제공량 null: 통신사에 문의 / -1: 제공안함
  tetheringDataUnit?: DataSpeedUnit; // 테더링 제공량 단위
  tetheringDailyData?: number; // 테더링 일 제공량
  tetheringDailyDataUnit?: DataSpeedUnit; // 테더링 일 제공량 단위
  tetheringStatus?: TetheringStatusType;
}

export enum QosUnit {
  KBPS = 'Kbps',
  MBPS = 'Mbps',
}

export enum DataSpeedUnit {
  GB = 'GB',
  MB = 'MB',
}

interface Time {
  hour: number;
  minute: number;
  second: number;
  nano: number;
}

interface DeliveryOption {
  option: string;
  start: Time;
  end: Time;
}

interface UsimDeliveryOptions {
  availableForNow: string[];
  availableForPlan: string[];
  availableOptions: DeliveryOption[];
}

interface Gift {
  id: number;
  eventId: number;
  planId: number;
  type: string;
  description: string;
  eventUrl: string;
  eventTitle: string;
  category: string;
  condition: string;
  receiptDate: string;
  useLink: boolean;
  endDate: string;
}

interface Benefit {
  benefitType: string;
  title: string;
}

interface DirectBenefit {
  benefitList: Benefit[];
}

export interface IMobileOperatorMeta {
  basicInfo: BasicInfoDto;
  signupSupportType: null;
  businessInfo: null;
  customerCenterInfo: null;
  partnershipPolicy: null;
  operationPolicy: null;
  evaluation: null;
  exceedRateInfo: ExceedRateList;
  usimInfo: UsimInfo;
  additionalInfo: null;
}

export interface IMobilePlan {
  id: number;
  name: string;
  rawName: string;
  mvno: string;
  mvnoId: number;
  mobilePlanOperatorId: number;
  mobileNetworkProviderId: string;
  mno: string;
  net: string;
  code: string;
  mobileData: number;
  mobileData2: number;
  mobileDataStandard: string;
  speedWhenExhausted: number;
  additionalWhenExhausted: null;
  mobileVideo: null;
  mobileVoice: number;
  mobileMessage: number;
  note: null;
  fee: number;
  twMonthFee: number;
  feeString: string;
  originalFee: null;
  할인끝난후_금액: number;
  할인적용_개월수: number;
  discount: null;
  번호이동_파트너링크: string;
  신규가입_파트너링크: null;
  signupUrl: string;
  mobileSignupUrl: string;
  regTs: number;
  updTs: number;
  mobileDataString: string;
  mobileDataDescription: string;
  mobileDataDateExhaustedDescription: string;
  speedWhenExhaustedDescription: string;
  mobileData3Description: null;
  사은품리스트: any[];
  isWishlist: boolean;
  isDeleted: boolean;
  recommendRanking: null;
  mobileDataStr: string;
  mobileData2Str: string;
  mobileData3Str: null;
  isTablet: boolean;
  isPartnerMvno: boolean;
  isRocketCallMvno: boolean;
  isRocketMvno: boolean;
  isSupportSelfActivate: boolean;
  isSupportEsimSelfActivate: boolean;
  isSupportAutoValidation: boolean;
  isSupportAutoActivation: boolean;
  isSupportNewSelfActivate: boolean;
  usimDeliveryOptions: UsimDeliveryOptions;
  isSupportUsimOrder: boolean;
  isEsim: boolean;
  numOfSignup: number;
  numOfSignup1Weekly: number;
  isSpecialCase: boolean;
  specialPlanCategory: string;
  mvnoRating: null;
  state: string;
  aov: number;
  moyoCommission: null;
  applyCommission: null;
  activationCompletedDate: null;
  oneMonthMvnoReviewScore: null;
  isManualCreated: boolean;
  agreement: null;
  agreementType: null;
  agreementPeriod: null;
  mobileDataTotal: number;
  directBenefit: DirectBenefit;
  isPostActivation: boolean;
}

export type PagedPlanMetaAdmin = {
  list: PlanMetaAdmin[];
  totalSize: number;
};

interface ConstraintInfo {
  description: string;
}

export interface SubscriptionRestrictionContent {
  info: ConstraintInfo[];
  danger: string[];
}

export interface SubscriptionRestriction {
  id: number;
  historyId: number;
  mvno: string;
  mno: string;
  type: string;
  content: SubscriptionRestrictionContent | string;
  detailType: string | null;
  typeId: number;
  description: string | null;
}

export type PlanHistory = {
  id: number;
  eventType: string;
  name: string;
  mvno: string;
  mno: string;
  net: string;
  mobileData: number;
  mobileData2: number;
  speedWhenExhausted: number;
  mobileVoice: number;
  mobileMessage: number;
  fee: number;
  originalFee: number;
  할인끝난후_금액: number;
  할인적용_개월수: number;
  번호이동_파트너링크: string;
  신규가입_파트너링크: string;
  signupUrl: string; // 커뮤니티 홍보할 때 가입하기 링크 ( 광고 티 날까봐 utm_source 제거 ;
  regTs: number;
  createdDateTime: string;
};

export type UpdatedValueMeta = {
  fee: number;
  할인끝난후_금액: number;
  할인적용_개월수: number;
  번호이동_파트너링크: string;
  신규가입_파트너링크: string;
};

export type PlanEditReserve = {
  id: number;
  type: string;
  reserveTime: string;
  isApplied: boolean;
  updatedValue: UpdatedValueMeta;
  createdTime: string;
  planName: string;
  planCode: string;
};

export type PlanEdit = {
  manualName?: string;
  번호이동_파트너링크?: string;
  신규가입_파트너링크?: string;
  fee?: number;
  할인적용_개월수?: number;
  할인끝난후_금액?: number;
  modifier?: string;
  code?: string;
  hideFromUser?: boolean;
};

export type PlanEditBulkRow = {
  rowNum: number;
  planName: string;
  planCode: string;
  할인끝난후_금액: number;
  할인적용_개월수: number;
  mno: string;
  reserveTime: string;
  creationConfirmed: boolean;
};

export type PlanEditBulkRowResult = {
  rowNum: number;
  planName: string;
  planCode: string;
  할인끝난후_금액: string;
  할인적용_개월수: string;
  mno: string;
  reserveTime: string;
  creationConfirmed: string;
};

export type PlanEditBulkData = {
  mvno: string;
  rows: Array<PlanEditBulkRow>;
};

export type PlanEditBulkResult = {
  failedRows: Array<PlanEditBulkRowResult>;
};

export type ScrapeReserve = {
  id: number;
  effectiveAt: string;
  isApplied: boolean;
};

export type SpecialGroupNameType = {
  none: '일반 요금제';
  senior: '시니어 요금제';
  iphone: '아이폰 요금제';
  tablet: '테블릿 요금제';
  official: '공무원 요금제';
  watch: '워치 요금제';
  junior: '청소년 요금제';
  koreans: '해외출국자 요금제';
  soldier: '군인 요금제';
  welfare: '복지 요금제';
};

export type PlanState =
  | 'CONFIRMED'
  | 'READY'
  | 'DELETED'
  | 'DELETE_RESERVED'
  | 'CHANGE_RESERVED'
  | 'CREATE_RESERVED';

export const SpecialGroupNameSelectList: {
  key: keyof SpecialGroupNameType;
  value: string;
}[] = [
  {
    key: 'none',
    value: '일반요금제',
  },
  {
    key: 'senior',
    value: '시니어 요금제',
  },
  {
    key: 'iphone',
    value: '아이폰 전용 요금제',
  },
  {
    key: 'tablet',
    value: '테블릿 전용 요금제',
  },
  {
    key: 'official',
    value: '공무원 요금제',
  },
  {
    key: 'watch',
    value: '워치 전용 요금제',
  },
  {
    key: 'junior',
    value: '청소년 요금제',
  },
  {
    key: 'koreans',
    value: '해외출국자 요금제',
  },
  {
    key: 'soldier',
    value: '군인 요금제',
  },
  {
    key: 'welfare',
    value: '복지 요금제',
  },
];

export interface PlanMetaLogHistory {
  content: PlanMetaHistoryContent[];
  totalElements: number;
  totalPage: number;
  page: number;
  size: number;
  last: boolean;
  planMetaId: number;
}

export interface PlanMetaHistoryContent {
  updatedDateTime: string;
  event: 'CHANGED' | 'CREATED' | 'DELETED';
  message: string;
  modifier: string;
}

export interface PlanOperatorStatus {
  isBusinessActive: boolean;
  isVisibleInMoyo: boolean;
  isMoyoPartner: boolean;
  supportingNetworkProviders: string; // ex) "SKT,LGU,KT"
}

export type TetheringStatusType =
  | 'CONTACT_MOBILE_PLAN_OPERATOR' // 통신사에 문의
  | 'PROVIDED' // 제공
  | 'NOT_PROVIDED'; // 미제공

export interface Tethering {
  tetheringStatus: TetheringStatusType;
  tetheringData?: number;
  tetheringDataUnit?: DataSpeedUnit;
  tetheringDailyData?: number;
  tetheringDailyDataUnit?: DataSpeedUnit;
}
