import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { DeliveryInfo } from '../../../entity/delivery';
import {
  MainAccordion,
  MainAccordionDetails,
  MainAccordionSummary,
} from '../../../shared/components/defaultAccordian';

type Props = {
  deliveryInfo?: DeliveryInfo;
};

export function OrderDeliveryInfoSection({ deliveryInfo }: Props) {
  if (deliveryInfo == null || deliveryInfo.deliveryList.length === 0)
    return null;
  return (
    <MainAccordion>
      <MainAccordionSummary>
        <Typography variant="h6">배송 정보</Typography>
      </MainAccordionSummary>
      <MainAccordionDetails>
        <div style={{ padding: '12px 0' }}>
          <TableContainer component={Paper} elevation={5}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">단계</StyledTableCell>
                  <StyledTableCell align="center">처리시간</StyledTableCell>
                  <StyledTableCell align="center">설명</StyledTableCell>
                  <StyledTableCell align="center">담당 점소</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {deliveryInfo.deliveryList.map((row) => (
                  <StyledTableRow>
                    <StyledTableCell align="center" component="th" scope="row">
                      {row.status}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.timeString}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.detail}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.location}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </MainAccordionDetails>
    </MainAccordion>
  );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
    fontWeight: 'bold',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
