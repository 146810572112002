import { Controller, useFormContext } from 'react-hook-form';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';

import { MobilePlanOrder } from '../../../entity/order';

interface CertificationInfoProps {
  order: MobilePlanOrder;
}

export function CertificationInfo({ order }: CertificationInfoProps) {
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext<MobilePlanOrder>();

  return (
    <TableRow>
      <TableCell>주민등록번호</TableCell>
      <TableCell>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <TextField
            {...register('detail.registrationNumberFirst')}
            size="small"
            sx={{
              width: '120px',
            }}
            disabled
          />
          <Typography
            sx={{
              flex: '0 0 auto',
              padding: '0 5px',
            }}
          >
            -
          </Typography>
          <TextField
            {...register('detail.registrationNumberSecond')}
            size="small"
            sx={{
              width: '120px',
            }}
            disabled
          />
        </Box>
      </TableCell>
      <TableCell>본인 인증 방법</TableCell>
      <TableCell>
        <FormControl disabled>
          <Controller
            name="detail.certificationType"
            control={control}
            defaultValue={order?.detail?.certificationType ?? 'card'}
            render={({ field }) => (
              <RadioGroup {...field} row aria-required>
                <FormControlLabel
                  value="naver"
                  control={<Radio />}
                  label="네이버"
                />
                <FormControlLabel
                  value="toss"
                  control={<Radio />}
                  label="토스"
                />
                <FormControlLabel
                  value="card"
                  control={<Radio />}
                  label="신용카드"
                />
              </RadioGroup>
            )}
          />
          <FormHelperText error={!!errors.detail?.certificationType}>
            {errors.detail?.certificationType?.message}
          </FormHelperText>
        </FormControl>
      </TableCell>
    </TableRow>
  );
}
