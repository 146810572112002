import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';

const OrderDataGridToolbar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      {/* @ts-ignore */}
      <GridToolbarFilterButton onResize={() => {}} onResizeCapture={() => {}} />
    </GridToolbarContainer>
  );
};

export default OrderDataGridToolbar;
