import { Alert, Button, Typography } from '@mui/material';
import { FC } from 'react';
import { comma } from '../../../../hooks/string-util';

interface Props {
  planName?: string;
  planId?: number;
  fee: number;
}

export const AlertInfo: FC<Props> = ({ planName, fee, planId }) => {
  return (
    <Alert
      severity="info"
      style={{ whiteSpace: 'pre-line', alignItems: 'center' }}
    >
      <Typography component={'span'} fontSize={14} fontWeight={400}>
        이 조건으로 모요Only를 생성하시려면{' '}
        <Typography component={'span'} fontSize={14} fontWeight={700}>
          12개월치 요금이 {comma(fee - 1000)}원 이하
        </Typography>
        여야 가능해요
        <br />
        <br />
        이유 : 같은 스펙의 자사몰 요금제 (
        <a
          href={`https://www.moyoplan.com/plans/${planId}`}
          target="_blank"
          rel="noreferrer"
        >
          <Typography
            component={'span'}
            style={{ textDecorationLine: 'underline', color: 'rgb(1, 67, 97)' }}
            fontSize={14}
            fontWeight={400}
          >
            ({planName})
          </Typography>
        </a>
        (12개월치 요금: {comma(fee)}원)) <br />
        보다 12개월치 요금이 1,000원 이상 저렴해야해요
      </Typography>
    </Alert>
  );
};
