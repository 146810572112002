import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import MvnoInfoItem from '../Common/MvnoInfoItem';
import type {
  MnoOperationPolicy,
  MnoOperationPolicyByOperatorList,
} from '../../types/mobile-plan-operator-admin-types';
import { Mno } from '../../../../entity/mno';
import { convertStringToMno } from '../../../../common/order-util';
import { MvnoInfoItemBody, MvnoInfoItemRow } from '../Common/MvnoInfoItemFrame';

const MvnoInfoMnoOperationPolicyDetailSection: FC<{
  mno: Mno;
  mnoOperationPolicyByOperator: MnoOperationPolicy;
}> = ({ mno, mnoOperationPolicyByOperator }) => {
  const title = convertStringToMno(mno);

  return (
    <MvnoInfoItemBody gap={3}>
      <Box>
        <Typography variant="body1" color="text.primary" fontWeight={700}>
          {title}망
        </Typography>
      </Box>
      <MvnoInfoItemRow gap={3}>
        <MvnoInfoItem
          title="외국인 개통 (자사몰 기준)"
          content={
            mnoOperationPolicyByOperator.signupForeigner ? '가능' : '불가능'
          }
        />
        <MvnoInfoItem
          title="미성년자 개통 (자사몰 기준)"
          content={mnoOperationPolicyByOperator.signupMinor ? '가능' : '불가능'}
        />
        <MvnoInfoItem
          fallback={{
            isActive: mnoOperationPolicyByOperator.roamingSupport == null,
            text: '입력 필요',
            color: 'error',
          }}
          title="해외 로밍 부가서비스 (기간형/일반형)"
          content={
            mnoOperationPolicyByOperator.roamingSupport ? '제공' : '미제공'
          }
        />
      </MvnoInfoItemRow>
      <MvnoInfoItemRow gap={3}>
        <MvnoInfoItem
          fallback={{
            isActive: mnoOperationPolicyByOperator.microPayment == null,
            text: '입력 필요',
            color: 'error',
          }}
          title="휴대폰 소액결제"
          content={
            mnoOperationPolicyByOperator.microPayment ? '가능' : '불가능'
          }
        />
      </MvnoInfoItemRow>
    </MvnoInfoItemBody>
  );
};

const MvnoInfoMnoOperationPolicyDetail: FC<MnoOperationPolicyByOperatorList> = (
  mnoOperationPolicyByOperatorList,
) => {
  return (
    <MvnoInfoItemBody gap={6}>
      {Object.entries(mnoOperationPolicyByOperatorList).map(
        ([key, mnoOperationPolicyByOperator]) => (
          <MvnoInfoMnoOperationPolicyDetailSection
            key={key}
            mno={key as Mno}
            mnoOperationPolicyByOperator={mnoOperationPolicyByOperator}
          />
        ),
      )}
    </MvnoInfoItemBody>
  );
};

export default MvnoInfoMnoOperationPolicyDetail;
