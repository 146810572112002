import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Chip } from '@mui/material';
import { krCurrencyFormatter } from '../../utils/valueFormatter';
import { PlanMetaAdmin } from '../../entity/plan';

export const ColumnList = () => {
  const columns: GridColDef<PlanMetaAdmin>[] = [
    {
      field: 'state',
      headerAlign: 'center',
      headerName: '상태',
      align: 'center',

      renderCell: (params) => {
        const { text, color } = getStatus(
          params.value,
          params.row.isDeleted,
          params.row.manualIsDeleted,
        );
        return <Chip color={color} label={text} size="small"></Chip>;
      },
    },
    {
      field: 'id',
      headerAlign: 'center',
      headerName: '요금제 id',
      align: 'center',
      renderCell: (params) => {
        return <span>{params.row.id}</span>;
      },
    },
    {
      field: 'name',
      headerAlign: 'center',
      headerName: '요금제 이름',
      align: 'center',
      width: 500,
    },
    {
      field: 'mno',
      headerAlign: 'center',
      headerName: '통신망',
      align: 'center',
    },
    {
      field: 'code',
      headerAlign: 'center',
      headerName: '요금제 코드',
      align: 'center',
      minWidth: 150,
    },
    {
      field: 'fee',
      headerAlign: 'center',
      headerName: '기본가',
      type: 'number',
      align: 'center',
      valueGetter: (params) => {
        if (params.row.feeAfterDiscount) {
          return params.row.feeAfterDiscount;
        } else {
          return params.value;
        }
      },
      renderCell: (params) => {
        if (params.row.feeAfterDiscount) {
          return krCurrencyFormatter.format(params.row.feeAfterDiscount);
        } else {
          return krCurrencyFormatter.format(params.value);
        }
      },
    },
    {
      field: 'feeAfterDiscount',
      headerAlign: 'center',
      headerName: '할인가',
      type: 'number',
      align: 'center',
      valueGetter: (params) => {
        if (!params.value) return 0;
        return params.row.fee;
      },
      renderCell: (params) => {
        if (!params.value) return '';

        return krCurrencyFormatter.format(params.row.fee);
      },
    },
    {
      field: 'numberOfMonthsForDiscount',
      headerAlign: 'center',
      headerName: '할인 기간',
      align: 'center',
    },
  ];
  return columns;
};

function getStatus(
  state: string,
  isDeleted: boolean,
  manualIsDeleted: boolean,
): { text: string; color: 'error' | 'warning' | 'default' | 'success' } {
  if (state === 'READY') {
    return {
      text: '진행 대기',
      color: 'error',
    };
  }

  if (state === 'CHANGE_RESERVED') {
    return {
      text: '수정 예약',
      color: isDeleted ? 'error' : 'success',
    };
  }

  if (state === 'DELETE_RESERVED') {
    return {
      text: '종료 예약',
      color: 'success',
    };
  }

  if (
    state === 'CONFIRMED' &&
    isDeleted === false &&
    manualIsDeleted === false
  ) {
    return {
      text: '진행중',
      color: 'success',
    };
  }

  if (isDeleted || manualIsDeleted) {
    return {
      text: '종료',
      color: 'error',
    };
  }

  // Default case
  return {
    text: '진행중',
    color: 'success',
  };
}
