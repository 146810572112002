import { FC } from 'react';
import { ControllerRenderProps } from 'react-hook-form';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { styled } from '@mui/material';
import { TimePickerProps } from '@mui/x-date-pickers/TimePicker';

interface Props extends ControllerRenderProps {
  label: string;
}

const CustomTimePicker = styled(TimePicker)<TimePickerProps<dayjs.Dayjs>>(
  ({ theme }) => ({
    width: '128.67px',
    '& .MuiInputBase-root': {
      paddingRight: '0px',
      '& .MuiInputAdornment-root': {
        paddingRight: '24px',
        '& .MuiIconButton-root': {
          padding: '0px',
        },
      },
    },
  }),
);

const TimePickerWithDayjs: FC<Props> = ({ label, ...field }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <CustomTimePicker
        value={field.value ? dayjs(field.value, 'HH:mm:ss') : null}
        onChange={(newValue: unknown) => {
          const dayjsValue =
            newValue && dayjs.isDayjs(newValue) ? newValue : null;
          field.onChange(dayjsValue ? dayjsValue.format('HH:mm:ss') : '');
        }}
        label={label}
      />
    </LocalizationProvider>
  );
};

export default TimePickerWithDayjs;
