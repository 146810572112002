import { ReleaseNoteItem } from './releaseNotes';
import ReleaseStatusType from './ReleaseStatusType';

const Release_2_3_0: ReleaseNoteItem = {
  version: '2.3.0',
  date: '2023-07-05',
  title: '장기간 미완료건 탭 추가',
  details: [
    {
      title: '장기간 미완료건 탭 추가',
      contents: [
        {
          content: (
            <>
              일주일 넘게 상태가 완결(취소or개통)되지 않고있는 접수건들을
              <br />
              모아볼 수 있게 되었어요.
            </>
          ),
          status: ReleaseStatusType.NEW,
        },
      ],
    },
  ],
};

export default Release_2_3_0;
