import { FC, ReactNode } from 'react';
import { useCheckPermission } from '../../../router/useCheckPermission';
import { Permission, Role } from '../../../types/AuthType';
import { useCheckRole } from '../../../router/useCheckRole';
import { useRole } from '../../../router/useRole';
import { PlanManagementMethodType } from '../../../components/MvnoInfoPage/types/mobile-plan-operator-admin-types';
import { useAuth } from '../../../context/UserContext';

interface Props {
  permission?: {
    requiredPermissions?: Permission[];
  };
  roles?: Role[];
  isMoyoAdminOnly?: boolean;
  // NOTE: moyo 계정에 대해 강제로 보여준다
  moyoAdmin?: boolean;
  children: ReactNode;
  permissionErrorFallback?: ReactNode;
  planManagementMethodList?: PlanManagementMethodType[];
}

const PermissionRender: FC<Props> = ({
  permission,
  roles = [],
  isMoyoAdminOnly,
  moyoAdmin,
  children,
  permissionErrorFallback,
  planManagementMethodList,
}) => {
  const { checkPermission } = useCheckPermission();
  const { checkRole } = useCheckRole();
  const { isMoyoRole } = useRole();
  const { planOperatorMeta } = useAuth();

  function checkPlanManagementMethod(
    planManagementMethodType?: PlanManagementMethodType,
  ) {
    if (!planManagementMethodList) return true;
    if (!planManagementMethodType) return false;
    return planManagementMethodList.includes(planManagementMethodType);
  }

  if (moyoAdmin && isMoyoRole) {
    return <>{children}</>;
  }
  if (isMoyoAdminOnly && !isMoyoRole) {
    return <>{permissionErrorFallback}</>;
  }

  if (
    !checkRole(roles) ||
    !checkPermission(permission?.requiredPermissions ?? [], [], []) ||
    !checkPlanManagementMethod(planOperatorMeta?.basicInfo.planManagementMethod)
  ) {
    return <>{permissionErrorFallback}</>;
  }

  return <>{children}</>;
};

export default PermissionRender;
