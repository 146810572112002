import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import { planApiService } from '../../../api/plan';
import { useDebounce } from '../../../common/stream';
import { PlanMetaHistory, SimplePlanMeta } from '../../../entity/plan';
import MobilePlanList from '../../../modules/MobilePlanList';
import { OrderGridColumns } from './GridColumns';
import { useSearchParams } from 'react-router-dom';
import { GridRowSelectionModel } from '@mui/x-data-grid';
import pbl from '../../../pbl/pbl-service/pbl-service';

type BaseSelectPlanPopupProps = {
  open: boolean;
  orderId: string;
  onClose: () => void;
  onSubmit?: (planId: number, planName: string, mno: string) => void;
  mvno: string;
  changePlan?: PlanMetaHistory;
};

export function BaseSelectPlanPopup({
  open,
  orderId,
  onClose,
  onSubmit,
  mvno,
  changePlan,
}: BaseSelectPlanPopupProps) {
  const [plans, setPlans] = useState<Array<SimplePlanMeta>>([]);
  const [searchText, setSearchText] = useState<string>();
  const [selectedPlanId, setSelectedPlanId] = useState<number>();
  const [searchParams, setSearchParams] = useSearchParams();

  const debouncedSearchText = useDebounce(searchText, 500);

  const fetchPlans = async () => {
    try {
      const res = await planApiService.getList(mvno, searchText);

      if (res.data.result) {
        setPlans(res.data.result);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (open) {
      fetchPlans();
    }
  }, [open, debouncedSearchText]);

  const onSearchTextChanged = (s: string) => {
    setSearchText(s);
  };

  const onSelectedPlanIdChanged = (id: number) => {
    setSelectedPlanId(id);
  };

  const onClickSubmit = async () => {
    if (!selectedPlanId) {
      closeDialog();
      return;
    }

    const selectedPlan = plans.find((plan) => plan.id === selectedPlanId);
    if (!selectedPlan) {
      closeDialog();
      return;
    }

    if (window.confirm(`${selectedPlan.name}으로 변경하시겠습니까?`)) {
      await onSubmit?.(selectedPlanId, selectedPlan.name, selectedPlan.mno);
      pbl('click', 'modal_button', 'here', {
        object: {
          id: 'change_plan',
          section: 'plan_info',
        },
        eventProperties: {
          orderId,
          newPlanId: selectedPlanId,
          newPlanName: selectedPlan.name,
        },
      });
      closeDialog();
    }
  };

  const closeDialog = () => {
    setSearchText(undefined);
    setSelectedPlanId(undefined);
    onClose();
  };

  const onSelectionChanged = (selectedIds: GridRowSelectionModel) => {
    if (selectedIds.length > 0) {
      onSelectedPlanIdChanged(parseInt(selectedIds[0].toString()));
    }
  };

  const updateSearchParams = (data: {
    [key: string]: string | undefined | null;
  }) => {
    for (const [key, value] of Object.entries(data)) {
      if (value == null) {
        searchParams.delete(key);
      } else {
        searchParams.set(key, value);
      }
    }
    searchParams.set('version', `${Date.now()}`);

    setSearchParams(searchParams);
  };

  const onCloseHandler = () => {
    pbl('click', 'modal_button', 'here', {
      object: {
        id: 'change_plan_cancel',
        section: 'plan_info',
      },
      eventProperties: {
        orderId,
      },
    });
    closeDialog();
  };

  return (
    <Dialog open={open} onClose={closeDialog} fullWidth maxWidth="md">
      <DialogTitle>요금제 변경하기</DialogTitle>
      <DialogContent>
        <DialogContentText>
          고객과 상담 중 다른 요금제로 신청한 경우, 변경된 요금제를
          선택해주세요.
        </DialogContentText>
        {changePlan && (
          <>
            <Alert severity="info" sx={{ mt: 2, mb: 2 }}>
              고객님께서 <b>{changePlan?.name}</b> <br />
              요금제로 변경 요청하였습니다.
            </Alert>
          </>
        )}
        <MobilePlanList
          plans={plans}
          onSearchTextChanged={onSearchTextChanged}
          onSelectedPlanIdChanged={onSelectedPlanIdChanged}
          gridColumns={OrderGridColumns}
          updateSearchParams={updateSearchParams}
          onSelectionChanged={onSelectionChanged}
        />
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            mr: 2,
            mb: 2,
          }}
        >
          <Button onClick={onCloseHandler} color="warning" variant="outlined">
            취소
          </Button>
          <Button onClick={onClickSubmit} variant="contained">
            저장
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
