import { useState } from 'react';
import { PlanMetaHistoryContent } from '../../../entity/plan';
import { Box, Typography, IconButton, Chip } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { generateUUID } from '../../../common/string_util';

type PlanHistoryComponentProps = {
  historyList: Array<PlanMetaHistoryContent>;
  onChangePage: (newPage: number, newSize: number) => void;
  page: number;
  size: number;
  totalCount: number;
};

const PlanHistoryComponent = ({
  historyList,
  page,
  size,
  onChangePage,
  totalCount,
}: PlanHistoryComponentProps) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const columns: GridColDef[] = [
    {
      field: 'updatedDateTime',
      headerAlign: 'left',
      headerName: '업데이트',
      align: 'left',
      width: 170,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        const yy = String(date.getFullYear()).slice(-2);
        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        const hh = String(date.getHours()).padStart(2, '0');
        const min = String(date.getMinutes()).padStart(2, '0');
        return `${yy}-${mm}-${dd} ${hh}:${min}`;
      },
    },
    {
      field: 'event',
      headerAlign: 'left',
      headerName: '분류',
      align: 'left',

      renderCell: (params: GridRenderCellParams) => {
        let text = '생성';

        if (params.value === 'NEW') {
          text = '생성';
        } else if (params.value === 'CHANGED') {
          text = '수정';
        } else if (params.value === 'REMOVED') {
          text = '종료';
        } else if (params.value === 'RECOVER') {
          text = '노출';
        } else if (params.value === 'CREATE_RESERVED') {
          text = '노출예약';
        } else if (params.value === 'CHANGE_RESERVED') {
          text = '수정예약';
        } else if (params.value === 'REMOVE_RESERVED') {
          text = '종료예약';
        } else if (params.value === 'CANCEL_CREATE_RESERVED') {
          text = '노출예약취소';
        } else if (params.value === 'CANCEL_CHANGE_RESERVED') {
          text = '수정예약취소';
        } else if (params.value === 'CANCEL_REMOVE_RESERVED') {
          text = '종료예약취소';
        }

        return text;
      },
    },
    {
      field: 'message',
      headerAlign: 'left',
      headerName: '변경내용',
      align: 'left',
      width: 450,
      renderCell: (params: GridRenderCellParams) => {
        const formattedValue = params.value
          .split('\n')
          .map((line: string, index: number) => (
            <span key={index}>
              {line}
              <br />
            </span>
          ));
        return (
          <Box
            sx={{
              whiteSpace: 'normal',
              wordBreak: 'break-word',
              paddingBottom: '10px',
            }}
          >
            {formattedValue}
          </Box>
        );
      },
    },
    {
      field: 'modifier',
      headerAlign: 'left',
      headerName: '계정',
      align: 'left',
      width: 120,
    },
  ];

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '30px 0 16px 0',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <Typography variant="h6">등록 및 수정 히스토리</Typography>

        <IconButton>
          <ExpandMoreIcon
            sx={{
              transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
              transition: 'transform 0.2s',
            }}
          />
        </IconButton>
      </Box>

      {isExpanded && (
        <DataGrid
          initialState={{
            sorting: {
              sortModel: [{ field: 'createdDateTime', sort: 'desc' }],
            },
            pagination: {
              paginationModel: {
                pageSize: size,
              },
            },
          }}
          disableColumnSelector
          disableRowSelectionOnClick
          rowCount={totalCount}
          pagination
          paginationMode="server"
          pageSizeOptions={[10, 25, 50]}
          columns={columns}
          rows={historyList}
          autoHeight
          getRowHeight={() => 'auto'}
          sx={{
            border: 'none',
            '& .MuiDataGrid-row': {
              minHeight: '52px !important',

              pointerEvents: 'none',
            },
            '& .MuiDataGrid-cell': {
              whiteSpace: 'normal',
              wordWrap: 'break-word',
              alignItems: 'flex-start',
              paddingTop: '12px',
            },
            '& .MuiDataGrid-cellContent': {
              alignItems: 'flex-start',
            },
            '& .MuiDataGrid-columnHeaders': {
              alignItems: 'flex-start',
              minHeight: '52px !important',
              '& .MuiDataGrid-columnHeader': {
                height: '100%',
                alignItems: 'flex-start',
                paddingTop: '12px',
              },
            },
          }}
          getRowId={(row) => generateUUID()}
          onPaginationModelChange={(model) =>
            onChangePage(model.page, model.pageSize)
          }
        />
      )}
    </>
  );
};

export default PlanHistoryComponent;
