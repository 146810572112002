import { GridColDef } from '@mui/x-data-grid';

import * as GridPreset from '../../utils/gridPreset';
import { DateTime } from 'luxon';
import { dateFormatter } from '../../utils/gridFormatter';

export const OrderCallTableColumns: GridColDef[] = [
  {
    ...GridPreset.createdAt_신청일,
    width: 250,
    valueFormatter: dateFormatter(DateTime.DATETIME_SHORT_WITH_SECONDS),
  },
  { ...GridPreset.status_상태, width: 180 },
  GridPreset.customerName_고객명,
  GridPreset.customerPhoneNumber_신청전화번호,
  GridPreset.mvno_통신사,
  GridPreset.planName_요금제,
];
