import { Link } from '@mui/material';
import { ReleaseNoteItem } from './releaseNotes';
import ReleaseStatusType from './ReleaseStatusType';

const Release_2_1_5_egmobile: ReleaseNoteItem = {
  version: '2.1.5_0',
  date: '2023-03-29',
  authorizedMvnos: ['egmobile'],
  title: '알림톡 보내기 기능 추가',
  details: [
    {
      title: '알림톡 보내기 기능 추가',
      contents: [
        {
          content: (
            <>
              - 주요 케이스에 대해 파트너스 사이트에서 간편하게 알림톡을 발송할
              수 있어요.
              <br />
              {`  ex: 정보가 틀려서 개통 진행이 어려울 때\n\t3개월 내 번호이동일 때\n\t타인납부 일 때 등`}
              <br />
              <br />
              - 알림톡이 발송되는 경우, 알림톡 발송 사실을 더 잘 인지할 수 있게
              + 발송된 알림톡 내용을 확인할 수 있게 개선되었어요.
              <br />
              <br />더 자세한 안내는{' '}
              <Link
                href="https://moyoplan.notion.site/for-79f8806504a6441a966df7d0dd92a2ed"
                target="__blank__"
              >
                링크
              </Link>
              에서 확인해주세요.
            </>
          ),
          status: ReleaseStatusType.NEW,
        },
      ],
    },
  ],
};

export default Release_2_1_5_egmobile;
