import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/UserContext';

type AuthErrorHandlerProps = {
  children: JSX.Element;
};

type AuthErrorState = {
  authErrorMessage?: string;
};

export default function AuthErrorHandler({ children }: AuthErrorHandlerProps) {
  const location = useLocation();
  const state = location.state as AuthErrorState;

  const navigate = useNavigate();
  const { logoutUser } = useAuth();

  useEffect(() => {
    if (state && state.authErrorMessage) {
      logoutUser();
      const redirectUrl =
        location.pathname === '/'
          ? ''
          : `?redirect-url=${encodeURIComponent(location.pathname)}`;

      alert(state.authErrorMessage);
      navigate(`/login${redirectUrl}`);
    }
  }, [state]);

  if (state && state.authErrorMessage) return null;

  return children;
}
