import { FC, useState } from 'react';
import { CSSObject, styled, Theme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import { Link, useLocation } from 'react-router-dom';
import ProfileButton from '../../modules/Header/ProfileButton';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import PushPinIcon from '@mui/icons-material/PushPin';
import DrawerNavigationListItem from './DrawerNavigationListItem';
import { Badge, IconButton } from '@mui/material';
import { useOptions } from '../../context/OptionsContext';
import ThemeSwitch from '../../shared/components/themeSwitch';
import NotificationsIcon from '@mui/icons-material/Notifications';
import useReleaseModal from '../ReleaseModal/ReleaseModal';
import DrawerNavigationChannelTalkButton from './DrawerNavigationChannelTalkButton';
import { useMvnoPolicy } from '../../hooks/useMvnoPolicy';
import { Mvno } from '../../entity/mvno';
import { useNavigationList } from '../../router/NavigationList';
import { usePermission } from '../../router/usePermission';
import { SessionTimer } from './SessionTimer';
import { useModal } from '../../hooks/useModal';
import PrivacyPolicyButton from './PrivacyPolicyButton';

const getInitialValue = () => {
  try {
    return Boolean(
      JSON.parse(localStorage.getItem('navigation_pin') || 'true'),
    );
  } catch (error) {
    console.error('json parse error-drawer navigation', error);
    return false;
  }
};

interface DrawerNavigationProps {}

const DrawerNavigation: FC<DrawerNavigationProps> = () => {
  const [open, setOpen] = useState(getInitialValue());
  const { modalContent, isModalOpen } = useModal();
  const { adminUser } = useOptions();
  const { pathname: pathName } = useLocation();
  const { fallbackPath } = usePermission();

  const policy = useMvnoPolicy(
    adminUser?.authorizedMvnos?.[0] as Mvno | undefined,
  );
  const releaseModal = useReleaseModal(adminUser?.authorizedMvnos);

  const navigationList = useNavigationList();

  const onClickOpenHandler = () => {
    setOpen((rs) => {
      try {
        localStorage.setItem('navigation_pin', `${!rs}`);
      } catch (error) {
        console.log('storage error-drawer navigation', error);
      }
      return !rs;
    });
  };

  return (
    <Drawer variant="permanent" open={open}>
      <DrawerHeader open={open}>
        <Link to={fallbackPath}>
          {open ? (
            <img width={183} height={26} src="/moyo_partners.png" />
          ) : (
            <img width={26} height={26} src="/m_partners.png" />
          )}
        </Link>
        {open ? (
          <PushPinIcon onClick={onClickOpenHandler} />
        ) : (
          <PushPinOutlinedIcon onClick={onClickOpenHandler} />
        )}
      </DrawerHeader>
      <ListFrame>
        <List id="navigation-box">
          {navigationList.map((navigationItem) => (
            <DrawerNavigationListItem
              key={navigationItem.name}
              navigationItem={navigationItem}
              activePath={pathName}
              foldedNavigation={!open}
            />
          ))}
          <DrawerNavigationChannelTalkButton foldedNavigation={!open} />
          {/* NOTE(hu.kang): 개인정보처리방침 버튼 추가 */}
          <PrivacyPolicyButton />
        </List>
      </ListFrame>
      <SessionTimer />
      <ProfileFrame>
        {open && <ThemeSwitch />}
        <div>
          {open && policy?.isDeletePersonalInfo === false && (
            <IconButton onClick={releaseModal.open}>
              <Badge badgeContent={releaseModal.newUpdate} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
          )}
          <ProfileButton
            foldedNavigation={!open}
            newUpdateCount={releaseModal.newUpdate}
          />
        </div>
      </ProfileFrame>
      {isModalOpen && modalContent}
    </Drawer>
  );
};

export default DrawerNavigation;

const drawerWidth = 280;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ open }: { open: boolean }) => ({
  padding: 24,
  height: open ? 74 : 'auto',
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: open ? 'row' : 'column',
  gap: open ? 'initial' : '10px',
  svg: {
    cursor: 'pointer',
  },
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
  '.MuiPaper-root': {
    boxShadow: theme.shadows[12],
  },
}));

const ListFrame = styled('div')`
  height: calc(100% - 128px);
  overflow: auto;
`;

const ProfileFrame = styled('div')`
  display: flex;
  padding: 16px;
  height: 64px;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
`;
