import { FC } from 'react';
import SwitchEditableMvnoInfo from '../../SwitchEditableMvnoInfo/SwitchEditableMvnoInfo';
import MvnoInfoOperationPolicyByMoyoDetail from './MvnoInfoOperationPolicyByMoyoDetail';
import MvnoInfoOperationPolicyByMoyoEdit from './MvnoInfoOperationPolicyByMoyoEdit';
import type { MnoOperationPolicyByMoyoList } from '../../types/mobile-plan-operator-admin-types';
import { useMobilePlanOperatorAdmin } from '../../hooks/useMobilePlanOperatorAdmin';
import mvnoInfoApiService, {
  MvnoInfoPutFunction,
} from '../../api/mvno-info-api-service';
import { useRole } from '../../../../router/useRole';

interface Props {
  mnoOperationPolicyByMoyoList: MnoOperationPolicyByMoyoList;
  mobilePlanOperatorId: number;
}

const MvnoInfoOperationPolicyByMoyo: FC<Props> = ({
  mnoOperationPolicyByMoyoList,
  mobilePlanOperatorId,
}) => {
  const { update } = useMobilePlanOperatorAdmin(mobilePlanOperatorId);
  const { isMoyoRole } = useRole();

  const put: MvnoInfoPutFunction<MnoOperationPolicyByMoyoList> = async (
    data: MnoOperationPolicyByMoyoList,
  ) => {
    const response = await mvnoInfoApiService.putMnoOperationPolicyByMoyoList(
      mobilePlanOperatorId,
      data,
    );

    if (response.data.result) {
      update({
        mnoOperationPolicyByMoyoList: response.data.result,
      });
    }
    return response;
  };

  const enableEdit = isMoyoRole;

  return (
    <SwitchEditableMvnoInfo
      title="망별 운영 정책 1"
      subTitle="수정은 모요에 요청해 주세요"
      detail={
        <MvnoInfoOperationPolicyByMoyoDetail
          {...mnoOperationPolicyByMoyoList}
        />
      }
      edit={
        <MvnoInfoOperationPolicyByMoyoEdit {...mnoOperationPolicyByMoyoList} />
      }
      put={put}
      enableEdit={enableEdit}
    />
  );
};

export default MvnoInfoOperationPolicyByMoyo;
