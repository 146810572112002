import { useCallback } from 'react';
import { useSearchDataContext } from '../context/SearchDataContext';
import { initialPaginationState } from '../types';
import { PaginationState } from '../types';

// NOTE: SearchSectionContext를 통해 data를 공유함
export function useSearchPagination() {
  const { paginationState, setPaginationState } = useSearchDataContext();

  const resetPaginationData = useCallback(() => {
    setPaginationState(initialPaginationState);
  }, [setPaginationState]);

  // NOTE: setPaginationStateWithParams와 방향이 다름. get은 searchParams의 값을 추출만 함
  // set은 추출후에 내부 state에 값을 세팅함. 세팅한 값들이 fetchOrders에서 사용돼야 하지만
  // 현재 구조로는 searchParams만 사용하고 있음. 이 구조를 어떻게 변경할지 고민해봐야 함.
  // searchParams가 state만 업데이트 하면 url이 변경되었을때 적절한 fetch 시점을 찾기 힘듬.
  const getPaginationDataWithParams = useCallback(
    (searchParams: URLSearchParams) => {
      let page = 0;
      const pageParams = parseInt(searchParams.get('page') ?? '');
      if (!isNaN(pageParams)) page = pageParams;

      let pageSize = 25;
      const pageSizeParams = parseInt(searchParams.get('pageSize') ?? '');
      if (!isNaN(pageSizeParams)) pageSize = pageSizeParams;

      // NOTE: 솔루션 페이지에서는 parterOrderId가 기본 정렬필드로 들어감
      const sortField = searchParams.get('sortField') ?? 'applicationStartedTs';
      const sortDirection = searchParams.get('sortDirection');
      let sort: 'asc' | 'desc' | null = 'desc';
      if (sortDirection === 'asc' || sortDirection === 'desc') {
        sort = sortDirection;
      }

      return {
        page,
        pageSize,
        sort,
        sortField,
      };
    },
    [],
  );

  const setOrders = useCallback(
    (orders: PaginationState['orders']) => {
      setPaginationState((draft) => {
        draft.orders = orders;
      });
    },
    [setPaginationState],
  );

  const setTotalCount = useCallback(
    (totalCount: PaginationState['totalCount']) => {
      setPaginationState((draft) => {
        draft.totalCount = totalCount;
      });
    },
    [setPaginationState],
  );

  const setPage = useCallback(
    (page: PaginationState['page']) => {
      setPaginationState((draft) => {
        draft.page = page;
      });
    },
    [setPaginationState],
  );

  const setPageSize = useCallback(
    (pageSize: PaginationState['pageSize']) => {
      setPaginationState((draft) => {
        draft.pageSize = pageSize;
      });
    },
    [setPaginationState],
  );

  const setSortModel = useCallback(
    (sortModel: PaginationState['sortModel']) => {
      setPaginationState((draft) => {
        draft.sortModel = sortModel;
      });
    },
    [setPaginationState],
  );

  const setPaginationStateWithParams = useCallback(
    (searchParams: URLSearchParams) => {
      const pageParams = searchParams.get('page');
      const page = parseInt(pageParams ?? '');
      if (!isNaN(page)) {
        setPage(page);
      }

      const pageSizeParams = parseInt(searchParams.get('pageSize') ?? '');
      if (!isNaN(pageSizeParams)) {
        setPageSize(pageSizeParams);
      }

      const sortField = searchParams.get('sortField');
      const sortDirection = searchParams.get('sortDirection');
      if (sortField) {
        let sort: 'asc' | 'desc' | null = null;
        if (sortDirection === 'asc' || sortDirection === 'desc')
          sort = sortDirection;
        setSortModel([{ field: sortField, sort }]);
      }
    },
    [setPage, setPageSize, setSortModel],
  );

  return {
    paginationState,
    paginationHandler: {
      resetPaginationData,
      setOrders,
      setTotalCount,
      setPage,
      setPageSize,
      setSortModel,
      setPaginationStateWithParams,
      getPaginationDataWithParams,
    },
  };
}
