import { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * 세션 관리를 위한 옵션 인터페이스
 */
interface UseSecureSessionOptions {
  /** 세션 지속 시간 (분) */
  sessionDuration?: number;
  /** 세션 만료 경고 시간 (분) */
  warningTime?: number;
  /** 세션 만료 시 실행될 콜백 함수 */
  onSessionExpired?: () => void;
  /** 경고 시간에 도달했을 때 실행될 콜백 함수 (선택사항) */
  onWarning?: () => void;
}

/** 로컬 스토리지에 저장될 키 */
const STORAGE_KEY = 'secureSessionExpireTime';
const INTERVAL_MS = 60000;
const DEFAULT_SESSION_DURATION = 60;
const DEFAULT_SESSION_DURATION_MS = DEFAULT_SESSION_DURATION * 1000;
const DEFAULT_WARNING_TIME = 5;

const getTimeRemaining = () => {
  const storedExpireTime = localStorage.getItem(STORAGE_KEY);
  if (storedExpireTime) {
    return Math.max(
      0,
      Math.floor(
        (parseInt(storedExpireTime) - Date.now()) / DEFAULT_SESSION_DURATION_MS,
      ),
    );
  }
  return DEFAULT_SESSION_DURATION;
};

/**
 * @param options - 세션 관리 옵션
 * @returns 남은 시간, 경고 상태, 세션 연장 함수를 포함한 객체
 *
 * @example
 * const { timeRemaining, isWarningActive, extendSession } = useSecureSession({
 *   sessionDuration: 60,
 *   warningTime: 5,
 *   onSessionExpired: () => console.log("세션 만료"),
 *   onWarning: () => console.log("경고: 세션 곧 만료")
 * });
 */
export function useSecureSession({
  sessionDuration = DEFAULT_SESSION_DURATION,
  warningTime = DEFAULT_WARNING_TIME,
  onSessionExpired,
  onWarning,
}: UseSecureSessionOptions = {}) {
  const location = useLocation();
  const [timeRemaining, setTimeRemaining] = useState<number>(
    getTimeRemaining(),
  );
  const [isWarningActive, setIsWarningActive] = useState(false);
  const lastLocationRef = useRef(location);

  const setExpireTime = useCallback((duration: number) => {
    const expireTime = Date.now() + duration * INTERVAL_MS;
    localStorage.setItem(STORAGE_KEY, expireTime.toString());
    return duration;
  }, []);

  const extendSession = useCallback(() => {
    setTimeRemaining(setExpireTime(sessionDuration));
    setIsWarningActive(false);
  }, [sessionDuration, setExpireTime]);

  const expireSession = useCallback(() => {
    localStorage.removeItem(STORAGE_KEY);
    if (onSessionExpired) {
      onSessionExpired();
    }
  }, [onSessionExpired]);

  // NOTE: 외부 노출 함수
  const initializeSession = useCallback(() => {
    extendSession();
  }, [extendSession]);

  const clearSession = useCallback(() => {
    expireSession();
  }, [expireSession]);

  useEffect(() => {
    const timer = setInterval(() => {
      const remaining = getTimeRemaining();
      setTimeRemaining(remaining);

      if (remaining <= 0) {
        clearInterval(timer);
        expireSession();
      } else if (remaining === warningTime && !isWarningActive) {
        setIsWarningActive(true);
        if (onWarning) {
          onWarning();
        }
      }
    }, INTERVAL_MS);

    const handleStorageChange = (e: StorageEvent) => {
      if (e.key === STORAGE_KEY) {
        setTimeRemaining(getTimeRemaining());
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      clearInterval(timer);
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [
    warningTime,
    onSessionExpired,
    onWarning,
    isWarningActive,
    expireSession,
  ]);

  useEffect(() => {
    // 컴포넌트 마운트 시 만료 시간 설정 (이미 설정되어 있지 않은 경우)
    if (!localStorage.getItem(STORAGE_KEY)) {
      setExpireTime(sessionDuration);
    }
  }, [sessionDuration, setExpireTime]);

  useEffect(() => {
    // location이 변경되었을 때만 세션 갱신
    if (location !== lastLocationRef.current) {
      extendSession();
      lastLocationRef.current = location;
    }
  }, [location, extendSession]);

  return {
    initializeSession,
    clearSession,
    timeRemaining,
    isWarningActive,
    extendSession,
  };
}
