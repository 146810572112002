const CHANNEL_TALK_CLASS_NAME = 'channeltalk__hook__launch';
const isRenderedChannelTalkScript: { ref: boolean } = { ref: false };

const CHANNEL_TALK_OPTIONS: { [key: string]: any } = {
  pluginKey: '7e4ebde5-066e-4a82-8521-282b3b9faa2c',
  customLauncherSelector: `.${CHANNEL_TALK_CLASS_NAME}`,
  hideChannelButtonOnBoot: true,
};

const setChannelTalkOptions = (profile: any) => {
  try {
    CHANNEL_TALK_OPTIONS.name = profile?.name;
    CHANNEL_TALK_OPTIONS.profile = {
      url: window.location.href,
    };
    CHANNEL_TALK_OPTIONS.profile = {
      ...CHANNEL_TALK_OPTIONS.profile,
      ...profile,
    };
  } catch (_: any) {}
};

const openChannelTalkAction = (
  interval?: NodeJS.Timer | number,
  iniCallback?: () => void,
) => {
  // @ts-ignore
  if (window.ChannelIOInitialized) {
    iniCallback && iniCallback();
    // @ts-ignore
    setTimeout(() => {
      // @ts-ignore
      window.ChannelIO('showMessenger');
    });
    clearInterval(interval);
  }
};

const openChannelTalkPannel = (iniCallback?: () => void) => {
  let count = 0;
  const interval = setInterval(() => {
    if (count > 10) {
      clearInterval(interval);
    }
    openChannelTalkAction(interval, iniCallback);
    count++;
  }, 300);

  openChannelTalkAction(interval, iniCallback);
};

interface LoadChannelTalkOptions {
  onBadgeChanged?: (count: number) => void;
}

export const onLoadChannelTalk = (
  profile?: Object,
  channelTalkEventOptions?: LoadChannelTalkOptions,
) => {
  if (profile) {
    setChannelTalkOptions(profile);
  }

  if (!isRenderedChannelTalkScript.ref) {
    const options = {
      ...CHANNEL_TALK_OPTIONS,
      profile: {
        ...CHANNEL_TALK_OPTIONS.profile,
      },
    };

    const script = document.createElement('script');
    script.innerHTML = `!function(){var n=window;if(n.ChannelIO)return(window.console.error||window.console.log||function(){})("ChannelIO script included twice.");var e=function(){e.c(arguments)};function t(){if(!n.ChannelIOInitialized){n.ChannelIOInitialized=!0;var e=document.createElement("script");e.type="text/javascript",e.async=!0,e.src="https://cdn.channel.io/plugin/ch-plugin-web.js",e.charset="UTF-8";var t=document.getElementsByTagName("script")[0];t.parentNode.insertBefore(e,t)}}e.q=[],e.c=function(n){e.q.push(n)},n.ChannelIO=e,"complete"===document.readyState?t():window.attachEvent?window.attachEvent("onload",t):(window.addEventListener("DOMContentLoaded",t,!1),window.addEventListener("load",t,!1))}()`;
    script.id = 'channeltalk';
    document.body.appendChild(script);

    if (channelTalkEventOptions) {
      channelTalkOptionsSetting(channelTalkEventOptions);
    }

    // @ts-ignore
    window.ChannelIO('boot', options, function onBoot(error, user) {
      if (channelTalkEventOptions?.onBadgeChanged) {
        channelTalkEventOptions.onBadgeChanged(user?.alert || 0);
      }
    });

    isRenderedChannelTalkScript.ref = true;
  }
};

const channelTalkOptionsSetting = (
  channelTalkEventOptions: LoadChannelTalkOptions,
) => {
  if (channelTalkEventOptions.onBadgeChanged) {
    // @ts-ignore
    window.ChannelIO?.(
      'onBadgeChanged',
      channelTalkEventOptions.onBadgeChanged,
    );
  }
};

const openChannelTalk = (initCallback?: () => void, profile?: Object) => {
  onLoadChannelTalk(profile);
  openChannelTalkPannel(initCallback);
};

export const setChannelTalkTheme = (theme: 'light' | 'dark' | 'system') => {
  // @ts-ignore
  window.ChannelIO?.('setAppearance', theme);
};

export default openChannelTalk;
