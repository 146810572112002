import { GridColDef } from '@mui/x-data-grid';
import { DateTime } from 'luxon';

import { dateFormatter } from '../../utils/gridFormatter';

import { idRenderCell } from '../../utils/gridRenderer';

import * as GridPreset from '../../utils/gridPreset';

export const OrderSelfListColumns: GridColDef[] = [
  { ...GridPreset.createdAt_신청일, width: 200 },
  GridPreset.status_상태,
  GridPreset.deliveryStatus_배송상태,
  { ...GridPreset.id_상세, renderCell: idRenderCell('/orders', '상세 보기') },
  GridPreset.customerName_고객명,
  GridPreset.birthDay_생년월일,
  GridPreset.registerType_신청유형,
  GridPreset.customerPhoneNumber_신청전화번호,
  GridPreset.orderPhoneNumber_개통전화번호,
  GridPreset.mno_통신망,
  { ...GridPreset.planName_요금제, width: 220 },
  GridPreset.memo_메모,
  {
    ...GridPreset.completedAt_개통완료일,
    valueFormatter: dateFormatter(DateTime.DATE_SHORT),
  },
  GridPreset.updatedAt_수정일,
  GridPreset.isDuplicate_기접수여부,
];
