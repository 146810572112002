export function toChunkArray<T>(
  arr: Array<T>,
  chunkSize: number,
): Array<Array<T>> {
  const result = Array<Array<T>>();
  const arrCopy = arr.slice();

  while (arrCopy.length) {
    result.push(arrCopy.splice(0, chunkSize));
  }

  return result;
}

export function intersects<T>(arr1?: Array<T>, arr2?: Array<T>): boolean {
  if (!arr1 || !arr2) return false;

  return arr1.filter((value) => arr2.includes(value)).length > 0;
}
