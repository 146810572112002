import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box, Button, Container, Typography } from '@mui/material';
import { DataGrid, GridRowParams, GridSortModel } from '@mui/x-data-grid';
import { orderApiService } from '../../api/order';
import { handleApi } from '../../common/http_util';
import { MobilePlanOrder, OrderStatus } from '../../entity/order';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import { useLoading } from '../../context/LoadingContext';
import { useOptions } from '../../context/OptionsContext';
import OrderSearchSection from '../OrderListPage/OrderSearchSection';
import ExcelDownloadPopup from '../OrderListPage/ExcelDownloadPopup';
import { OrderCallTableColumns } from './GridHeader';
import {
  pblResetSearchOrderFilter,
  pblSearchOrderFilter,
} from '../../pbl/pbl-common-service/pbl-search-order-filter';
import pbl from '../../pbl/pbl-service/pbl-service';
import { usePermission } from '../../router/usePermission';

const rows: Array<MobilePlanOrder> = [];

export default function OrderCallListPage() {
  const { isExcelPermission } = usePermission();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { setLoading } = useLoading();

  const [orders, setOrders] = useState<Array<MobilePlanOrder>>(rows);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(25);
  const [sortModel, setSortModel] = useState<GridSortModel>([]);

  const [dateStandard, setDateStandard] = useState<string>('createdAt');
  const [startDate, setStartDate] = useState<DateTime | undefined | null>(null);
  const [endDate, setEndDate] = useState<DateTime | undefined | null>(null);
  const [customerName, setCustomerName] = useState<string>('');
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState<string>('');
  const [planName, setPlanName] = useState<string>('');
  const [mno, setMno] = useState<string>('');
  const [status, setStatus] = useState<OrderStatus | null>(null);
  const [birthday, setBirthday] = useState<string>('');

  const { statusList } = useOptions();

  useEffect(() => {
    pbl('pageview', 'none', 'call_orders');
  }, []);

  const filterData = useMemo(() => {
    const startDateParam = searchParams.get('startDate');
    let startDate;
    if (startDateParam != null) {
      startDate = DateTime.fromISO(startDateParam);
    }

    const endDateParam = searchParams.get('endDate');
    let endDate;
    if (endDateParam != null) {
      endDate = DateTime.fromISO(endDateParam);
    }

    return {
      customerName: searchParams.get('customerName'),
      customerPhoneNumber: searchParams
        .get('customerPhoneNumber')
        ?.replaceAll('-', ''),
      planName: searchParams.get('planName'),
      dateStandard: searchParams.get('dateStandard'),
      orderDateTimeFrom: startDate?.toISODate(),
      orderDateTimeTo: endDate?.toISODate(),
      status: searchParams.get('status'),
      birthday: searchParams.get('birthday') || undefined,
      orderType: 'call',
    };
  }, [searchParams]);

  const fetchOrders = useCallback(async () => {
    setLoading(true);

    const data = filterData;

    let page = 0;
    const pageParams = parseInt(searchParams.get('page') ?? '');
    if (!isNaN(pageParams)) page = pageParams;

    let pageSize = 25;
    const pageSizeParams = parseInt(searchParams.get('pageSize') ?? '');
    if (!isNaN(pageSizeParams)) pageSize = pageSizeParams;

    const sortField = searchParams.get('sortField') ?? 'createdAt';
    const sortDirection = searchParams.get('sortDirection');
    let sort: 'asc' | 'desc' | null = 'desc';
    if (sortDirection === 'asc' || sortDirection === 'desc') {
      sort = sortDirection;
    }

    const orderList = await handleApi(
      () =>
        orderApiService.getList(
          data,
          page,
          pageSize,
          sortField,
          sort ?? undefined,
        ),
      navigate,
    );

    setLoading(false);

    if (!orderList) return;

    setTotalCount(orderList.totalCount);
    setOrders(orderList.list);
  }, [searchParams, filterData]);

  useEffect(() => {
    fetchOrders();
  }, [searchParams]);

  useEffect(() => {
    const startDateParam = searchParams.get('startDate');
    if (startDateParam != null) setStartDate(DateTime.fromISO(startDateParam));

    const endDateParam = searchParams.get('endDate');
    if (endDateParam != null) setEndDate(DateTime.fromISO(endDateParam));

    setDateStandard(searchParams.get('dateStandard') ?? 'createdAt');
    setCustomerName(searchParams.get('customerName') ?? '');
    setCustomerPhoneNumber(
      searchParams.get('customerPhoneNumber')?.replaceAll('-', '') ?? '',
    );
    setPlanName(searchParams.get('planName') ?? '');
    setMno(searchParams.get('mno') ?? '');
    setBirthday(searchParams.get('birthday') ?? '');

    const status = searchParams.get('status');
    if (status) {
      const newStatus = statusList.find((s: OrderStatus) => s.key === status);
      if (newStatus) {
        setStatus(newStatus);
      }
    }

    const pageParams = searchParams.get('page');
    const page = parseInt(pageParams ?? '');
    if (!isNaN(page)) {
      setPage(page);
    }

    const pageSizeParams = parseInt(searchParams.get('pageSize') ?? '');
    if (!isNaN(pageSizeParams)) {
      setPageSize(pageSizeParams);
    }

    const sortField = searchParams.get('sortField');
    const sortDirection = searchParams.get('sortDirection');
    if (sortField) {
      let sort: 'asc' | 'desc' | null = null;
      if (sortDirection === 'asc' || sortDirection === 'desc')
        sort = sortDirection;
      setSortModel([{ field: sortField, sort }]);
    }
  }, [searchParams, statusList]);

  const updateSearchParams = (data: {
    [key: string]: string | undefined | null;
  }) => {
    for (const [key, value] of Object.entries(data)) {
      if (value == null) {
        searchParams.delete(key);
      } else {
        searchParams.set(key, value);
      }
    }

    setSearchParams(searchParams);
  };

  const onClickSearch = () => {
    const searchData = {
      startDate: startDate?.toSQLDate(),
      endDate: endDate?.toSQLDate(),
      dateStandard,
      customerName,
      customerPhoneNumber,
      planName,
      mno,
      birthday,
      status: status?.key,
    };
    updateSearchParams(searchData);
    pblSearchOrderFilter(searchData);
  };

  const onClickReset = () => {
    navigate(window.location.pathname);
    setDateStandard('createdAt');
    setStartDate(null);
    setEndDate(null);
    setCustomerName('');
    setCustomerPhoneNumber('');
    setPlanName('');
    setMno('');
    setStatus(null);
    setCustomerName('');
    pblResetSearchOrderFilter();
  };

  const onStartDateChange = (date: DateTime | null | undefined) => {
    if (date == null) return;

    setStartDate(date);
  };

  const onEndDateChange = (date: DateTime | null | undefined) => {
    if (date == null) return;
    setEndDate(date);
  };

  const onCustomerPhoneNumberChange = (phoneNumber: string) => {
    setCustomerPhoneNumber(phoneNumber.replace('-', ''));
  };

  const onPageChange = (page: number) => {
    updateSearchParams({ page: page.toString() });
  };

  const onPageSizeChange = (pageSize: number) => {
    updateSearchParams({ pageSize: pageSize.toString() });
  };

  const onSortModelChange = (newModel: GridSortModel) => {
    if (newModel.length <= 0) return;

    updateSearchParams({
      sortField: newModel[0].field,
      sortDirection: newModel[0].sort?.toString(),
    });
  };

  const onClickRow = (rowParams: GridRowParams) => {
    window.open(`/orders/${rowParams.row.id}`, '_blank');
  };

  const [showExcelDownloadPopup, setShowExcelDownloadPopup] = useState(false);

  const onClickExcelDownload = () => {
    setShowExcelDownloadPopup(true);
  };

  return (
    <>
      <Container sx={{ display: 'grid', mt: 3 }} maxWidth="xl">
        <Typography variant="h5" fontWeight={'bold'}>
          전화 상담 접수
        </Typography>
        <OrderSearchSection
          dateStandard={dateStandard}
          onDateStandardChanged={setDateStandard}
          startDate={startDate}
          onStartDateChanged={onStartDateChange}
          endDate={endDate}
          onEndDateChanged={onEndDateChange}
          customerName={customerName}
          onCustomerNameChanged={setCustomerName}
          customerPhoneNumber={customerPhoneNumber}
          onCustomerPhoneNumberChanged={onCustomerPhoneNumberChange}
          planName={planName}
          onPlanNameChanged={setPlanName}
          mno={mno}
          onMnoChanged={setMno}
          status={status}
          onStatusChanged={setStatus}
          statusList={statusList.filter((s) => s.orderType === 'call')}
          birthday={birthday}
          onBirthdayChanged={setBirthday}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mt: 1,
            mb: 1,
          }}
        >
          <Button onClick={onClickSearch} variant="contained" sx={{ mr: 1 }}>
            검색
          </Button>
          <Button onClick={onClickReset} variant="outlined">
            검색 초기화
          </Button>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h5" mt={3}>
            전화 상담 신청서
          </Typography>
          {isExcelPermission && (
            <Box
              sx={{
                display: 'inline-block',
              }}
            >
              <Button onClick={onClickExcelDownload}>엑셀로 추출하기</Button>
            </Box>
          )}
        </Box>
        <DataGrid
          sx={{
            mt: 3,
            '& .MuiDataGrid-columnSeparator': {
              visibility: 'visible',
            },
          }}
          rows={orders}
          columns={OrderCallTableColumns}
          onRowClick={onClickRow}
          disableRowSelectionOnClick={true}
          autoHeight
          rowCount={totalCount}
          pagination
          paginationMode="server"
          paginationModel={{ page, pageSize }}
          onPaginationModelChange={(newModel) => {
            onPageChange(newModel.page);
            onPageSizeChange(newModel.pageSize);
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={onSortModelChange}
        />
      </Container>
      <ExcelDownloadPopup
        filterData={filterData}
        open={showExcelDownloadPopup}
        onClose={() => setShowExcelDownloadPopup(false)}
        totalCount={totalCount}
      />
    </>
  );
}
