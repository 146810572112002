import { useEffect, useState } from 'react';
import useMoyoActiveCollection from '../common/moyoActivationCollection';
import { useOptions } from '../context/OptionsContext';
import { useAuth } from '../context/UserContext';
import { Mvno } from '../entity/mvno';
import { useMvnoPolicy } from '../hooks/useMvnoPolicy';

export type NavigationPermission =
  | 'INTERNETS'
  | 'PLAN_EDIT'
  | 'SELF'
  | 'OUTLINK_SELF'
  | 'MOYO_ADMIN'
  | 'SELF_MOYO_ACTIVE'
  | 'CALL_ACTIVE'
  | 'BARO_ACTIVE'
  | 'INTERNET_BENEFIT_CONSULT'
  | 'DELETE_PERSONAL_INFO'
  | 'DELETE_PERSONAL_INFO_NOT_ALLOWED';

export function useNavigationPermission() {
  const { user } = useAuth();
  const { adminUser } = useOptions();

  const {
    isMoyoAdmin,
    isSelfMoyoActive,
    isCallActive,
    isBaroActive,
    isInternetBenefitConsult,
  } = useMoyoActiveCollection();

  const policy = useMvnoPolicy(
    adminUser?.authorizedMvnos?.[0] as Mvno | undefined,
  );

  const [navigationPermissions, setNavigationPermissions] = useState<
    NavigationPermission[]
  >([]);

  useEffect(() => {
    const company = user?.company;

    if (company == null) {
      return;
    }

    const permissions: NavigationPermission[] = [];

    if (company.internets != null) {
      permissions.push('INTERNETS');
    }

    if (company.plans != null) {
      permissions.push('PLAN_EDIT');
    }

    if (company.isSelfActive) {
      permissions.push('SELF');
    }

    if (company.isOutlinkSelfActive) {
      permissions.push('OUTLINK_SELF');
    }

    if (isMoyoAdmin) {
      permissions.push('MOYO_ADMIN');
    }

    if (isSelfMoyoActive) {
      permissions.push('SELF_MOYO_ACTIVE');
    }

    if (isCallActive) {
      permissions.push('CALL_ACTIVE');
    }

    if (isBaroActive) {
      permissions.push('BARO_ACTIVE');
    }

    if (isInternetBenefitConsult) {
      permissions.push('INTERNET_BENEFIT_CONSULT');
    }

    if (policy?.isDeletePersonalInfo) {
      permissions.push('DELETE_PERSONAL_INFO');
    } else {
      permissions.push('DELETE_PERSONAL_INFO_NOT_ALLOWED');
    }

    setNavigationPermissions(permissions);
  }, [
    user,
    isMoyoAdmin,
    isSelfMoyoActive,
    isCallActive,
    isBaroActive,
    isInternetBenefitConsult,
    policy,
  ]);

  return { navigationPermissions };
}
