import pbl from '../pbl-service/pbl-service';

type AssigneeType =
  | 'null' // 전체
  | 'false' // 담당자 없는 건
  | 'true'; // 나에게 배정된 건

const getAssigneeForEventProperty = (assignee: AssigneeType | string) => {
  switch (assignee) {
    case 'null':
      return 'all';
    case 'false':
      return 'empty';
    case 'true':
      return 'mine';
    default:
      return assignee;
  }
};

export const pblAssigneeFilter = (assignee: string) => {
  const assigneeForEventProperty = getAssigneeForEventProperty(assignee);
  pbl('click', 'filter', 'here', {
    object: {
      id: 'assignee_filter',
      section: 'action_menu',
    },
    eventProperties: {
      assignee: assigneeForEventProperty,
    },
  });
};
