export function comma(str?: number | string | null): string | undefined {
  if (!str) return str?.toString();
  str = String(str);
  return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
}

export function generateUUID(): string {
  return crypto.randomUUID();
}

export function amountStringToInt(amountString: string | number) {
  if (typeof amountString === 'number') {
    return amountString;
  }

  return Number(amountString.replace(/,/g, ''));
}

export function extractUnit(str: string): string {
  const match = str.match(/[^0-9.]+/);
  if (match) {
    return match[0].trim();
  }
  return '';
}
