import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { ControllerRenderProps } from 'react-hook-form';

const CustomMvnoInfoToggleButtonGroup = ({
  label,
  items,
  toggleButtonGroupProps,
}: {
  label: string;
  items: {
    label: string;
    value: string | boolean;
  }[];
  toggleButtonGroupProps: ControllerRenderProps;
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: 1.5,
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <Typography variant="body2" color="text.secondary">
        {label}
      </Typography>
      <ToggleButtonGroup
        color="primary"
        exclusive
        size="small"
        fullWidth
        {...toggleButtonGroupProps}
        onChange={(e, value) => {
          if (value === null) return;
          toggleButtonGroupProps.onChange?.(value);
        }}
      >
        {items.map((item) => (
          <ToggleButton key={`${item.label}-${item.value}`} value={item.value}>
            {item.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Box>
  );
};

export default CustomMvnoInfoToggleButtonGroup;
