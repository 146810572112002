import { Grid, MenuItem, Select, Typography } from '@mui/material';

import { useSearchSection } from '../hooks';
import { MnoList } from '../../../constants/Mno';

export function CurrentMnoSection() {
  const { searchState, searchHandler } = useSearchSection();

  const { currentMno } = searchState;
  const { setCurrentMno } = searchHandler;

  return (
    <Grid item xs={12} md={6}>
      <Typography mb={1}>이전 통신망</Typography>
      <Select
        fullWidth
        size="small"
        defaultValue=""
        value={currentMno}
        onChange={(event) => setCurrentMno(event.target.value)}
        displayEmpty
      >
        {MnoList.map((mno) => (
          <MenuItem key={mno.key} value={mno.value}>
            {mno.text}
          </MenuItem>
        ))}
      </Select>
    </Grid>
  );
}
