import { MoyoActivationState } from '../domains/activations/states/enum';
import { PlanMetaHistory } from './plan';

interface SelfActivationSupportPolicy {
  isSupportSelfActivate: boolean;
  isSupportPartnersActivation: boolean;
}

export type MobilePlanOrder = {
  id: number;
  status: string;
  mvno: string;
  mno: string;
  planName: string;
  customerName: string;
  customerPhoneNumber: string;
  birthday?: string;
  orderType: string;
  orderPhoneNumber: string;
  registerType?: string;
  userId?: number;
  planHistoryId: string;
  memo?: string;
  createdAt: number;
  updatedAt: number;
  applicationStartedTs: number;
  historyList: Array<MobilePlanOrderHistory>;
  changePlan?: PlanMetaHistory;
  planMeta?: PlanMetaHistory;
  moyoLink: string;
  detail?: MobilePlanOrderDetail;
  completedAt: number;
  requestedAt: number;
  agreements: Array<TermAgreement>;
  usimDelivery?: UsimDelivery;
  isSupportSelfActivate?: boolean;
  selfActivationSupportPolicy?: SelfActivationSupportPolicy;
  autoActivateState?: MoyoActivationState;
  isEsim?: boolean;
  partnerOrderId?: string;
  mvnoOrderId?: string;
  discountCode?: string;
  willPurchaseUsim?: boolean;
  orderAgency?: OrderAgency;
  orderAgencyId?: number | '';
  source?: string;
  canSendToMno?: boolean;
};

export type OrderStatusOptions = {
  statusList: Array<OrderStatus>;
};

export type OrderStatus = {
  key: string;
  name: string;
  details: Array<string>;
  orderType: string;
};

export type MobilePlanOrderHistory = {
  status: string;
  statusDetail: string;
  memo: string;
  adminUserName?: string;
  createdAt: number;
};

export type MobilePlanOrderListDto = {
  totalCount: number;
  list: Array<MobilePlanOrder>;
};

export type MobilePlanOrderDetail = {
  id: number;
  registrationNumberFirst?: string;
  registrationNumberSecond?: string;

  licenseType?: string;
  licenseIssuedAt?: string;
  driverLicense1?: string;
  driverLicense2?: string;
  driverLicense3?: string;
  driverLicense4?: string;

  /**
   * 이전 통신사 관련 정보
   */
  phoneMno?: string;
  phoneMvno?: string;
  previousCarrierCode?: string;

  certificationType?: string;
  certificationTransactionId?: string;
  ci?: string;
  di?: string;

  simType?: SimType; // 유심 최초 보유 여부
  hasUsim?: boolean;
  isNfcUsim?: boolean;
  usimModel?: string;
  usimSerialNumber?: string;

  recipientName?: string;
  recipientPhoneNumber?: string;
  postalCode?: string;
  addressLevel1?: string;
  addressLevel2?: string;
  email?: string;

  paymentNotiChannel?: string;

  paymentMethod?: string;
  bank?: string;
  accountNumber?: string;
  cardCompany?: string;
  cardNumber?: string;
  cardExpiresMonth?: string;
  cardExpiresYear?: string;

  changeCertBillingMethod?: string;
  changeCertBillingInfo?: string;
  changeCertBillingMethodSub?: string;
  changeCertBillingInfoSub?: string;

  wishNumber1?: string;
  wishNumber2?: string;

  deliveryCompany?: string;
  deliveryTrackingNumber?: string;

  penaltyFee?: number;
  installmentFee?: number;

  payerName?: string;
  payerPhoneNumber?: string;
  payerBirthday?: string;
  payerRelationship?: string;
  payerIdCardImageUrl?: string;
  payerChecked?: boolean;

  phoneModel?: string;
  phoneMemory?: string;
  phoneSerialNumber?: string;
  phoneInfoImageUrlList?: string[];

  imei?: string;
  imei2?: string;
  eid?: string;
  esimImageUrlList?: string[];

  isBaroUsim?: boolean;
};

export type TermAgreement = {
  termId: number;
  title: string;
  isAgreed: boolean;
};

export type UsimDelivery = {
  id: number;
  status: string;
  deliveryCompany?: string;
  deliveryTrackingNumber?: string;
};

export type OrderAgency = {
  id: number;
  mvno: string;
  agencyName: string;
  posCode: string;
  isDeleted: boolean;
};

export type OrderHandleAssignDto = {
  orderIds: Array<number>;
  allowOverwrite: boolean;
};

export enum SimType {
  HAS_NOT_USIM = 'HAS_NOT_USIM',
  HAS_USIM = 'HAS_USIM',
  GOING_TO_BUY_USIM = 'GOING_TO_BUY_USIM',
  ESIM = 'ESIM',
}
