import { GridColDef } from '@mui/x-data-grid';

export const OrderGridColumns: GridColDef[] = [
  {
    field: 'name',
    headerName: '요금제명',
    headerAlign: 'center',
    width: 400,
  },
  {
    field: 'mvno',
    headerName: '통신사',
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'mno',
    headerName: 'MNO망',
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'net',
    headerName: '통신망',
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'fee',
    headerName: '가격',
    headerAlign: 'center',
    align: 'center',
  },
];
