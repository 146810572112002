import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  Box,
} from '@mui/material';
import { PlanMetaAdmin } from '../../../entity/plan';
import { useCallback } from 'react';
import { planApiService } from '../../../api/plan';

type DeleteConfirmDialogProps = {
  open: boolean;
  plans: PlanMetaAdmin[];
  onClose: () => void;
  onSuccess: () => void;
};

export const ExposureSettingModal = ({
  open,
  plans,
  onSuccess,
  onClose,
}: DeleteConfirmDialogProps) => {
  const { 노출중요금제, 미노출요금제 } = plans.reduce(
    (
      acc: { 노출중요금제: PlanMetaAdmin[]; 미노출요금제: PlanMetaAdmin[] },
      plan: PlanMetaAdmin,
    ) => {
      if (!plan.display) {
        acc.미노출요금제.push(plan);
      } else {
        acc.노출중요금제.push(plan);
      }
      return acc;
    },
    { 노출중요금제: [], 미노출요금제: [] },
  );

  const handleDelete = async () => {
    const promises = 노출중요금제.map((plan) =>
      planApiService.deletePlan(plan.id),
    );
    await Promise.all(promises);
    onSuccess();
  };

  const handleExpose = async () => {
    const promises = 미노출요금제.map((plan) =>
      planApiService.restorePlan(plan.id),
    );

    await Promise.all(promises);
    onSuccess();
  };

  return (
    <Dialog aria-labelledby="alert-dialog-title" open={open} onClose={onClose}>
      <DialogTitle id="alert-dialog-title" sx={{ width: '372px' }}>
        아래 요금제 노출 상태를 변경할게요
      </DialogTitle>
      <DialogContent>
        <Box>
          <Typography variant="body1">
            <b>노출중 요금제</b>
          </Typography>
          {노출중요금제.map((plan) => (
            <Typography variant="body1">요금제 ID: {plan.id}</Typography>
          ))}
        </Box>

        <br />
        <Box>
          <Typography variant="body1">
            <b>미노출 요금제</b>
          </Typography>
          {미노출요금제.map((plan) => (
            <Typography variant="body1">요금제 ID: {plan.id}</Typography>
          ))}
        </Box>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'end' }}>
        <Button
          color={'error'}
          disabled={노출중요금제.length === 0}
          variant={'outlined'}
          onClick={handleDelete}
        >
          일괄 종료하기
        </Button>
        <Button
          color={'primary'}
          disabled={미노출요금제.length === 0}
          variant={'contained'}
          onClick={handleExpose}
        >
          일괄 노출하기
        </Button>
      </DialogActions>
    </Dialog>
  );
};
