import styled from '@emotion/styled';
import {
  Button,
  CircularProgress,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { FC, useEffect, useMemo, useState } from 'react';
import orderDetailOneTongApi, {
  SelfActivationStageInfo,
} from '../../../api/orderDetailOneTongApi';
import { useLoading } from '../../../context/LoadingContext';
import { MobilePlanOrder } from '../../../entity/order';
import pbl from '../../../pbl/pbl-service/pbl-service';
import { isPartnersActivationAvailable } from './is-partners-activation-available';
import OneTongStage from './OneTongStore';
import OrderDetailOneTongStepItem from './OrderDetailOneTongStepItem';
import { isProcessingActivationState } from '../../../shared/components/autoActivateStateChip';
import { OrderDetailOneTongCallapsed } from '../OrderDetailOneTongCallapsed';

const Frame = styled.div`
  margin-top: 16px;
`;

const ResetButton = styled(Button)`
  width: 83px;
  min-width: 83px;
  height: 36px;
`;

interface ResetButtonProps {
  onClick: () => Promise<void>;
}

const StageResetButton: FC<ResetButtonProps> = ({ onClick }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const onClickHandler = async () => {
    setLoading(true);

    try {
      await onClick();
    } finally {
      setLoading(false);
    }
  };

  return (
    <ResetButton variant="outlined" onClick={onClickHandler}>
      {loading ? <CircularProgress size={16} color="inherit" /> : '초기화'}
    </ResetButton>
  );
};

interface OrderDetailOneTongProps {
  order: MobilePlanOrder;
}

const OrderDetailOneTong: FC<OrderDetailOneTongProps> = ({ order }) => {
  const orderId = useMemo(() => `${order.id}`, [order]);
  const { setLoading } = useLoading();
  const [stages, setStages] = useState<OneTongStage[]>();
  const [isIgnoreWarning, setIsIgnoreWarning] = useState<boolean>(false);
  const _isPartnersActivationAvailable = isPartnersActivationAvailable(order);

  useEffect(() => {
    if (!stages) return;
    const currentStage = stages.find((stage) => stage.isActive);
    pbl('impression', 'partners_activation', 'here', {
      object: {
        section: 'partners_activation',
      },
      eventProperties: {
        isPartnersActivationAvailable: _isPartnersActivationAvailable,
        stage: _isPartnersActivationAvailable
          ? currentStage?.stageId
          : undefined,
      },
    });
  }, [stages]);

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        const oneTongStages: OneTongStage[] = [];
        const [stageInfoRs, stageStatusRs] = await Promise.all([
          orderDetailOneTongApi.getSelfActivationStage(orderId),
          orderDetailOneTongApi.postSelfActivationStageStatus(orderId),
        ]);

        const stageInfo = stageInfoRs.data.result;

        const stageStatus = stageStatusRs.data.result;
        stageInfo?.stages.forEach((stage) => {
          const newOneTongStage = new OneTongStage(
            orderId,
            stage.name,
            stage.subStages,
            stage.apiPath,
            stage.pollingPath,
          );
          oneTongStages[oneTongStages.length - 1]?.setNext(newOneTongStage);
          oneTongStages.push(newOneTongStage);
        });
        stageStatus?.previousList.forEach(
          (previous: SelfActivationStageInfo) => {
            const currentStage = oneTongStages.find(
              (_oneTongStage) => _oneTongStage.name === previous.name,
            );

            if (currentStage) {
              switch (previous.type) {
                case 'ERROR':
                  currentStage.errorInfo = previous.memo;
                  break;
                case 'INFO':
                  currentStage.info = previous.memo;

                  break;
              }
              currentStage.status = 'success';
            }
          },
        );
        const currentStage = oneTongStages.find(
          (stage) => stage.name === stageStatus?.currentStage.name,
        );
        if (currentStage) {
          currentStage.setActive();
          currentStage.status = stageStatus?.isFailed ? 'error' : undefined;
          currentStage.errorInfo = stageStatus?.isFailed
            ? stageStatus?.failReason
            : undefined;
          currentStage.canSendToMno = stageStatus?.canSendToMno;

          switch (stageStatus?.currentStage.type) {
            case 'ERROR':
              currentStage.status = 'error';
              currentStage.errorInfo = stageStatus?.currentStage.memo;
              break;
            case 'INFO':
              currentStage.info = stageStatus?.currentStage.memo;
              break;
          }
        }

        setStages(oneTongStages);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const onClickResetHandler = async () => {
    pbl('click', 'button', 'here', {
      object: {
        id: 'reset',
        section: 'partners_activation',
      },
      eventProperties: {
        orderId,
      },
    });
    await orderDetailOneTongApi.deleteSelfActivationStageStatus(orderId);
    stages?.forEach((stage) => {
      stage.clear();
    });
    stages?.[0]?.setActive();
  };

  if (!stages || !stages.length) {
    return <></>;
  }

  // NOTE: pbl-pageview-order-detail << isPartnersActivationAvailable 함수로 옮겨뒀는데 나중에 합칠 필요가 있을듯... 서버로 바뀌면서 한번에 되면 좋겠다
  // TODO: 판단 기준을 서버로 정책 이전하기
  if (!_isPartnersActivationAvailable) {
    return <></>;
  }

  if (
    order.autoActivateState &&
    isProcessingActivationState(order.autoActivateState) &&
    !isIgnoreWarning
  ) {
    return (
      <>
        <OrderDetailOneTongCallapsed
          state={order.autoActivateState}
          onClickIgnoreWarning={() => {
            setIsIgnoreWarning(true);
          }}
        />
        <Divider />
      </>
    );
  }

  return (
    <Frame>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h5" my={3}>
          개통하기
        </Typography>
        <StageResetButton onClick={onClickResetHandler} />
      </Box>
      <Divider />
      <Stack style={{ marginTop: 16 }} spacing={2}>
        {stages.map((stage, index) => (
          <OrderDetailOneTongStepItem
            key={stage.name}
            index={index + 1}
            order={order}
            oneTongStage={stage}
          />
        ))}
      </Stack>
    </Frame>
  );
};

export default OrderDetailOneTong;
