import { Controller, useFormContext } from 'react-hook-form';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TableCell,
  TableRow,
} from '@mui/material';

import { MobilePlanOrder } from '../../../entity/order';

interface HasUsimProps {
  order: MobilePlanOrder;
}

export function HasUsimAndType({ order }: HasUsimProps) {
  const { control } = useFormContext<MobilePlanOrder>();

  return (
    <TableRow>
      <TableCell>유심 보유 여부</TableCell>
      <TableCell>
        <FormControl>
          <Controller
            name="detail.simType"
            control={control}
            defaultValue={order.detail?.simType}
            render={({ field }) => (
              <RadioGroup {...field} row>
                <FormControlLabel
                  value={'HAS_USIM'}
                  control={<Radio />}
                  label={'유심 보유 중'}
                />
                <FormControlLabel
                  value={'HAS_NOT_USIM'}
                  control={<Radio />}
                  label="유심 미보유(택배 배송)"
                />
                <FormControlLabel
                  value={'ESIM'}
                  control={<Radio />}
                  label="eSIM"
                />
                {/* <FormControlLabel
                  value={'GOING_TO_BUY_USIM'}
                  control={<Radio />}
                  label="직접구매"
                /> */}
              </RadioGroup>
            )}
          />
        </FormControl>
      </TableCell>
      <TableCell>유심 종류</TableCell>
      <TableCell>
        <FormControl>
          <Controller
            name="detail.isNfcUsim"
            control={control}
            defaultValue={order.detail?.hasUsim ?? false}
            render={({ field }) => (
              <RadioGroup {...field} row>
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="일반 유심"
                />
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="NFC 유심"
                />
              </RadioGroup>
            )}
          />
        </FormControl>
      </TableCell>
    </TableRow>
  );
}
