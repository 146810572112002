import { useCallback } from 'react';
import { DateTime } from 'luxon';

import { SearchState, initialSearchState } from '../types';
import { useOptions } from '../../../context/OptionsContext';
import { OrderStatus } from '../../../entity/order';
import { useInternetOptions } from '../../../context/InternetOptionsContext';
import { InternetOrderStatus } from '../../../entity/internet-order';
import { useSearchDataContext } from '../context/SearchDataContext';

// NOTE: SearchSectionContext를 통해 data를 공유함
export function useSearchSection() {
  const { searchState, setSearchState } = useSearchDataContext();

  const { statusList } = useOptions();
  const { statusList: internetStatusList } = useInternetOptions();

  const resetSearchData = useCallback(() => {
    setSearchState(initialSearchState);
  }, [setSearchState]);

  const getSearchParamsData = useCallback(() => {
    return {
      startDate: searchState.startDate?.toSQLDate(),
      endDate: searchState.endDate?.toSQLDate(),
      dateStandard: searchState.dateStandard,
      customerName: searchState.customerName,
      customerPhoneNumber: searchState.customerPhoneNumber,
      planName: searchState.planName,
      mno: searchState.mno,
      status: searchState.status?.key,
      internetStatus: searchState.internetStatus?.key,
      registerType: searchState.registerType,
      memo: searchState.memo,
      isChangePlanRequest: searchState.isChangePlanRequest.toString(),
      hasUsim: searchState.hasUsim,
      birthday: searchState.birthday,
      isEsim: searchState.isEsim,
      willPurchaseUsim: searchState.willPurchaseUsim,
      mvno: searchState.mvno,
      유심도착후_진행되지않은일자: searchState.유심도착후_진행되지않은일자,
      iptvBundle: searchState.iptvBundle?.toString(),
      currentMno: searchState.currentMno,
      orderHandlerId: searchState.orderHandlerId?.toString(),
      hasOrderHandler: searchState.hasOrderHandler?.toString(),
    };
  }, [searchState]);

  const setDateStandard = useCallback(
    (dateStandard: SearchState['dateStandard'] | null) => {
      setSearchState((draft) => {
        draft.dateStandard = dateStandard ?? 'createdAt';
      });
    },
    [setSearchState],
  );

  const setCustomerName = useCallback(
    (customerName: SearchState['customerName'] | null) => {
      setSearchState((draft) => {
        draft.customerName = customerName ?? '';
      });
    },
    [setSearchState],
  );

  const setStartDate = useCallback(
    (startDate?: SearchState['startDate'] | null) => {
      if (startDate === null) return;

      setSearchState((draft) => {
        draft.startDate = startDate;
      });
    },
    [setSearchState],
  );

  const setEndDate = useCallback(
    (endDate?: SearchState['endDate'] | null) => {
      if (endDate == null) return;

      setSearchState((draft) => {
        draft.endDate = endDate;
      });
    },
    [setSearchState],
  );

  const setCustomerPhoneNumber = useCallback(
    (customerPhoneNumber: SearchState['customerPhoneNumber'] | null) => {
      let phoneNumber = customerPhoneNumber?.replaceAll('-', '') ?? '';
      if (phoneNumber.startsWith('10')) {
        phoneNumber = phoneNumber.replace(/10/g, '010');
      }

      setSearchState((draft) => {
        draft.customerPhoneNumber = phoneNumber;
      });
    },
    [setSearchState],
  );

  const setPlanName = useCallback(
    (planName: SearchState['planName'] | null) => {
      setSearchState((draft) => {
        draft.planName = planName ?? '';
      });
    },
    [setSearchState],
  );

  const setMno = useCallback(
    (mno: SearchState['mno'] | null) => {
      setSearchState((draft) => {
        draft.mno = mno ?? '';
      });
    },
    [setSearchState],
  );

  const setStatus = useCallback(
    (status: SearchState['status'] | null) => {
      setSearchState((draft) => {
        draft.status = status;
      });
    },
    [setSearchState],
  );

  const setInternetStatus = useCallback(
    (internetStatus: SearchState['internetStatus'] | null) => {
      setSearchState((draft) => {
        draft.internetStatus = internetStatus;
      });
    },
    [setSearchState],
  );

  const setRegisterType = useCallback(
    (registerType: SearchState['registerType'] | null) => {
      setSearchState((draft) => {
        draft.registerType = registerType ?? '';
      });
    },
    [setSearchState],
  );

  const setHasUsim = useCallback(
    (hasUsim: SearchState['hasUsim'] | null) => {
      setSearchState((draft) => {
        draft.hasUsim = hasUsim ?? '';
      });
    },
    [setSearchState],
  );

  const setIsEsim = useCallback(
    (isEsim: SearchState['isEsim'] | null) => {
      setSearchState((draft) => {
        draft.isEsim = isEsim ?? '';
      });
    },
    [setSearchState],
  );
  const setWillPurchaseUsim = useCallback(
    (willPurchaseUsim: SearchState['willPurchaseUsim'] | null) => {
      setSearchState((draft) => {
        draft.willPurchaseUsim = willPurchaseUsim ?? '';
      });
    },
    [setSearchState],
  );
  const setMvno = useCallback(
    (mvno: SearchState['mvno'] | null) => {
      setSearchState((draft) => {
        draft.mvno = mvno ?? '';
      });
    },
    [setSearchState],
  );

  const set유심도착후_진행되지않은일자 = useCallback(
    (
      유심도착후_진행되지않은일자:
        | SearchState['유심도착후_진행되지않은일자']
        | null,
    ) => {
      setSearchState((draft) => {
        draft.유심도착후_진행되지않은일자 = 유심도착후_진행되지않은일자 ?? '';
      });
    },
    [setSearchState],
  );

  const setIsChangePlanRequest = useCallback(
    (isChangePlanRequest: SearchState['isChangePlanRequest'] | null) => {
      setSearchState((draft) => {
        draft.isChangePlanRequest = isChangePlanRequest ?? false;
      });
    },
    [setSearchState],
  );

  const setBirthday = useCallback(
    (birthday: SearchState['birthday'] | null) => {
      setSearchState((draft) => {
        draft.birthday = birthday ?? '';
      });
    },
    [setSearchState],
  );
  const setMemo = useCallback(
    (memo: SearchState['memo'] | null) => {
      setSearchState((draft) => {
        draft.memo = memo ?? '';
      });
    },
    [setSearchState],
  );

  const setCurrentMno = useCallback(
    (currentMno: SearchState['currentMno'] | null) => {
      console.log('currentMno', currentMno);
      setSearchState((draft) => {
        draft.currentMno = currentMno ?? '';
      });
    },
    [setSearchState],
  );

  const setOrderHandlerId = useCallback(
    (orderHandlerId: string | undefined | null) => {
      const id = orderHandlerId ? Number(orderHandlerId) : undefined;
      setSearchState((draft) => {
        draft.orderHandlerId = id;
      });
    },
    [setSearchState],
  );

  const setHasOrderHandler = useCallback(
    (hasOrderHandler: string | null) => {
      const value = !hasOrderHandler ? null : hasOrderHandler === 'true';
      setSearchState((draft) => {
        draft.hasOrderHandler = value;
      });
    },
    [setSearchState],
  );

  const setIptvBundle = useCallback(
    (iptvBundle: SearchState['iptvBundle'] | null) => {
      setSearchState((draft) => {
        draft.iptvBundle = iptvBundle;
      });
    },
    [setSearchState],
  );

  const onChangeUsim = useCallback(
    (value: string) => {
      if (value === 'eSIM') {
        setIsEsim('true');
        setWillPurchaseUsim(undefined);
        setHasUsim('');
      } else if (value === 'willPurchaseUsim') {
        setWillPurchaseUsim('true');
        setIsEsim(undefined);
        setHasUsim('');
      } else if (!value) {
        setIsEsim(undefined);
        setWillPurchaseUsim('false');
        setHasUsim(value);
      } else {
        setIsEsim('false');
        setWillPurchaseUsim('false');
        setHasUsim(value);
      }
    },
    [setHasUsim, setIsEsim, setWillPurchaseUsim],
  );

  const setSearchStateWithParams = (searchParams: URLSearchParams) => {
    // TODO: searchParams를 한 번에 useReducer에서 처리하도록 변경하기
    const startDateParam = searchParams.get('startDate');
    if (startDateParam != null) setStartDate(DateTime.fromISO(startDateParam));

    const endDateParam = searchParams.get('endDate');
    if (endDateParam != null) setEndDate(DateTime.fromISO(endDateParam));

    setDateStandard(searchParams.get('dateStandard'));
    setCustomerName(searchParams.get('customerName'));
    setCustomerPhoneNumber(searchParams.get('customerPhoneNumber'));
    setPlanName(searchParams.get('planName'));
    setMno(searchParams.get('mno'));
    setRegisterType(searchParams.get('registerType'));
    setHasUsim(searchParams.get('hasUsim'));
    setIsEsim(searchParams.get('isEsim'));
    setWillPurchaseUsim(searchParams.get('willPurchaseUsim'));
    setCurrentMno(searchParams.get('currentMno'));

    setMvno(searchParams.get('mvno'));
    set유심도착후_진행되지않은일자(
      searchParams.get('유심도착후_진행되지않은일자'),
    );
    setBirthday(searchParams.get('birthday'));
    setMemo(searchParams.get('memo'));
    setIsChangePlanRequest(searchParams.get('isChangePlanRequest') === 'true');
    setOrderHandlerId(searchParams.get('orderHandlerId'));
    setHasOrderHandler(searchParams.get('hasOrderHandler'));
    if (searchParams.get('iptvBundle') !== null) {
      setIptvBundle(searchParams.get('iptvBundle') === 'true');
    }

    const status = searchParams.get('status');

    if (status) {
      const newStatus = statusList.find((s: OrderStatus) => s.key === status);
      if (newStatus) {
        setStatus(newStatus);
      }
    }

    const internetStatus = searchParams.get('internetStatus');
    if (internetStatus) {
      const newStatus = internetStatusList.find(
        (s: InternetOrderStatus) => s.key === internetStatus,
      );
      if (newStatus) {
        setInternetStatus(newStatus);
      }
    }
  };

  return {
    searchState,
    searchHandler: {
      resetSearchData,
      setSearchStateWithParams,
      setDateStandard,
      setCustomerName,
      setStartDate,
      setEndDate,
      setCustomerPhoneNumber,
      setPlanName,
      setMno,
      setStatus,
      setInternetStatus,
      setRegisterType,
      setHasUsim,
      setIsEsim,
      setWillPurchaseUsim,
      setMvno,
      set유심도착후_진행되지않은일자,
      setIsChangePlanRequest,
      setBirthday,
      setMemo,
      setOrderHandlerId,
      setHasOrderHandler,
      setCurrentMno,
      setIptvBundle: setIptvBundle,
      getSearchParamsData,
      onChangeUsim,
    },
  };
}
