export const feeCalculator = (
  fee: number | undefined,
  feeAfterDiscount: number | undefined,
  promotionPeriod: number | undefined,
  discountType: string,
) => {
  if (discountType === '평생할인 요금제' && fee !== undefined) {
    return fee * 12;
  }
  if (
    promotionPeriod === undefined ||
    fee === undefined ||
    feeAfterDiscount === undefined
  )
    return 0;
  if (promotionPeriod < 12) {
    return fee * promotionPeriod + feeAfterDiscount * (12 - promotionPeriod);
  } else if (promotionPeriod >= 12) {
    return fee * 12;
  }

  return 0;
};
