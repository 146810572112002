import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import React, {
  FormEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import slackBot from '../../api/slackBot';
import styled from '@emotion/styled';
import { fetchCompanyInfo } from '../../common/fetch-company';
import { MoyoOnlyApiService } from '../../api/moyoOnly';
import { FormProvider, useForm } from 'react-hook-form';
import {
  AdditionalCall,
  FeeInformationInputs,
  MnoSelect,
  PartnersInfo,
  PlanDataInputs,
  SpecialCase,
  TetheringService,
} from './components';
import { KtNetworkRegistered } from './components/KtNetworkRegistered';
import { CreateMoyoPlanTypes } from './type';
import pbl from '../../pbl/pbl-service/pbl-service';
import PlanName from './components/PlanName';
import { NetworkSelect } from './components/Select/NetworkSelect';
import { ValidateButton } from './components/Button/ValidateButton';
import { AlertDialog } from './components/Dialog/AlertDialog';
import { CalculatorFeeButton } from './components/FeeInput/CalculatorFeeButton';
import { CreatePlanButton } from './components/Button/CreatePlanButton';
import CreatePlanDialog from './components/Dialog/CreatePlanDialog';
import { DateTime } from 'luxon';
import { AlertInfo } from './components/FeeInput/AlertInfo';
import { DangerCallout } from './components/FeeInput/DangerCallout';
import { feeCalculator } from './hook/feeCalculator';
import { useAuth } from '../../context/UserContext';
import { PlanMetaV2 } from '../../entity/plan-v2';

export const _ReuqiredTypography = styled(Typography)`
  ::after {
    content: '*';
    color: #d32f2f;
  }
`;

export const _AdornTextField = styled(TextField)`
  .Mui-error .MuiTypography-root {
    color: #d32f2f;
  }
  .Mui-focused:not(.Mui-error) .MuiTypography-root {
    color: #1976d2;
  }
`;

const plan = {
  name: '',
  carrier: '',
  mno: 'LGU',
  dataSpeed: null,
  dataSpeed2: null,
  throttledSpeed: null,
  call: null,
  textMessage: null,
  fee: null,
  feeAfterDiscount: null,
  promotionPeriod: null,
  code: '',
  partnerSignupUrl: '',
  additionalCall: null,
  isSpecialCase: false,
  specialGroupName: null,
  tethering: false,
  tetheringAmount: null,
  tetheringDailyAmount: null,
  network: 'LTE',
  isKtNetworkRegistered: false,
};

export const NewMoyoOnlyPage = () => {
  const [discountType, setDiscountType] = useState<
    '할인 요금제' | '평생할인 요금제' | string
  >('할인 요금제');
  const [validateFee, setValidateFee] = useState<number>(0);
  const [guardRailFee, setGuardRailFee] = useState<number>(0);
  const [companyName, setCompanyName] = useState<string>('');
  const [isDone, setIsDone] = useState({
    first: false,
    second: false,
    third: false,
  });
  const [dialog, setDialog] = useState<{
    title: string;
    text: string;
    isOpen: boolean;
    cancelText?: string;
    okText?: string;
    onOk?: () => void;
    onCancel?: () => void;
  }>({
    title: '',
    text: '',
    isOpen: false,
    cancelText: '',
    okText: '',
    onOk: () => undefined,
    onCancel: () => undefined,
  });
  const [isModifying, setIsModifying] = useState({
    first: false,
    second: false,
  });
  const form = useForm<CreateMoyoPlanTypes>({ defaultValues: plan });
  const [guardRailPlan, setGuardRailPlan] = useState<PlanMetaV2>();
  const [isNotConfirm, setIsNotConfirm] = useState<boolean>(false);
  const { watch, handleSubmit, setValue, getValues } = form;
  const {
    fee,
    feeAfterDiscount,
    promotionPeriod,
    call,
    name,
    mno,
    network,
    textMessage,
    dataSpeed,
    dataSpeed2,
    partnerSignupUrl,
    code,
  } = getValues();
  const { throttledSpeed } = watch();
  const secondStepRef = useRef<HTMLDivElement>(null);
  const thirdStepRef = useRef<HTMLDivElement>(null);

  const planFee = Number(fee?.toString().replace(',', ''));
  const planFeeAfter = Number(feeAfterDiscount?.toString().replace(',', ''));
  const planPromotion = Number(promotionPeriod?.toString().replace(',', ''));
  const { user } = useAuth();

  useEffect(() => {
    fetchCompanyInfo().then((res) => {
      if (res?.plans) {
        setCompanyName(res.plans[0]);
      }
    });
  }, []);

  useEffect(() => {
    fetchCompanyInfo().then((res) => {
      const mvnoMeta = { ...res?.plans };
      setValue('carrier', mvnoMeta[0]);
    });
  }, []);

  useEffect(() => {
    if (
      guardRailPlan?.fee &&
      guardRailPlan.할인끝난후_금액 !== null &&
      guardRailPlan.할인적용_개월수 !== null
    ) {
      onCreateGuardRail({
        fee: guardRailPlan?.fee,
        feeAfterDiscount: guardRailPlan?.할인끝난후_금액,
        promotionPeriod: guardRailPlan?.할인적용_개월수,
      });
    } else if (
      guardRailPlan?.할인끝난후_금액 === null &&
      guardRailPlan.할인적용_개월수 === null &&
      guardRailPlan.fee !== null
    ) {
      setGuardRailFee(guardRailPlan.fee * 12);
    }
  }, [guardRailPlan]);

  useEffect(() => {
    pbl('pageview', 'none', 'plans_moyoonly_create');
  }, []);

  const SendSlackBot = async (
    plan: CreateMoyoPlanTypes | undefined,
    guardRailId: number | undefined,
    date: string,
    name: string,
    isReservation: boolean,
    validateFee: number,
    guardRailFee: number,
    responseId: number,
  ) => {
    await slackBot.sendNewPlan(
      plan,
      guardRailId,
      date,
      name,
      isReservation,
      validateFee,
      guardRailFee,
      responseId,
    );
  };

  const onSubmit = async (
    plan: CreateMoyoPlanTypes,
    date: DateTime,
    isReservation: boolean,
  ) => {
    const planFee = plan.fee?.toString().replace(',', '');
    const planFeeAfter = plan.feeAfterDiscount?.toString().replace(',', '');
    const planPromotion = plan.promotionPeriod?.toString().replace(',', '');
    const requestBody = {
      ...plan,
      throttledSpeed: plan.throttledSpeed ? plan.throttledSpeed * 1000 : null,
      fee: Number(planFee),
      feeAfterDiscount: Number(planFeeAfter),
      promotionPeriod: Number(planPromotion),
    };
    try {
      if (isReservation) {
        const createReserveDate = date.toString().slice(0, 19);
        const response = await MoyoOnlyApiService.CreateMoyoOnlyPlanReserve(
          plan.carrier,
          JSON.stringify(requestBody),
          createReserveDate,
        );

        setDialog({
          title: '생성 예약이 등록되었어요',
          text: '',
          isOpen: true,
          onCancel: () => window.location.reload(),
        });
        SendSlackBot(
          plan,
          guardRailPlan?.id,
          createReserveDate,
          name,
          isReservation,
          validateFee,
          guardRailFee,
          0,
        );
      } else {
        const response = await MoyoOnlyApiService.CreateMoyoOnlyPlan(
          plan.carrier,
          JSON.stringify(requestBody),
        );
        const responsePlanId = response.data.result?.id;
        setDialog({
          title: '요금제가 바로 생성되었어요',
          text: '',
          isOpen: true,
          cancelText: '닫기',
          okText: '모요 링크 확인하기',
          onCancel: () => window.location.reload(),
          onOk: () =>
            window.open(`https://www.moyoplan.com/plans/${responsePlanId}`),
        });
        SendSlackBot(
          plan,
          guardRailPlan?.id,
          '',
          name,
          isReservation,
          validateFee,
          guardRailFee,
          Number(responsePlanId),
        );
      }
    } catch (error) {
      console.error(error);
      showPriceErrorDialog('요금제 생성 실패', '모요로 문의주시길 바랍니다');
    }
  };

  // 현 쉐이크 구 미니게이트의 경우에는 가드레일 금액이 맞지 않아도 오류처리 하지 않는 임시로직 처리
  const isShakeMobile = () => {
    return !!user?.company?.mvnos?.includes('쉐이크모바일');
  };

  const CommonValidationFeeInfo = (
    fee: number,
    feeAfterDiscount: number,
    promotionPeriod: number,
  ) => {
    // 할인기간 반영해서 12개월 요금을 계산하는 로직임
    const validateFee = feeCalculator(
      fee,
      feeAfterDiscount,
      promotionPeriod,
      '',
    );

    if (discountType === '평생할인 요금제' && fee > 100000) {
      showPriceErrorDialog(
        '요금제 가격이\n10만원보다 비싸요',
        '가격 정보가 맞는지 확인해주세요',
      );
    }
    if (discountType === '할인 요금제' && fee > feeAfterDiscount) {
      showPriceErrorDialog(
        '할인 시 가격이\n할인 끝난 가격보다 비싸요',
        '가격 정보를 수정해주세요',
      );
    } else if (fee > 100000) {
      showPriceErrorDialog(
        '할인 시 가격이\n10만원보다 비싸요',
        '가격 정보가 맞는지 확인해주세요',
      );
    } else if (feeAfterDiscount > 100000) {
      showPriceErrorDialog(
        '할인 끝난 가격이\n10만원보다 비싸요',
        '가격 정보가 맞는지 확인해주세요',
      );
    } else if (promotionPeriod > 36) {
      showPriceErrorDialog(
        '할인기간은 최대 36개월을 넘을 수 없어요',
        '할인기간을 올바르게 적어주세요',
      );
    } else if (
      guardRailFee - validateFee > 1000 ||
      guardRailFee === 0 ||
      isShakeMobile()
    ) {
      validateGuardRail();
      setTimeout(() => {
        thirdStepRef?.current?.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    } else {
      setIsNotConfirm(true);
    }
  };

  const validateGuardRail = () => {
    setIsNotConfirm(false);
    setIsDone({ ...isDone, second: true });
  };

  const showPriceErrorDialog = (title: string, text: string) => {
    setDialog({
      title: title,
      text: text,
      isOpen: true,
      onCancel: () => setDialog({ title: '', text: '', isOpen: false }),
    });
  };

  const onCreateClick = () => {
    setIsDone({ ...isDone, third: true });
  };

  const isValidate = () => {
    if (
      watch('name') &&
      watch('call') &&
      watch('textMessage') &&
      (watch('dataSpeed') || watch('dataSpeed2'))
    ) {
      return true;
    } else {
      return false;
    }
  };

  const onCreateGuardRail = useCallback(
    ({
      fee,
      feeAfterDiscount,
      promotionPeriod,
    }: {
      fee: number | undefined;
      feeAfterDiscount: number | undefined;
      promotionPeriod: number | undefined;
    }) => {
      if (discountType === '평생할인 요금제' && fee !== undefined) {
        return setGuardRailFee(fee * 12);
      }
      if (
        promotionPeriod === undefined ||
        fee === undefined ||
        feeAfterDiscount === undefined
      )
        return;
      if (promotionPeriod < 12) {
        return setGuardRailFee(
          fee * promotionPeriod + feeAfterDiscount * (12 - promotionPeriod),
        );
      } else if (promotionPeriod >= 12) {
        return setGuardRailFee(fee * 12);
      }
    },
    [plan],
  );

  //첫번째 요금제 정보 플로우//////////////////////////
  const createGuardRail = async (
    mvno: string,
    mno: string,
    network: string,
    dataSpeed: number,
    dataSpeed2: number,
    voice: number,
    message: number,
  ) => {
    const requestData = {
      mvno: mvno,
      mno: mno,
      net: network === '5G' ? '_5G' : 'LTE',
      mobile_data: dataSpeed,
      mobile_data2: dataSpeed2,
      voice: voice,
      message: message,
    };
    try {
      const response = await MoyoOnlyApiService.getSimilarPlanWhenCreate({
        ...requestData,
      });
      setGuardRailPlan(response.data.result);
    } catch (error) {
      console.log('요금제 없음');
    }
  };

  const isDoneFunction = (throttledSpeed: number) => {
    if (isDone.first === false) {
      if (throttledSpeed > 5) {
        showPriceErrorDialog(
          '소진 시 속도는 5 Mbps를 넘을 리 없어요',
          '정보를 올바르게 입력했는지 다시 확인해보세요',
        );
      } else {
        setTimeout(() => {
          secondStepRef?.current?.scrollIntoView({ behavior: 'smooth' });
        }, 100);
        setIsDone({ ...isDone, first: true });
      }
    }
  };

  const ModifyValue = () => {
    if (isDone.first) {
      setIsModifying({ ...isModifying, first: true });
      setIsDone({ ...isDone, first: false, second: false });
    }
  };

  const ModifyValueSecond = () => {
    if (isDone.second) {
      setIsModifying({ ...isModifying, second: true });
      setIsDone({ ...isDone, second: false });
    }
  };
  //////////////////////////////

  const isEmptyValue = () => {
    const fee = watch('fee');
    const feeAfterDiscount = watch('feeAfterDiscount');
    const promotionPeriod = watch('promotionPeriod');
    const feeValid = !Number.isNaN(fee) && fee !== null;
    const feeAfterDiscountValid =
      !Number.isNaN(feeAfterDiscount) && feeAfterDiscount !== null;
    const promotionPeriodValid =
      !Number.isNaN(promotionPeriod) && promotionPeriod !== null;
    if (
      discountType === '할인 요금제' &&
      feeValid &&
      feeAfterDiscountValid &&
      promotionPeriodValid
    ) {
      return false;
    }

    if (discountType === '평생할인 요금제' && feeValid) {
      return false;
    }
    return true;
  };

  const ValidateFee = (
    fee: number | null,
    feeAfterDiscount: number | null,
    promotionPeriod: number | null,
  ) => {
    setValidateFee(
      feeCalculator(
        fee as number,
        feeAfterDiscount as number,
        promotionPeriod as number,
        discountType,
      ),
    );
  };

  const CreatePlanFlow = () => {
    const FirstStepCreateGuardRail = () => {
      createGuardRail(
        companyName,
        mno as string,
        network as string,
        dataSpeed as number,
        watch('dataSpeed2') as number,
        call as number,
        textMessage as number,
      );
      isDoneFunction(throttledSpeed as number);
      ModifyValue();
    };
    const SecondStepValidationFee = (
      fee: number | null,
      feeAfterDiscount: number | null,
      promotionPeriod: number | null,
    ) => {
      ValidateFee(fee, feeAfterDiscount, promotionPeriod);
      CommonValidationFeeInfo(
        fee as number,
        feeAfterDiscount as number,
        promotionPeriod as number,
      );
      ModifyValueSecond();
      return {
        isEmptyValue,
      };
    };

    const ThirdValidateAdditionalInfo = () => {
      onCreateClick();
    };

    const isThirdEmptyValue = () => {
      if (watch('tethering')) {
        if (
          watch('partnerSignupUrl') !== '' &&
          watch('code') !== '' &&
          watch('tetheringAmount')
        ) {
          return false;
        }
      } else if (watch('partnerSignupUrl') !== '' && watch('code') !== '') {
        return false;
      }
      return true;
    };

    const onCreateSubmit = (
      plan: CreateMoyoPlanTypes,
      date: DateTime,
      isReservation: boolean,
    ) => {
      onSubmit(plan, date, isReservation);
    };
    return {
      FirstStepCreateGuardRail,
      SecondStepValidationFee,
      ThirdValidateAdditionalInfo,
      isThirdEmptyValue,
      onCreateSubmit,
    };
  };
  return (
    <FormProvider {...form}>
      <div style={{ display: 'flex', height: '100%' }}>
        <Box
          sx={{
            '& > div': { marginBottom: '12px' },
            margin: '2rem auto auto auto',
            width: '75%',
            minWidth: '75%',
          }}
        >
          <Typography variant="h4" m={0}>
            모요 Only 요금제 생성
          </Typography>
          <Grid
            component={'section'}
            display={'flex'}
            flexDirection={'column'}
            gap={'72px'}
            mt={'52px'}
          >
            <PlanName isDone={isDone.first} />
            <Grid>
              <Grid container gap={'32px'}>
                <MnoSelect isDone={isDone.first} />
                <NetworkSelect isDone={isDone.first} />
              </Grid>
              {watch('mno') === 'KT' && (
                <KtNetworkRegistered
                  isKtNetworkRegistered={plan.isKtNetworkRegistered}
                />
              )}
            </Grid>
            <PlanDataInputs isDone={isDone.first} />
            <ValidateButton
              key={1}
              isDone={isDone.first}
              onClick={() => {
                CreatePlanFlow().FirstStepCreateGuardRail();
              }}
              isValidated={isValidate()}
            />
          </Grid>
          {(isDone.first || (isModifying.first === false && isDone.first)) && (
            <>
              <div
                style={{
                  width: '100%',
                  height: '1px',
                  background: '#bdbdbd',
                  margin: '75px 0 56px 0',
                }}
              />
              <Grid
                component={'section'}
                display={'flex'}
                flexDirection={'column'}
                ref={secondStepRef}
              >
                <Typography variant="h6">가격 정보 등록</Typography>
                {guardRailFee !== 0 && (
                  <AlertInfo
                    planId={guardRailPlan?.id}
                    planName={guardRailPlan?.name}
                    fee={guardRailFee}
                  />
                )}
                <FeeInformationInputs
                  isDisabled={isDone.second}
                  discountType={discountType}
                  setDiscountType={setDiscountType}
                />
                <CalculatorFeeButton
                  onValidationClick={() => {
                    ValidateFee(planFee, planFeeAfter, planPromotion);
                  }}
                  fee={validateFee}
                  isDisabled={isEmptyValue()}
                />
                {isNotConfirm && <DangerCallout />}
                <ValidateButton
                  key={2}
                  style={{ marginTop: '15px' }}
                  isDone={isDone.second}
                  onClick={() => {
                    CreatePlanFlow().SecondStepValidationFee(
                      planFee,
                      planFeeAfter,
                      planPromotion,
                    );
                  }}
                  isValidated={!isEmptyValue()}
                />
              </Grid>
            </>
          )}
          {(isDone.second ||
            (isModifying.second === false &&
              isDone.second &&
              isModifying.first === false)) && (
            <>
              <div
                style={{
                  width: '100%',
                  height: '1px',
                  background: '#bdbdbd',
                  margin: '85px 0 66px 0',
                }}
              />
              <Grid
                display={'flex'}
                flexDirection={'column'}
                ref={thirdStepRef}
              >
                <Typography variant="h6">부가정보</Typography>
                <Grid container>
                  <AdditionalCall />
                  <TetheringService />
                </Grid>
                <SpecialCase />
                <PartnersInfo />
                <CreatePlanButton
                  key={3}
                  isNotNullValidated={CreatePlanFlow().isThirdEmptyValue()}
                  isDone={isDone.third}
                  onClick={() => {
                    CreatePlanFlow().ThirdValidateAdditionalInfo();
                  }}
                />
              </Grid>
            </>
          )}
          <AlertDialog
            onCancel={dialog.onCancel}
            isOpen={dialog.isOpen}
            title={dialog.title}
            text={dialog.text}
            okText={dialog.okText}
            onOk={dialog.onOk}
            cancelText={dialog.cancelText}
          />
          <CreatePlanDialog
            open={isDone.third}
            onSuccess={(date: DateTime, isReservation: boolean) => {
              CreatePlanFlow().onCreateSubmit(getValues(), date, isReservation);
              setIsDone({ ...isDone, third: false });
            }}
            onClose={() => setIsDone({ ...isDone, third: false })}
          />
        </Box>
      </div>
    </FormProvider>
  );
};
