import { GridColDef } from '@mui/x-data-grid';

import { idRenderCell } from '../../utils/gridRenderer';

import * as GridPreset from '../../utils/gridPreset';

export const OrderTableColumns: GridColDef[] = [
  GridPreset.createdAt_신청일,
  GridPreset.status_상태,
  { ...GridPreset.id_상세, renderCell: idRenderCell('/orders') },
  GridPreset.customerName_고객명,
  GridPreset.birthDay_생년월일,
  GridPreset.registerType_신청유형,
  GridPreset.customerPhoneNumber_신청전화번호,
  GridPreset.orderPhoneNumber_개통전화번호,
  GridPreset.hasUsim_유심보유,
  GridPreset.mno_통신망,
  GridPreset.planName_요금제,
  GridPreset.memo_메모,
  GridPreset.completedAt_개통완료일,
  GridPreset.requestedAt_개통요청일,
  GridPreset.updatedAt_수정일,
  GridPreset.isDuplicate_기접수여부,
  GridPreset.orderHandlerName_개통담당자,
];

export const activateStateColumn: GridColDef[] = [
  ...OrderTableColumns.slice(0, 1),
  GridPreset.autoActivateState_모요처리상태,
  ...OrderTableColumns.slice(1),
];

export const moyoActivateStateColumn: GridColDef[] = [
  ...OrderTableColumns.slice(0, 1),
  GridPreset.autoActivateState_모요처리상태,
  GridPreset.autoActivateStateValue_처리상태,
  ...OrderTableColumns.slice(1),
];
