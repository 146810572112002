export enum API_RESULT_TYPE {
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
  UNAUTHORIZED = 'UNAUTHORIZED',
  BAD_REQUEST = 'BAD_REQUEST',
}

enum LG_API_ERROR_CODE {
  // LG 셀프개통 예외
  LG_IMPOSSIBLE_TIME = 'LG_IMPOSSIBLE_TIME', // 개통 가능시간이 아님
  LG_PLAN_CODE_UPDATED = 'LG_PLAN_CODE_UPDATED', // 요금제 코드가 바뀜
  LG_COUNSELOR_OPENING = 'LG_COUNSELOR_OPENING', // 상담사 개통이 필요함

  LG_VALIDATE_FAIL = 'LG_VALIDATE_FAIL', // 주민등록번호 가입 가능 여부 공통 에러
  LG_ONLY_FOR_JUNIOR = 'LG_ONLY_FOR_JUNIOR', // 미성년자임
  LG_ONLY_FOR_SENIOR = 'LG_ONLY_FOR_SENIOR', // 시니어 요금제임
  LG_ONLY_FOR_SOLDIER = 'LG_ONLY_FOR_SOLDIER', // 군인 요금제임

  LG_FAIL_TO_GET_NEW_PHONE_NUMBER_LIST = 'LG_FAIL_TO_GET_NEW_PHONE_NUMBER_LIST', // 신규 가입 번호 목록 조회 실패

  LG_INVALID_ID_DRIVER = 'LG_INVALID_ID_DRIVER', // 운전면허 번호 입력 오류
  LG_INVALID_ID_ISSUED_DATE = 'LG_INVALID_ID_ISSUED_DATE', // 발급일자 오류
  LG_INVALID_ID_REGISTRATION_NUMBER = 'LG_INVALID_ID_REGISTRATION_NUMBER', // 주민등록번호 에러
  LG_INVALID_ID_REGISTRATION_NAME = 'LG_INVALID_ID_REGISTRATION_NAME', // 주민등록번호 이름 에러
  LG_INVALID_ID_FOREIGNER = 'LG_INVALID_ID_FOREIGNER', //  외국인
  LG_INVALID_ID_KAIT_ERROR = 'LG_INVALID_ID_KAIT_ERROR', //  신분증 에러
  LG_INVALID_ID = 'LG_INVALID_ID', // 신분증 관련 에러 -> 위 4개 예외 에러

  LG_INVALID_CARD_NUMBER = 'LG_INVALID_CARD_NUMBER', // 청구납부 카드 번호 에러
  LG_INVALID_CARD_DATE = 'LG_INVALID_CARD_DATE', // 카드 유효기간 에러
  LG_INVALID_BILL = 'LG_INVALID_BILL', // 청구납부에러 -> 위 2개 예외 에러
  LG_INVALID_CARD_NOT_MINE = 'LG_INVALID_CARD_NOT_MINE', // 청구 납부 명의 틀림
  LG_EXCEED_VALIDATE_CARD_CALL_API = 'LG_EXCEED_VALIDATE_CARD_CALL_API', // 청구 납부 횟수 초과

  LG_ALREADY_RESERVE_NEW_PHONE_NUMBER = 'LG_ALREADY_RESERVE_NEW_PHONE_NUMBER', // 이미 번호예약이 됨

  LG_INVALID_ACCOUNT = 'LG_INVALID_ACCOUNT', // 요금 납부 방법계좌에러

  LG_ACTIVATION_FAIL = 'LG_ACTIVATION_FAIL', // 개통 실패 에러

  LG_USED_USIM = 'LG_USED_USIM', // 이미 사용중인 유심
  LG_INVALID_USIM = 'LG_INVALID_USIM', // 사용할 수 없는 유심
  LG_WRONG_USIM_NUMBER = 'LG_WRONG_USIM_NUMBER', // 잘못된 유심 번호
  LG_WRONG_USIM_MODEL = 'LG_WRONG_USIM_MODEL', // 잘못된 유심 모델
  WRONG_USIM_MVNO = 'WRONG_USIM_MVNO', // 잘못된 유심 모델

  LG_INVALID_ID_MAX_PHONE_NUMBER = 'LG_INVALID_ID_MAX_PHONE_NUMBER', // 가입 가능한 회선 수 초과

  LG_WRONG_PREVIOUS_CARRIER = 'LG_WRONG_PREVIOUS_CARRIER', // 전 사업자 입력 에러
  LG_WRONG_PREVIOUS_PHONENUMBER = 'LG_WRONG_PREVIOUS_PHONENUMBER', //전 전화번호를 입력 에러
  LG_NOT_PRE_AGREE = 'LG_NOT_PRE_AGREE', // 문자 사전 동의 아직 안함

  LG_미납금 = 'LG_미납금',
  LG_서비스_체납금 = 'LG_서비스_체납금',

  LG_CHANGE_WITHIN_3_MONTH = 'LG_CHANGE_WITHIN_3_MONTH', // 3개월 이내 제한
  CHANGE_WITHIN_3_MONTH = 'CHANGE_WITHIN_3_MONTH', // 3개월 이내 제한
  LG_CHANGE_WITHIN_2_WEEK = 'LG_CHANGE_WITHIN_2_WEEK', // 2주 이내 제한
  LG_이전_통신사_해지_고객 = 'LG_이전_통신사_해지_고객',
  LG_ALREADY_PRE_AGREE = 'LG_ALREADY_PRE_AGREE',
  LG_INVALID_TIME_TO_CALL_API = 'LG_INVALID_TIME_TO_CALL_API', // 상담사 개통으로 전환
  LG_USER_VALIDATION_REQUIRED = 'LG_USER_VALIDATION_REQUIRED', // 군인 요금제 처리 추가

  타인납부정보 = '타인납부정보',
  LG_KAIT_ERROR = 'LG_KAIT_ERROR', // KAIT 에러로 상담사 전환,

  LG_ACTIVATION_RESTRICT = 'LG_ACTIVATION_RESTRICT', // 가입제한 서비스 등록 에러

  LG_ALREADY_ACTIVATION = 'LG_ALREADY_ACTIVATION', // 이미 개통 됨

  LG_평생계좌 = 'LG_평생계좌',
  LG_연체계좌 = 'LG_연체계좌',

  LG_ILLEGAL_USE_SUSPENSION_PHONE_NUMBER = 'LG_ILLEGAL_USE_SUSPENSION_PHONE_NUMBER', // 불법이용정지 번호이동 불가

  LG_INTERNAL_SERVER_ERROR = 'LG_INTERNAL_SERVER_ERROR', // LG 500
  LG_UNKNOWN_SERVER_ERROR = 'LG_UNKNOWN_SERVER_ERROR',
  LG_MNO_SERVER_ERROR = 'LG_MNO_SERVER_ERROR',
}

enum SCRAPING_ERROR_CODE {
  ATH2003 = 'ATH2003', // "재전송 제한시간(1분)이 지난 후에 이용해 주세요."
  ATH2006 = 'ATH2006', // "제한시간 내 인증번호를 보낼 수 있는 횟수가 넘었습니다."
  ATH1221 = 'ATH1221', // "시간이 만료되었습니다."
  ICAS3162 = 'ICAS3162', // "인증번호를 전송할 수 없는 번호입니다."
  ATH2007 = 'ATH2007', // "인증번호가 일치하지 않습니다."
  ATH2008 = 'ATH2008', // "인증번호 입력 시간이 초과했습니다."
  ATH2011 = 'ATH2011', // "인증번호의 입력 오류 횟수가 초과되었습니다."
  ATH1004 = 'ATH1004', // "입력하신 정보가 일치하지 않습니다."
  ATH1005 = 'ATH1005', // "입력하신 정보가 일치하지 않습니다."
  M001 = 'M001', // "알 수 없는 에러"              //스크립트에서 catch문에러,
  M002 = 'M002', // "알 수 없는 에러! ["+code+"]"  //skt 인증번호 요청 및 검증에서 확인되지 않은 에러코드
  M003 = 'M003', // "LOGINFIRST"              //잘못된 uuid,
  M004 = 'M004', // :"wrong jobName"// jobName 오류
  // KT 코드
  KT001 = 'KT001', // 개인정보 오류
  KT002 = 'KT002', // 알수없는 오류 <- 스크래핑 진우님 서버 오류
  KT003 = 'KT003', // 일시적인 오류 <- 실제 KT 오류일떄
  KT004 = 'KT004', // 차단당함
  KT005 = 'KT005', // 인증번호 불일치
  KT006 = 'KT006', // 회원가입 필요
  KT007 = 'KT007', // 중복된 아이디
}

enum TIMEDEAL_ERROR_CODE {
  TIMEDEAL_EVENT_NOT_EXIST = 'TIMEDEAL_EVENT_NOT_EXIST',
  TIMEDEAL_EVENT_CLOSED = 'TIMEDEAL_EVENT_CLOSED',
}

/**
 * 모요 주문 서버 관련 에러코드
 */
enum ORDER_ERROR_CODE {
  INVALID_PERSONAL_AUTHENTICATION = 'INVALID_PERSONAL_AUTHENTICATION',
}

enum CERT_ERROR_CODE {
  INVALID_PHONE_NUMBER = 'INVALID_PHONE_NUMBER',
  INVALID_SELF_AUTHENTICATION = 'INVALID_SELF_AUTHENTICATION', // 계정의 CI 정보와 신청인의 CI 정보가 일치하지 않음
  INVALID_USER_AUTHENTICATION = 'INVALID_USER_AUTHENTICATION', // 계정의 CI 정보가 없음
  /** CE3102: 인증이 아직 완료되지 않았고 마무리 될 때까지 `verifyTossCert`를 주기적으로 호출해주어야 한다. */
  TOSS_NOT_FINISHED = 'CE3102',

  AUTHENTICATION_FAIL = 'AUTHENTICATION_FAIL', // 인증 실패 -> 다른 인증수단 선택 or 잠시 후 이용
  AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR', // 인증에 실패
}

enum KT_ERROR_CODE {
  KT_IMEI_NOT_EXIST = 'KT_IMEI_NOT_EXIST',
  KT_단말기_MEP_미지원 = 'KT_단말기_MEP_미지원',
  KT_단말기_정보_조회_실패 = 'KT_단말기_정보_조회_실패',
  KT_단말_기기원부_조회_실패 = 'KT_단말_기기원부_조회_실패',
  KT_OMD_단말_처리_사전체크_실패 = 'KT_OMD_단말_처리_사전체크_실패',
  KT_OMD_단말_처리_실패 = 'KT_OMD_단말_처리_실패',
  ALL_DELIVERY_COMPANY_NOT_AVAILABLE = 'ALL_DELIVERY_COMPANY_NOT_AVAILABLE',
  LOCATION_NOT_SUPPORTED = 'LOCATION_NOT_SUPPORTED',
  PAYMENT_SUCCESS_NOT_FOUND = 'PAYMENT_SUCCESS_NOT_FOUND',
  PAYMENT_FAIL = 'PAYMENT_FAIL',
  /** KT 고객 등록이 2분 이상 걸릴 때 */
  KT_CUSTOMER_REGISTER_PROBLEM = 'KT_CUSTOMER_REGISTER_PROBLEM',
}

enum PAYMENT_ERROR_CODE {
  ALREADY_PROCESSED_PAYMENT = 'ALREADY_PROCESSED_PAYMENT',
}

enum PLAN_ERROR_CODE {
  PLAN_NOT_FOUND = 'PLAN_NOT_FOUND',
}

export const API_ERROR_CODE = {
  ...LG_API_ERROR_CODE,
  ...SCRAPING_ERROR_CODE,
  ...TIMEDEAL_ERROR_CODE,
  ...CERT_ERROR_CODE,
  ...KT_ERROR_CODE,
  ...PAYMENT_ERROR_CODE,
  ...ORDER_ERROR_CODE,
  ...PLAN_ERROR_CODE,
};

export type ERROR_CODE_TYPE =
  (typeof API_ERROR_CODE)[keyof typeof API_ERROR_CODE];

export interface ApiResponse<T = any> {
  resultType: API_RESULT_TYPE;
  result: T;
  error: string;
  errorCode?: ERROR_CODE_TYPE;
}

// moyo-common protocol
export interface ApiResponseV2<T = any> {
  data: T;
  result: string;
}

export const isApiFailAboutErrorCode = (
  rs: ApiResponse,
  errorCode: ERROR_CODE_TYPE,
): boolean => {
  return rs.resultType === API_RESULT_TYPE.FAIL && rs.errorCode === errorCode;
};
