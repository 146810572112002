import React from 'react';
import { UserMe, authApiService } from '../../api/auth';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

interface AccountDeletingModalProps {
  members: UserMe[];
  open: boolean;
  onClose: () => void;
  onDidChange: () => void;
}

interface DeleteError {
  message: string;
}

const AccountDeletingModal: React.FC<AccountDeletingModalProps> = ({
  members,
  open,
  onClose,
  onDidChange,
}) => {
  const onDelete = async () => {
    if (!window.confirm(`총 ${members.length}명의 계정을 삭제하겠습니까?`)) {
      return;
    }
    try {
      const promiseList = members.map((member) =>
        authApiService.deleteMember(member.adminUserId),
      );
      await Promise.all(promiseList);

      onClose();
      onDidChange();
    } catch (error) {
      const err = error as DeleteError;
      alert(`삭제하는데 문제가 생겼습니다. ${err.message}`);
    }
  };
  const checkMembers: React.ReactNode = members.map((member, index) => (
    <DialogContentText key={index}>{member.name}</DialogContentText>
  ));
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>계정 삭제</DialogTitle>
      <DialogContent>
        <DialogContentText>
          총 {members.length}명의 계정을 삭제합니다.
        </DialogContentText>
        <DialogContentText>다음 유저를 삭제하시겠습니까?</DialogContentText>
        <br />
        <DialogContentText>{checkMembers}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="outlined" onClick={onClose}>
          취소
        </Button>
        <Button variant="contained" onClick={onDelete}>
          삭제
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AccountDeletingModal;
