import { useSuspenseQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { getMvnoPolicyList } from '../api/policy';
import { Mvno, mvnoByAuthorizedMvno } from '../entity/mvno';

export function useMvnoPolicy(authorizedMvno?: Mvno) {
  const policyList = useSuspenseQuery({
    queryKey: ['mvno-policy-list', authorizedMvno],
    queryFn: async () => {
      try {
        const response = await getMvnoPolicyList();
        return response.data.result;
      } catch (e) {
        return {
          policyList: [],
        };
      }
    },
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  }).data!.policyList;

  return useMemo(() => {
    if (authorizedMvno == null) {
      return null;
    }

    return (
      policyList.find(
        (policy) => policy.mvno === mvnoByAuthorizedMvno[authorizedMvno],
      ) ?? null
    );
  }, [authorizedMvno, policyList]);
}
