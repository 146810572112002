import { Box, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';

const SwitchEditableMvnoInfoHeader: FC<{
  title: string;
  subTitle?: string;
  actionArea?: ReactNode;
}> = ({ title, subTitle, actionArea }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 3,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 0.5,
        }}
      >
        <Typography variant="h6">{title}</Typography>
        {subTitle && (
          <Typography variant="body1" color="text.secondary">
            {subTitle}
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}
      >
        {actionArea}
      </Box>
    </Box>
  );
};

export default SwitchEditableMvnoInfoHeader;
