import styled from '@emotion/styled';
import { SupportAgent } from '@mui/icons-material';
import { Badge } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import openChannelTalk, {
  onLoadChannelTalk,
  setChannelTalkTheme,
} from '../../common/channelTalk';
import { useOptions } from '../../context/OptionsContext';
import { useThemeControl } from '../../context/ThemeControlContext';
import pbl from '../../pbl/pbl-service/pbl-service';
import { DrawerNavigationListItemButton } from './DrawerNavigationListItem';

const Frame = styled.div`
  position: relative;
`;

const BadgeFrame = styled.div`
  position: absolute;
`;
const DrawerNavigationChannelTalkButton: FC<{ foldedNavigation?: boolean }> = ({
  foldedNavigation,
}) => {
  const { adminUser } = useOptions();
  const [badgeCount, setBadgeCount] = useState<number>(0);
  const { theme } = useThemeControl();
  const [init, setInit] = useState(false);

  useEffect(() => {
    if (!adminUser?.name) return;
    onLoadChannelTalk(
      {
        name: adminUser?.name,
        partnersName: adminUser?.name,
        partnerCompanyName: adminUser?.partnerCompanyName,
      },
      {
        onBadgeChanged: (count: number) => {
          setBadgeCount(count);
        },
      },
    );
    setInit(true);
  }, [adminUser]);

  useEffect(() => {
    if (!init) return;
    if (theme.palette.mode === 'dark') {
      setChannelTalkTheme(theme.palette.mode);
    } else {
      setChannelTalkTheme(theme.palette.mode);
    }
  }, [theme.palette.mode, init]);

  const onClickHandler = () => {
    pbl('click', 'button', 'here', {
      object: {
        id: 'inquiry',
        section: 'GNB',
      },
    });
    openChannelTalk();
  };

  if (!adminUser?.name) return <></>;
  return (
    <Frame>
      <DrawerNavigationListItemButton
        name="문의하기"
        icon={<SupportAgent fontSize="small" />}
        onClick={onClickHandler}
        foldedNavigation={foldedNavigation}
      />
      <BadgeFrame
        style={
          foldedNavigation
            ? {
                left: 45,
                top: 5,
              }
            : {
                left: 95,
                top: 5,
              }
        }
      >
        <Badge badgeContent={badgeCount} color="secondary" />
      </BadgeFrame>
    </Frame>
  );
};

export default DrawerNavigationChannelTalkButton;
