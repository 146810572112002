import React from 'react';
import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import {
  DataGrid,
  GridRenderCellParams,
  GridRowSelectionModel,
} from '@mui/x-data-grid';

import { Mvnos } from '../../entity/mvno';
import { ColumnList } from './PlanListColumn';
import { planApiService } from '../../api/plan';
import { useLoading } from '../../context/LoadingContext';
import { companyApiService } from '../../api/company';
import { PlanMetaAdmin } from '../../entity/plan';
import { useDebounce } from '../../common/stream';
import MoyoOnlyRequestPopup from './MoyoOnlyRequestPopup';
import pbl from '../../pbl/pbl-service/pbl-service';

import { AlertDialog } from '../NewMoyoOnlyPage/components/Dialog/AlertDialog';
import slackBot from '../../api/slackBot';
import FeeGuardRailDialog from './FeeGuardRailDialog';
import { MoyoOnlyApiService } from '../../api/moyoOnly';
import { feeCalculator } from '../NewMoyoOnlyPage/hook/feeCalculator';
import { DateTime } from 'luxon';
import { FormProvider, useForm } from 'react-hook-form';
import ModifyPlanFeeDialog from './Dialog/CreatePlanDialog';
import { ReserveTable } from './ReserveTable';
import useGetReservePlanList from './hook/queries/useGetReservePlanList';
import { useDeleteReservePlan } from './hook/queries/useDeleteReservePlan';
import { PlanDetailDialog } from './Dialog/PlanDetailDialog';
import { useAuth } from '../../context/UserContext';
import { PlanMetaV2 } from '../../entity/plan-v2';

const rows: PlanMetaAdmin[] = [];
const MoyoOnlyPlanListPage = () => {
  const [plans, setPlans] = useState<PlanMetaAdmin[]>(rows);
  const [filterText, setFilterText] = useState<string>(''); // 요금제 이름
  const debouncedFilterText = useDebounce(filterText, 500);

  const [mvno, setMvno] = useState<string>('');
  const [mvnoFilter, setMvnoFilter] = useState<string>('전체');
  const [totalCount, setTotalCount] = useState<number>();
  const [state, setState] = useState('ALL');
  const [mno, setMno] = useState<string>('전체');
  const [selectedIds, setSelectedIds] = useState<GridRowSelectionModel>([]);
  const [selectedReserveIds, setSelectedReserveIds] =
    useState<GridRowSelectionModel>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenPlanDetail, setIsOpenPlanDetail] = useState<boolean>(false);
  const [guardRailIsOpen, setGuardRailIsOpen] = useState<boolean>(false);
  const [guardRailFee, setGuardRailFee] = useState<number>(0);
  const [guardRailPlan, setGuardRailPlan] = useState<PlanMetaV2>();
  const [isCreateOpen, setIsCreateOpen] = useState<boolean>(false);
  const [isNotConfirm, setIsNotConfirm] = useState<boolean>(false);

  const [dialog, setDialog] = useState<{
    title: string;
    text: string;
    isOpen: boolean;
    cancelText?: string;
    okText?: string;
    onOk?: () => void;
    onCancel?: () => void;
  }>({
    title: '',
    text: '',
    isOpen: false,
    cancelText: '확인',
    okText: '',
    onOk: () => undefined,
    onCancel: () => undefined,
  });
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 50,
  });

  const form = useForm<PlanMetaAdmin>();
  const { user } = useAuth();
  const { getValues, reset } = form;
  const { setLoading } = useLoading();
  const { reservePlans, refetch } = useGetReservePlanList(mvno);
  const deleteReservePlanMutate = useDeleteReservePlan();

  useEffect(() => {
    pbl('pageview', 'none', 'plan_list');
  }, []);

  const fetchPlans = async () => {
    const companyInfoResponse = await companyApiService.getInfo();
    const companyInfo = companyInfoResponse.data.result;
    const authorizedPlans = companyInfo?.plans;
    if (!authorizedPlans) return;
    // mvno 에게만 스크래핑 요청 버튼 제공
    if (authorizedPlans.length === 1) {
      setMvno(authorizedPlans[0]);
    } else {
      setMvno('valuecomm');
    }

    let mnoParam: string | undefined = mno;
    if (mno === '전체') mnoParam = undefined;
    let mvnoNames;
    if (mvnoFilter === '전체') {
      mvnoNames = authorizedPlans.join(',');
    } else {
      mvnoNames = mvnoFilter;
    }

    //rq로 리팩토링
    const plans = await planApiService.getPlansByMvnoPagination({
      mvno: mvnoNames,
      searchText: debouncedFilterText,
      state,
      mno: mnoParam,
      page: paginationModel.page,
      size: paginationModel.pageSize,
      isManualPlan: true,
      isMoyoOnlyPlan: true,
    });

    setTotalCount(plans.data.result?.totalSize);

    setLoading(false);
    if (!plans) return;
    if (plans.data.result) {
      setPlans(plans.data.result.list);
    }
  };

  const selectedData = plans.filter((plan) => selectedIds.includes(plan.id))[0];

  useEffect(() => {
    fetchPlans();
  }, [mno, debouncedFilterText, paginationModel, mvnoFilter, state]);

  const handleMvnoChange = (event: SelectChangeEvent) => {
    setMvnoFilter(event.target.value as string);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setState(event.target.value as string);
  };
  const handleMnoChange = (event: SelectChangeEvent) => {
    setMno(event.target.value as string);
  };

  // 현 쉐이크 구 미니게이트의 경우에는 가드레일 금액이 맞지 않아도 오류처리 하지 않는 임시로직 처리
  const isShakeMobile = () => {
    return !!user?.company?.mvnos?.includes('쉐이크모바일');
  };

  const isMvnoMatch = (plan: PlanMetaAdmin, mvnoFilter: string) =>
    plan.mvno === mvnoFilter || mvnoFilter === '전체';

  const filteredPlans = plans.filter((plan) => {
    const isMvno = isMvnoMatch(plan, mvnoFilter);

    return isMvno;
  });

  const validateFeeInfo = (
    fee: number,
    feeAfterDiscount: number,
    promotionPeriod: number,
  ) => {
    const validateFee = feeCalculator(
      fee,
      feeAfterDiscount,
      promotionPeriod,
      '',
    );
    if (fee > feeAfterDiscount) {
      showPriceInfoErrorDialog(
        '할인 시 가격이\n할인 끝난 가격보다 비싸요',
        '가격 정보를 수정해주세요',
      );
    } else if (fee > 100000) {
      showPriceInfoErrorDialog(
        '할인 시 가격이\n10만원보다 비싸요',
        '가격 정보가 맞는지 확인해주세요',
      );
    } else if (feeAfterDiscount > 100000) {
      showPriceInfoErrorDialog(
        '할인 끝난 가격이\n10만원보다 비싸요',
        '가격 정보가 맞는지 확인해주세요',
      );
    } else if (promotionPeriod > 36) {
      showPriceInfoErrorDialog(
        '할인기간은 최대 36개월을 넘을 수 없어요',
        '할인기간을 올바르게 적어주세요',
      );
    } else if (
      guardRailFee - validateFee > 1000 ||
      guardRailFee === 0 ||
      isShakeMobile()
    ) {
      validateGuardRail();
    } else {
      setIsNotConfirm(true);
    }
  };

  const validateGuardRail = () => {
    setIsNotConfirm(false);
    setIsCreateOpen(true);
    setIsOpen(false);
  };

  const showPriceInfoErrorDialog = (title: string, text: string) => {
    setDialog({
      title: title,
      text: text,
      isOpen: true,
      onCancel: () => setDialog({ title: '', text: '', isOpen: false }),
    });
  };

  useEffect(() => {
    reset({
      mvno: selectedData?.mvno,
      name: selectedData?.name,
      partnerSignupUrl: selectedData?.partnerSignupUrl,
    });
  }, [selectedData]);

  const SendSlackBot = async (
    exists: {
      fee: number;
      feeAfterDiscount: number | undefined;
      promotionPeriod: number | undefined;
    },
    date: string,
    isReservation: boolean,
    previewFee: number,
    guardRailFee: number,
    guardRailPlanId: number | undefined,
    responseId?: number,
    plan?: PlanMetaAdmin,
  ) => {
    await slackBot.sendChangeDetailInfoToSlack(
      exists,
      date,
      isReservation,
      previewFee,
      guardRailFee,
      guardRailPlanId,
      responseId,
      plan,
    );
  };

  const onSubmit = async (
    plan: PlanMetaAdmin,
    date: DateTime,
    isReservation: boolean,
  ) => {
    const planFee = plan.fee?.toString().replace(',', '');
    const planFeeAfter = plan.feeAfterDiscount?.toString().replace(',', '');
    const planPromotion = plan.promotionPeriod?.toString().replace(',', '');
    const validateFee = feeCalculator(
      Number(planFee),
      Number(planFeeAfter),
      Number(planPromotion),
      '',
    );
    const exists = {
      fee: selectedData.fee,
      feeAfterDiscount: selectedData.feeAfterDiscount,
      promotionPeriod: selectedData.numberOfMonthsForDiscount,
    };
    const requestBody = {
      fee: Number(planFee),
      feeAfterDiscount: Number(planFeeAfter),
      promotionPeriod: Number(planPromotion),
      status: 'UPDATE',
    };
    const reserveDate = date.toString().slice(0, 19);
    if (isReservation) {
      const response = await MoyoOnlyApiService.ModifyMoyoOnlyFeeReserve(
        selectedData.mvno,
        selectedData.id,
        requestBody,
        reserveDate,
      );
      setDialog({
        title: '수정 예약이 등록되었어요',
        text: '',
        isOpen: true,
        onCancel: () => setDialog({ title: '', text: '', isOpen: false }),
      });
      SendSlackBot(
        exists,
        reserveDate,
        isReservation,
        validateFee,
        guardRailFee,
        guardRailPlan?.id,
        selectedData.id,
        plan,
      );
    } else {
      const response = await MoyoOnlyApiService.ModifyMoyoOnlyFee(
        selectedData.mvno,
        selectedData.id,
        requestBody,
      );
      const responsePlanId = response.data.result?.id;
      setDialog({
        title: '수정 사항이 반영되었어요',
        text: '',
        isOpen: true,
        cancelText: '닫기',
        okText: '모요 링크 확인하기',
        onCancel: () => {
          fetchPlans();
          setDialog({ title: '', text: '', isOpen: false });
        },
        onOk: () =>
          window.open(`https://www.moyoplan.com/plans/${responsePlanId}`),
      });
      SendSlackBot(
        exists,
        reserveDate,
        isReservation,
        validateFee,
        guardRailFee,
        guardRailPlan?.id,
        Number(responsePlanId),
        plan,
      );
    }
  };

  const fetchGuardRailPlan = async (id: number) => {
    const response = await MoyoOnlyApiService.getSimilarPlan(id);
    setGuardRailPlan(response.data.result);
    setGuardRailIsOpen(false);
    setIsOpen(true);
  };

  useEffect(() => {
    if (
      guardRailPlan?.fee &&
      guardRailPlan?.할인끝난후_금액 &&
      guardRailPlan.할인적용_개월수
    ) {
      setGuardRailFee(
        feeCalculator(
          guardRailPlan.fee,
          guardRailPlan.할인끝난후_금액,
          guardRailPlan.할인적용_개월수,
          '',
        ),
      );
    } else if (
      guardRailPlan?.fee !== null &&
      guardRailPlan?.할인끝난후_금액 === null &&
      guardRailPlan.할인적용_개월수 === null
    ) {
      setGuardRailFee(guardRailPlan.fee * 12);
    }
  }, [guardRailPlan]);

  const ModifyFeeFlow = () => {
    const FirstStepCreateGuardRail = (id: number) => {
      setGuardRailIsOpen(true);
      fetchGuardRailPlan(id);
      pbl('click', 'button', 'plan_list', {
        object: {
          id: 'modify_fee_click',
        },
        eventProperties: {
          mvno: mvno,
        },
      });
    };
    const SecondStepInputFeeInformation = (plan: PlanMetaAdmin) => {
      const planFee = Number(plan.fee?.toString().replace(',', ''));
      const planFeeAfter = Number(
        plan.feeAfterDiscount?.toString().replace(',', ''),
      );
      const planPromotion = Number(
        plan.promotionPeriod?.toString().replace(',', ''),
      );
      validateFeeInfo(planFee, planFeeAfter, planPromotion);
    };
    const ThirdStepExportPlan = (
      plan: PlanMetaAdmin,
      date: DateTime,
      isReservation: boolean,
    ) => {
      onSubmit(plan, date, isReservation);
      setIsCreateOpen(false);
    };

    return {
      FirstStepCreateGuardRail,
      SecondStepInputFeeInformation,
      ThirdStepExportPlan,
    };
  };

  const onCancel = async (reserveId: number) => {
    const response = await deleteReservePlanMutate.mutateAsync({
      mvno,
      reserveId,
    });
    if (response.status === 200) {
      refetch();
      return response;
    } else {
      setDialog({ title: '예약 취소를 실패했습니다', text: '', isOpen: false });
    }
  };

  const onCancelReservePlan = (reserveId: number, state: string) => {
    const DialogTitleStatus =
      state === 'CREATE_RESERVED' ? '생성 예약' : '수정 예약';
    setDialog({
      title: `${DialogTitleStatus}을 취소하시겠어요?`,
      text: '',
      isOpen: true,
      cancelText: '닫기',
      okText: '예약취소',
      onCancel: () => {
        setDialog({ title: '', text: '', isOpen: false });
      },
      onOk: () => {
        onCancel(reserveId);
        setDialog({ title: '', text: '', isOpen: false });
      },
    });
  };

  // const DetailPlanInfo = () => {
  //   const text = ``
  //   setDialog({
  //     title: "상세",
  //     text:
  //   })
  // }

  const planColumnList = ColumnList();
  const sortingMvnos = Mvnos.sort((prev, next) => {
    if (prev.value === '전체' || next.value === '전체') return 1;
    if (prev.value.toLowerCase() > next.value.toLowerCase()) return 1;
    else if (prev.value.toLowerCase() < next.value.toLowerCase()) return -1;
    else return 0;
  });

  const selectedReservePlan = reservePlans?.filter((plan) =>
    selectedReserveIds.includes(plan.reservedCreateDto.id),
  )[0];

  return (
    <FormProvider {...form}>
      <Container sx={{ display: 'grid', mt: 3 }} maxWidth="xl">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h4" fontWeight={'light'}>
            모요ONLY 요금제 관리
          </Typography>
          <Box
            sx={{
              display: 'inline-block',
            }}
          ></Box>
        </Box>
        {/*요금제 찾기 필터*/}
        <Grid container mt={1} mb={2} spacing={4}>
          <Grid item xs={2}>
            <FormControl fullWidth>
              <InputLabel size="small" id="demo-simple-select-label">
                요금제 상태
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={state}
                label="요금제 상태"
                onChange={handleChange}
              >
                <MenuItem value={'ALL'}>전체</MenuItem>
                <MenuItem value={'CONFIRMED'}>진행중</MenuItem>
                <MenuItem value={'DELETED'}>종료</MenuItem>
                <MenuItem value={'READY'}>진행 대기</MenuItem>
                <MenuItem value={'CHANGE_RESERVED'}>수정 예약</MenuItem>
                <MenuItem value={'DELETE_RESERVED'}>종료 예약</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl fullWidth>
              <InputLabel id="mno-label" size="small">
                통신망
              </InputLabel>
              <Select
                labelId="mno-label"
                id="mno-select"
                value={mno}
                label="통신망"
                onChange={handleMnoChange}
              >
                <MenuItem value={'전체'}>전체</MenuItem>
                <MenuItem value={'LGU'}>LG망</MenuItem>
                <MenuItem value={'KT'}>KT망</MenuItem>
                <MenuItem value={'SKT'}>SKT망</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {mvno === 'valuecomm' ? (
            <Grid item xs={2}>
              <FormControl fullWidth>
                <InputLabel size="small">Mvno</InputLabel>
                <Select
                  value={mvnoFilter}
                  label="Mvno"
                  onChange={handleMvnoChange}
                >
                  {sortingMvnos.map((group, index) => (
                    <MenuItem key={index} value={group.value}>
                      {group.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          ) : (
            <Grid item xs={2}></Grid>
          )}
          <Grid item xs={2}></Grid>
          <Grid item xs={4}>
            <TextField
              label="요금제 이름"
              value={filterText}
              onChange={(e) => setFilterText(e.target.value)}
              size="small"
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid mb={'55px'} mt={'80px'}>
          <ReserveTable
            setSelected={setSelectedReserveIds}
            onCancel={(reserveId: number, state: string) =>
              onCancelReservePlan(reserveId, state)
            }
            onDetail={() => setIsOpenPlanDetail(true)}
            reservePlan={reservePlans}
          />
        </Grid>

        {/*요금제 목록 테이블*/}
        <DataGrid
          columns={[
            ...planColumnList,
            // TODO: 모요온리 가격 수정 시 reserve가 따로 관리되고 있는데, 해당 부분 하나로 통일되게 개선해야함 (https://themoyo.slack.com/archives/C06N00K9ERJ/p1733118918181349)
            // {
            //   field: 'changeFee',
            //   headerName: '가격 수정',
            //   headerAlign: 'center',
            //   align: 'center',
            //   renderCell: (params: GridRenderCellParams) => {
            //     return (
            //       params.row.name?.includes('모요only') && (
            //         <Button
            //           variant="text"
            //           color="primary"
            //           onClick={() =>
            //             ModifyFeeFlow().FirstStepCreateGuardRail(params.row.id)
            //           }
            //         >
            //           가격 수정
            //         </Button>
            //       )
            //     );
            //   },
            // },
            {
              field: 'detailButton',
              headerName: '상세 보기',
              headerAlign: 'center',
              align: 'center',
              renderCell: (params: GridRenderCellParams) => {
                return (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      window.open(`/plans/moyoonly/${params.row.id}`);
                    }}
                  >
                    상세 보기
                  </Button>
                );
              },
            },
          ]}
          sx={{
            borderWidth: '0px',
            '& .MuiDataGrid-columnSeparator': {
              visibility: 'visible',
            },
          }}
          rows={filteredPlans}
          onRowSelectionModelChange={(id) => {
            setSelectedIds(id);
            setGuardRailFee(0);
            setIsNotConfirm(false);
          }}
          rowSelectionModel={selectedIds}
          rowCount={totalCount}
          pagination
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[10, 25, 50, 100]}
          autoHeight
        />
      </Container>
      <FeeGuardRailDialog
        title="모요Only 가격 수정"
        description="가격 가이드라인 설정 중... 잠시 기다려주세요"
        open={guardRailIsOpen}
      />
      <AlertDialog
        onCancel={dialog.onCancel}
        isOpen={dialog.isOpen}
        title={dialog.title}
        text={dialog.text}
        okText={dialog.okText}
        onOk={dialog.onOk}
        cancelText={dialog.cancelText}
      />
      <ModifyPlanFeeDialog
        open={isCreateOpen}
        onSuccess={(date: DateTime, isReservation: boolean) => {
          ModifyFeeFlow().ThirdStepExportPlan(getValues(), date, isReservation);
        }}
        onClose={() => setIsCreateOpen(false)}
        feeInfo={{
          fee: selectedData?.fee,
          feeAfterDiscount: selectedData?.feeAfterDiscount as number,
          promotionPeriod: selectedData?.numberOfMonthsForDiscount as number,
        }}
        modifyInfo={{
          fee: getValues('fee'),
          feeAfterDiscount: getValues('feeAfterDiscount') as number,
          promotionPeriod: getValues('promotionPeriod') as number,
        }}
      />
      <PlanDetailDialog
        onCancel={() => setIsOpenPlanDetail(false)}
        isOpen={isOpenPlanDetail}
        plan={selectedReservePlan?.planMetaDto}
      />
      <MoyoOnlyRequestPopup
        open={isOpen}
        onSuccess={(plan: PlanMetaAdmin) =>
          ModifyFeeFlow().SecondStepInputFeeInformation(plan)
        }
        guardRailPlan={guardRailPlan}
        onClose={() => setIsOpen(false)}
        guardRailFee={guardRailFee ?? 0}
        plans={selectedData}
        isNotConfirm={isNotConfirm}
        title="모요Only로 가격 수정"
        description="요금제 가격 및
        할인기간 정보를 수정합니다"
      />
    </FormProvider>
  );
};

export default MoyoOnlyPlanListPage;
