import { ReleaseNoteItem } from './releaseNotes';
import ReleaseStatusType from './ReleaseStatusType';

const Release_2_9_0: ReleaseNoteItem = {
  version: '2.9.0',
  date: '2024-06-12',
  title: '요금제 접수 탭 구성 변경',
  details: [
    {
      title: '요금제 접수 탭의 노출되는 하위 탭 구성 변경',
      contents: [
        {
          content: (
            <>
              기존 요금제 접수 탭의 하위 탭들을 간소화하여 전체/간편신청
              접수탭으로만 노출됩니다.
              <br />
              때문에, 명확히 개통실분들의 도움을 필요로하는 신청건들이 모여있는{' '}
              간편신청 접수 페이지만 노출하여 조금 더 쉽게 접근하실 수 있도록
              업데이트 하였습니다.
            </>
          ),
          status: ReleaseStatusType.CHANGE,
        },
      ],
    },
  ],
};

export default Release_2_9_0;
