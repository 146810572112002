import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@mui/material';
import { useCallback, useEffect, useMemo } from 'react';
import { useOptions } from '../../../context/OptionsContext';
import { OrderStatus } from '../../../entity/order';
import { IBehaviorLogOptions } from '../../../pbl/pbl-service/create-pbl-service';
import pbl from '../../../pbl/pbl-service/pbl-service';

type PopupProps = {
  loading: boolean;
  logParams: IBehaviorLogOptions;
  isSendAlimtalk: boolean;
  onChangeSendAlimtalk: (checked: boolean) => void;
  memo?: string;
  onChangeMemo: (memo: string) => void;
  statusToApply: OrderStatus | null;
  onSelectStatusToApply: (value: OrderStatus | null) => void;
  onSubmit: () => void;
};

export function SelectStatusToApply({
  loading: isLoading,
  logParams,
  isSendAlimtalk,
  onChangeSendAlimtalk,
  memo,
  onChangeMemo,
  statusToApply,
  onSelectStatusToApply,
  onSubmit,
}: PopupProps) {
  useEffect(() => {
    pbl('impression', 'modal', 'here', {
      object: {
        id: 'change_bulk_status_select_status_to_apply',
        section: 'action_menu',
      },
      eventProperties: {
        ...logParams,
      },
    });
  }, []);

  const { statusList: allStatus } = useOptions();
  const statusList = useMemo(() => {
    return allStatus.filter((s) => s.orderType === 'register');
  }, [allStatus]);

  const handleSelectStatusToApply = (value: string) => {
    const newStatus = statusList.find((s: OrderStatus) => s.key === value);
    onSelectStatusToApply(newStatus ?? null);
  };

  const handleClickSubmit = useCallback(async () => {
    pbl('click', 'modal_button', 'here', {
      object: {
        id: 'change_bulk_status',
        section: 'action_menu',
      },
      eventProperties: {
        ...logParams,
      },
    });

    onSubmit();
  }, [logParams, onSubmit]);

  return (
    <>
      <DialogTitle>상태값 일괄 변경</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: '16px' }}>
          일괄 변경할 상태값을 선택해주세요. 상태값을 선택하지 않으면 메모만
          추가됩니다.
        </DialogContentText>
        <FormControl fullWidth>
          <InputLabel>상태값</InputLabel>
          <Select
            value={statusToApply?.key ?? ''}
            onChange={(e) => handleSelectStatusToApply(e.target.value)}
            label="상태값"
            disabled={isLoading}
          >
            {statusList.map((status) => (
              <MenuItem key={status.key} value={status.key}>
                {status.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label="남길 메모가 있다면 입력해주세요"
          value={memo}
          onChange={(e) => onChangeMemo(e.target.value)}
          disabled={isLoading}
          fullWidth
          sx={{
            mt: '16px',
          }}
        />
        <FormControlLabel
          label={'알림톡 발송 여부'}
          control={
            <Switch
              checked={isSendAlimtalk}
              onChange={(e) => onChangeSendAlimtalk(e.target.checked)}
              disabled={isLoading}
            />
          }
          sx={{
            mt: '16px',
          }}
        />
      </DialogContent>
      <DialogActions style={{ marginBottom: '12px', marginRight: '24px' }}>
        <Button onClick={handleClickSubmit} disabled={isLoading}>
          상태값 변경하기
        </Button>
      </DialogActions>
    </>
  );
}
