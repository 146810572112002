import { Button, Grid, Typography } from '@mui/material';
import {
  DataGrid,
  GridRenderCellParams,
  GridRowSelectionModel,
} from '@mui/x-data-grid';
import { FC } from 'react';
import { ReserveTableColumn } from './column';
import { MoyoOnlyReservation } from '../../../api/moyoOnly';

interface Props {
  reservePlan?: MoyoOnlyReservation[];
  onCancel: (reserveId: number, state: string) => void;
  onDetail: () => void;
  setSelected: (id: GridRowSelectionModel) => void;
}

export const ReserveTable: FC<Props> = ({
  reservePlan,
  onCancel,
  onDetail,
  setSelected,
}) => {
  const reserveRows = reservePlan?.map((plan) => {
    return { ...plan.planMetaDto, ...plan.reservedCreateDto };
  });

  return (
    <Grid>
      <Typography variant="subtitle1">모요ONLY 예약</Typography>
      <DataGrid
        columns={[
          ...ReserveTableColumn,
          {
            field: 'reserveCancel',
            headerAlign: 'center',
            align: 'center',
            headerName: '예약 취소',
            renderCell: (param: GridRenderCellParams) => {
              return (
                <Button onClick={() => onCancel(param.row.id, param.row.state)}>
                  예약 취소
                </Button>
              );
            },
            width: 150,
          },
          {
            field: '상세보기',
            headerAlign: 'center',
            align: 'center',
            renderCell: (param: GridRenderCellParams) => {
              return (
                <Button
                  onClick={() => {
                    onDetail();
                  }}
                >
                  상세보기
                </Button>
              );
            },
            width: 150,
          },
        ]}
        onRowSelectionModelChange={(id) => {
          setSelected(id);
        }}
        rows={reserveRows ? reserveRows : []}
      />
    </Grid>
  );
};
