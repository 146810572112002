import { FC } from 'react';
import SwitchEditableMvnoInfo from '../../SwitchEditableMvnoInfo/SwitchEditableMvnoInfo';
import MvnoInfoMnoOperationPolicyDetail from './MvnoInfoMnoOperationPolicyDetail';
import MvnoInfoMnoOperationPolicyEdit from './MvnoInfoMnoOperationPolicyEdit';
import type { MnoOperationPolicyByOperatorList } from '../../types/mobile-plan-operator-admin-types';
import { useMobilePlanOperatorAdmin } from '../../hooks/useMobilePlanOperatorAdmin';
import mvnoInfoApiService, {
  MvnoInfoPutFunction,
} from '../../api/mvno-info-api-service';
import { useCheckRole } from '../../../../router/useCheckRole';
import { useRole } from '../../../../router/useRole';

interface Props {
  mnoOperationPolicyByOperatorList: MnoOperationPolicyByOperatorList;
  mobilePlanOperatorId: number;
}

const MvnoInfoMnoOperationPolicy: FC<Props> = ({
  mnoOperationPolicyByOperatorList,
  mobilePlanOperatorId,
}) => {
  const { update } = useMobilePlanOperatorAdmin(mobilePlanOperatorId);
  const { checkRole } = useCheckRole();
  const { isMoyoRole } = useRole();

  const put: MvnoInfoPutFunction<MnoOperationPolicyByOperatorList> = async (
    data: MnoOperationPolicyByOperatorList,
  ) => {
    const response =
      await mvnoInfoApiService.putMnoOperationPolicyByOperatorList(
        mobilePlanOperatorId,
        data,
      );

    if (response.data.result) {
      update({
        mnoOperationPolicyByOperatorList: response.data.result,
      });
    }
    return response;
  };

  const enableEdit = isMoyoRole ? true : checkRole(['ROLE_ADMIN']);

  return (
    <SwitchEditableMvnoInfo
      title="망별 운영 정책 2"
      detail={
        <MvnoInfoMnoOperationPolicyDetail
          {...mnoOperationPolicyByOperatorList}
        />
      }
      edit={
        <MvnoInfoMnoOperationPolicyEdit {...mnoOperationPolicyByOperatorList} />
      }
      put={put}
      enableEdit={enableEdit}
    />
  );
};

export default MvnoInfoMnoOperationPolicy;
