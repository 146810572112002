import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { NumericFormat, NumberFormatBase } from 'react-number-format';
import { CreateMoyoPlanTypes } from '../../type';
import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import changeEnteredNum from '../../hook/inputComma';

interface Props {
  discountType: '할인 요금제' | '평생할인 요금제' | string;
  setDiscountType: React.Dispatch<React.SetStateAction<string>>;
  isDisabled: boolean;
}

export const FeeInformationInputs: FC<Props> = (props) => {
  const { discountType, setDiscountType, isDisabled } = props;
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext<CreateMoyoPlanTypes>();

  return (
    <>
      <Grid container>
        <RadioGroup
          style={{ gap: '32px' }}
          value={discountType}
          row
          onChange={(event) => {
            setDiscountType(event.target.value);
          }}
        >
          <FormControlLabel
            value="할인 요금제"
            control={<Radio />}
            label="할인 요금제"
            disabled={isDisabled}
          />
          <FormControlLabel
            value="평생할인 요금제"
            control={<Radio />}
            onChange={() => {
              setValue('feeAfterDiscount', null);
              setValue('promotionPeriod', null);
            }}
            label="평생할인 요금제"
            disabled={isDisabled}
          />
        </RadioGroup>
      </Grid>

      <Grid item xs={12} md={6} mt={'15px'}>
        <FormControl sx={{ gap: '15px' }} fullWidth>
          {discountType === '할인 요금제' ? (
            <>
              <Controller
                control={control}
                name="fee"
                render={({ field }) => (
                  <TextField
                    {...field}
                    onChange={(event) => {
                      field.onChange(changeEnteredNum(event));
                    }}
                    onWheel={(event) => (event.target as HTMLElement).blur()}
                    required
                    fullWidth
                    label="할인시 가격"
                    disabled={isDisabled}
                    inputMode="numeric"
                  />
                )}
              />
              <Controller
                control={control}
                name="feeAfterDiscount"
                render={({ field }) => (
                  <TextField
                    {...field}
                    onChange={(event) => {
                      field.onChange(changeEnteredNum(event));
                    }}
                    onWheel={(event) => (event.target as HTMLElement).blur()}
                    required
                    fullWidth
                    label="할인종료후 가격"
                    disabled={isDisabled}
                    inputMode="numeric"
                  />
                )}
              />
              <Controller
                control={control}
                name="promotionPeriod"
                rules={{
                  min: 1,
                  max: 36,
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    onChange={(event) =>
                      field.onChange(changeEnteredNum(event))
                    }
                    onWheel={(event) => (event.target as HTMLElement).blur()}
                    fullWidth
                    label="할인기간"
                    disabled={isDisabled}
                    inputMode="numeric"
                  />
                )}
              />
            </>
          ) : (
            <Controller
              control={control}
              name="fee"
              render={({ field }) => (
                <TextField
                  {...field}
                  onChange={(event) => field.onChange(changeEnteredNum(event))}
                  required
                  onWheel={(event) => (event.target as HTMLElement).blur()}
                  fullWidth
                  label="요금제 가격"
                  disabled={isDisabled}
                  inputMode="numeric"
                />
              )}
            />
          )}
        </FormControl>
      </Grid>
    </>
  );
};
