import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { BottomButtonRow } from '../styled';
import { DateTimePicker } from '@mui/x-date-pickers';
import { FC, useState } from 'react';
import { DateTime } from 'luxon';
import { useModal } from '../../../hooks/useModal';
import DisplayPlanDialog from '../../PlanDetailPage/Dialog/DisplayPlanDialog';
import { useLoading } from '../../../context/LoadingContext';
import { ExposeConfirmDialog } from '../../PlanListPage/ExposeConfirmDialog';
interface AddBottomButtonGroupProps {
  onOnlyRegister: () => void;
  onValidate: () => Promise<boolean>;
  onExpose: (date: DateTime | null) => void;
}

export const AddBottomButtonGroup = ({
  onOnlyRegister,
  onValidate,
  onExpose,
}: AddBottomButtonGroupProps) => {
  const { openModal, closeModal } = useModal();
  const { setLoading } = useLoading();
  const [openOnlyRegisterConfirm, setOpenOnlyRegisterConfirm] = useState(false);

  /** 요금제 등록만 하기 핸들러 */
  const handleOnlyRegister = async () => {
    if (!(await onValidate())) return;
    setOpenOnlyRegisterConfirm(true);
  };

  const handleCancelOnlyRegister = () => {
    setOpenOnlyRegisterConfirm(false);
  };

  const handleReserve = async () => {
    openModal(
      <ExposeConfirmDialog
        open
        title="요금제 노출 예약"
        onClose={closeModal}
        onExpose={(date) => {
          closeModal();
          onExpose(date);
        }}
      />,
    );
  };

  /** 요금제 노출하기 핸들러 */
  const handleExpose = async () => {
    if (!(await onValidate())) return;
    openModal(
      <DisplayPlanDialog
        open
        onReserve={handleReserve}
        onDisplay={async () => {
          try {
            setLoading(true);
            await onExpose(DateTime.now().startOf('minute'));
            closeModal();
          } catch (error) {
            console.error(error);
          } finally {
            setLoading(false);
          }
        }}
        onClose={closeModal}
      />,
    );
    // setOpenExposeConfirm(true);

    // DisplayPlanDialog
  };

  const handleOnOnlyRegister = () => {
    setOpenOnlyRegisterConfirm(false);
    onOnlyRegister();
  };

  return (
    <>
      <BottomButtonRow>
        <Button variant="outlined" size="large" onClick={handleOnlyRegister}>
          요금제 등록만 하기
        </Button>
        <Button variant="contained" size="large" onClick={handleExpose}>
          등록하고 노출하기
        </Button>
      </BottomButtonRow>

      {/* 요금제 등록만 하기 확인 다이얼로그 */}
      <Dialog
        open={openOnlyRegisterConfirm}
        onClose={handleCancelOnlyRegister}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">요금제를 등록할까요?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            스펙 정보는 요금제를 등록한 이후에는 수정할 수 없어
            <br />
            요. 입력한 값이 맞는지 꼼꼼히 확인해 주세요
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelOnlyRegister}>취소</Button>
          <Button
            onClick={handleOnOnlyRegister}
            color="primary"
            variant="contained"
          >
            등록하기
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
