import { ReleaseNoteItem } from './releaseNotes';
import ReleaseStatusType from './ReleaseStatusType';

const Release_2_2_1: ReleaseNoteItem = {
  version: '2.2.1',
  date: '2023-06-05',
  title: '문의하기 및 릴리즈 노트 개선',
  details: [
    {
      title: '문의하기 기능 개선',
      contents: [
        {
          content: (
            <>
              문의하기 기능의 위치가 좌측 네비게이션으로 이동하였고,
              <br />
              문의하기의 응답 알림이 보이도록 수정되었습니다. 있습니다.
            </>
          ),
          status: ReleaseStatusType.CHANGE,
        },
      ],
    },
    {
      title: '릴리즈 노트 개선',
      contents: [
        {
          content: (
            <>
              릴리즈 노트 리스트에서 버전과 함께 타이틀이 보이도록
              개선되었습니다.
            </>
          ),
          status: ReleaseStatusType.CHANGE,
        },
      ],
    },
  ],
};

export default Release_2_2_1;
