import { ContentCopy } from '@mui/icons-material';
import { Alert, IconButton, Snackbar } from '@mui/material';
import { FC, useState } from 'react';
import pbl from '../../pbl/pbl-service/pbl-service';

interface CopyButtonProps {
  copyText: string;
  actionName: string;
  size?: 'small' | 'medium' | 'large';
  style?: React.CSSProperties;
}

const CopyButton: FC<CopyButtonProps> = ({
  size,
  copyText,
  actionName,
  style,
}) => {
  const [openCopySnackbar, setOpenCopySnackbar] = useState<boolean>(false);

  return (
    <>
      <IconButton
        size={size}
        style={style}
        onClick={() => {
          pbl('click', 'copy', 'here', {
            object: {
              id: actionName,
            },
          });
          navigator.clipboard.writeText(copyText);
          setOpenCopySnackbar(true);
        }}
      >
        <ContentCopy fontSize={size} />
      </IconButton>
      <Snackbar
        open={openCopySnackbar}
        autoHideDuration={1500}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClose={(_, reason?: string) => {
          if (reason === 'clickaway') {
            return;
          }

          setOpenCopySnackbar(false);
        }}
      >
        <Alert elevation={6} variant="filled" severity="success">
          복사되었습니다.
        </Alert>
      </Snackbar>
    </>
  );
};

export default CopyButton;
