import { FC } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { SearchSectionTemplate } from '../../../SearchData';

interface Props {
  onClickSearch: () => void;
  onClickReset: () => void;
}

const InternetConsultListSearch: FC<Props> = ({
  onClickSearch,
  onClickReset,
}) => {
  return (
    <>
      <Box>
        <SearchSectionTemplate
          dateSection
          customerNameSection
          customerPhoneNumberSection
          planNameSection
          internetStatusSection
          mnoSection
          memoSection
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mt: 2,
          mb: 1,
        }}
      >
        <Button onClick={onClickSearch} variant="contained" sx={{ mr: 1 }}>
          검색
        </Button>
        <Button onClick={onClickReset} variant="outlined">
          검색 초기화
        </Button>
      </Box>
    </>
  );
};

export default InternetConsultListSearch;
