import { ReleaseNoteItem } from './releaseNotes';
import ReleaseStatusType from './ReleaseStatusType';

const Release_2_9_1: ReleaseNoteItem = {
  version: '2.9.1',
  date: '2024-10-16',
  title: '장기간 미완료 탭 삭제',
  details: [
    {
      title: '장기간 미완료 탭 삭제',
      contents: [
        {
          content: (
            <>
              해당 탭이 사업자 관점에서는 거의 사용되지 않고 있으며, 탭을 생성할
              당시보다 현재 개통이 훨씬 원활히 진행되고 있는 사유로 해당 탭을
              제거합니다.
            </>
          ),
          status: ReleaseStatusType.DEPRECATED,
        },
      ],
    },
  ],
};

export default Release_2_9_1;
