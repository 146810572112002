import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

import { orderApiService } from '../../../api/order';
import { handleApi } from '../../../common/http_util';
import { useLoading } from '../../../context/LoadingContext';
import { MobilePlanOrder } from '../../../entity/order';
import { BeforeOrderInfoTableDialog } from '../popup/BeforeOrderInfoTableDialog';
import pbl from '../../../pbl/pbl-service/pbl-service';

interface BeforeOrderInfoButtonProps {
  order: MobilePlanOrder;
}

export const BeforeOrderInfoButton: FC<BeforeOrderInfoButtonProps> = ({
  order,
}) => {
  const [userOrderList, setUserOrderList] = useState<MobilePlanOrder[]>();
  const navigate = useNavigate();
  const { setLoading } = useLoading();
  const [showDialog, setShowDialog] = useState<boolean>(false);

  useEffect(() => {
    if (!order.customerName || !order.customerPhoneNumber) return;
    fetchUserOrderList(order.customerName, order.customerPhoneNumber);
  }, [order]);

  useEffect(() => {
    if (!userOrderList) return;
    pbl('impression', 'button', 'here', {
      object: {
        section: 'past_order_button',
      },
      eventProperties: {
        orderCount: userOrderList.length,
        hasPastOrders: !!userOrderList?.length,
      },
    });
  }, [userOrderList]);

  const fetchUserOrderList = useCallback(
    async (customerName: string, customerPhoneNumber: string) => {
      setLoading(true);

      try {
        const rs = await handleApi(
          () =>
            orderApiService.getList(
              {
                customerName,
                customerPhoneNumber,
                orderTypes: ['register', 'self', 'outlink_self'],
              },
              0,
              25,
              'createdAt',
            ),
          navigate,
        );

        setUserOrderList(rs?.list.filter((item) => item.id !== order.id) ?? []);
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  const onClickShowHandler = () => {
    pbl('click', 'button', 'here', {
      object: {
        id: 'past_history',
        section: 'register_info',
      },
      eventProperties: {
        orderId: order.id,
      },
    });
    setShowDialog(true);
  };

  const onCloseHandler = () => {
    setShowDialog(false);
  };

  if (!userOrderList?.length) return <></>;
  return (
    <>
      <Button variant="outlined" onClick={onClickShowHandler}>
        이전 신청 내역 보기
      </Button>
      <BeforeOrderInfoTableDialog
        open={showDialog}
        userOrderList={userOrderList}
        onClose={onCloseHandler}
      />
    </>
  );
};
