import styled from '@emotion/styled';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { MoyoActivationState } from '../../../domains/activations/states/enum';
import { getActivationStateLabelText } from '../../../domains/activations/states/services';
import { useEffect, useState } from 'react';
import { sendPBL } from '../../../pbl/pbl-api-service';
import pbl from '../../../pbl/pbl-service/pbl-service';

const Frame = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

interface OrderDetailOneTongCallapsedProps {
  state: MoyoActivationState;
  onClickIgnoreWarning: () => void;
}

export const OrderDetailOneTongCallapsed = ({
  state,
  onClickIgnoreWarning,
}: OrderDetailOneTongCallapsedProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const onClickFakePartnersActivationButton = () => {
    pbl('click', 'button', 'order_detail', {
      object: {
        id: 'force_partners_activation_button',
        section: 'partners_activation',
      },
    });
    openDialog();
  };

  const onClickIgnoreWraningButton = () => {
    pbl('click', 'modal_button', 'order_detail', {
      object: {
        id: 'force_partners_activation_popup_force_submit',
        section: 'partners_activation',
      },
    });
    onClickIgnoreWarning();
    closeDialog();
  };

  const onClickCloseDialogButton = () => {
    pbl('click', 'modal_button', 'order_detail', {
      object: {
        id: 'force_partners_activation_popup_cancel',
        section: 'partners_activation',
      },
    });
    closeDialog();
  };

  useEffect(() => {
    pbl('impression', 'button', 'order_detail', {
      object: {
        id: 'force_partners_activation_button',
        section: 'partners_activation',
      },
    });
  }, []);

  return (
    <>
      <Frame>
        <Typography variant="h5" my={3}>
          개통하기
        </Typography>
        <Button
          size="small"
          color="inherit"
          variant="contained"
          onClick={onClickFakePartnersActivationButton}
        >
          직접 개통하기
        </Button>
      </Frame>
      <WarningDialog
        open={isDialogOpen && state !== MoyoActivationState.원통_대기}
        state={state}
        onClickCloseDialogButton={onClickCloseDialogButton}
        onClickIgnoreWraningButton={onClickIgnoreWraningButton}
      />
      <WarningForWatingSelfActivationDialog
        open={isDialogOpen && state === MoyoActivationState.원통_대기}
        state={state}
        onClickCloseDialogButton={onClickCloseDialogButton}
        onClickIgnoreWraningButton={onClickIgnoreWraningButton}
      />
    </>
  );
};

function WarningDialog({
  open,
  state,
  onClickCloseDialogButton,
  onClickIgnoreWraningButton,
}: {
  open: boolean;
  state: MoyoActivationState;
  onClickCloseDialogButton: () => void;
  onClickIgnoreWraningButton: () => void;
}) {
  return (
    <Dialog open={open}>
      <DialogTitle>
        {getActivationStateLabelText({
          state,
          isMoyoAuthority: false,
        })}
        인 주문이에요
      </DialogTitle>
      <DialogContent>
        고객이 요청하지 않았다면, 해당 주문을 처리하지 말아주세요.
        <br />
        파트너가 직접 개통을 시작하는 순간, 자동으로 돌고있던 검증이
        초기화됩니다.
      </DialogContent>
      <DialogActions>
        <Button size="medium" onClick={onClickCloseDialogButton}>
          그만두기
        </Button>
        <Button
          size="medium"
          color="error"
          onClick={onClickIgnoreWraningButton}
        >
          무시하고 진행하기
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function WarningForWatingSelfActivationDialog({
  open,
  state,
  onClickCloseDialogButton,
  onClickIgnoreWraningButton,
}: {
  open: boolean;
  state: MoyoActivationState;
  onClickCloseDialogButton: () => void;
  onClickIgnoreWraningButton: () => void;
}) {
  return (
    <Dialog open={open}>
      <DialogTitle>
        {getActivationStateLabelText({
          state,
          isMoyoAuthority: false,
        })}
        인 주문이에요
      </DialogTitle>
      <DialogContent>
        개통 가능한 시점을 기준으로 3일 동안 스스로 개통하도록 알림톡을 보내고
        있어요. (영업일 기준)
        <br />
        고객이 특별히 요청한 것이 아니라면, 스스로 개통하도록 기다려 주세요.
      </DialogContent>
      <DialogActions>
        <Button size="medium" onClick={onClickCloseDialogButton}>
          그만두기
        </Button>
        <Button
          size="medium"
          color="error"
          onClick={onClickIgnoreWraningButton}
        >
          무시하고 진행하기
        </Button>
      </DialogActions>
    </Dialog>
  );
}
