import { Box, TextField } from '@mui/material';
import { DataGrid, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';

import { SimplePlanMeta } from '../../entity/plan';
import { useState } from 'react';

type MobilePlanListProps = {
  plans: Array<SimplePlanMeta>;
  gridColumns: GridColDef[];
  onSearchTextChanged: (s: string) => void;
  onSelectedPlanIdChanged: (planId: number) => void;
  updateSearchParams: (data: {
    [key: string]: string | null | undefined;
  }) => void;
  onSelectionChanged: (selectedIds: GridRowSelectionModel) => void;
};

export default function MobilePlanList({
  plans,
  gridColumns,
  onSearchTextChanged,
  onSelectedPlanIdChanged,
  updateSearchParams,
  onSelectionChanged,
}: MobilePlanListProps) {
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(25);

  const onPageChange = (page: number) => {
    setPage(page);
    updateSearchParams({ page: page.toString() });
  };

  const onPageSizeChange = (pageSize: number) => {
    setPageSize(pageSize);
    updateSearchParams({ pageSize: pageSize.toString() });
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'end',
        }}
        mb={1}
      >
        <TextField
          size="small"
          label="검색"
          onChange={(e) => onSearchTextChanged(e.target.value)}
        />
      </Box>
      <div style={{ height: '50vh' }}>
        <DataGrid
          sx={{
            '& .MuiDataGrid-columnSeparator': {
              visibility: 'visible',
            },
          }}
          localeText={{
            footerRowSelected: (count: number) => `${count}개의 요금제 선택됨`,
          }}
          rows={plans}
          columns={gridColumns}
          pagination
          paginationMode="client"
          paginationModel={{ page, pageSize }}
          onPaginationModelChange={(newModel) => {
            onPageChange(newModel.page);
            onPageSizeChange(newModel.pageSize);
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          onRowSelectionModelChange={onSelectionChanged}
        />
      </div>
    </>
  );
}
