import { GridRowId, GridRowSelectionModel } from '@mui/x-data-grid';
import { IBehaviorLogObject } from '../pbl-service/create-pbl-service';
import pbl from '../pbl-service/pbl-service';

const notIncludesItemFilter = (
  source: GridRowSelectionModel,
  target: GridRowSelectionModel,
) => {
  return source.filter((item) => !target.includes(item));
};

const pblDatagridCheckboxAll = (
  items: GridRowId[],
  isAdded: boolean,
  options?: {
    object?: IBehaviorLogObject;
  },
) => {
  pbl('click', 'checkbox', 'here', {
    object: {
      id: 'all',
      ...options?.object,
    },
    eventProperties: {
      orderIds: items,
      checked: isAdded,
    },
  });
};

const pblDatagridCheckboxItem = (
  items: GridRowId[],
  isAdded: boolean,
  options?: {
    object?: IBehaviorLogObject;
  },
) => {
  pbl('click', 'checkbox', 'here', {
    object: {
      id: items[0].toString(),
      ...options?.object,
    },
    eventProperties: {
      orderId: items[0].toString(),
      checked: isAdded,
    },
  });
};

export const pblDatagridCheckbox = (
  beforeIds: GridRowSelectionModel,
  afterIds: GridRowSelectionModel,
  options?: {
    object?: IBehaviorLogObject;
  },
) => {
  const isAdded = beforeIds.length < afterIds.length;
  const modifiedItems = isAdded
    ? notIncludesItemFilter(afterIds, beforeIds)
    : notIncludesItemFilter(beforeIds, afterIds);
  if (modifiedItems.length === 1) {
    pblDatagridCheckboxItem(modifiedItems, isAdded, options);
  } else {
    pblDatagridCheckboxAll(modifiedItems, isAdded, options);
  }
};
