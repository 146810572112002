import { Table } from '@mui/material';
import { styled } from '@mui/system';

const VerticalColorTable = styled(Table)(({ theme }) => ({
  td: {
    ':nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
      fontWeight: 600,
    },
  },
  'tr:last-child': {
    td: {
      borderBottom: 'none',
    },
  },
}));

export { VerticalColorTable };
