import { FC, useCallback, useMemo, useState } from 'react';
import {
  Paper,
  styled,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';

import { ColumnGroupDefinition } from './ColumnGroupDefinition';
import { VerticalColorTable } from '../../../shared/components/coloredTable';
import { MobilePlanOrder } from '../../../entity/order';
import {
  ETC_PHONE_MODEL_OPTIONS,
  IPHONE_MODEL_OPTIONS,
  PHONE_MEMORY_MAP,
  SAMSUNG_MODEL_OPTIONS,
} from '../../../types/ESimTypes';
import CopyButton from '../../../shared/components/copyButton';
import ImageDialog from '../../../shared/components/ImageDialog';

const ImageTableCell = styled(TableCell)`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  align-items: center;
  img {
    max-width: 100%;
    cursor: pointer;
  }
`;

type Props = {
  order: MobilePlanOrder;
};

export const OrderESimInfoSection: FC<Props> = ({ order }) => {
  const [openImage, setOpenImage] = useState<{ src: string; alt: string }>();
  const model = useMemo(
    () =>
      [
        ...Object.values(IPHONE_MODEL_OPTIONS),
        ...Object.values(SAMSUNG_MODEL_OPTIONS),
        ...Object.values(ETC_PHONE_MODEL_OPTIONS),
      ].find((model) => model.value === order.detail?.phoneModel),
    [order],
  );

  const onClickImgHandler = useCallback((src: string, alt: string) => {
    return () => {
      setOpenImage({ src, alt });
    };
  }, []);

  if (!order.isEsim) return <></>;

  return (
    <>
      <Typography variant="h6" my={2}>
        eSIM 정보
      </Typography>
      <TableContainer component={Paper} elevation={5}>
        <VerticalColorTable>
          {ColumnGroupDefinition}
          <TableBody>
            <TableRow>
              <TableCell>기기 모델</TableCell>
              <TableCell>
                {`${model?.label ?? ''}${
                  model?.value ? ` (${model?.value})` : ''
                }`}
              </TableCell>
              <TableCell>EID</TableCell>
              <TableCell>
                {order.detail?.eid}
                {order.detail?.eid && (
                  <CopyButton
                    actionName="eid"
                    copyText={order.detail?.eid}
                    style={{ marginLeft: '10px' }}
                    size="small"
                  />
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>용량</TableCell>
              <TableCell>
                {
                  Object.values(PHONE_MEMORY_MAP).find(
                    (memory) => memory.value === `${order.detail?.phoneMemory}`,
                  )?.label
                }
              </TableCell>
              <TableCell>IMEI</TableCell>
              <TableCell>
                {order.detail?.imei}
                {order.detail?.imei && (
                  <CopyButton
                    actionName="imei"
                    copyText={order.detail?.imei}
                    style={{ marginLeft: '10px' }}
                    size="small"
                  />
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>일련번호</TableCell>
              <TableCell>
                {order.detail?.phoneSerialNumber}
                {order.detail?.phoneSerialNumber && (
                  <CopyButton
                    actionName="phoneSerialNumber"
                    copyText={order.detail?.phoneSerialNumber}
                    style={{ marginLeft: '10px' }}
                    size="small"
                  />
                )}
              </TableCell>
              <TableCell>IMEI2</TableCell>
              <TableCell>
                {order.detail?.imei2}
                {order.detail?.imei2 && (
                  <CopyButton
                    actionName="imei2"
                    copyText={order.detail?.imei2}
                    style={{ marginLeft: '10px' }}
                    size="small"
                  />
                )}
              </TableCell>
            </TableRow>
            {(order.detail?.esimImageUrlList ||
              order.detail?.phoneInfoImageUrlList) && (
              <TableRow>
                <TableCell>휴대폰 정보</TableCell>
                <ImageTableCell>
                  {order.detail?.esimImageUrlList?.map((imgUrl) => (
                    <img
                      key={imgUrl}
                      src={imgUrl}
                      onClick={onClickImgHandler(imgUrl, imgUrl)}
                      alt="휴대폰 정보 이미지"
                    />
                  ))}
                </ImageTableCell>
                <TableCell>고유번호</TableCell>
                <ImageTableCell>
                  {order.detail?.phoneInfoImageUrlList?.map((imgUrl) => (
                    <img
                      key={imgUrl}
                      src={imgUrl}
                      onClick={onClickImgHandler(imgUrl, imgUrl)}
                      alt="고유번호 이미지"
                    />
                  ))}
                </ImageTableCell>
              </TableRow>
            )}
          </TableBody>
        </VerticalColorTable>
      </TableContainer>
      <ImageDialog
        open={!!openImage}
        src={openImage?.src}
        alt={openImage?.alt}
        onClose={() => setOpenImage(undefined)}
      />
    </>
  );
};
