import {
  Alert,
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { DateTimePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { BootstrapDialog } from '../../PlanDetailPage/Dialog/Dialog';
import { fetchCompanyInfo } from '../../../common/fetch-company';
import { comma } from '../../../hooks/string-util';

interface ModifyPlanFeeDialogProps {
  open: boolean;
  onSuccess: (date: DateTime, isReservation: boolean) => void;
  onClose: () => void;
  feeInfo: {
    fee: number;
    feeAfterDiscount: number;
    promotionPeriod: number;
  };
  modifyInfo: {
    fee: number;
    feeAfterDiscount: number;
    promotionPeriod: number;
  };
}
const ModifyPlanFeeDialog = ({
  open,
  onSuccess,
  onClose,
  feeInfo,
  modifyInfo,
}: ModifyPlanFeeDialogProps) => {
  const now = DateTime.now().plus({ days: 1 }).setZone('Asia/Seoul');
  const [isReservation, setIsReservation] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);
  const [name, setName] = useState<string | undefined>('');
  const [reservation, setReservation] = useState<DateTime | null>(
    DateTime.local(now.year, now.month, now.day),
  );
  const onChangeDeleteReservation = (value: boolean) => {
    setIsReservation(value);
  };
  const onChangeDatePicker = (dateValue: DateTime | null) => {
    if (dateValue && dateValue < DateTime.now().setZone('Asia/Seoul')) {
      return;
    }
    setReservation(dateValue);
  };

  const fetchCompanyName = async () => {
    await fetchCompanyInfo().then((res) => {
      setName(res?.name);
    });
  };

  useEffect(() => {
    fetchCompanyName();
  }, []);

  return (
    <BootstrapDialog
      maxWidth="sm"
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <div style={{ padding: '16px 24px' }}>
        <Typography fontSize={20} fontWeight={500} color={'#000'}>
          이 수정사항을 언제 반영할까요?
        </Typography>
      </div>
      <DialogContent style={{ width: 'unset' }}>
        <Alert variant="outlined" severity="info">
          할인 시 가격 :{' '}
          {`${comma(feeInfo.fee)}원 -> ${comma(modifyInfo.fee)}원`}
          <br />
          할인 종료 후 가격 :{' '}
          {`${comma(feeInfo.feeAfterDiscount)}원 -> ${comma(
            modifyInfo.feeAfterDiscount,
          )}원`}
          <br />
          할인기간 :{' '}
          {`${
            feeInfo.promotionPeriod ? feeInfo.promotionPeriod : '할인기간 없음'
          } -> ${modifyInfo.promotionPeriod}`}
        </Alert>
      </DialogContent>
      <Grid
        display={'flex'}
        flexDirection={'column'}
        gap={'24px'}
        style={{ padding: '0 24px' }}
      >
        <RadioGroup
          sx={{ flexDirection: 'row' }}
          defaultValue={false}
          onChange={(event) =>
            onChangeDeleteReservation(event.target.value === 'true')
          }
        >
          <FormControlLabel
            value={false}
            control={<Radio />}
            label="지금 바로"
          />
          <FormControlLabel
            value={true}
            control={<Radio />}
            label="특정 시간에 예약"
          />
        </RadioGroup>
        {isReservation && (
          <Box>
            <DateTimePicker
              sx={{ width: '100%' }}
              ampm={true}
              value={reservation}
              onChange={onChangeDatePicker}
              format="yyyy년 MM월 dd일 a hh시 mm분"
              minDateTime={DateTime.now().setZone('Asia/Seoul')}
            />
          </Box>
        )}
        <Box
          sx={{
            background: '#f5f5f5',
            borderRadius: '12px',
            padding: '16px',
          }}
          display={'flex'}
          alignItems={'center'}
          gap={'8px'}
        >
          <input
            type="checkbox"
            checked={checked}
            style={{
              width: '20px',
              height: '20px',
              borderRadius: '4px',
              border: '1px solid #DEE2E6',
              borderColor: '#DEE2E6',
              background: '#fff',
            }}
            onChange={(event) => setChecked(event.target.checked)}
          />
          <Typography
            fontSize={16}
            fontWeight={500}
            component={'span'}
            style={{ width: '100%' }}
          >
            모요 측 검수 없이 즉시 노출되며, 정보를 잘못 입력한 경우 고객 응대
            책임이 {name}에 있음을 확인했습니다.
          </Typography>
        </Box>
      </Grid>
      <DialogActions style={{ justifyContent: 'center', padding: '20px 0' }}>
        <Button color={'inherit'} variant={'contained'} onClick={onClose}>
          닫기
        </Button>
        <Button
          color={'info'}
          variant={'contained'}
          onClick={() => {
            onSuccess(reservation as DateTime, isReservation);
            setIsReservation(false);
          }}
          disabled={!checked}
        >
          확인
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};

export default ModifyPlanFeeDialog;
