import { Grid, MenuItem, Select, Typography } from '@mui/material';

import { useSearchSection } from '../hooks';

export function HasUsimSection() {
  const { searchState, searchHandler } = useSearchSection();

  const { isEsim, hasUsim, willPurchaseUsim } = searchState;
  const { onChangeUsim } = searchHandler;

  return (
    <Grid item xs={12} md={6}>
      <Typography mb={1}>유심 보유</Typography>
      <Select
        fullWidth
        defaultValue=""
        value={
          isEsim === 'true'
            ? 'eSIM'
            : willPurchaseUsim === 'true'
            ? 'willPurchaseUsim'
            : hasUsim
        }
        onChange={(event) => onChangeUsim(event.target.value)}
        size="small"
        displayEmpty
      >
        <MenuItem key={'all'} value="">
          전체
        </MenuItem>
        <MenuItem key={'true'} value="true">
          보유
        </MenuItem>
        <MenuItem key={'false'} value="false">
          미보유(배송)
        </MenuItem>
        <MenuItem key={'willPurchaseUsim'} value="willPurchaseUsim">
          미보유(직접구매)
        </MenuItem>
        <MenuItem key={'eSIM'} value="eSIM">
          eSIM
        </MenuItem>
      </Select>
    </Grid>
  );
}
