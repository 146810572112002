import { useQuery } from '@tanstack/react-query';
import { MoyoOnlyApiService } from '../../../../api/moyoOnly';

export default function useGetReservePlanList(mvno: string) {
  const { data, refetch } = useQuery({
    queryKey: [mvno, 'reserve', 'moyoonly', 'list'],
    queryFn: async () => {
      const response = await MoyoOnlyApiService.getReserveCreateList(mvno);
      return response.data.result;
    },
  });
  return {
    reservePlans: data,
    refetch,
  };
}
