import { useFormContext } from 'react-hook-form';
import { TableCell, TableRow, TextField } from '@mui/material';

import { MobilePlanOrder } from '../../../entity/order';

export function DeliveryInfo() {
  const { register } = useFormContext<MobilePlanOrder>();

  return (
    <TableRow>
      <TableCell>택배사</TableCell>
      <TableCell>
        <TextField {...register('detail.deliveryCompany')} size="small" />
      </TableCell>
      <TableCell>송장 번호</TableCell>
      <TableCell>
        <TextField
          {...register('detail.deliveryTrackingNumber')}
          size="small"
        />
      </TableCell>
    </TableRow>
  );
}
