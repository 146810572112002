import { generateUUID } from '../../common/string_util';

const PBL_NAVIGATION_KEY = 'pbl_memories_navigation';
const PBL_PREV_NAVIGATION_KEY = 'pbl_prev_memories_navigation';
const PBL_PAGEVIEW_UUID = 'pbl_pageview_uuid';

const getHereNavigation = () => {
  return sessionStorage.getItem(PBL_NAVIGATION_KEY);
};

const updateNavigation = (navigation: string) => {
  sessionStorage.setItem(PBL_NAVIGATION_KEY, navigation);
};

const updatePreviousNavigation = () => {
  const hereNavigation = getHereNavigation();
  if (!hereNavigation) return;
  sessionStorage.setItem(PBL_PREV_NAVIGATION_KEY, hereNavigation);
};

const getPreviousNavigation = () => {
  return sessionStorage.getItem(PBL_PREV_NAVIGATION_KEY) ?? undefined;
};

const updatePageviewUuid = () => {
  return sessionStorage.setItem(PBL_PAGEVIEW_UUID, generateUUID());
};

const getPageviewUuid = () => {
  return sessionStorage.getItem(PBL_PAGEVIEW_UUID) ?? undefined;
};

export const pblPageviewNavigationProcessor = {
  updatePreviousNavigation,
  getPreviousNavigation,
  getHereNavigation,
  updateNavigation,
  updatePageviewUuid,
  getPageviewUuid,
};
