import { Box, Grid, MenuItem, Select, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { useSearchSection } from '../hooks';

export function DateSection() {
  const { searchState, searchHandler } = useSearchSection();

  const { dateStandard, startDate, endDate } = searchState;
  const { setDateStandard, setStartDate, setEndDate } = searchHandler;

  return (
    <Grid item xs={12} md={6}>
      <Box>
        <Typography mb={1}>날짜</Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Select
            size="small"
            sx={{
              marginRight: '1rem',
            }}
            defaultValue="createdAt"
            value={dateStandard}
            onChange={(event) => setDateStandard(event.target.value)}
          >
            <MenuItem value="createdAt">신청일</MenuItem>
            <MenuItem value="completedAt">개통일</MenuItem>
          </Select>
          <div
            style={{
              display: 'inline-block',
              marginRight: '1rem',
            }}
          >
            <DatePicker
              label="시작일"
              value={startDate}
              onChange={setStartDate}
              slotProps={{ textField: { size: 'small' } }}
            />
          </div>
          <DatePicker
            label="종료일"
            value={endDate}
            onChange={setEndDate}
            slotProps={{ textField: { size: 'small' } }}
          />
        </Box>
      </Box>
    </Grid>
  );
}
