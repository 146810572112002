import { FC } from 'react';
import {
  ApplicationMethod,
  ApplicationMethodType,
} from '../../types/mobile-plan-operator-admin-types';
import { Mno } from '../../../../entity/mno';
import { convertStringToMno } from '../../../../common/order-util';
import { Box, Chip, ChipOwnProps, Typography } from '@mui/material';

const getChipColorBySupportType = (
  supportType: ApplicationMethodType,
): ChipOwnProps['color'] => {
  if (supportType === '모요개통') return 'primary';
  if (supportType === '간편신청') return 'success';
  if (supportType === '아웃링크') return 'default';
  return 'default';
};

const SignupSupportChipItem = ({
  title,
  supportType,
}: {
  title: string;
  supportType: ApplicationMethodType;
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: 0.5,
        alignItems: 'center',
        flex: 1,
      }}
    >
      <Typography variant="body1" color="text.primary">
        {title}
      </Typography>
      <Chip
        variant="outlined"
        size="small"
        color={getChipColorBySupportType(supportType)}
        label={supportType}
      />
    </Box>
  );
};

const MvnoInfoSignupSupportMnoItem: FC<{ mno: Mno } & ApplicationMethod> = ({
  changeApplicationMethod,
  newApplicationMethod,
  mno,
}) => {
  const title = convertStringToMno(mno);
  const unSupportSingup =
    changeApplicationMethod === '미지원' && newApplicationMethod === '미지원';

  return (
    <Box
      sx={{
        display: 'flex',
        opacity: unSupportSingup ? 0.5 : 1,
        paddingY: 1,
        flexDirection: 'column',
        gap: 1,
        flex: 1,
      }}
    >
      <Typography variant="caption" color="text.secondary" fontWeight={700}>
        {title}망
      </Typography>
      <Box
        sx={{
          display: 'flex',
          paddingY: 1,
          gap: 1,
        }}
      >
        <SignupSupportChipItem
          title="신규"
          supportType={newApplicationMethod}
        />
        <SignupSupportChipItem
          title="번이"
          supportType={changeApplicationMethod}
        />
      </Box>
    </Box>
  );
};

export default MvnoInfoSignupSupportMnoItem;
