import { Resolver } from 'react-hook-form';
import { MobilePlanOrder } from '../../../entity/order';
import { customerPhoneNumberErrorMessage } from '../components';

const orderEditFormValidationResolverForOrderTypeCall: Resolver<
  MobilePlanOrder,
  any
> = async (values: MobilePlanOrder) => {
  if (!values.customerPhoneNumber) {
    return {
      errors: {
        customerPhoneNumber: {
          type: 'required',
          message: customerPhoneNumberErrorMessage,
        },
      },
      values: {
        ...values,
      },
    };
  }

  return {
    errors: {},
    values: {
      ...values,
    },
  };
};

export const orderEditFormValidationResolver = (orderType?: string) => {
  return orderType === 'call'
    ? orderEditFormValidationResolverForOrderTypeCall
    : undefined;
};
