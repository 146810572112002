import { Controller, useFormContext } from 'react-hook-form';
import {
  FormControl,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField,
} from '@mui/material';

import { MobilePlanOrder } from '../../../entity/order';

interface UsimModelProps {
  order: MobilePlanOrder;
}

export function UsimModelAndNumber({ order }: UsimModelProps) {
  const { control, register } = useFormContext<MobilePlanOrder>();

  return (
    <TableRow>
      <TableCell>유심 모델명</TableCell>
      <TableCell>
        {order.mno === 'SKT' ? (
          <TextField {...register('detail.usimModel')} size="small" />
        ) : (
          <FormControl>
            <Controller
              name="detail.usimModel"
              control={control}
              defaultValue={order.detail?.usimModel ?? ''}
              render={({ field }) => (
                <Select {...field} size="small" style={{ width: '200px' }}>
                  <MenuItem value="C4930">C4930</MenuItem>
                  <MenuItem value="K3600">K3600</MenuItem>
                  <MenuItem value="K3610">K3610</MenuItem>
                  <MenuItem value="K3620">K3620</MenuItem>
                  <MenuItem value="K3630">K3630</MenuItem>
                  <MenuItem value="K3820">K3820</MenuItem>
                  <MenuItem value="K3840">K3840</MenuItem>
                  <MenuItem value="K5100">K5100</MenuItem>
                  <MenuItem value="K6000">K6000</MenuItem>
                  <MenuItem value="K9000">K9000</MenuItem>
                  <MenuItem value="U2610">U2610</MenuItem>
                  <MenuItem value="U8600">U8600</MenuItem>
                  <MenuItem value="U8660">U8660</MenuItem>
                  <MenuItem value="U8800">U8800</MenuItem>
                  <MenuItem value="U8680">U8680</MenuItem>
                  <MenuItem value="L1600">L1600</MenuItem>
                  <MenuItem value="V2317">V2317</MenuItem>
                  {/* 아래 3개는 SKT 모델명 (스마텔 요청사항) */}
                  <MenuItem value="22066">22066</MenuItem>
                  <MenuItem value="21126">21126</MenuItem>
                  <MenuItem value="20026">20026</MenuItem>
                </Select>
              )}
            />
          </FormControl>
        )}
      </TableCell>
      <TableCell>유심 일련번호</TableCell>
      <TableCell>
        <TextField {...register('detail.usimSerialNumber')} size="small" />
      </TableCell>
    </TableRow>
  );
}
