import { Grid, TextField, Typography } from '@mui/material';

import { useSearchSection } from '../hooks';

export function PlanNameSection() {
  const { searchState, searchHandler } = useSearchSection();

  const { planName } = searchState;
  const { setPlanName } = searchHandler;

  return (
    <Grid item xs={12} md={6}>
      <Typography mb={1}>요금제 이름</Typography>
      <TextField
        label="요금제 이름"
        value={planName}
        onChange={(event) => setPlanName(event.target.value)}
        size="small"
        fullWidth
      />
    </Grid>
  );
}
