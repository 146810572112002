import { FC, MouseEvent, useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';

import { useOptions } from '../../../context/OptionsContext';
import { AlarmTalkType, useAlarmTalkSendModal } from './AlarmTalkSendModal';
import { useToastAlarmTalkSend } from './ToastAlarmTalkSend';
import { isSupportFailAlarmTalk } from '../../../common/moyoActivationCollection';
import pbl from '../../../pbl/pbl-service/pbl-service';

interface AlarmTalkButtonProps {
  userName?: string;
  orderId: string;
}

export const AlarmTalkButton: FC<AlarmTalkButtonProps> = ({
  userName = '고객',
  orderId,
}) => {
  const alarmTalkSendModal = useAlarmTalkSendModal();
  const toastAlarmTalkSend = useToastAlarmTalkSend();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { adminUser } = useOptions();

  const onOpenMenuHandler = (event: MouseEvent<HTMLElement>) => {
    pbl('click', 'button', 'here', {
      object: {
        id: 'send_alimtalk',
        section: 'order_history',
      },
      eventProperties: {
        orderId,
      },
    });
    setAnchorEl(event.currentTarget);
  };

  const onCloseMenuHandler = () => {
    setAnchorEl(null);
  };

  const pblSendAlarmTalkTemplate = (actionName: AlarmTalkType) => {
    pbl('click', 'dropdown', 'here', {
      object: {
        id: 'send_alimtalk',
        section: 'order_history',
      },
      eventProperties: {
        reason: actionName,
      },
    });
  };

  const on개통불가기타사유 = async () => {
    pblSendAlarmTalkTemplate('개통 불가 (기타 사유)');
    const result = await alarmTalkSendModal.open.개통불가기타사유(orderId);
    if (!result) return;
    toastAlarmTalkSend.open(userName, result);
  };
  const on미납 = async () => {
    pblSendAlarmTalkTemplate('미납');
    const result = await alarmTalkSendModal.open.미납(orderId);
    if (!result) return;
    toastAlarmTalkSend.open(userName, result);
  };
  const on사용불가유심 = async () => {
    pblSendAlarmTalkTemplate('사용 불가 유심');
    const result = await alarmTalkSendModal.open.사용불가유심(orderId);
    if (!result) return;
    toastAlarmTalkSend.open(userName, result);
  };
  const on개통사유정정처리 = async () => {
    pblSendAlarmTalkTemplate('개통불가 사유 정정 요청');
    const result = await alarmTalkSendModal.open.개통사유불가(orderId);
    if (!result) return;
    toastAlarmTalkSend.open(userName, result);
  };

  const on번호이동3개월제한 = async () => {
    pblSendAlarmTalkTemplate('3개월 내 번호이동');
    const result = await alarmTalkSendModal.open.번호이동3개월제한(orderId);
    if (!result) return;
    toastAlarmTalkSend.open(userName, result);
  };

  const onNFC유심결제요청 = async () => {
    pblSendAlarmTalkTemplate('NFC 유심 결제 요청');
    const result = await alarmTalkSendModal.open.NFC유심결제요청(orderId);
    if (!result) return;
    toastAlarmTalkSend.open(userName, result);
  };
  const on타인납부 = async () => {
    pblSendAlarmTalkTemplate('타인납부');
    const result = await alarmTalkSendModal.open.타인납부(orderId);
    if (!result) return;
    toastAlarmTalkSend.open(userName, result);
  };

  const on명의불일치 = async () => {
    pblSendAlarmTalkTemplate('명의불일치');
    const result = await alarmTalkSendModal.open.명의불일치(orderId);
    if (!result) return;
    toastAlarmTalkSend.open(userName, result);
  };

  const on개통예정 = async () => {
    pblSendAlarmTalkTemplate('개통 예정');
    const result = await alarmTalkSendModal.open.개통예정(orderId);
    if (!result) return;
    toastAlarmTalkSend.open(userName, result);
  };

  const on중복신청 = async () => {
    pblSendAlarmTalkTemplate('중복신청');
    const result = await alarmTalkSendModal.open.중복신청(orderId);
    if (!result) return;
    toastAlarmTalkSend.open(userName, result);
  };

  const on14일이내개통불가 = async () => {
    pblSendAlarmTalkTemplate('14일 이내 개통 불가');
    const result = await alarmTalkSendModal.open['14일이내개통불가'](orderId);
    if (!result) {
      return;
    }
    toastAlarmTalkSend.open(userName, result);
  };

  if (!isSupportFailAlarmTalk(adminUser)) {
    return <></>;
  }

  return (
    <>
      <Button onClick={onOpenMenuHandler} variant="outlined">
        알림톡 보내기
      </Button>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={onCloseMenuHandler}
        onClick={onCloseMenuHandler}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={on개통사유정정처리}>
          개통불가 사유 정정 요청
        </MenuItem>
        <MenuItem onClick={on번호이동3개월제한}>3개월 내 번호이동</MenuItem>

        {['all', 'ins'].some((mvno) =>
          adminUser?.authorizedMvnos.includes(mvno),
        ) && (
          <MenuItem onClick={onNFC유심결제요청}>NFC 유심 결제 요청</MenuItem>
        )}
        <MenuItem onClick={on타인납부}>타인납부</MenuItem>
        <MenuItem onClick={on명의불일치}>명의불일치</MenuItem>

        {['all', 'marvelring', 'sugarmobile'].some((mvno) =>
          adminUser?.authorizedMvnos.includes(mvno),
        ) && (
          <>
            <MenuItem onClick={on개통예정}>개통 예정</MenuItem>
            <MenuItem onClick={on14일이내개통불가}>
              14일 이내 개통 불가
            </MenuItem>
          </>
        )}

        {['all', 'marvelring'].some((mvno) =>
          adminUser?.authorizedMvnos.includes(mvno),
        ) && (
          <>
            <MenuItem onClick={on중복신청}>중복신청</MenuItem>
          </>
        )}

        <MenuItem onClick={on개통불가기타사유}>개통 불가 (기타 사유)</MenuItem>
        <MenuItem onClick={on사용불가유심}>사용 불가 유심</MenuItem>
        <MenuItem onClick={on미납}>미납</MenuItem>
      </Menu>
    </>
  );
};
