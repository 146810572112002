import { Button } from '@mui/material';
import { CheckCircleOutline, HighlightOff } from '@mui/icons-material';
import { GridRenderCellParams, GridRowModel } from '@mui/x-data-grid';

import { Permission, hasPermissionType } from '../types/AuthType';
import AutoActivateStateChip from '../shared/components/autoActivateStateChip';
import { SimType } from '../entity/order';
import pbl from '../pbl/pbl-service/pbl-service';
import { MoyoActivationState } from '../domains/activations/states/enum';

const removeSensitiveInformation = (object: GridRowModel) => {
  const {
    customerName,
    birthday,
    customerPhoneNumber,
    orderPhoneNumber,
    applicantPhoneNumber,
    applicantName,
    ...rest
  } = object;
  return rest;
};

/**
 *
 * @param path - 클릭시 이동할 path를 지정합니다. 패스 뒤에는 /를 붙이지 않습니다.
 * @param buttonText - 버튼에 표시할 텍스트를 지정합니다. 기본값은 '상세'입니다.
 * @returns
 */
export const idRenderCell =
  (path: string, buttonText?: string, subPath?: string, idKey?: string) =>
  (params: GridRenderCellParams) => {
    const subPathString = subPath ? `/${subPath}` : '';
    const onClickDetail = () => {
      pbl('click', 'order', 'here', {
        object: {
          id: params.id.toString(),
          section: 'order_list',
          name: buttonText || '상세',
        },
        eventProperties: {
          ...removeSensitiveInformation(params.row),
        },
      });
      window.open(
        `${path}/${params.row?.[idKey ?? 'id']}${subPathString}`,
        '_blank',
      );
    };

    return (
      <Button variant="text" onClick={onClickDetail}>
        {buttonText || '상세'}
      </Button>
    );
  };

export const hasUsimRenderCell = (params: GridRenderCellParams) => {
  if (params.row.isEsim) {
    return 'eSIM';
  } else if (params.row.hasUsim || params.row.simType === SimType.HAS_USIM) {
    return 'O';
  } else if (params.row.simType == SimType.GOING_TO_BUY_USIM) {
    return 'X(직접구매)';
  } else {
    return 'X';
  }
};

/**
 *
 * @param moyoOnlyView - 모요 전용인지 확인하는 값. 기본값은 false
 * @returns
 */
export const autoActivateStateRenderCell =
  (moyoOnlyView = false) =>
  (params: GridRenderCellParams) =>
    (
      <AutoActivateStateChip
        state={params.row.autoActivateState}
        moyoOnlyView={moyoOnlyView}
        size="small"
      />
    );

export const isConfirmStateRenderCell = (params: GridRenderCellParams) => {
  return (
    <AutoActivateStateChip
      state={
        params.row.isConfirmed
          ? MoyoActivationState.처리_완료
          : MoyoActivationState.처리_전
      }
      size="small"
    />
  );
};

export const registerTypeRenderCell = (params: GridRenderCellParams) => {
  if (params.row.registerType === 'change') {
    return '번호이동';
  } else {
    return '신규가입';
  }
};

/**
 *
 * @param hasRole - 해당 유저가 해당 권한을 가지고 있는지 확인하는 값. 기본값은 false
 * @returns
 */
export const permissionTypeRenderCell =
  (permission: Permission) => (params: GridRenderCellParams) => {
    const hasPermission = hasPermissionType(
      params.row.permissions ?? [],
      permission,
    );
    return hasPermission ? (
      <CheckCircleOutline color="success" />
    ) : (
      <HighlightOff color="error" />
    );
  };
