import { useOptions } from '../context/OptionsContext';
import { AdminUser } from '../entity/admin';
import { IMobileOperatorMeta } from '../entity/plan';
import { Permission } from '../types/AuthType';

// FIXME 서버단에서 받아오도록 수정
const isSelfMoyoActive = (adminUser?: AdminUser) => {
  return (
    adminUser?.authorizedMvnos.includes('all') ||
    adminUser?.authorizedMvnos.includes('ins') ||
    adminUser?.authorizedMvnos.includes('insmobile') ||
    adminUser?.authorizedMvnos.includes('egmobile') ||
    adminUser?.authorizedMvnos.includes('smartel') ||
    adminUser?.authorizedMvnos.includes('sugarmobile') ||
    adminUser?.authorizedMvnos.includes('에르엘') ||
    adminUser?.authorizedMvnos.includes('쉐이크모바일') ||
    adminUser?.authorizedMvnos.includes('고고모바일') ||
    adminUser?.authorizedMvnos.includes('오파스모바일') ||
    adminUser?.authorizedMvnos.includes('핀다이렉트') ||
    adminUser?.authorizedMvnos.includes('marvelring') ||
    adminUser?.authorizedMvnos.includes('KG모바일') ||
    adminUser?.authorizedMvnos.includes('모나') ||
    adminUser?.authorizedMvnos.includes('tplus') ||
    adminUser?.authorizedMvnos.includes('yeoyou') ||
    adminUser?.authorizedMvnos.includes('찬스모바일')
  );
};

const isCallActive = (adminUser?: AdminUser) => {
  return (
    adminUser?.authorizedMvnos.includes('all') ||
    adminUser?.authorizedMvnos.includes('kTskylife') ||
    adminUser?.authorizedMvnos.includes('LGU') ||
    adminUser?.authorizedMvnos.includes('snowman')
  );
};

const isBaroActive = (adminUser?: AdminUser) => {
  return adminUser?.authorizedMvnos.includes('all');
};

/**
 * 개통 불가 알림톡 보내기 지원 Mvno 목록
 */
export const isSupportFailAlarmTalk = (adminUser?: AdminUser) => {
  // FIXME 서버 DB단으로 옮기기
  return (
    adminUser?.authorizedMvnos.includes('all') ||
    adminUser?.authorizedMvnos.includes('ins') ||
    adminUser?.authorizedMvnos.includes('marvelring') ||
    adminUser?.authorizedMvnos.includes('egmobile') ||
    adminUser?.authorizedMvnos.includes('쉐이크모바일') ||
    adminUser?.authorizedMvnos.includes('sugarmobile')
  );
};

export const isMoyoAdmin = (adminUser?: AdminUser) =>
  adminUser?.authorizedMvnos?.includes('all') === true;

const isInternetBenefitConsult = (adminUser?: AdminUser) => {
  return (
    adminUser?.authorizedMvnos.includes('all') ||
    adminUser?.authorizedMvnos.includes('KT') ||
    adminUser?.partnerCompanyId === 17
  );
};

const useMoyoActiveCollection = () => {
  const { adminUser } = useOptions();

  return {
    isSelfMoyoActive: isSelfMoyoActive(adminUser),
    isCallActive: isCallActive(adminUser),
    isBaroActive: isBaroActive(adminUser),
    isMoyoAdmin: isMoyoAdmin(adminUser),
    isInternetBenefitConsult: isInternetBenefitConsult(adminUser),
  };
};

export default useMoyoActiveCollection;
