import { MoyoActivationState } from './enum';

const getActivationStateLabelTextWithMoyoAuthority = (
  state: MoyoActivationState,
): string => {
  switch (state) {
    case MoyoActivationState.검증_완료:
      return '인증 완료';
    case MoyoActivationState.검증_대기:
      return '인증 대기';
    case MoyoActivationState.검증_진행중:
      return '인증 중';
    case MoyoActivationState.개통_시도_완료:
      return '개통 시도 완료';
    case MoyoActivationState.개통_대기:
      return '개통 대기';
    case MoyoActivationState.개통_진행중:
      return '개통 중';
    case MoyoActivationState.처리_완료:
      return '처리 완료';
    case MoyoActivationState.배송_대상:
      return '배송 대상';
    case MoyoActivationState.처리_전:
      return '처리 전';
    case MoyoActivationState.유심구매중:
      return '유심구매중';
    case MoyoActivationState.배송_처리_완료:
      return '배송 완료';
    case MoyoActivationState.KT_바로배송중:
      return 'KT_바로배송중';
    case MoyoActivationState.원통_대기:
      return '원통 대기';
    case MoyoActivationState.KT_바로배송_검증완료_개통불가능:
      return '인증 완료';
    case MoyoActivationState.KT_바로배송_검증완료_개통가능:
      return '인증 완료';
  }
};

const getActivationStateLabelTextWithPartnersAuthority = (
  state: MoyoActivationState,
): string | undefined => {
  switch (state) {
    case MoyoActivationState.검증_대기:
    case MoyoActivationState.검증_진행중:
      return '고객 검증중';
    case MoyoActivationState.원통_대기:
      return '고객개통 대기중';
    case MoyoActivationState.개통_대기:
    case MoyoActivationState.개통_진행중:
      return '개통 진행중';
    case MoyoActivationState.배송_대상:
    case MoyoActivationState.처리_전:
      return '처리 중';
    case MoyoActivationState.유심구매중:
      return '유심 구매중';
    case MoyoActivationState.KT_바로배송중:
      return 'KT 바로배송중';
    case MoyoActivationState.검증_완료:
    case MoyoActivationState.개통_시도_완료:
    case MoyoActivationState.처리_완료:
    case MoyoActivationState.배송_처리_완료:
      return undefined;
  }
};

interface GetActivationStateLabelTextParams {
  state: MoyoActivationState;
  isMoyoAuthority: boolean;
}

export const getActivationStateLabelText = ({
  state,
  isMoyoAuthority,
}: GetActivationStateLabelTextParams): string | undefined => {
  if (isMoyoAuthority) {
    return getActivationStateLabelTextWithMoyoAuthority(state);
  }
  return getActivationStateLabelTextWithPartnersAuthority(state);
};
