import { ReleaseNoteItem } from './releaseNotes';
import ReleaseStatusType from './ReleaseStatusType';

const Release_2_6_0: ReleaseNoteItem = {
  version: '2.6.0',
  date: '2023-09-12',
  title: '계정 삭제 탭 및 페이지 추가',
  details: [
    {
      title: '계정 삭제 탭 및 페이지 추가',
      contents: [
        {
          content: (
            <>
              파트너스 어드민 권한이 있는 계정으로 로그인 후 프로필 클릭 시 계정
              삭제 탭 및 페이지가 추가되었습니다.
            </>
          ),
          status: ReleaseStatusType.NEW,
        },
      ],
    },
  ],
};

export default Release_2_6_0;
