import { useMemo } from 'react';
import {
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';

import { ColumnGroupDefinition } from './ColumnGroupDefinition';
import {
  convertStringToCertType,
  convertStringToLicenseType,
  convertStringToRegisterType,
} from '../../../common/order-util';
import { MobilePlanOrder } from '../../../entity/order';
import { VerticalColorTable } from '../../../shared/components/coloredTable';
import CopyButton from '../../../shared/components/copyButton';

type Props = {
  order: MobilePlanOrder;
};

export function OrderApplicationInfoSection({ order }: Props) {
  const usimStateStr = useMemo(() => {
    if (order.isEsim) return 'eSIM';
    if (order.detail?.hasUsim) return '유심 보유 중';
    if (order.usimDelivery) return '유심 배송 예정';
    if (order.willPurchaseUsim) return '유심 미보유(직접구매)';
    if (order.detail?.isBaroUsim) return '유심 미보유(바로배송유심)';
    return '유심 미보유(택배 배송)';
  }, [order]);

  if (!order.detail) return null;

  const isRegistrationLicense = order.detail.licenseType === 'registration';

  return (
    <>
      <Typography variant="h6" my={2}>
        고객 정보
      </Typography>
      <TableContainer component={Paper} elevation={5}>
        <VerticalColorTable>
          {ColumnGroupDefinition}
          <TableBody>
            <TableRow>
              <TableCell>신청자 이름</TableCell>
              <TableCell>
                {order?.customerName}{' '}
                {order && (
                  <CopyButton
                    actionName="customerName"
                    copyText={order.customerName}
                    style={{ marginLeft: '10px' }}
                  />
                )}
              </TableCell>
              <TableCell>가입 유형</TableCell>
              <TableCell>
                {convertStringToRegisterType(order?.registerType)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>현재 통신사</TableCell>
              <TableCell>
                {`${order.detail.previousCarrierCode}`}
                {order.detail.phoneMvno ? `(${order.detail.phoneMvno})` : ''}
                {order.detail.previousCarrierCode && (
                  <CopyButton
                    actionName="previousCarrierCode"
                    copyText={order.detail.previousCarrierCode}
                    style={{ marginLeft: '10px' }}
                    size="small"
                  />
                )}
              </TableCell>
              <TableCell>전화번호</TableCell>
              <TableCell>
                {order?.customerPhoneNumber.replace(
                  /(\d{3})(\d{4})(\d{4})/,
                  '$1-$2-$3',
                )}
                {order.customerPhoneNumber && (
                  <CopyButton
                    actionName="customerPhoneNumber"
                    copyText={order.customerPhoneNumber}
                    style={{ marginLeft: '10px' }}
                  />
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>주민등록번호</TableCell>
              <TableCell>
                {`${order.detail.registrationNumberFirst}-${order.detail.registrationNumberSecond}`}
                {order && (
                  <CopyButton
                    actionName="registrationNumberFirst"
                    copyText={`${order.detail.registrationNumberFirst}-${order.detail.registrationNumberSecond}`}
                    style={{ marginLeft: '10px' }}
                  />
                )}
              </TableCell>
              <TableCell>본인 인증 방법</TableCell>
              <TableCell>
                {convertStringToCertType(order.detail.certificationType)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>실명확인자료</TableCell>
              <TableCell>
                {convertStringToLicenseType(order.detail.licenseType)}
              </TableCell>
              <TableCell>신분증 정보</TableCell>
              <TableCell>
                {isRegistrationLicense ? (
                  <>
                    발급일자: {order.detail.licenseIssuedAt}{' '}
                    {order.detail.licenseIssuedAt && (
                      <CopyButton
                        actionName="licenseIssuedAt"
                        copyText={order.detail.licenseIssuedAt}
                        style={{ marginLeft: '10px' }}
                      />
                    )}
                  </>
                ) : (
                  <>
                    <Typography fontSize={14}>
                      면허 번호: {order.detail.driverLicense1}-
                      {order.detail.driverLicense2}-
                      {order.detail.driverLicense3}-
                      {order.detail.driverLicense4}{' '}
                      <CopyButton
                        actionName="driverLicense"
                        copyText={`${order.detail.driverLicense2}-${order.detail.driverLicense3}-${order.detail.driverLicense4}`}
                        style={{ marginLeft: '10px' }}
                      />
                    </Typography>
                    <Typography fontSize={14}>
                      발급일자: {order.detail.licenseIssuedAt}{' '}
                      {order.detail.licenseIssuedAt && (
                        <CopyButton
                          actionName="licenseIssuedAt"
                          copyText={order.detail.licenseIssuedAt}
                          style={{ marginLeft: '10px' }}
                        />
                      )}
                    </Typography>
                  </>
                )}
              </TableCell>
            </TableRow>
            {order.registerType === 'change' && (
              <>
                <TableRow>
                  <TableCell>번호이동 인증 수단</TableCell>
                  <TableCell>{order.detail.changeCertBillingMethod}</TableCell>
                  <TableCell>번호이동 인증 정보</TableCell>
                  <TableCell>
                    {order.detail.changeCertBillingInfo}{' '}
                    {order.detail.changeCertBillingInfo && (
                      <CopyButton
                        actionName="changeCertBillingInfo"
                        copyText={order.detail.changeCertBillingInfo}
                        style={{ marginLeft: '10px' }}
                        size="small"
                      />
                    )}
                  </TableCell>
                </TableRow>
                {order.detail.changeCertBillingMethodSub ? (
                  <TableRow>
                    <TableCell>번호이동 인증 수단 2</TableCell>
                    <TableCell>
                      {order.detail.changeCertBillingMethodSub}
                    </TableCell>
                    <TableCell>번호이동 인증 정보 2</TableCell>
                    <TableCell>
                      {order.detail.changeCertBillingInfoSub}
                      {order.detail.changeCertBillingInfoSub && (
                        <CopyButton
                          actionName="changeCertBillingInfoSub"
                          copyText={order.detail.changeCertBillingInfoSub}
                          style={{ marginLeft: '10px' }}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                ) : (
                  <></>
                )}
              </>
            )}
            <TableRow>
              <TableCell>유심 보유 여부</TableCell>
              <TableCell>{usimStateStr}</TableCell>
              {order.detail.hasUsim !== true && !order.isEsim && (
                <>
                  <TableCell>유심 종류</TableCell>
                  <TableCell>
                    {order.detail.isNfcUsim === true ? 'NFC 유심' : '일반 유심'}
                  </TableCell>
                </>
              )}
            </TableRow>
            <TableRow>
              <TableCell>유심 모델명</TableCell>
              <TableCell>
                {order.detail.usimModel}{' '}
                {order.detail.usimModel && (
                  <CopyButton
                    actionName="usimModel"
                    copyText={order.detail.usimModel}
                    style={{ marginLeft: '10px' }}
                  />
                )}
              </TableCell>
              <TableCell>유심 일련번호</TableCell>
              <TableCell>
                {order.detail.usimSerialNumber}
                {order.detail.usimSerialNumber && (
                  <CopyButton
                    actionName="usimSerialNumber"
                    copyText={order.detail.usimSerialNumber}
                    style={{ marginLeft: '10px' }}
                  />
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>택배사</TableCell>
              <TableCell>
                {order.detail.deliveryCompany}
                {order.detail.deliveryCompany && (
                  <CopyButton
                    actionName="deliveryCompany"
                    copyText={order.detail.deliveryCompany}
                    style={{ marginLeft: '10px' }}
                  />
                )}
              </TableCell>
              <TableCell>송장 번호</TableCell>
              <TableCell>
                {order.detail.deliveryTrackingNumber}
                {order.detail.deliveryTrackingNumber && (
                  <CopyButton
                    actionName="deliveryTrackingNumber"
                    copyText={order.detail.deliveryTrackingNumber}
                    style={{ marginLeft: '10px' }}
                  />
                )}
              </TableCell>
            </TableRow>
            {order.registerType === 'new' && (
              <TableRow>
                <TableCell>희망번호 1</TableCell>
                <TableCell>
                  {order.detail.wishNumber1}{' '}
                  {order.detail.wishNumber1 && (
                    <CopyButton
                      actionName="wishNumber1"
                      copyText={order.detail.wishNumber1}
                      style={{ marginLeft: '10px' }}
                    />
                  )}
                </TableCell>
                <TableCell>희망번호 2</TableCell>
                <TableCell>
                  {order.detail.wishNumber2}{' '}
                  {order.detail.wishNumber2 && (
                    <CopyButton
                      actionName="wishNumber2"
                      copyText={order.detail.wishNumber2}
                      style={{ marginLeft: '10px' }}
                    />
                  )}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </VerticalColorTable>
      </TableContainer>
    </>
  );
}
