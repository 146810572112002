import { datadogRum } from '@datadog/browser-rum';
import { env } from '../utils/env';

// Initialize the Datadog RUM for browser monitoring.
const initDatadogRum = () => {
  const applicationId = env('REACT_APP_DATADOG_RUM_APPLICATION_ID');
  const clientToken = env('REACT_APP_DATADOG_RUM_CLIENT_TOKEN');
  const rumEnv = env('REACT_APP_DATADOG_RUM_ENV');

  if (!applicationId || !clientToken || !rumEnv) {
    return;
  }

  datadogRum.init({
    applicationId,
    clientToken,
    site: 'datadoghq.com',
    service: 'moyo-partners',
    env: rumEnv,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    silentMultipleInit: true,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    trackFrustrations: true,
    useSecureSessionCookie: true,
    allowedTracingUrls: [/https:\/\/.*\.moyoplan\.com/],
  });

  datadogRum.startSessionReplayRecording();
};

export default initDatadogRum;
