import React, { useMemo } from 'react';
import { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { DataGrid, GridRowSelectionModel } from '@mui/x-data-grid';

//icons
import CachedIcon from '@mui/icons-material/Cached';
import SearchIcon from '@mui/icons-material/Search';

import { Mvnos } from '../../entity/mvno';
import { ColumnList } from './PlanListColumn';
import { planApiService } from '../../api/plan';
import { useLoading } from '../../context/LoadingContext';
import { companyApiService } from '../../api/company';
import { PlanMetaAdmin, ScrapeReserve } from '../../entity/plan';
import { useDebounce } from '../../common/stream';
import Spinner from '../../modules/Spinner';

import pbl from '../../pbl/pbl-service/pbl-service';

import { useNavigate } from 'react-router-dom';
import { isMoyoAdmin } from '../../common/moyoActivationCollection';
import { useOptions } from '../../context/OptionsContext';
import { useModal } from '../../hooks/useModal';
import { ExposureSettingModal } from './components/ExposureSettingModal';
import { PlanUpdateDialog } from './PlanUpdateDiaglog';
import PermissionRender from '../../shared/components/PermissionRender/PermissionRender';
import { useRole } from '../../router/useRole';

const rows: PlanMetaAdmin[] = [];
const scrapes: ScrapeReserve[] = [];

const PlanListPage = () => {
  const navigate = useNavigate();
  const { isMoyoRole } = useRole();

  const { adminUser } = useOptions();

  const [reserveScrapeList, setReserveScrapeList] =
    useState<Array<ScrapeReserve>>(scrapes);
  const [plans, setPlans] = useState<PlanMetaAdmin[]>(rows);
  const [filterText, setFilterText] = useState(''); // 요금제 이름
  const [filterIdText, setFilterIdText] = useState(''); // 요금제 ID
  const debouncedFilterText = useDebounce(filterText, 500);
  const debouncedFilterIdText = useDebounce(filterIdText, 500);
  const [isLoading, setIsLoading] = useState(false);

  const [mvno, setMvno] = useState<string>('');
  const [mvnoFilter, setMvnoFilter] = useState<string>('전체');
  const [totalCount, setTotalCount] = useState<number>();
  const [state, setState] = useState('ALL');
  const [mno, setMno] = useState<string>('전체');
  const [display, setdisplay] = useState<boolean | undefined>(undefined);
  const [selectedIds, setSelectedIds] = useState<GridRowSelectionModel>([]);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 50,
  });

  const { setLoading } = useLoading();

  const { openModal, closeModal } = useModal();

  const handleExposureSetting = () => {
    const selectedPlans = selectedIds
      .map((id) => {
        const plan = filteredPlansMap.get(id as number);
        if (!plan) return null;
        return plan;
      })
      .filter((i): i is PlanMetaAdmin => i !== null);

    openModal(
      <ExposureSettingModal
        open
        onSuccess={async () => {
          try {
            setLoading(true);
            await fetchPlans();
            closeModal();
          } catch (error) {
            throw error;
          } finally {
            setLoading(false);
          }
        }}
        onClose={closeModal}
        plans={selectedPlans}
      />,
    );
  };

  useEffect(() => {
    pbl('pageview', 'none', 'plan_list');
  }, []);

  const fetchPlans = async () => {
    setLoading(true);

    try {
      const companyInfoResponse = await companyApiService.getInfo();
      const companyInfo = companyInfoResponse.data.result;
      const authorizedPlans = companyInfo?.plans;
      if (!authorizedPlans) return;
      // mvno 에게만 스크래핑 요청 버튼 제공
      if (authorizedPlans.length === 1) {
        setMvno(authorizedPlans[0]);
      } else {
        setMvno('valuecomm');
      }

      let mnoParam: string | undefined = mno;
      if (mno === '전체') mnoParam = undefined;
      let mvnoNames;
      if (mvnoFilter === '전체') {
        mvnoNames = authorizedPlans.join(',');
      } else {
        mvnoNames = mvnoFilter;
      }

      const plans = await planApiService.getPlansByMvnoPagination({
        mvno: mvnoNames,
        searchText: debouncedFilterText,
        searchIdText: debouncedFilterIdText,
        state,
        mno: mnoParam,
        display,
        page: paginationModel.page,
        size: paginationModel.pageSize,
        isMoyoOnlyPlan: false,
      });

      setTotalCount(plans.data.result?.totalSize);

      setLoading(false);
      if (!plans) return;
      if (plans.data.result) {
        setPlans(plans.data.result.list);
      }
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const fetchReserveScrapes = useCallback(async () => {
    if (plans.length === 0) return;

    const result = await planApiService.getScrapingReserve(plans[0].mvno);

    setReserveScrapeList(result.data.result ?? []);
  }, [plans]);

  useEffect(() => {
    fetchPlans();
  }, [
    mno,
    debouncedFilterText,
    debouncedFilterIdText,
    paginationModel,
    mvnoFilter,
    state,
    display,
  ]);

  useEffect(() => {
    fetchReserveScrapes();
  }, [plans]);

  const handleMvnoChange = (event: SelectChangeEvent) => {
    setMvnoFilter(event.target.value as string);
  };

  const handleMnoChange = (event: SelectChangeEvent) => {
    setMno(event.target.value as string);
  };

  const handlePlanRegister = async () => {
    navigate('/plan-edit');
  };

  const updatePlanList = async () => {
    setIsLoading(true);
    let response = await planApiService.requestScraping(mvno);
    if (response.status === 200) {
      alert(`${mvno} 최신정보로 업데이트가 완료되었습니다`);
    } else {
      alert(`${mvno} 최신정보로 업데이트가 실패하였습니다!`);
    }
    setIsLoading(false);
  };

  //TODO 예약 업데이트 기능 제거 문의 후 제거
  // const reserveUpdatePlanList = async (reserveDate: DateTime) => {
  //   try {
  //     setLoading(true);
  //     await planApiService.requestScrapingReserve(mvno, reserveDate);
  //   } catch (error) {
  //     throw error;
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleUpdateList = async () => {
    if (mvno === '') return;

    openModal(
      <PlanUpdateDialog
        isOpen
        onUpdate={async () => {
          try {
            setLoading(true);
            await updatePlanList();
            closeModal();
          } catch (error) {
            throw error;
          } finally {
            setLoading(false);
          }
        }}
        onClose={closeModal}
      />,
    );
  };

  const isMvnoMatch = (plan: PlanMetaAdmin, mvnoFilter: string) =>
    plan.mvno === mvnoFilter || mvnoFilter === '전체';

  const filteredPlans = plans.filter((plan) => {
    const isMvno = isMvnoMatch(plan, mvnoFilter);

    return isMvno;
  });

  const filteredPlansMap = useMemo(() => {
    return new Map(filteredPlans.map((plan) => [plan.id, plan]));
  }, [filteredPlans]);

  const planColumnList = useMemo(() => {
    if (isMoyoAdmin(adminUser)) {
      return ColumnList();
    } else {
      return ColumnList().filter(
        (column) =>
          column.field !== 'mvno' && column.field !== 'planManagementType',
      );
    }
  }, [adminUser]);

  const sortingMvnos = Mvnos.sort((prev, next) => {
    if (prev.value === '전체' || next.value === '전체') return 1;
    if (prev.value.toLowerCase() > next.value.toLowerCase()) return 1;
    else if (prev.value.toLowerCase() < next.value.toLowerCase()) return -1;
    else return 0;
  });

  const handleVisibilityChange = (
    _: React.MouseEvent<HTMLElement>,
    newValue: string | null,
  ) => {
    if (newValue === 'all') {
      setdisplay(undefined);
    } else if (newValue === 'visible') {
      setdisplay(true);
    } else if (newValue === 'invisible') {
      setdisplay(false);
    }
  };

  return (
    <>
      <Spinner show={isLoading}></Spinner>
      <Container sx={{ display: 'grid', mt: 5 }} maxWidth="xl">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="h4"
            fontWeight={'regular'}
            style={{ padding: '16px 0' }}
          >
            요금제 목록
          </Typography>
          <Box
            sx={{
              display: 'inline-block',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <PermissionRender
                moyoAdmin
                permission={{
                  requiredPermissions: ['PARTNERS_MOBILE_PLAN_ORDER_MANAGE'],
                }}
                planManagementMethodList={['API', 'SCRAPING']}
              >
                <Button
                  variant={'outlined'}
                  color={'primary'}
                  onClick={handleUpdateList}
                >
                  <CachedIcon style={{ marginRight: '8px' }} />
                  정보 업데이트
                </Button>
              </PermissionRender>
              <PermissionRender
                moyoAdmin
                permission={{
                  requiredPermissions: ['PARTNERS_MOBILE_PLAN_ORDER_MANAGE'],
                }}
                planManagementMethodList={['MANUAL']}
              >
                <Button
                  variant={'contained'}
                  color={'primary'}
                  onClick={handlePlanRegister}
                >
                  요금제 등록
                </Button>
              </PermissionRender>
            </div>
          </Box>
        </Box>

        {/*요금제 찾기 필터*/}
        <Grid container mt={1} mb={3}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Grid container spacing={2}>
              <Grid item>
                <FormControl>
                  <ToggleButtonGroup
                    size="small"
                    value={
                      display === undefined
                        ? 'all'
                        : display
                        ? 'visible'
                        : 'invisible'
                    }
                    exclusive
                    onChange={handleVisibilityChange}
                    sx={{
                      '& .MuiToggleButton-root.Mui-selected': {
                        backgroundColor: 'primary.default',
                        color: 'primary.black',
                      },
                    }}
                  >
                    <ToggleButton value="all" style={{ whiteSpace: 'nowrap' }}>
                      모든 상태
                    </ToggleButton>
                    <ToggleButton value="visible">노출중</ToggleButton>
                    <ToggleButton value="invisible">미노출</ToggleButton>
                  </ToggleButtonGroup>
                </FormControl>
              </Grid>

              <Grid item>
                <FormControl style={{ width: '120px' }}>
                  <InputLabel id="mno-label" size="small">
                    통신망
                  </InputLabel>
                  <Select
                    labelId="mno-label"
                    id="mno-select"
                    size="small"
                    value={mno}
                    label="통신망"
                    onChange={handleMnoChange}
                  >
                    {/* TODO - 해당 통신망 필터링 추가 */}
                    <MenuItem value={'전체'}>전체</MenuItem>
                    <MenuItem value={'LGU'}>LG망</MenuItem>
                    <MenuItem value={'KT'}>KT망</MenuItem>
                    <MenuItem value={'SKT'}>SKT망</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {mvno === 'valuecomm' && (
                <Grid item>
                  <FormControl style={{ width: '160px' }}>
                    <InputLabel size="small">통신사</InputLabel>
                    <Select
                      value={mvnoFilter}
                      size="small"
                      label="Mvno"
                      onChange={handleMvnoChange}
                    >
                      {sortingMvnos.map((group, index) => (
                        <MenuItem key={index} value={group.value}>
                          {group.value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}

              {isMoyoAdmin(adminUser) && (
                <>
                  <Grid item xs={2.5}>
                    <TextField
                      value={filterText}
                      onChange={(e) => setFilterText(e.target.value)}
                      size="small"
                      placeholder="요금제 이름 검색"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={2.5}>
                    <TextField
                      value={filterIdText}
                      onChange={(e) => setFilterIdText(e.target.value)}
                      size="small"
                      placeholder="요금제 ID 검색"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                    />
                  </Grid>
                </>
              )}
            </Grid>
            {isMoyoRole && (
              <Button
                variant="outlined"
                color="inherit"
                style={{ whiteSpace: 'nowrap', height: '40px' }}
                size="medium"
                onClick={handleExposureSetting}
                disabled={selectedIds.length === 0}
              >
                선택 요금제 노출설정
              </Button>
            )}
          </Box>
        </Grid>

        {/* 요금제 목록 테이블 */}
        <DataGrid
          columns={planColumnList}
          rows={filteredPlans}
          onRowSelectionModelChange={
            isMoyoRole
              ? (id) => {
                  setSelectedIds(id);
                }
              : undefined
          }
          checkboxSelection={isMoyoRole}
          rowSelectionModel={selectedIds}
          rowCount={totalCount}
          pagination
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[10, 25, 50, 100]}
          autoHeight
        />
      </Container>
    </>
  );
};

export default PlanListPage;
