import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  Box,
} from '@mui/material';
import { useEffect } from 'react';

interface SimpleConfirmModalProps {
  show: boolean;
  title: string;
  description: string;
  onHide: () => void;
  onConfirm: () => void;
  onImpression?: () => void;
  cancelText?: string;
  confirmText?: string;
}

function SimpleConfirmModal({
  show,
  title,
  description,
  onImpression,
  onHide,
  onConfirm,
  cancelText,
  confirmText = '확인',
}: SimpleConfirmModalProps): JSX.Element {
  useEffect(() => {
    if (show) {
      onImpression?.();
    }
  }, [show]);

  return (
    <Dialog open={show} fullWidth maxWidth="xs">
      <DialogTitle fontSize={20} fontWeight={700}>
        {title}
      </DialogTitle>
      <DialogContent>
        <Typography sx={{ my: 1.5 }}>{description}</Typography>
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            mr: 2,
            mb: 1,
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 1,
          }}
        >
          {cancelText && (
            <Button onClick={onHide} color="warning" variant="outlined">
              {cancelText}
            </Button>
          )}
          <Button onClick={onConfirm} variant="contained">
            {confirmText}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

export default SimpleConfirmModal;
