import { ReleaseNoteItem } from './releaseNotes';
import ReleaseStatusType from './ReleaseStatusType';

const Release_2_5_0: ReleaseNoteItem = {
  version: '2.5.0',
  date: '2023-08-21',
  authorizedMvnos: ['ins', 'smartel', 'sugarmobile', '에르엘'],
  title: '법인 요금제 접수 탭 추가',
  details: [
    {
      title: '법인 요금제 접수 탭 추가',
      contents: [
        {
          content: (
            <>
              법인 개통 서류 처리를 위한 법인 요금제 접수 탭이 추가되었습니다.
            </>
          ),
          status: ReleaseStatusType.NEW,
        },
      ],
    },
  ],
};

export default Release_2_5_0;
