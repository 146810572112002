import {
  InternetIptvBundlePlanConsult,
  InternetPlanConsult,
} from '../internet-plan-consult';
import { InternetPlanConsultHistory } from '../internet-plan-consult-history';

export interface GetInternetPlanConsultResponse extends InternetPlanConsult {
  histories: InternetPlanConsultHistory[];
}

export interface GetInternetIptvBundlePlanConsultResponse
  extends InternetIptvBundlePlanConsult {
  histories: InternetPlanConsultHistory[];
}

export const isGetInternetPlanConsultResponseType = (
  type:
    | GetInternetPlanConsultResponse
    | GetInternetIptvBundlePlanConsultResponse,
): type is GetInternetPlanConsultResponse => {
  return 'internetPlanName' in type;
};
