interface networkTypes {
  key: string;
  value: string;
}

export const NetworkList: networkTypes[] = [
  {
    key: 'LTE',
    value: 'LTE',
  },
  {
    key: '5G',
    value: '5G',
  },
  {
    key: '3G',
    value: '3G',
  },
];
