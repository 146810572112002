const PBL_PAGEVIEW_EVENT_PROPERTIES_KEY =
  'pbl_memories_pageview_event_properties';

const updateCurrentPageviewEventProperties = (
  eventProperties?: Record<string, unknown>,
) => {
  sessionStorage.setItem(
    PBL_PAGEVIEW_EVENT_PROPERTIES_KEY,
    JSON.stringify(eventProperties ?? {}),
  );
};

const getCurrentPageviewEventProperties = (): Record<string, unknown> => {
  try {
    return JSON.parse(
      sessionStorage.getItem(PBL_PAGEVIEW_EVENT_PROPERTIES_KEY) ?? '{}',
    );
  } catch (error) {
    return {};
  }
};

export const pblPageviewEventPropertiesProcessor = {
  updateCurrentPageviewEventProperties,
  getCurrentPageviewEventProperties,
};
