import {
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import {
  FormContent,
  InputRow,
  Section,
  SectionDivider,
  SectionHeader,
  SectionTitle,
} from '../styled';

import { ICreatePlanDto } from '../../../entity/plan';
import { useFormContext } from 'react-hook-form';

import { useEffect, useState } from 'react';
import React from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { amountStringToInt } from '../../../common/string_util';

interface PlanInfoSectionProps {
  isEdit: boolean;
  initialDiscountPeriod: number | null;
  initialDiscountFee: number | null;
  initialAgreementPeriod: number | null;
  initialOriginalFee: number | null;
}

const MAX_DISCOUNT_FEE_LENGTH = 6;

const MAX_FEE_AMOUNT = 999999;

const NumericFormatCustom = React.forwardRef<NumericFormatProps, any>(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        thousandSeparator=","
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
      />
    );
  },
);

const NumericFormatCustomPeriod = React.forwardRef<NumericFormatProps, any>(
  function NumericFormatCustomPeriod(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
      />
    );
  },
);

export const PlanInfoSection = ({
  isEdit,
  initialDiscountPeriod,
  initialDiscountFee,
  initialAgreementPeriod,
  initialOriginalFee,
}: PlanInfoSectionProps) => {
  const {
    register,
    setValue,
    watch,
    trigger,
    formState: { errors },
  } = useFormContext<ICreatePlanDto>();

  const [tmpDiscountFee, setTmpDiscountFee] = useState<number | null>(
    initialDiscountFee,
  );
  const [tmpOriginalFee, setTmpOriginalFee] = useState<number | null>(
    initialOriginalFee,
  );
  // NOTE: 약정기간은 요금제가 있을 때, 기본 0으로 설정되어야함
  const [tmpAgreementPeriod, setTmpAgreementPeriod] = useState<string>(
    isEdit ? `${initialAgreementPeriod ?? 0}` : '',
  );

  const [isFreeDiscount, setIsFreeDiscount] = useState(
    initialDiscountPeriod === 0 ? true : false,
  );

  const discountFee = watch('discountFee');
  const originalFee = watch('originalFee');

  const [tmpDiscountPeriod, setTmpDiscountPeriod] = useState<number | null>(
    initialDiscountPeriod ?? null,
  );

  const handleChangeDiscountType = (
    e: React.MouseEvent<HTMLElement>,
    value: string | null,
  ) => {
    if (value === '평생할인') {
      setValue('discountPeriod', 0, { shouldDirty: true });
      setIsFreeDiscount(true);
    } else {
      setValue('discountPeriod', tmpDiscountPeriod ?? null, {
        shouldDirty: true,
      });
      setIsFreeDiscount(false);
    }
  };

  useEffect(() => {
    if (discountFee !== null) {
      trigger('discountFee');
    }
  }, [discountFee, trigger]);

  const handleKeyDownDiscountAmount = (
    e: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (e.key === '-') {
      e.preventDefault();
    }
  };

  return (
    <Section>
      <SectionHeader flexDirection="row" mt={6}>
        <SectionTitle variant="h5">요금 정보</SectionTitle>
        <ToggleButtonGroup
          onChange={(e, value) => {
            handleChangeDiscountType(e, value);
          }}
          value={isFreeDiscount ? '평생할인' : '기간할인'}
          exclusive
          style={{ height: '48px' }}
          aria-label="text alignment"
        >
          <ToggleButton value="기간할인" aria-label="left aligned">
            기간할인
          </ToggleButton>
          <ToggleButton value="평생할인" aria-label="centered">
            평생할인
          </ToggleButton>
        </ToggleButtonGroup>
      </SectionHeader>

      <FormContent>
        <InputRow>
          <TextField
            style={{ width: '265.3px' }}
            {...register('agreementPeriod')}
            onChange={(e) => {
              setTmpAgreementPeriod(String(e.target.value));
            }}
            onBlur={() =>
              setValue('agreementPeriod', Number(tmpAgreementPeriod), {
                shouldValidate: true,
                shouldDirty: true,
              })
            }
            label={'약정 기간'}
            fullWidth
            value={tmpAgreementPeriod}
            placeholder="숫자만 입력"
            InputProps={{
              inputComponent: NumericFormatCustomPeriod as any,
              endAdornment: <Typography variant="body1">일</Typography>,
              inputProps: {
                onKeyDown: handleKeyDownDiscountAmount,
              },
            }}
            error={!!errors.agreementPeriod}
            helperText={errors.agreementPeriod?.message}
            InputLabelProps={{ shrink: true }}
          />
        </InputRow>

        <InputRow>
          <TextField
            {...register('discountFee', {
              required: '할인 시 요금은 필수입니다',
              validate: (value) => {
                if (value == null) return '할인 시 요금은 필수입니다';
                const numericValue = amountStringToInt(value);

                if (!isFreeDiscount && numericValue > MAX_FEE_AMOUNT) {
                  return '100만원 이하만 입력 가능해요.';
                }

                if (
                  !isFreeDiscount &&
                  originalFee &&
                  numericValue > amountStringToInt(originalFee)
                ) {
                  return '할인 시 요금이 종료 후 요금보다 비싸면 안돼요';
                }

                return true;
              },
            })}
            onChange={(e) => {
              setTmpDiscountFee(Number(e.target.value));
            }}
            onBlur={async () => {
              await setValue('discountFee', tmpDiscountFee, {
                shouldValidate: false,
                shouldDirty: true,
              });
              trigger(['discountFee', 'originalFee']);
            }}
            label={isFreeDiscount ? '할인 요금' : '할인 시 요금'}
            fullWidth
            InputProps={{
              inputComponent: NumericFormatCustom as any,
              endAdornment: <Typography variant="body1">원</Typography>,
              inputProps: {
                min: 0,
                onKeyDown: handleKeyDownDiscountAmount,
              },
            }}
            value={discountFee || ''}
            placeholder="숫자만 입력"
            error={!!errors.discountFee}
            helperText={errors.discountFee?.message}
            InputLabelProps={{ shrink: true }}
          />

          <TextField
            {...register('originalFee', {
              validate: (value) => {
                if (isFreeDiscount) return true;
                if (!value) return '할인 종료 후 요금 필수입니다';

                const numericValue = amountStringToInt(value);
                const discountValue = amountStringToInt(discountFee || '');

                if (numericValue.toString().length > MAX_DISCOUNT_FEE_LENGTH) {
                  return '100만원 이하만 입력 가능해요.';
                }

                if (!isFreeDiscount && discountValue > numericValue) {
                  return '할인 시 요금이 종료 후 요금보다 비싸면 안돼요';
                }

                return true;
              },
            })}
            label="할인 종료 후 요금"
            style={{ visibility: isFreeDiscount ? 'hidden' : 'visible' }}
            fullWidth
            value={originalFee || ''}
            InputProps={{
              inputComponent: NumericFormatCustom as any,
              endAdornment: <Typography variant="body1">원</Typography>,
              inputProps: {
                min: 0,
                onKeyDown: handleKeyDownDiscountAmount,
              },
            }}
            onChange={(e) => {
              setTmpOriginalFee(Number(e.target.value));
            }}
            onBlur={async () => {
              await setValue('originalFee', tmpOriginalFee, {
                shouldValidate: false,
                shouldDirty: true,
              });
              trigger(['discountFee', 'originalFee']);
            }}
            placeholder="숫자만 입력"
            error={!!errors.originalFee}
            helperText={errors.originalFee?.message}
            InputLabelProps={{
              shrink: true,
            }}
          />

          <TextField
            label="할인 기간"
            fullWidth
            type="number"
            value={tmpDiscountPeriod || ''}
            style={{ visibility: !isFreeDiscount ? 'visible' : 'hidden' }}
            placeholder="숫자만 입력"
            InputLabelProps={{ shrink: true }}
            {...register('discountPeriod', {
              required: !isFreeDiscount ? '할인 기간은 필수입니다' : false,
              validate: (value) => {
                if (!isFreeDiscount) {
                  const numericValue = Number(value);
                  if (numericValue < 1) {
                    return '할인 기간을 1개월 이상으로 설정해 주세요.';
                  }
                  if (numericValue > 120) {
                    return '할인 기간은 120개월까지만 지원해요.';
                  }
                }
                return true;
              },
            })}
            onChange={(e) => {
              const value = e.target.value;
              setTmpDiscountPeriod(Number(value));
            }}
            onBlur={() =>
              setValue('discountPeriod', tmpDiscountPeriod, {
                shouldValidate: true,
                shouldDirty: true,
              })
            }
            error={!isFreeDiscount && !!errors.discountPeriod}
            helperText={errors.discountPeriod?.message}
            InputProps={{
              endAdornment: (
                <Typography
                  variant="body1"
                  style={{
                    whiteSpace: 'nowrap',
                  }}
                >
                  개월
                </Typography>
              ),
              inputProps: {
                min: 1,
                max: 120,
                onKeyDown: (e) => e.key === '-' && e.preventDefault(),
              },
            }}
          />
        </InputRow>

        {/* TODO: 요금제 할인코드 추후 구현예정  */}
        {/* <BottomBlock>
          <span>
            <Typography variant="body2">요금제 할인코드</Typography>
            <Typography variant="body2" color={colors.grey[500]}>
              (선택)
            </Typography>
          </span>
          <Button variant="contained">할인코드 생성</Button>
        </BottomBlock> */}
      </FormContent>

      <SectionDivider orientation="horizontal" flexItem />
    </Section>
  );
};
