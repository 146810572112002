import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import MvnoInfoItem from '../Common/MvnoInfoItem';
import {
  UsimInfo,
  UsimInfoDetails,
  UsimStatus,
} from '../../types/mobile-plan-operator-admin-types';
import { Mno } from '../../../../entity/mno';
import { convertStringToMno } from '../../../../common/order-util';
import { comma } from '../../../../common/string_util';
import { MvnoInfoItemBody, MvnoInfoItemRow } from '../Common/MvnoInfoItemFrame';

const getSimContent = (feeStatus: UsimStatus, fee: number | null): string => {
  switch (feeStatus) {
    case 'FREE':
      return '무료';
    case 'FREE_ON_ACTIVATION':
      return '개통 시 무료';
    case 'PAID':
      return `지원 (${comma(fee)}원)`;
    case 'UNSUPPORTED':
      return '지원 안 함';
  }
};

const MvnoInfoUsimSupportSection: FC<{
  mno: Mno;
  usimInfoDetails: UsimInfoDetails;
}> = ({ mno, usimInfoDetails }) => {
  const title = convertStringToMno(mno);

  const commonUsimDelivery = getSimContent(
    usimInfoDetails.usimDeliveryFeeStatus,
    usimInfoDetails.usimDeliveryFee,
  );
  const nfcUsimDelivery = getSimContent(
    usimInfoDetails.nfcUsimDeliveryFeeStatus,
    usimInfoDetails.nfcUsimDeliveryFee,
  );
  const esimActivation = getSimContent(
    usimInfoDetails.esimFeeStatus,
    usimInfoDetails.esimFee,
  );
  return (
    <MvnoInfoItemBody gap={3}>
      <Box>
        <Typography variant="body1" color="text.primary" fontWeight={700}>
          {title}망
        </Typography>
      </Box>
      <MvnoInfoItemRow gap={3}>
        <MvnoInfoItem
          fallback={{
            isActive: !commonUsimDelivery,
            text: '입력 필요',
            color: 'error',
          }}
          title="일반 유심 배송"
          content={commonUsimDelivery}
        />
        <MvnoInfoItem
          fallback={{
            isActive: !nfcUsimDelivery,
            text: '입력 필요',
            color: 'error',
          }}
          title="NFC 유심 배송"
          content={nfcUsimDelivery}
        />
        <MvnoInfoItem
          fallback={{
            isActive: !esimActivation,
            text: '입력 필요',
            color: 'error',
          }}
          title="eSIM 개통"
          content={esimActivation}
        />
      </MvnoInfoItemRow>
    </MvnoInfoItemBody>
  );
};

const MvnoInfoUsimSupportDetail: FC<UsimInfo> = (usimInfo) => {
  return (
    <MvnoInfoItemBody gap={6}>
      {Object.entries(usimInfo).map(([key, usimInfoDetails]) => (
        <MvnoInfoUsimSupportSection
          key={key}
          mno={key as Mno}
          usimInfoDetails={usimInfoDetails}
        />
      ))}
    </MvnoInfoItemBody>
  );
};

export default MvnoInfoUsimSupportDetail;
