import { useNavigate } from 'react-router-dom';
import { handleApi } from '../../common/http_util';
import { useInternetOptions } from '../../context/InternetOptionsContext';
import { internetOrderApiService } from '../../api/internet-order';
import InternetCommonHistoryCreateSection from './InternetCommonHistoryCreateSection';

interface Props {
  orderId: string;
  afterSubmitProcess?: () => void;
}

export default function InternetHistoryCreateSection({
  orderId,
  afterSubmitProcess,
}: Props) {
  const navigate = useNavigate();
  const { statusList } = useInternetOptions();

  const onSubmitHandler = async (
    status: string,
    memo: string,
    statusDetail?: string,
  ) => {
    await handleApi(
      () =>
        internetOrderApiService.updateOrderStatus(
          orderId,
          status,
          statusDetail,
          memo,
        ),
      navigate,
      (err) => {
        alert(err);
      },
    );
    afterSubmitProcess?.();
  };

  return (
    <InternetCommonHistoryCreateSection
      onSubmit={onSubmitHandler}
      statusList={statusList}
    />
  );
}
