import { Player } from '@lottiefiles/react-lottie-player';
import { Box, Button, Typography } from '@mui/material';
import { Container } from '@mui/system';

interface CommonExceptionProps {
  title: string;
  description?: string;
  lottieSrc?: string;
  subActionTitle?: string;
  subAction?: () => void;
  mainActionTitle?: string;
  mainAction?: () => void;
}

export function CommonException({
  title,
  description,
  lottieSrc,
  subActionTitle,
  subAction,
  mainActionTitle,
  mainAction,
}: CommonExceptionProps) {
  return (
    <Container sx={{ mt: 15 }} maxWidth="xs">
      {lottieSrc && (
        <Box
          sx={{
            mb: 4,
          }}
        >
          <Player autoplay loop background="transparent" src={lottieSrc} />
        </Box>
      )}
      <Typography variant="h3">{title}</Typography>
      {description && (
        <Typography variant="subtitle1">{description}</Typography>
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          mt: 4,
          gap: 2,
        }}
      >
        {subActionTitle && (
          <Button variant="outlined" onClick={subAction}>
            {subActionTitle}
          </Button>
        )}
        {mainActionTitle && (
          <Button variant="contained" color="primary" onClick={mainAction}>
            {mainActionTitle}
          </Button>
        )}
      </Box>
    </Container>
  );
}
