import { pblQueryParamsService } from './pbl-query-params-service';
import generateSnakeCase from '../../common/generate-snake-case';
import { pblPageviewEventPropertiesProcessor } from './pbl-pageview-event-properties-process';
import { PblCategoryTypes } from '../pbl-types/pbl-category-types';
import { pblPageviewNavigationProcessor } from './pbl-pageview-navigation-process';

export const createPblEventProperties = (
  category: PblCategoryTypes,
  eventProperties?: Record<string, unknown>,
): Record<string, unknown> => {
  const queryParams = pblQueryParamsService(window.location.search);
  const pageviewUuid = pblPageviewNavigationProcessor.getPageviewUuid();
  const pageviewEventProperties =
    category === 'pageview'
      ? undefined
      : pblPageviewEventPropertiesProcessor.getCurrentPageviewEventProperties();
  const _eventProperties = {
    queryParams,
    pageviewEventProperties,
    pageviewUuid,
    ...eventProperties,
  };
  return generateSnakeCase(_eventProperties) as Record<string, unknown>;
};
