import { createContext, useContext, useEffect, useState } from 'react';
import { orderApiService } from '../api/order';
import { CookieConstants } from '../common/constants';
import { getCookie, setCookie } from '../common/cookie';
import { AdminUser } from '../entity/admin';
import { OrderStatus } from '../entity/order';
import { useAuth } from './UserContext';

type OptionsContextType = {
  adminUser?: AdminUser;
  statusList: Array<OrderStatus>;
};

const OptionsContext = createContext<OptionsContextType>({
  statusList: [],
});

const getLocalStatusList = () => {
  try {
    const statusCookies = getCookie(CookieConstants.STATUS_OPTIONS) ?? '[]';
    return JSON.parse(JSON.stringify(statusCookies)) as Array<OrderStatus>;
  } catch (error) {
    console.error('json parse error-orderlist', error);
    return [];
  }
};

export function OptionsContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { user } = useAuth();
  const [adminUser, setAdminUser] = useState<AdminUser>();
  const [statusList, setStatusList] = useState<Array<OrderStatus>>([]);
  const [initialized, setInitialized] = useState(false);

  const fetchStatusList = async () => {
    const localStatusList = getLocalStatusList();

    try {
      const userResponse = await orderApiService.getUser();
      setAdminUser(userResponse.data.result);
    } catch (err) {
      console.log(err);
    }

    if (Array.isArray(localStatusList) && localStatusList.length > 0) {
      setStatusList(localStatusList);
      return;
    }

    try {
      const res = await orderApiService.getOrderStatusOptions();
      const remoteStatusList = res.data.result;
      if (remoteStatusList?.statusList) {
        setCookie(
          CookieConstants.STATUS_OPTIONS,
          JSON.stringify(remoteStatusList.statusList),
          {
            path: '/',
          },
        );

        setStatusList(remoteStatusList.statusList ?? []);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchStatusList();
    setInitialized(true);
  }, [user]);

  return (
    <OptionsContext.Provider value={{ adminUser, statusList }}>
      {initialized ? children : <></>}
    </OptionsContext.Provider>
  );
}

export function useOptions() {
  return useContext(OptionsContext);
}
