import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useCallback, useEffect } from 'react';
import pbl from '../../../pbl/pbl-service/pbl-service';

type PopupProps = {
  logParams: Record<string, string | undefined> & { section: string };
  open: boolean;
  onClose: () => void;
  errorMessage?: string;
};

export function FailedToSendToMnoPopup({
  logParams,
  open,
  onClose,
  errorMessage,
}: PopupProps) {
  useEffect(() => {
    if (open) {
      const { section, ...params } = logParams;
      pbl('impression', 'modal', 'here', {
        object: {
          id: 'failed_to_send_to_mno_popup',
          section: section,
        },
        eventProperties: {
          ...params,
        },
      });
    }
  }, [logParams, open]);

  const handleCancelClick = useCallback(async () => {
    pbl('click', 'modal_button', 'here', {
      object: {
        id: 'failed_to_send_to_mno_popup_cancel',
        section: logParams.section ?? 'partners_activation',
      },
      eventProperties: { ...logParams },
    });
    onClose();
  }, [logParams, onClose]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>전산에 전송하지 못했어요</DialogTitle>
      <DialogContent>
        <DialogContentText
          sx={{ mb: '16px' }}
          style={{ overflowWrap: 'anywhere' }}
        >
          {errorMessage ? (
            <>
              {errorMessage}
              <br />
              <br />
            </>
          ) : (
            ''
          )}
          {errorMessage?.includes('서비스온라인신청일련번호가 중복되었습니다')
            ? ''
            : '잠시 후 다시 시도해보시거나, 재시도해도 안되면 모요에 문의해주세요'}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ marginBottom: '12px', marginRight: '24px' }}>
        <Button onClick={handleCancelClick}>확인</Button>
      </DialogActions>
    </Dialog>
  );
}
