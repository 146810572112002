import { BootstrapDialog, BootstrapDialogTitle } from './Dialog';
import { Button, DialogActions, DialogContent } from '@mui/material';
import { planApiService } from '../../../api/plan';
import { useLoading } from '../../../context/LoadingContext';

type DeleteConfirmDialogProps = {
  open: boolean;
  onSuccess: () => void;
  onClose: () => void;
  planIdList: number[];
};
const DeleteConfirmDialog = ({
  open,
  onSuccess,
  onClose,
  planIdList,
}: DeleteConfirmDialogProps) => {
  const { setLoading } = useLoading();

  const onDelete = async () => {
    setLoading(true);
    await Promise.all(
      planIdList.map((id) => {
        return planApiService.deletePlan(id);
      }),
    );
    setTimeout(() => {
      onClose();
      onSuccess();
      setLoading(false);
    }, 1000);
  };

  return (
    <BootstrapDialog aria-labelledby="customized-dialog-title" open={open}>
      <BootstrapDialogTitle id="customized-dialog-title">
        요금제를 종료하시겠어요?
      </BootstrapDialogTitle>
      <DialogContent>종료된 요금제는 모요에서 노출되지 않아요</DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button color={'error'} variant={'contained'} onClick={onDelete}>
          종료
        </Button>
        <Button color={'inherit'} variant={'contained'} onClick={onClose}>
          닫기
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};

export default DeleteConfirmDialog;
