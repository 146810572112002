import { Box } from '@mui/material';
import React, { FC, ReactNode } from 'react';

const FRACTIONDIGITS_SIZE = 1000;

const getCalcOfWidth = (numberOfRow: number, columnGap: number) => {
  const widthPerArea =
    Math.round((100 * FRACTIONDIGITS_SIZE) / numberOfRow) / FRACTIONDIGITS_SIZE;
  const totalRowGapSize = columnGap * 8 * (numberOfRow - 1);
  const gapWidthPerItem =
    Math.round((totalRowGapSize * FRACTIONDIGITS_SIZE) / numberOfRow) /
    FRACTIONDIGITS_SIZE;

  return `calc(${widthPerArea}% - ${gapWidthPerItem}px)`;
};

export const MvnoInfoItemRow: FC<{
  children: ReactNode;
  numberOfRow?: number;
  gap: number;
}> = ({ children, numberOfRow, gap }) => {
  const _numberOfRow = numberOfRow ?? React.Children.toArray(children).length;
  const calc = getCalcOfWidth(_numberOfRow, gap);

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        columnGap: gap,
        '& > *': {
          flex: `1 1 ${calc}`,
          maxWidth: calc,
        },
      }}
    >
      {children}
    </Box>
  );
};

export const MvnoInfoItemBody: FC<{
  children: ReactNode;
  gap: number;
}> = ({ children, gap }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap,
      }}
    >
      {children}
    </Box>
  );
};
