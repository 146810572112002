import { useState } from 'react';
import {
  Box,
  Button,
  Link,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';

import { MobilePlanOrder } from '../../../entity/order';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import { useLoading } from '../../../context/LoadingContext';
import { useNavigate, useParams } from 'react-router-dom';
import { handleApi } from '../../../common/http_util';
import { orderApiService } from '../../../api/order';
import { SelectPlanPopup } from '../popup/SelectPlanPopup';
import { VerticalColorTable } from '../../../shared/components/coloredTable';
import pbl from '../../../pbl/pbl-service/pbl-service';
import { PlanMetaHistory } from '../../../entity/plan';
import { comma } from '../../../common/string_util';

type Props = {
  order: MobilePlanOrder;
  onOrderUpdated: () => void;
};

export function OrderPlanInfoSection({ order, onOrderUpdated }: Props) {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const { setLoading } = useLoading();

  const [showSelectPlanDialog, setShowSelectPlanDialog] = useState(false);

  const onClickConfirmChangePlan = async () => {
    setLoading(true);

    if (!orderId) return;
    if (!order) return;
    pbl('click', 'button', 'here', {
      object: {
        id: 'approve_plan_change',
        section: 'plan_info',
      },
      eventProperties: {
        newPlanId: order.planMeta?.id,
        newPlanName: order.planMeta?.name,
      },
    });
    try {
      const updateOrder = await handleApi(
        () =>
          orderApiService.confirmChangePlan(Number(orderId), order.userId!!),
        navigate,
        (error) => {
          alert(error);
        },
      );

      if (!updateOrder) return;
      onOrderUpdated();
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const onClickChangePlan = () => {
    pbl('click', 'button', 'here', {
      object: {
        id: 'change_plan',
        section: 'plan_info',
      },
      eventProperties: {
        orderId: order.id,
      },
    });
    setShowSelectPlanDialog(true);
  };

  if (!order.planMeta) return <></>;

  const geFeeExplanation = (planMeta: PlanMetaHistory) => {
    const feeString = `${comma(planMeta.fee)}원`;
    if (planMeta.할인끝난후_금액 && planMeta.할인적용_개월수) {
      return `${feeString} (${planMeta.할인적용_개월수}개월 후 ${comma(
        planMeta.할인끝난후_금액,
      )}원)`;
    }

    return feeString;
  };

  return (
    <>
      <Box
        sx={{
          my: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6">요금제 정보</Typography>
        <Box>
          {order?.changePlan && (
            <>
              <Button
                variant="contained"
                onClick={onClickConfirmChangePlan}
                sx={{
                  mr: 1,
                }}
              >
                요금제 변경 승인
              </Button>
            </>
          )}
          <Button variant="outlined" onClick={onClickChangePlan}>
            다른 요금제로 변경하기
          </Button>
        </Box>
      </Box>

      <Box
        sx={{
          my: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <TableContainer component={Paper} elevation={5}>
          <VerticalColorTable>
            <TableBody>
              <TableRow>
                <TableCell>요금제 이름</TableCell>
                <TableCell>{order.planMeta.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>통신망</TableCell>
                <TableCell>{order.planMeta.mno}망</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>통신사</TableCell>
                <TableCell>{order.planMeta.mvno}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>요금제 가격</TableCell>
                <TableCell>{geFeeExplanation(order.planMeta)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>요금제 링크</TableCell>
                <TableCell>
                  <Link href={order?.moyoLink} target="_blank" rel="noreferrer">
                    {order?.moyoLink}
                  </Link>
                </TableCell>
              </TableRow>
              {order.discountCode ? (
                <TableRow>
                  <TableCell>할인코드</TableCell>
                  <TableCell>{order.discountCode}</TableCell>
                </TableRow>
              ) : (
                <></>
              )}
            </TableBody>
          </VerticalColorTable>
        </TableContainer>
        {order?.changePlan && (
          <>
            <ArrowCircleRightOutlinedIcon
              sx={{
                mr: 1,
                ml: 1,
              }}
              fontSize={'large'}
            />
            <TableContainer component={Paper} elevation={5}>
              <VerticalColorTable>
                <TableBody>
                  <TableRow>
                    <TableCell>요금제 이름</TableCell>
                    <TableCell>{order?.changePlan?.name}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>통신망</TableCell>
                    <TableCell>{order?.changePlan?.mno}망</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>통신사</TableCell>
                    <TableCell>{order?.changePlan?.mvno}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>요금제 가격</TableCell>
                    <TableCell>{geFeeExplanation(order?.changePlan)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>요금제 링크</TableCell>
                    <TableCell>
                      <Link
                        href={`https://www.moyoplan.com/plans/${order?.changePlan?.planMetaId}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {`https://www.moyoplan.com/plans/${order?.changePlan?.planMetaId}`}
                      </Link>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </VerticalColorTable>
            </TableContainer>
          </>
        )}
      </Box>

      {orderId && order?.planMeta && (
        <>
          <SelectPlanPopup
            orderId={orderId}
            open={showSelectPlanDialog}
            onClose={() => setShowSelectPlanDialog(false)}
            mvno={order.planMeta.mvno}
            onSubmit={() => onOrderUpdated()}
            originalPlanName={order.planMeta.name}
            changePlan={order.changePlan}
          />
        </>
      )}
    </>
  );
}
