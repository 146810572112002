import { Box, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';
import { isEmptyString } from '../../../../utils/stringUtil';

interface MvnoInfoFallback {
  isActive: boolean;
  text: string;
  color?: 'error';
}

interface Props {
  title: string;
  content: ReactNode | string | null | undefined;
  fallback?: MvnoInfoFallback;
}

const renderContent = (content: ReactNode | string[]) => {
  const _content = typeof content === 'string' ? [content] : content;
  return Array.isArray(_content)
    ? _content.map((item, index) => (
        <Typography
          key={index}
          variant="body1"
          color="text.primary"
          sx={{
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-all',
          }}
        >
          {item}
        </Typography>
      ))
    : content;
};

const renderFallback = (fallback: MvnoInfoFallback | undefined) => {
  if (!fallback) return <></>;

  return (
    <Typography variant="body1" color={fallback.color || 'dark'}>
      {fallback.text}
    </Typography>
  );
};

/**
 *
 * @param fallback content가 없을 때 보여줄 텍스트와 색상을 설정합니다.
 */
const MvnoInfoItem: FC<Props> = ({ title, content, fallback }) => {
  const _content = typeof content === 'string' ? [content] : content;
  const isEmptyContent =
    fallback?.isActive &&
    (typeof content === 'string' ? isEmptyString(content) : !content);

  return (
    <Box
      sx={{
        paddingY: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        flex: 1,
      }}
    >
      <Typography variant="caption" color="text.secondary">
        {title}
      </Typography>
      {isEmptyContent ? renderFallback(fallback) : renderContent(_content)}
    </Box>
  );
};

export default MvnoInfoItem;
