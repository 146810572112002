import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { CreateMoyoPlanTypes } from '../../type';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { MnoList } from '../../../../entity/mno';

interface Props {
  isDone: boolean;
}

export const MnoSelect: FC<Props> = ({ isDone }) => {
  const { control, register } = useFormContext<CreateMoyoPlanTypes>();

  return (
    <div>
      <Typography variant="subtitle1" m={0}>
        통신망
      </Typography>
      <FormControl sx={{ mt: 1, mb: 1, minWidth: 150 }}>
        <InputLabel>종류</InputLabel>
        <Controller
          control={control}
          name="mno"
          render={({ field }) => (
            <Select required {...field} disabled={isDone}>
              {MnoList.map((group, index) => (
                <MenuItem key={index} value={group.value}>
                  {group.value}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </FormControl>
    </div>
  );
};
