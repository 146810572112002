import { env } from '../utils/env';
import UBLSchema from './pbl-interface';

const usableBeacon = navigator != null && navigator.sendBeacon != null;

const requestUrl = `${env('REACT_APP_LOG_BASE_URL')}/api/v1/log`;

const sendPBLByBeacon = (ublSchema: UBLSchema) => {
  const requestBodyJson = JSON.stringify([ublSchema]);
  navigator.sendBeacon(requestUrl, requestBodyJson);
};

const sendPBLByFetch = async (ublSchema: UBLSchema) => {
  const requestBodyJson = JSON.stringify([ublSchema]);
  try {
    await fetch(requestUrl, {
      method: 'POST',
      body: requestBodyJson,
    });
  } catch (e) {
    // Do nothing
  }
};

export const sendPBL = async (ublSchemaList: UBLSchema) => {
  if (usableBeacon) {
    sendPBLByBeacon(ublSchemaList);
  } else {
    sendPBLByFetch(ublSchemaList);
  }
};
