import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { usePortal, usePortalController } from '../../../common/usePortal';
import { ThemeControlProvider } from '../../../context/ThemeControlContext';
import pbl from '../../../pbl/pbl-service/pbl-service';

interface Props {
  section?: string;
}

const MoyoProcessingDialog: FC<Props> = ({ section }) => {
  const { close, dismiss } = usePortalController<boolean>();
  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (!open) return;
    pbl('impression', 'modal', 'here', {
      object: {
        id: 'auto_processing_popup',
        section,
      },
    });
  }, [open]);

  useEffect(() => {
    if (open) return;
    onCloseDialogHandler();
  }, [open]);

  const onCloseHandler = () => {
    pbl('click', 'modal_button', 'here', {
      object: {
        id: 'auto_processing_popup_cancel',
        section,
      },
    });
    setOpen(false);
    close(false);
  };

  const onOkHandler = () => {
    pbl('click', 'modal_button', 'here', {
      object: {
        id: 'auto_processing_popup',
        section,
      },
    });
    setOpen(false);
    close(true);
  };

  const onCloseDialogHandler = () => {
    setTimeout(() => {
      dismiss();
    }, 500);
  };

  return (
    <Dialog open={open} onClose={onCloseHandler} closeAfterTransition>
      <DialogTitle>안내</DialogTitle>
      <DialogContent>
        <DialogContentText>
          모요 자동 처리 중인 신청 건입니다
          <br />
          정말 변경하시겠습니까?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseHandler} color="error">
          취소
        </Button>
        <Button onClick={onOkHandler}>확인</Button>
      </DialogActions>
    </Dialog>
  );
};

export const useMoyoProcessingDialog = () => {
  const portal = usePortal<boolean>();

  const open = (section?: string) => {
    return portal.open(
      <ThemeControlProvider>
        <MoyoProcessingDialog section={section} />
      </ThemeControlProvider>,
    );
  };

  return { open };
};
