import { CookieConstants } from '../common/constants';
import { getCookie } from '../common/cookie';
import jwtParse from '../common/jwt-parser';

interface TokenPayload {
  id: number;
  name: string;
  adminUserId: string;
  partnerCompanyId: number;
  authorities: string; // "ROLE_OPERATOR,ROLE_EXCEL_DOWNLOAD,ROLE_EDIT_ORDER,ROLE_ADMIN,ROLE_SUPER_ADMIN,ROLE_EDIT_ROLE",
  iat: number;
  exp: number;
}

export const getAdminUserInfoFromJwtToken = (): TokenPayload | undefined => {
  const token = getCookie(CookieConstants.ACCESS_TOKEN);
  if (!token) return;
  return jwtParse(token) as unknown as TokenPayload;
};
