import { AxiosResponse } from 'axios';
import { ApiResponse } from '../entity/api';
import { adminApi, moyoOnlyApi } from '../common/http_util';
import { RequestChangeMoyoOnlyTypes } from '../components/MoyoOnlyPlanListPage/MoyoOnlyRequestPopup';
import { PlanMetaV2, PlanV2 } from '../entity/plan-v2';

export interface getMoyoOnlyTypes {
  resultType: string;
  result: {
    totalSize: number;
    list: {
      id: number;
      alreadyExistPlan: boolean;
      planMetaJson: string;
      status: string;
      createdAt: string;
      updatedAt: string;
    }[];
  };
  error: string;
  errorCode: number;
}

export interface MoyoOnlyRequestTypes {
  id: number;
  alreadyhExistPlan: boolean;
  planMetaJson: string;
  status: string;
}

export interface MoyoOnlyReservation {
  reservedCreateDto: {
    id: number;
    effectiveAt: number[];
    isApplied: boolean;
    requestId: null | number;
    requestPlanId: number;
    createdAt: number[];
  };
  planMetaDto: PlanV2;
}

class MoyoOnlyApi {
  /**
   *
   * @param requestMvno
   */
  getMoyoOnlyPlans({
    requestMvno,
  }: {
    requestMvno: string;
  }): Promise<AxiosResponse<getMoyoOnlyTypes>> {
    return moyoOnlyApi.get<getMoyoOnlyTypes>(`/request/mvno/${requestMvno}`);
  }

  /**
   * @param mvno
   * @param mno
   * @param net
   * @param mobile_data
   * @param mobile_data2
   * @param voice
   * @param message
   */
  getSimilarPlanWhenCreate({
    mvno,
    mno,
    net,
    mobile_data,
    mobile_data2,
    voice,
    message,
  }: {
    mvno: string;
    mno: string;
    net: string;
    mobile_data: number;
    mobile_data2: number | null;
    voice: number;
    message: number;
  }): Promise<AxiosResponse<ApiResponse<PlanMetaV2>>> {
    const mobileData = mobile_data ? `&mobile_data=${mobile_data}` : '';
    const mobileData2 = mobile_data2 ? `&mobile_data2=${mobile_data2}` : '';
    return adminApi.get<ApiResponse<PlanMetaV2>>(
      `/mobile-plan-meta/similar-plan?mvno=${mvno}&mno=${mno}&net=${net}${mobileData}${mobileData2}&voice=${voice}&message=${message}`,
    );
  }

  /**
   * @param planId
   */
  getSimilarPlan(
    planId: number,
  ): Promise<AxiosResponse<ApiResponse<PlanMetaV2>>> {
    return adminApi.get<ApiResponse<PlanMetaV2>>(
      `/mobile-plan-meta/${planId}/similar-plan`,
    );
  }

  /**
   * @param requestMvno
   * @param planMetaJson
   */
  CreateMoyoOnlyPlan(
    requestMvno: string,
    planMetaJson: string,
  ): Promise<AxiosResponse<ApiResponse<MoyoOnlyRequestTypes>>> {
    return moyoOnlyApi.post<ApiResponse<MoyoOnlyRequestTypes>>(
      `/${requestMvno}`,
      {
        planMetaJson,
      },
    );
  }
  /**
   * @param requestMvno
   * @param planId
   */
  ModifyMoyoOnlyFee(
    requestMvno: string,
    planId: number,
    payload: RequestChangeMoyoOnlyTypes,
  ): Promise<AxiosResponse<ApiResponse<MoyoOnlyRequestTypes>>> {
    return moyoOnlyApi.post<ApiResponse<MoyoOnlyRequestTypes>>(
      `/${requestMvno}/${planId}`,
      payload,
    );
  }

  /**
   * @param requestMvno
   * @param planMetaJson
   * @param reserveTime
   */
  CreateMoyoOnlyPlanReserve(
    requestMvno: string,
    planMetaJson: string,
    reserveTime: string,
  ): Promise<AxiosResponse<ApiResponse<MoyoOnlyRequestTypes>>> {
    return moyoOnlyApi.post<ApiResponse<MoyoOnlyRequestTypes>>(
      `/${requestMvno}/reserve-create`,
      {
        planMetaJson,
        reserveTime,
      },
    );
  }

  /**
   * @param requestMvno
   * @param planId
   */
  ModifyMoyoOnlyFeeReserve(
    requestMvno: string,
    planId: number,
    payload: RequestChangeMoyoOnlyTypes,
    reserveTime: string,
  ): Promise<AxiosResponse<ApiResponse<MoyoOnlyRequestTypes>>> {
    return moyoOnlyApi.post<ApiResponse<MoyoOnlyRequestTypes>>(
      `/${requestMvno}/${planId}/reserve-create`,
      {
        ...payload,
        reserveTime,
      },
    );
  }

  /**
   *
   * @param mvno
   */
  getReserveCreateList(
    mvno: string,
  ): Promise<AxiosResponse<ApiResponse<MoyoOnlyReservation[]>>> {
    return moyoOnlyApi.get<ApiResponse<MoyoOnlyReservation[]>>(
      `/${mvno}/reserve-create`,
    );
  }

  /**
   * @param mvno
   * @param reserveId
   */
  deleteReservePlan({
    mvno,
    reserveId,
  }: {
    mvno: string;
    reserveId: number;
  }): Promise<AxiosResponse<ApiResponse>> {
    return moyoOnlyApi.delete<ApiResponse>(
      `/${mvno}/reserve-create/${reserveId}`,
    );
  }
}

export const MoyoOnlyApiService = new MoyoOnlyApi();
