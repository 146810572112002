export async function asyncPoll<T>(
  asyncFunction: () => PromiseLike<T>,
  endPollPredicate: (response: T) => boolean,
  pollInterval: number = 5 * 1000,
): Promise<T> {
  const checkCondition = (
    resolve: (value: T | PromiseLike<T>) => void,
    reject: (reason?: unknown) => void,
  ): void => {
    Promise.resolve(asyncFunction())
      .then((result) => {
        if (endPollPredicate(result)) {
          resolve(result);
        } else {
          window.setTimeout(checkCondition, pollInterval, resolve, reject);
        }
      })
      .catch((err) => {
        reject(err);
      });
  };
  return new Promise(checkCondition);
}
