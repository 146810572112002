import { Grid, TextField, Typography } from '@mui/material';

import { useSearchSection } from '../hooks';

export function BirthdaySection() {
  const { searchState, searchHandler } = useSearchSection();

  const { birthday } = searchState;
  const { setBirthday } = searchHandler;

  return (
    <Grid xs={6} md={3} sx={{ padding: '16px 0 0 16px' }}>
      <Typography mb={1}>생년월일</Typography>
      <TextField
        label="생년월일"
        value={birthday}
        onChange={(event) => setBirthday(event.target.value)}
        size="small"
        fullWidth
      />
    </Grid>
  );
}
