import getRedirectQueryString from '../utils/getRedirectQueryString';
export class ApiError extends Error {
  constructor(message?: string) {
    super(message);
  }
}

export class AuthError extends ApiError {
  private loginPath = '/login';

  private redirect = (path: string) => {
    window.location.href = path;
  };

  handler = () => {
    const location = window.location;
    const currentPath = location.pathname;

    if (location.pathname !== this.loginPath) {
      return this.redirect(
        this.loginPath +
          getRedirectQueryString({
            redirectUrl: currentPath,
            includeQuestionMark: true,
          }),
      );
    }
  };
}
