import { RouteKey, routes } from './routes';
import { useCheckPermission } from './useCheckPermission';

/**
 * @description
 * 네비게이션 순서를 정의합니다. routes에 sub menu로 지정되어 있으면 해당 뎁스에서 순서를 적용받습니다.
 * 따라서 화면에 보이는 순서대로 정의를 해주세요.
 */
export const navigationOrder: RouteKey[] = [
  'orders',
  'orders_all',
  'orders_rocket',
  'orders_auto',
  'orders_baro',
  'orders_outlink_self',
  'orders_self_complete',
  'call_orders',
  'internet',
  'internet_orders',
  'plans',
  'plans_list',
  'plans_moyoonly_list',
  'plans_moyoonly_create',
  'mvno_info',
  'guide',
];

export const getRouteKeys = (): RouteKey[] => {
  return Object.keys(routes) as RouteKey[];
};

export interface NavigationItem {
  key: RouteKey;
  name: string;
  path: string | undefined;
  id: RouteKey;
  hasChildren: boolean;
  innerItem?: React.ReactNode;
  icon?: React.ReactNode;
  children: NavigationItem[];
  divide?: boolean;
  outlink?: string;
}

export function useNavigationList() {
  const { checkPermission } = useCheckPermission();

  const filterParentItems = (key: RouteKey) => {
    if (!routes[key]) {
      return false;
    }
    return (
      routes[key].isNavItem &&
      !routes[key].parentId &&
      checkPermission(
        routes[key].requiredPermissions,
        routes[key].navigationPermissionsAll,
        routes[key].navigationPermissionsAny,
      )
    );
  };

  const filterChildItems = (parentKey: RouteKey) =>
    Object.entries(routes)
      .filter(
        ([_, r]) =>
          r.parentId === parentKey &&
          r.isNavItem &&
          checkPermission(
            r.requiredPermissions,
            r.navigationPermissionsAll,
            r.navigationPermissionsAny,
          ),
      )
      .map(([childKey, r]) => {
        const childItem: NavigationItem = {
          key: childKey as RouteKey,
          name: r.name,
          path: r.path,
          innerItem: r.innerItem,
          children: [],
          hasChildren: false,
          divide: r.divide,
          id: r.objectId,
          icon: r.icon,
          outlink: r.outlink,
        };
        return childItem;
      });

  const parentItems = navigationOrder.filter(filterParentItems).map((key) => {
    const children = filterChildItems(key);
    const hasChildren = children.length > 0;

    const parentItem: NavigationItem = {
      key,
      name: routes[key].name,
      path: routes[key].path,
      innerItem: routes[key].innerItem,
      hasChildren: hasChildren,
      children: hasChildren ? children : [],
      id: routes[key].objectId,
      divide: routes[key].divide,
      icon: routes[key].icon,
      outlink: routes[key].outlink,
    };
    return parentItem;
  });

  return parentItems;
}
