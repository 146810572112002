import { AxiosResponse } from 'axios';
import { internetApi } from '../common/http_util';
import { ApiResponseV2, AxiosApiResponseV2 } from '../entity/api';
import {
  GetInternetIptvBundlePlanConsultResponse,
  GetInternetPlanConsultResponse,
} from '../entity/internet-plan/response/internet-plan-consult-response';
import {
  GetInternetIptvBundlePlanConsultsResponse,
  GetInternetPlanConsultsResponse,
} from '../entity/internet-plan/response/internet-plan-consults-response';

abstract class AbstractInternetConsultCommonApiService<
  GetInternetPlanConsultsResponseType,
  GetInternetPlanConsultResponseType,
> {
  constructor(private prefixUrl: string) {}

  getConsults(
    dateStandard?: string | null, // createdAt(신청일) | completedAt(개통일),
    startDate?: string | null,
    endDate?: string | null,
    status?: string | null,
    applicantName?: string | null,
    applicantPhoneNumber?: string | null,
    internetProviderId?: number,
    page?: number,
    size?: number,
  ): Promise<AxiosApiResponseV2<GetInternetPlanConsultsResponseType>> {
    const openedAtFrom = dateStandard === 'createdAt' ? startDate : undefined;
    const openedAtUntil = dateStandard === 'createdAt' ? endDate : undefined;
    const completedAtFrom =
      dateStandard === 'completedAt' ? startDate : undefined;
    const completedAtUntil =
      dateStandard === 'completedAt' ? endDate : undefined;

    const params = {
      openedAtFrom,
      openedAtUntil,
      completedAtFrom,
      completedAtUntil,
      status,
      applicantName,
      applicantPhoneNumber,
      internetProviderId,
      page,
      size,
    };

    return internetApi.get(`${this.prefixUrl}/consults`, {
      params,
    });
  }

  getConsult(
    consultId: number,
  ): Promise<AxiosApiResponseV2<GetInternetPlanConsultResponseType>> {
    return internetApi.get(`${this.prefixUrl}/consults/${consultId}`);
  }

  patchConsult(
    consultId: number,
    applicantName: string,
    applicantPhoneNumber: string,
  ): Promise<AxiosApiResponseV2> {
    return internetApi.patch(`${this.prefixUrl}/consults/${consultId}`, {
      applicantName,
      applicantPhoneNumber,
    });
  }

  patchConsultStatus(
    consultId: number,
    status: string,
    memo: string,
    statusDetail?: string,
  ): Promise<AxiosApiResponseV2> {
    return internetApi.patch(`${this.prefixUrl}/consults/${consultId}/status`, {
      status,
      memo,
      statusDetail,
    });
  }
}

class InternetConsultApiService extends AbstractInternetConsultCommonApiService<
  GetInternetPlanConsultsResponse,
  GetInternetPlanConsultResponse
> {
  constructor() {
    super('/internet-plan');
  }
}

class InternetIptvBundleConsultApiService extends AbstractInternetConsultCommonApiService<
  GetInternetIptvBundlePlanConsultsResponse,
  GetInternetIptvBundlePlanConsultResponse
> {
  constructor() {
    super('/internet-iptv-bundle-plan');
  }
}

class InternetCommonConsultApiService {
  constructor(
    private internetConsultApiService: InternetConsultApiService,
    private internetIptvBundleConsultApiService: InternetIptvBundleConsultApiService,
  ) {}

  iptvBundle(iptvBundle: true): InternetIptvBundleConsultApiService;
  iptvBundle(iptvBundle: false): InternetConsultApiService;
  iptvBundle(
    iptvBundle: boolean,
  ): InternetConsultApiService | InternetIptvBundleConsultApiService;
  iptvBundle(iptvBundle: boolean) {
    return iptvBundle
      ? this.internetIptvBundleConsultApiService
      : this.internetConsultApiService;
  }
}

const internetCommonConsultApiService = new InternetCommonConsultApiService(
  new InternetConsultApiService(),
  new InternetIptvBundleConsultApiService(),
);

export default internetCommonConsultApiService;
