import { FC, useEffect } from 'react';
import type {
  MnoOperationPolicy,
  MnoOperationPolicyByOperatorList,
} from '../../types/mobile-plan-operator-admin-types';
import { Mno } from '../../../../entity/mno';
import { Controller, useFormContext } from 'react-hook-form';
import { convertStringToMno } from '../../../../common/order-util';
import { Box, Typography } from '@mui/material';
import CustomMvnoInfoToggleButtonGroup from '../Common/CustomMvnoInfoToggleButtonGroup';
import { MvnoInfoItemBody, MvnoInfoItemRow } from '../Common/MvnoInfoItemFrame';

const MnoInfoMnoOperationPolicyEdit: FC<{
  mno: Mno;
  mnoOperationPolicy: MnoOperationPolicy;
}> = ({ mno, mnoOperationPolicy }) => {
  const { control } = useFormContext<MnoOperationPolicyByOperatorList>();
  const title = convertStringToMno(mno);

  return (
    <MvnoInfoItemBody gap={3}>
      <Box>
        <Typography variant="body1" color="text.primary" fontWeight={700}>
          {title}망
        </Typography>
      </Box>
      <MvnoInfoItemBody gap={6}>
        <MvnoInfoItemRow gap={3} numberOfRow={3}>
          <Controller
            control={control}
            name={`${mno}.signupForeigner`}
            defaultValue={mnoOperationPolicy.signupForeigner}
            render={({ field }) => (
              <CustomMvnoInfoToggleButtonGroup
                label="외국인 개통 (자사몰 기준)"
                items={[
                  {
                    label: '가능',
                    value: true,
                  },
                  {
                    label: '불가능',
                    value: false,
                  },
                ]}
                toggleButtonGroupProps={{ ...field }}
              />
            )}
          />
          <Controller
            control={control}
            name={`${mno}.signupMinor`}
            defaultValue={mnoOperationPolicy.signupMinor}
            render={({ field }) => (
              <CustomMvnoInfoToggleButtonGroup
                label="미성년자 개통 (자사몰 기준) "
                items={[
                  {
                    label: '가능',
                    value: true,
                  },
                  {
                    label: '불가능',
                    value: false,
                  },
                ]}
                toggleButtonGroupProps={{ ...field }}
              />
            )}
          />
          <Controller
            control={control}
            name={`${mno}.roamingSupport`}
            defaultValue={mnoOperationPolicy.roamingSupport}
            render={({ field }) => (
              <CustomMvnoInfoToggleButtonGroup
                label="해외 로밍 부가서비스 (기간형/일반형)"
                items={[
                  {
                    label: '제공',
                    value: true,
                  },
                  {
                    label: '미제공',
                    value: false,
                  },
                ]}
                toggleButtonGroupProps={{ ...field }}
              />
            )}
          />
        </MvnoInfoItemRow>
        <MvnoInfoItemRow gap={3} numberOfRow={3}>
          <Controller
            control={control}
            name={`${mno}.microPayment`}
            defaultValue={mnoOperationPolicy.microPayment}
            render={({ field }) => (
              <CustomMvnoInfoToggleButtonGroup
                label="휴대폰 소액결제"
                items={[
                  {
                    label: '가능',
                    value: true,
                  },
                  {
                    label: '불가능',
                    value: false,
                  },
                ]}
                toggleButtonGroupProps={{ ...field }}
              />
            )}
          />
        </MvnoInfoItemRow>
      </MvnoInfoItemBody>
    </MvnoInfoItemBody>
  );
};

const MvnoInfoMnoOperationPolicyEdit: FC<MnoOperationPolicyByOperatorList> = (
  mnoOperationPolicyByOperatorList,
) => {
  const { setValue, reset } =
    useFormContext<MnoOperationPolicyByOperatorList>();

  useEffect(() => {
    initialize(mnoOperationPolicyByOperatorList);
    return () => {
      reset();
    };
  }, []);

  const initialize = (
    mnoOperationPolicyByOperatorList: MnoOperationPolicyByOperatorList,
  ) => {
    Object.entries(mnoOperationPolicyByOperatorList).forEach(
      ([mnoOperationPolicyByOperatorListKey, mnoOperationPolicyByOperator]) => {
        Object.entries(mnoOperationPolicyByOperator).forEach(
          ([mnoOperationPolicyKey, value]) => {
            setValue(
              `${
                mnoOperationPolicyByOperatorListKey as keyof MnoOperationPolicyByOperatorList
              }.${mnoOperationPolicyKey as keyof MnoOperationPolicy}`,
              value,
            );
          },
        );
      },
    );
  };

  return (
    <MvnoInfoItemBody gap={6}>
      {Object.entries(mnoOperationPolicyByOperatorList).map(
        ([key, mnoOperationPolicy]) => (
          <MnoInfoMnoOperationPolicyEdit
            mnoOperationPolicy={mnoOperationPolicy}
            key={key}
            mno={key as Mno}
          />
        ),
      )}
    </MvnoInfoItemBody>
  );
};

export default MvnoInfoMnoOperationPolicyEdit;
