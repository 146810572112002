import { Grid, TextField, Typography } from '@mui/material';

import { useSearchSection } from '../hooks';

export function MemoSection() {
  const { searchState, searchHandler } = useSearchSection();

  const { memo } = searchState;
  const { setMemo } = searchHandler;

  return (
    <Grid item xs={12} md={6}>
      <Typography mb={1}>최신 메모</Typography>
      <TextField
        label="메모"
        value={memo}
        onChange={(event) => setMemo(event.target.value)}
        size="small"
        fullWidth
      />
    </Grid>
  );
}
