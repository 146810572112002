import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { styled } from '@mui/material';

const MainAccordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))'
      : undefined,
  border: 'none',
  padding: 0,
  marginTop: 16,
  '.MuiCollapse-root:not(.MuiCollapse-hidden)': {
    padding: '0px 12px',
    width: '100%',
    marginLeft: -12,
  },
  '&.MuiPaper-root::before': {
    display: 'none',
  },
}));

const MainAccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ExpandMoreIcon />} {...props} />
))(() => ({
  padding: 0,
}));

const MainAccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: 0,
}));

export { MainAccordion, MainAccordionSummary, MainAccordionDetails };
