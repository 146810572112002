import { Route, Routes } from 'react-router-dom';
import { PrivateRoute } from './PrivateRouter';
import { routes } from './routes';

export function RouteList() {
  const routeList = Object.values(routes).filter((route) => route.isRouteItem);

  return (
    <Routes>
      {routeList.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={<PrivateRoute routeConfig={route} />}
        >
          <Route path={route.path} element={route.element} />
        </Route>
      ))}
    </Routes>
  );
}
