import {
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import {
  GetInternetIptvBundlePlanConsultResponse,
  GetInternetPlanConsultResponse,
} from '../../../entity/internet-plan/response/internet-plan-consult-response';
import { VerticalColorTable } from '../../../shared/components/coloredTable';

const colGroupDefinition = (
  <colgroup>
    <col style={{ width: '15%' }} />
    <col style={{ width: '35%' }} />
    <col style={{ width: '15%' }} />
    <col style={{ width: '35%' }} />
  </colgroup>
);

interface Props {
  name: string;
  status: string;
  createdAt: string;
  phoneNumber: string;
  planName: string;
}

const InternetConsultDetailInfo: FC<Props> = ({
  name,
  status,
  createdAt,
  phoneNumber,
  planName,
}) => {
  return (
    <>
      <Typography variant="h6" my={2}>
        접수 정보
      </Typography>
      <TableContainer component={Paper} elevation={5}>
        <VerticalColorTable>
          {colGroupDefinition}
          <TableBody>
            <TableRow>
              <TableCell>신청자 이름</TableCell>
              <TableCell>{name}</TableCell>
              <TableCell>접수 상태</TableCell>
              <TableCell>{status}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>신청 일자</TableCell>
              <TableCell>{createdAt}</TableCell>
              <TableCell>신청 번호</TableCell>
              <TableCell>{phoneNumber}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>신청한 상품</TableCell>
              <TableCell>{planName}</TableCell>
              <TableCell>비고</TableCell>
              <TableCell>정찰제 대상</TableCell>
            </TableRow>
          </TableBody>
        </VerticalColorTable>
      </TableContainer>
    </>
  );
};

export default InternetConsultDetailInfo;
