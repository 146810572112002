import { adminApi, moyoApi } from '../common/http_util';
import qs from 'query-string';
import { AxiosResponse } from 'axios';
import { ApiResponse } from '../entity/api';
import {
  PagedPlanMetaAdmin,
  PlanEdit,
  PlanEditReserve,
  PlanHistory,
  PlanEditBulkData,
  PlanEditBulkResult,
  ScrapeReserve,
  SimplePlanMeta,
  ICreatePlanDto,
  IMobilePlan,
  PlanMetaLogHistory,
  IMobileOperatorMeta,
} from '../entity/plan';
import { DateTime } from 'luxon';
import { PlanV2 } from '../entity/plan-v2';

/**
 * FIXME 토큰 방식을 사용하는 admin과 통합 필요
 */
class PlanApiService {
  getList(
    mvno: string,
    searchText?: string,
  ): Promise<AxiosResponse<ApiResponse<Array<SimplePlanMeta>>>> {
    let params: { [key: string]: any } = {
      mvno,
    };

    if (searchText) {
      params['q'] = searchText;
    }

    return adminApi.get(`mobile-plan-meta/list?${qs.stringify(params)}`);
  }

  requestScraping(mvno: string) {
    return adminApi.get(`mobile-plan-meta/scraping?mvno=${mvno}`);
  }

  requestScrapingReserve(mvno: string, applyDate: DateTime) {
    const year = applyDate.year;
    const month = String(applyDate.month).padStart(2, '0');
    const date = String(applyDate.day).padStart(2, '0');
    const hour = String(applyDate.hour).padStart(2, '0');
    const minute = String(applyDate.minute).padStart(2, '0');
    return adminApi.post(
      `mobile-plan-meta/scraping/reserve?mvno=${mvno}&applyDate=${year}-${month}-${date}T${hour}:${minute}:00`,
    );
  }

  getScrapingReserve(
    mvno: string,
  ): Promise<AxiosResponse<ApiResponse<Array<ScrapeReserve>>>> {
    return adminApi.get(`mobile-plan-meta/scraping/reserve?mvno=${mvno}`);
  }

  getPlansByMvnoPagination({
    mvno,
    searchText,
    searchIdText,
    isDeleted,
    state,
    mno,
    isCreatedOnMvnoStoreScraping,
    hasReservedUpdate,
    page,
    size,
    isManualPlan,
    display,
    isMoyoOnlyPlan,
    sortBy = 'id',
    sortOrder = 'desc',
  }: {
    mvno: string;
    searchText: string;
    searchIdText?: string;
    isDeleted?: boolean;
    state?: string;
    mno?: string;
    isCreatedOnMvnoStoreScraping?: boolean;
    hasReservedUpdate?: boolean;
    page: number;
    size: number;
    isManualPlan?: boolean;
    display?: boolean;
    isMoyoOnlyPlan?: boolean;
    sortBy?: string;
    sortOrder?: 'asc' | 'desc';
  }): Promise<AxiosResponse<ApiResponse<PagedPlanMetaAdmin>>> {
    // TODO: SearchParams로 리팩이 필요해보임
    const stateParams = state !== 'ALL' ? `&state=${state}` : '';
    const searchIdTextParams =
      searchIdText !== undefined ? `&id=${searchIdText}` : '';
    const isDeletedParams = isDeleted ? `&isDeleted=${isDeleted}` : '';
    const displayParams = display !== undefined ? `&display=${display}` : '';
    const mnoParams = mno ? `&mno=${mno}` : '';
    const isCreatedOnMvnoStoreScrapingParams = isCreatedOnMvnoStoreScraping
      ? `&isCreatedOnMvnoStoreScraping=${isCreatedOnMvnoStoreScraping}`
      : '';
    const hasReservedUpdateParams = hasReservedUpdate
      ? `&hasReservedUpdate=${hasReservedUpdate}`
      : '';

    const isMoyoOnlyPlanParams =
      isMoyoOnlyPlan != null ? `&isMoyoOnlyPlan=${isMoyoOnlyPlan}` : '';

    const isManualParams =
      isManualPlan !== undefined ? `&isManual=${isManualPlan}` : '';

    let encodedQ = encodeURIComponent(searchText);

    const url =
      `mobile-plan-meta/v3/list?mvno=${mvno}&q=${encodedQ}` +
      stateParams +
      isDeletedParams +
      mnoParams +
      isCreatedOnMvnoStoreScrapingParams +
      hasReservedUpdateParams +
      displayParams +
      searchIdTextParams +
      isManualParams +
      isMoyoOnlyPlanParams +
      `&page=${page}&size=${size}&sort=${sortBy},${sortOrder}`;
    return adminApi.get(url);
  }

  /**
   * 요금제 부가 정보 조회
   * @param planId
   */
  getPlanDetail(planId: number): Promise<AxiosResponse<ApiResponse<PlanV2>>> {
    return adminApi.get(`mobile-plan-meta/detail/${planId}`);
  }

  /**
   * 요금제 수정 이력 목록 조회
   * @param planId
   */
  getPlanHistory(
    planId: number,
  ): Promise<AxiosResponse<ApiResponse<Array<PlanHistory>>>> {
    return adminApi.get(`mobile-plan-meta/${planId}/history`);
  }

  /**
   * 요금제 수정 이력 목록 조회 (로그 포함)
   * @param planId
   */
  getPlanMetaHistory(
    planId: number,
    page: number,
    size: number,
  ): Promise<AxiosResponse<ApiResponse<Array<PlanMetaLogHistory[]>>>> {
    return adminApi.get(`mobile-plan-meta/${planId}/change-log`, {
      params: {
        page,
        size,
      },
    });
  }

  /**
   * 요금제 변경 예약 내역 조회
   * @param planId
   */
  getPlanReserveEditHistoryById(
    planId: number,
  ): Promise<AxiosResponse<ApiResponse<Array<PlanEditReserve>>>> {
    return adminApi.get(`mobile-plan-meta/${planId}/reserve`);
  }

  /**
   * 요금제 예약 변경 내역 조회
   * @param reserveId
   * @returns
   */
  getPlanReservation(
    reserveId: number,
  ): Promise<AxiosResponse<ApiResponse<PlanEditReserve>>> {
    return adminApi.get(`mobile-plan-meta/reserve/${reserveId}`);
  }

  editPlanReservationBulk(
    data: PlanEditBulkData,
  ): Promise<AxiosResponse<ApiResponse<PlanEditBulkResult>>> {
    return adminApi.post(
      'mobile-plan-meta/bulk-upsert/reserve-edit?addedByMvno=true',
      data,
    );
  }

  deleteScrapeReserveEdit(reserveId: number) {
    return adminApi.delete(`mobile-plan-meta/scraping/reserve/${reserveId}`);
  }

  deletePlanReserveEdit(reserveId: number) {
    return adminApi.delete(`mobile-plan-meta/reserve/${reserveId}`);
  }

  editPlanById(planId: number, data: PlanEdit) {
    return adminApi.put(`/mobile-plan-meta/${planId}`, data);
  }

  deletePlanCode(planId: number) {
    return adminApi.delete(`/mobile-plan-meta/${planId}/code`);
  }
  restorePlan(planId: number) {
    return adminApi.put(`/mobile-plan-meta/${planId}/restore`);
  }

  deletePlan(planId: number) {
    return adminApi.delete(`/mobile-plan-meta/${planId}`);
  }

  reserveEditPlan(planId: number, data: any) {
    return adminApi.put(`/mobile-plan-meta/${planId}/reserve-edit`, data);
  }

  reserveDeletePlan(planId: number, applyDate: DateTime) {
    const year = applyDate.year;
    const month = String(applyDate.month).padStart(2, '0');
    const date = String(applyDate.day).padStart(2, '0');
    const hour = String(applyDate.hour).padStart(2, '0');
    const minute = String(applyDate.minute).padStart(2, '0');
    return adminApi.post(
      `/mobile-plan-meta/${planId}/reserve-delete?applyDate=${year}-${month}-${date}T${hour}:${minute}:00`,
    );
  }

  editPlanCommonInfo(mvno: string, mno: string, body: any) {
    return adminApi.put<ApiResponse<boolean>>(
      `/mobile-plan-meta/detail/mvno/${mvno}?mno=${mno}`,
      body,
    );
  }

  createPlan(body: ICreatePlanDto) {
    return adminApi.post<ApiResponse<IMobilePlan>>(`/plan/create/v2`, body);
  }

  putPlan(planId: number, body: ICreatePlanDto) {
    return adminApi.put<ApiResponse<IMobilePlan>>(
      `/mobile-plan-meta/v2/${planId}`,
      body,
    );
  }

  getPlanOperatorMeta(mobilePlanOperatorId: number) {
    return moyoApi.get<ApiResponse<IMobileOperatorMeta>>(
      `/mobile-plan-operator/${mobilePlanOperatorId}`,
    );
  }

  getPlanOperatorStatus(mobilePlanOperatorId: number) {
    return moyoApi.get<ApiResponse<any>>(
      `/mobile-plan-operator/${mobilePlanOperatorId}/status`,
    );
  }
}

export const planApiService = new PlanApiService();
