import { Button, Chip } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { calculateTime } from '../hook/calculateTime';
import { useDeleteReservePlan } from '../hook/queries/useDeleteReservePlan';

export const ReserveTableColumn: GridColDef[] = [
  {
    field: 'state',
    headerAlign: 'center',
    headerName: '상태',
    align: 'center',

    renderCell: (params: GridRenderCellParams) => {
      const { text, color } = getStatus(
        params.row.meta.state,
        params.row.meta.isDeleted,
        params.row.meta.manualIsDeleted,
      );
      return <Chip color={color} label={text} size="small"></Chip>;
    },
  },
  {
    field: 'name',
    headerAlign: 'center',
    headerName: '요금제 이름',
    align: 'center',
    renderCell: (params: GridRenderCellParams) => {
      return <div>{params.row.meta.name}</div>;
    },
    width: 400,
  },
  {
    field: 'createdAt',
    headerAlign: 'center',
    align: 'center',
    headerName: '예약 시간',
    renderCell: (params: GridRenderCellParams) => {
      const effectAt = params.row.effectiveAt;
      return (
        <div>{`${effectAt[0]}-${calculateTime(effectAt[1])}-${
          effectAt[2]
        } ${calculateTime(effectAt[3])}:${calculateTime(effectAt[4])}`}</div>
      );
    },
    width: 300,
  },
];

function getStatus(
  state: string,
  isDeleted: boolean,
  manualIsDeleted: boolean,
): { text: string; color: 'error' | 'warning' | 'default' | 'success' } {
  if (state === 'READY') {
    return {
      text: '진행 대기',
      color: 'error',
    };
  }

  if (state === 'CREATE_RESERVED') {
    return {
      text: '생성예약',
      color: 'error',
    };
  }

  if (state === 'CHANGE_RESERVED') {
    return {
      text: '수정예약',
      color: isDeleted ? 'error' : 'success',
    };
  }

  if (state === 'DELETE_RESERVED') {
    return {
      text: '종료 예약',
      color: 'success',
    };
  }

  if (
    state === 'CONFIRMED' &&
    isDeleted === false &&
    manualIsDeleted === false
  ) {
    return {
      text: '진행중',
      color: 'success',
    };
  }

  if (isDeleted || manualIsDeleted) {
    return {
      text: '종료',
      color: 'error',
    };
  }

  // Default case
  return {
    text: '진행중',
    color: 'success',
  };
}
