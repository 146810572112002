import { AccountCreatingForm } from '../Accounts/CreatingForm';
import { authApiService } from '../../api/auth';
import { useEffect } from 'react';
import pbl from '../../pbl/pbl-service/pbl-service';

const AccountSettingsPage = () => {
  useEffect(() => {
    pbl('pageview', 'none', 'account_create');
  }, []);

  const onSubmit = (id: string, password: string, name: string) => {
    authApiService.createMember(name, password, id);
  };
  return (
    <div
      style={{
        display: 'flex',
        height: '100%',
      }}
    >
      <AccountCreatingForm onSubmit={onSubmit} />
    </div>
  );
};

export default AccountSettingsPage;
