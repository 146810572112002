import { PropsWithChildren } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/UserContext';
import { RouteConfig } from './routes';
import { useCheckPermission } from './useCheckPermission';
import { useCheckRole } from './useCheckRole';
import { usePermission } from './usePermission';
import getRedirectQueryString from '../utils/getRedirectQueryString';

interface PrivateRouteProps extends PropsWithChildren {
  routeConfig: RouteConfig;
}

export function PrivateRoute({ routeConfig }: PrivateRouteProps) {
  const { user } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const { checkPermission } = useCheckPermission();
  const { checkRole } = useCheckRole();
  const { fallbackPath } = usePermission();

  const currentPath = location.pathname;
  const redirectQueryString = getRedirectQueryString({
    redirectUrl: currentPath,
    includeQuestionMark: true,
  });

  if (routeConfig.requiredAuth && !user) {
    return (
      <Navigate
        to={`/login${redirectQueryString}`}
        state={{ from: location }}
        replace
      />
    );
  }

  // TODO: role을 체크해야 되는로직을 분리해야될 수 있음
  if (
    routeConfig.requiredAuth &&
    !(
      checkPermission(
        routeConfig.requiredPermissions,
        routeConfig.navigationPermissionsAll,
        routeConfig.navigationPermissionsAny,
      ) && checkRole(routeConfig.requiredRoles)
    )
  ) {
    navigate(fallbackPath);
  }

  return <Outlet />;
}
