import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { FC } from 'react';

interface Props {
  isOpen: boolean;
  onOk?: () => void;
  onCancel?: () => void;
  cancelText?: string;
  okText?: string;
  title: string;
  text: string;
}

export const AlertDialog: FC<Props> = (props) => {
  const { isOpen, onOk, onCancel, title, text, cancelText, okText } = props;

  return (
    <Dialog maxWidth="lg" open={isOpen}>
      <DialogTitle style={{ whiteSpace: 'pre-line', paddingRight: '200px' }}>
        {title}
      </DialogTitle>
      <DialogContent style={{ whiteSpace: 'pre-line' }}>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {onCancel && (
          <Button onClick={() => onCancel()}>{cancelText ?? '확인'}</Button>
        )}
        {onOk && <Button onClick={() => onOk()}>{okText ?? '확인'}</Button>}
      </DialogActions>
    </Dialog>
  );
};
