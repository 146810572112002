import {
  FormControl,
  MenuItem,
  Paper,
  Select,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import { VerticalColorTable } from '../../../shared/components/coloredTable';
import { MobilePlanOrder } from '../../../entity/order';
import {
  IPHONE_MODEL_OPTIONS,
  PHONE_MEMORY_MAP,
  SAMSUNG_MODEL_OPTIONS,
} from '../../../types/ESimTypes';
import { ColumnGroupDefinition } from '../../OrderDetail/components/ColumnGroupDefinition';
import { Controller, useFormContext } from 'react-hook-form';

type Props = {
  order?: MobilePlanOrder;
};

export const OrderEditESimInfoSection: FC<Props> = ({ order }) => {
  const { register, watch, control } = useFormContext<MobilePlanOrder>();

  if (!order || !watch('isEsim')) return <></>;

  return (
    <>
      <Typography variant="h6" my={2}>
        eSIM 정보
      </Typography>
      <TableContainer component={Paper} elevation={5}>
        <VerticalColorTable>
          {ColumnGroupDefinition}
          <TableBody>
            <TableRow>
              <TableCell>기기 모델</TableCell>
              <TableCell>
                <FormControl>
                  <Controller
                    name="detail.phoneModel"
                    control={control}
                    defaultValue={order.detail?.phoneModel ?? ''}
                    render={({ field }) => (
                      <Select {...field} size="small">
                        {[
                          ...Object.values(IPHONE_MODEL_OPTIONS),
                          ...Object.values(SAMSUNG_MODEL_OPTIONS),
                        ].map((model) => (
                          <MenuItem key={model.value} value={model.value}>
                            {model.label}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
              </TableCell>
              <TableCell>EID</TableCell>
              <TableCell>
                <TextField {...register('detail.eid')} size="small" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>용량</TableCell>
              <TableCell>
                <FormControl>
                  <Controller
                    name="detail.phoneMemory"
                    control={control}
                    defaultValue={order.detail?.phoneMemory ?? ''}
                    render={({ field }) => (
                      <Select {...field} size="small">
                        {Object.values(PHONE_MEMORY_MAP).map((memory) => (
                          <MenuItem key={memory.value} value={memory.value}>
                            {memory.label}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
              </TableCell>
              <TableCell>IMEI</TableCell>
              <TableCell>
                <TextField {...register('detail.imei')} size="small" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>일련번호</TableCell>
              <TableCell>
                <TextField
                  {...register('detail.phoneSerialNumber')}
                  size="small"
                />
              </TableCell>
              <TableCell>IMEI2</TableCell>
              <TableCell>
                <TextField {...register('detail.imei2')} size="small" />
              </TableCell>
            </TableRow>
          </TableBody>
        </VerticalColorTable>
      </TableContainer>
    </>
  );
};
