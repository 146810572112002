import {
  FC,
  Fragment,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Box,
  ClickAwayListener,
  Collapse as MaterialCollapse,
  Divider,
  List,
  ListItemButton as MaterialListItemButton,
  Menu,
  MenuItem,
  styled,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import pbl from '../../pbl/pbl-service/pbl-service';
import { companyApiService } from '../../api/company';
import { NavigationItem } from '../../router/NavigationList';

interface ListFrameProps {
  navigationItem: NavigationItem;
  activePath: string;
  foldedNavigation?: boolean;
}

const DrawerNavigationListItem: FC<ListFrameProps> = ({
  navigationItem,
  activePath,
  foldedNavigation,
}) => {
  const isActivePath = () =>
    navigationItem.path === activePath ||
    !!navigationItem.children?.find(
      (collapseNavigationTab) => collapseNavigationTab.path === activePath,
    );

  const navigate = useNavigate();
  const [active, setActive] = useState(isActivePath());
  const [fold, setFold] = useState(!isActivePath());
  const [mvno, setMvno] = useState<string | undefined>('');
  const activeClass = useMemo(
    () => (active || !fold ? 'active' : undefined),
    [active, fold],
  );

  useEffect(() => {
    if (foldedNavigation) {
      setFold(true);
    } else if (active) {
      setFold(false);
    }
  }, [foldedNavigation, active]);

  useEffect(() => {
    const isActive = isActivePath();
    setActive(isActive);
    if (foldedNavigation) {
      setFold(true);
    } else {
      setFold(!isActive);
    }
  }, [activePath, navigationItem]);

  useEffect(() => {
    if (foldedNavigation) {
      setFold(true);
    } else {
      setFold(!active);
    }
  }, [active]);

  useEffect(() => {
    fetchMvno();
  }, []);

  const fetchMvno = async () => {
    const companyResponse = await companyApiService.getInfo();
    const companyInfo = companyResponse.data.result;
    const mvno = companyInfo?.plans?.[0];
    setMvno(mvno);
  };

  const onClickListItemHandler = () => {
    pbl('click', 'button', 'here', {
      object: {
        id: navigationItem.id ?? 'orders',
        section: 'GNB',
      },
    });
    if (navigationItem.outlink) {
      window.open(navigationItem.outlink, '_blank_');
    } else if (navigationItem.path && !navigationItem.hasChildren) {
      navigate(navigationItem.path);
    }
    setFold((rs) => !rs);
  };

  const mainNavigationButtonRef = useRef<HTMLDivElement | null>(null);
  return (
    <div style={{ position: 'relative' }}>
      <ListItemButton
        onClick={onClickListItemHandler}
        className={activeClass}
        ref={mainNavigationButtonRef}
      >
        {foldedNavigation ? navigationItem.icon : navigationItem.name}
      </ListItemButton>
      {navigationItem.children.length > 0 &&
        (foldedNavigation ? (
          !fold && (
            <ClickAwayListener
              onClickAway={() => {
                setFold(true);
              }}
            >
              <Menu
                id="folded-sub-navigation-list"
                anchorEl={mainNavigationButtonRef.current}
                open={!fold}
                onClose={() => setFold(true)}
                variant="menu"
                transformOrigin={{
                  vertical: 60,
                  horizontal: -70,
                }}
                sx={{ pointerEvents: 'none' }}
              >
                {navigationItem.children.map(({ name: label, path, id }) => {
                  return (
                    <MenuItem
                      key={label}
                      onClick={() => {
                        pbl('click', 'button', 'here', {
                          object: {
                            id: id ?? 'orders',
                            section: 'GNB',
                          },
                        });
                        path && navigate(path);
                        setFold(true);
                      }}
                      sx={{
                        pointerEvents: 'auto',
                        fontWeight: path === activePath ? 700 : 500,
                      }}
                    >
                      {label}
                    </MenuItem>
                  );
                })}
              </Menu>
            </ClickAwayListener>
          )
        ) : (
          <Collapse in={!fold} sx={{ gap: 4 }}>
            <Divider />
            <List component="div" disablePadding>
              {navigationItem.children.map(
                ({ name: label, path, innerItem, id, divide }) => {
                  const collapseActiveClass =
                    path === activePath ? 'active' : undefined;
                  return (
                    <Fragment key={label}>
                      {divide && <Divider sx={{ my: 1 }} />}
                      <CollapseListItemButtonFrame>
                        <CollapseListItemButton
                          className={collapseActiveClass}
                          onClick={() => {
                            pbl('click', 'button', 'here', {
                              object: {
                                id: id ?? 'orders',
                                section: 'GNB',
                              },
                              eventProperties: {
                                mvno,
                              },
                            });
                            path && navigate(path);
                          }}
                        >
                          {label}
                        </CollapseListItemButton>
                        {innerItem && (
                          <div
                            style={{
                              position: 'absolute',
                              right: 20,
                              top: '50%',
                              transform: 'translate(0, -50%)',
                            }}
                          >
                            {innerItem}
                          </div>
                        )}
                      </CollapseListItemButtonFrame>
                    </Fragment>
                  );
                },
              )}
            </List>
            <Divider />
          </Collapse>
        ))}
      {navigationItem.innerItem && (
        <div
          style={
            foldedNavigation
              ? {
                  position: 'absolute',
                  right: 16,
                  top: 20,
                  transform: 'translate(50%, -50%)',
                }
              : {
                  position: 'absolute',
                  right: 16,
                  top: '50%',
                  transform: 'translate(0, -50%)',
                }
          }
        >
          {navigationItem.innerItem}
        </div>
      )}
    </div>
  );
};

export const DrawerNavigationListItemButton: FC<{
  name: string;
  icon?: ReactNode;
  onClick?: () => void;
  foldedNavigation?: boolean;
}> = ({ name, icon, onClick, foldedNavigation }) => {
  const onClickListItemHandler = () => {
    onClick?.();
  };

  return (
    <Fragment>
      <ListItemButton onClick={onClickListItemHandler}>
        {foldedNavigation ? icon : name}
      </ListItemButton>
    </Fragment>
  );
};

export default DrawerNavigationListItem;

const ListItemButton = styled(MaterialListItemButton)(({ theme }) => ({
  padding: '20px 26px',
  height: '56px',
  '&.active': {
    fontWeight: 'bold',
    borderRight: `3px solid ${theme.palette.text.primary}`,
  },
  '&:hover': {
    fontWeight: 'bold',
    borderRight: `3px solid ${theme.palette.text.secondary}`,
  },
}));

const Collapse = styled(MaterialCollapse)(({ theme }) => ({
  background: theme.palette.background.default,
  '.MuiList-root': {
    padding: 16,
  },
}));

const CollapseListItemButtonFrame = styled(Box)(() => ({
  position: 'relative',
  marginBottom: '4px',
  '&:last-child': {
    marginBottom: 0,
  },
}));

const CollapseListItemButton = styled(ListItemButton)(({ theme }) => ({
  position: 'relative',
  padding: '12px 10px',
  height: '40px',
  borderRadius: '4px',
  '&.active': {
    fontWeight: 'bold',
    background: theme.palette.action.selected,
  },
  '&:hover': {
    fontWeight: 'bold',
    background: theme.palette.action.hover,
  },
}));
