export type Mno = 'LGU' | 'KT' | 'SKT';

export interface MnoTypes {
  key: string;
  value: Mno;
}

export const MnoList: MnoTypes[] = [
  {
    key: 'lg',
    value: 'LGU',
  },
  {
    key: 'kt',
    value: 'KT',
  },
  {
    key: 'skt',
    value: 'SKT',
  },
];
