import { CSSProperties, FC } from 'react';
import { LoadingButton } from '@mui/lab';

interface Props {
  isDone: boolean | undefined;
  isNotNullValidated: boolean;
  onClick: () => void;
  style?: CSSProperties;
}

const CreatePlanButtonStep = (isNotNull: boolean | undefined) => {
  const text = isNotNull ? '생성하기' : '생성하기 (예약 가능)';

  return {
    text,
  };
};

export const CreatePlanButton: FC<Props> = (props) => {
  const { isDone, isNotNullValidated, onClick, style } = props;
  const { text } = CreatePlanButtonStep(isNotNullValidated);
  return (
    <LoadingButton
      onClick={() => onClick()}
      disabled={isNotNullValidated}
      variant={'contained'}
      style={{
        width: '413px',
        maxWidth: '413px',
        margin: '152px auto 108px auto',
        ...style,
      }}
    >
      {text}
    </LoadingButton>
  );
};
