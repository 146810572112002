import { CommonException } from './CommonException';

export function Fallback({ error }: { error: Error }) {
  // TODO: sentry에 렌더링 에러 로그 날리기
  console.error('error', error);
  return (
    <CommonException
      title={'앗! 뭔가 잘못되었어요.'}
      description={'에러가 계속 되면 고객센터에 문의해주세요.'}
      lottieSrc={'/img/lottie/Website-under-construction.json'}
      mainActionTitle={'메인으로'}
      mainAction={() => {
        window.location.href = '/';
      }}
      subActionTitle={'뒤로가기'}
      subAction={() => {
        window.history.go(-1);
      }}
    />
  );
}
