import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  FormControl,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField,
} from '@mui/material';

import { Mvno, mvnoList } from '../../../common/order-util';
import { MobilePlanOrder } from '../../../entity/order';

interface PhoneInfoProps {
  order: MobilePlanOrder;
}

export const customerPhoneNumberErrorMessage = '전화번호를 입력해 주세요.';

export function PhoneInfo({ order }: PhoneInfoProps) {
  const {
    control,
    register,
    watch,
    formState: { errors },
  } = useFormContext<MobilePlanOrder>();
  const watchMno = watch('detail.phoneMno');

  const mvnoSelectList: Array<Mvno> = useMemo(() => {
    if (watchMno && ['SKT-MVNO', 'KT-MVNO', 'LGU-MVNO'].includes(watchMno)) {
      return (
        mvnoList[watchMno].sort((a, b) => {
          const nameA = a.label;
          const nameB = b.label;
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        }) ?? []
      );
    }

    return [];
  }, [watchMno]);

  return (
    <TableRow>
      <TableCell>현재 통신사</TableCell>
      <TableCell>
        <FormControl>
          <Controller
            name="detail.phoneMno"
            control={control}
            defaultValue={order?.detail?.phoneMno ?? ''}
            render={({ field }) => (
              <Select {...field} size="small">
                <MenuItem value="SKT">SKT</MenuItem>
                <MenuItem value="KT">KT</MenuItem>
                <MenuItem value="LGU">LG U+</MenuItem>
                <MenuItem value="SKT-MVNO">SKT 알뜰폰</MenuItem>
                <MenuItem value="KT-MVNO">KT 알뜰폰</MenuItem>
                <MenuItem value="LGU-MVNO">LG U+ 알뜰폰</MenuItem>
              </Select>
            )}
          />
        </FormControl>
        {mvnoSelectList && (
          <FormControl sx={{ ml: 2 }}>
            <Controller
              name="detail.phoneMvno"
              control={control}
              defaultValue={order?.detail?.phoneMvno ?? ''}
              render={({ field }) => (
                <Select {...field} size="small">
                  {mvnoSelectList.map((mvno) => (
                    <MenuItem value={mvno.value} key={mvno.value}>
                      {mvno.label}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        )}
      </TableCell>
      <TableCell>전화번호</TableCell>
      <TableCell>
        <TextField
          {...register('customerPhoneNumber', {
            required: customerPhoneNumberErrorMessage,
          })}
          size="small"
          error={!!errors.customerPhoneNumber}
          helperText={errors.customerPhoneNumber?.message}
        />
      </TableCell>
    </TableRow>
  );
}
