import { CSSProperties, FC } from 'react';
import { LoadingButton } from '@mui/lab';

interface Props {
  isDone: boolean | undefined;
  isValidated: boolean;
  onClick: () => void;
  style?: CSSProperties;
}

const validateButtonStep = (isDone: boolean | undefined) => {
  const text = isDone ? '수정하기' : '다음 단계로';
  const variant: 'contained' | 'outlined' = isDone ? 'outlined' : 'contained';
  return {
    text,
    variant,
  };
};

export const ValidateButton: FC<Props> = (props) => {
  const { isDone, isValidated, onClick, style } = props;
  const { text, variant } = validateButtonStep(isDone);
  return (
    <LoadingButton
      onClick={() => onClick()}
      disabled={!isValidated}
      variant={variant}
      style={{ width: '413px', maxWidth: '413px', margin: '0 auto', ...style }}
    >
      {text}
    </LoadingButton>
  );
};
