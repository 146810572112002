import { useQuery, QueryObserverResult } from '@tanstack/react-query';
import { PlanMetaLogHistory } from '../entity/plan';
import { planApiService } from '../api/plan';

export const usePlanMetaHistory = (
  planId: number,
  page: number = 0,
  size: number = 10,
) => {
  const {
    data,
    refetch,
    isLoading,
  }: {
    data: PlanMetaLogHistory | undefined;
    refetch: () => Promise<
      QueryObserverResult<PlanMetaLogHistory | undefined, Error>
    >;
    isLoading: boolean;
  } = useQuery({
    queryKey: ['get', 'planMetaHistory', planId, page, size],
    queryFn: async () => {
      if (planId === undefined) return;
      const response = await planApiService.getPlanMetaHistory(
        planId,
        page,
        size,
      );
      return response?.data?.result;
    },
    enabled: planId !== undefined,
  });
  return {
    data,
    isLoading,
    refetch,
  };
};
