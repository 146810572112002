import { DataGrid, GridColDef, GridSortModel } from '@mui/x-data-grid';
import { FC } from 'react';
import {
  InternetIptvBundlePlanConsult,
  InternetPlanConsult,
} from '../../../../entity/internet-plan/internet-plan-consult';
import { dateFormatter } from '../../../../utils/gridFormatter';
import * as GridPreset from '../../../../utils/gridPreset';
import { idRenderCell } from '../../../../utils/gridRenderer';
import {
  useSearchPagination,
  useSearchParamsData,
} from '../../../SearchData/hooks';

export const InternetConsultGridColumns = (
  _iptvBundle: boolean,
): GridColDef[] => {
  const url = _iptvBundle
    ? '/internet/benefit/iptv-bundle/consults'
    : '/internet/benefit/consults';

  return [
    {
      ...GridPreset.createdAt_신청일,
      field: 'openedAt',
      valueFormatter: undefined,
    },
    GridPreset.status_상태,
    {
      ...GridPreset.id_상세,
      renderCell: idRenderCell(url, undefined, undefined, 'consultRequestId'),
    },
    { ...GridPreset.customerName_고객명, field: 'applicantName' },
    {
      ...GridPreset.customerPhoneNumber_신청전화번호,
      field: 'applicantPhoneNumber',
    },
    {
      ...GridPreset.internetPlanName_인터넷요금제,
      field: _iptvBundle ? 'internetIptvBundlePlanName' : 'internetPlanName',
    },
    GridPreset.company_인터넷제공자,
    { ...GridPreset.memo_메모, headerName: '상담메모', field: 'latestMemo' },
  ];
};

interface Props {
  iptvBundle: boolean;
  totalCount: number;
  list: (InternetPlanConsult | InternetIptvBundlePlanConsult)[];
}

const InternetConsultTable: FC<Props> = ({ iptvBundle, totalCount, list }) => {
  const { paginationState, paginationHandler } = useSearchPagination();
  const { page, pageSize, sortModel } = paginationState;
  const { setPage, setPageSize, setSortModel } = paginationHandler;
  const { updateSearchParams } = useSearchParamsData();

  const onPageChange = (page: number) => {
    setPage(page);
    updateSearchParams({ page: page.toString() });
  };

  const onPageSizeChange = (pageSize: number) => {
    setPageSize(pageSize);
    updateSearchParams({ pageSize: pageSize.toString() });
  };

  const onSortModelChange = (newModel: GridSortModel) => {
    if (newModel.length <= 0) return;

    let field = newModel[0].field;
    if (field === 'isDuplicate') {
      field = 'duplicate';
    }

    setSortModel(newModel);

    updateSearchParams({
      sortField: field,
      sortDirection: newModel[0].sort?.toString(),
    });
  };

  return (
    <DataGrid
      sx={{
        mt: 3,
      }}
      getRowId={(row) => row.consultRequestId}
      rows={list}
      columns={InternetConsultGridColumns(!!iptvBundle)}
      disableRowSelectionOnClick={true}
      autoHeight
      rowCount={totalCount}
      pagination
      paginationMode="server"
      paginationModel={{ page, pageSize }}
      onPaginationModelChange={(newModel) => {
        onPageChange(newModel.page);
        onPageSizeChange(newModel.pageSize);
      }}
      pageSizeOptions={[10, 25, 50, 100]}
      sortingMode="server"
      sortModel={sortModel}
      onSortModelChange={onSortModelChange}
    />
  );
};

export default InternetConsultTable;
