import { InputAdornment, TextField } from '@mui/material';
import { FC } from 'react';

const TextFieldWithEndAdornment: FC<
  Parameters<typeof TextField>[0] & {
    endAdornment?: string;
  }
> = ({ endAdornment, InputProps, ...textFieldProps }) => {
  return (
    <TextField
      {...textFieldProps}
      InputProps={{
        ...InputProps,
        endAdornment: endAdornment ? (
          <InputAdornment position="end">{endAdornment}</InputAdornment>
        ) : (
          InputProps?.endAdornment
        ),
      }}
    />
  );
};

export default TextFieldWithEndAdornment;
