import { UsimStatus } from '../../../types/mobile-plan-operator-admin-types';

export interface SelectItem {
  label: string;
  value: UsimStatus;
}

export const USIM_SELECT_ITEM_LIST: SelectItem[] = [
  {
    label: '지원 (무료)',
    value: 'FREE',
  },
  {
    label: '지원 (개통시 무료)',
    value: 'FREE_ON_ACTIVATION',
  },
  {
    label: '지원 (유료)',
    value: 'PAID',
  },
  {
    label: '미지원',
    value: 'UNSUPPORTED',
  },
];

export const ESIM_SELECT_ITEM_LIST: SelectItem[] = [
  {
    label: '지원 (무료)',
    value: 'FREE',
  },
  {
    label: '지원 (유료)',
    value: 'PAID',
  },
  {
    label: '미지원',
    value: 'UNSUPPORTED',
  },
];
