import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  TableCell,
  TableRow,
  TextField,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { MobilePlanOrder } from '../../../entity/order';

interface CustomerNameAndRegisterTypeProps {
  order: MobilePlanOrder;
}

const customerNameErrorMessage = '신청자 이름을 입력해 주세요.';
const registerTypeErrorMessage = '가입 유형을 선택해 주세요.';

export function CustomerNameAndRegisterType({
  order,
}: CustomerNameAndRegisterTypeProps) {
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext<MobilePlanOrder>();

  return (
    <TableRow>
      <TableCell>신청자 이름</TableCell>
      <TableCell>
        <TextField
          {...register('customerName', {
            required: customerNameErrorMessage,
          })}
          size="small"
          error={!!errors.customerName}
          helperText={errors.customerName?.message}
          disabled
        />
      </TableCell>
      <TableCell>가입 유형</TableCell>
      <TableCell>
        <FormControl>
          <Controller
            name="registerType"
            rules={{ required: registerTypeErrorMessage }}
            control={control}
            defaultValue={order?.registerType ?? ''}
            render={({ field }) => (
              <RadioGroup {...field} row>
                <FormControlLabel
                  value="new"
                  control={<Radio />}
                  label="신규가입"
                />
                <FormControlLabel
                  value="change"
                  control={<Radio />}
                  label="번호이동"
                />
              </RadioGroup>
            )}
          />
        </FormControl>
        <FormHelperText error={!!errors.registerType}>
          {errors.registerType?.message}
        </FormHelperText>
      </TableCell>
    </TableRow>
  );
}
