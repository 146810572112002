import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { MoyoOnlyApiService } from '../../../../api/moyoOnly';
import { ApiResponse } from '../../../../entity/api';

interface DeleteReserve {
  mvno: string;
  reserveId: number;
}

export const useDeleteReservePlan = () => {
  return useMutation<
    AxiosResponse<ApiResponse>,
    AxiosError<AxiosResponse<void>>,
    DeleteReserve
  >({
    mutationKey: ['delete', 'reserve', 'plan'],
    mutationFn: (parameter) => MoyoOnlyApiService.deleteReservePlan(parameter),
  });
};
