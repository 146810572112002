import { RemoveRedEye } from '@mui/icons-material';
import { Button, Grid, Typography } from '@mui/material';
import { FC } from 'react';
import { comma } from '../../../../hooks/string-util';

interface Props {
  fee: number | undefined;
  isDisabled: boolean;
  onValidationClick: () => void;
}

export const CalculatorFeeButton: FC<Props> = ({
  fee,
  onValidationClick,
  isDisabled,
}) => {
  return (
    <Grid container alignItems={'center'} gap={'10px'} mt={'15px'}>
      <Button size="medium" onClick={onValidationClick} disabled={isDisabled}>
        <Grid display={'flex'} alignItems={'center'} gap={'18px'}>
          <Grid container gap={'8px'}>
            <RemoveRedEye />
            <span>12개월 가격 미리보기</span>
          </Grid>
        </Grid>
      </Button>
      {fee !== 0 && (
        <Typography variant="body2" m={0}>
          {comma(fee)}원
        </Typography>
      )}
    </Grid>
  );
};
