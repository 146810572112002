import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  Snackbar,
  Typography,
} from '@mui/material';
import {
  DataGrid,
  GridColumnVisibilityModel,
  GridRowModel,
  GridRowSelectionModel,
  GridSortModel,
} from '@mui/x-data-grid';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { orderApiService } from '../../api/order';
import { handleApi } from '../../common/http_util';
import { useLoading } from '../../context/LoadingContext';
import { useOptions } from '../../context/OptionsContext';
import { useAuth } from '../../context/UserContext';
import { MobilePlanOrder, OrderStatus } from '../../entity/order';
import { pblAssigneeFilter } from '../../pbl/pbl-common-service/pbl-assignee-filter';
import { pblDatagridCheckbox } from '../../pbl/pbl-common-service/pbl-datagrid-checkbox';
import { pblDatagridPagination } from '../../pbl/pbl-common-service/pbl-datagrid-pagination';
import {
  pblResetSearchOrderFilter,
  pblSearchOrderFilter,
} from '../../pbl/pbl-common-service/pbl-search-order-filter';
import pbl from '../../pbl/pbl-service/pbl-service';
import { OrderListNavigationType } from '../../pbl/pbl-types/pbl-navigation-types';
import SimpleConfirmModal from '../../shared/components/SimpleConfirmModal';
import { stringToBoolean, stringToNumber } from '../../utils/stringUtil';
import { useSelfAssign } from './hooks/useSelfAssign';
import { AutoActivateStates } from '../SearchData/types';
import BulkStatusChangePopup from './BulkStatusChangePopup/BulkStatusChangePopup';
import ExcelDownloadPopup from './ExcelDownloadPopup';
import {
  activateStateColumn,
  moyoActivateStateColumn,
  OrderTableColumns,
} from './GridHeader';
import OrderDataGridToolbar from './OrderDataGridToolbar';
import OrderSearchSection from './OrderSearchSection';
import UinExcelDownloadPopup from './UinplusExcelDownloadPopup ';
import { useRole } from '../../router/useRole';
import { usePermission } from '../../router/usePermission';
import UsimOrDeliveryExcelUploadPopup from './UsimOrDeliveryExcelUploadPopup';

const initialVisibilityModel = {
  createdAt: true,
  status: true,
  id: true,
  customerName: true,
  birthday: true,
  registerType: true,
  customerPhoneNumber: true,
  orderPhoneNumber: true,
  hasUsim: true,
  mno: true,
  planName: true,
  completedAt: true,
  requestedAt: true,
  updatedAt: true,
  isDuplicate: true,
};

const rows: Array<MobilePlanOrder> = [];

type Props = {
  title: string;
  orderTypes?: ('register' | 'outlink_self' | 'self' | 'call' | string)[];
  autoActivateStates?: AutoActivateStates[];
  initStatus?: string;
  showActivateState?: boolean;
  navigation: OrderListNavigationType;
};

const paramsToBoolean = (
  value: string | null | undefined,
): boolean | undefined => {
  if (value == null) return undefined;
  return value === 'true';
};

const paramsToNumber = (
  value: string | null | undefined,
): number | undefined => {
  if (value == null) return undefined;
  return Number(value);
};

export const DEFAULT_DATE_FOR_SEARCH = {
  from: DateTime.now().minus({ month: 1 }).toISODate(),
  to: DateTime.now().toISODate(),
};

export default function OrderListPage({
  title,
  orderTypes,
  initStatus,
  autoActivateStates,
  showActivateState,
  navigation,
}: Props) {
  const { user, getUserMvno } = useAuth();
  const { isSuperAdmin } = useRole();
  const { isExcelPermission } = usePermission();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { setLoading } = useLoading();

  const [orders, setOrders] = useState<Array<MobilePlanOrder>>(rows);
  const [initialized, setInitialized] = useState(false);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(25);
  const [sortModel, setSortModel] = useState<GridSortModel>([]);

  const [dateStandard, setDateStandard] = useState<string>('createdAt');
  const [startDate, setStartDate] = useState<DateTime | undefined | null>(
    DateTime.fromISO(DEFAULT_DATE_FOR_SEARCH.from ?? ''),
  );
  const [endDate, setEndDate] = useState<DateTime | undefined | null>(
    DateTime.fromISO(DEFAULT_DATE_FOR_SEARCH.to ?? ''),
  );
  const [customerName, setCustomerName] = useState<string>('');
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState<string>('');
  const [planName, setPlanName] = useState<string>('');
  const [mno, setMno] = useState<string>('');
  const [status, setStatus] = useState<OrderStatus | null>(null);
  const [registerType, setRegisterType] = useState<string>('');
  const [hasUsim, setHasUsim] = useState<string>('');
  const [isEsim, setIsEsim] = useState<string>();
  const [willPurchaseUsim, setWillPurchaseUsim] = useState<string>();
  const [mvno, setMvno] = useState<string>();
  const [유심도착후_진행되지않은일자, set유심도착후_진행되지않은일자] =
    useState<string>();
  const [isChangePlanRequest, setIsChangePlanRequest] =
    useState<boolean>(false);
  const [birthday, setBirthday] = useState<string>('');
  const [memo, setMemo] = useState<string>();
  const [orderHandlerId, setOrderHandlerId] = useState<number | null>(null);
  const [hasOrderHandler, setHasOrderHandler] = useState<boolean | null>(null);

  const [selectedIds, setSelectedIds] = useState<GridRowSelectionModel>([]);

  const { adminUser, statusList } = useOptions();

  const moyoOnlyColumnVisibilityModel = useMemo(() => {
    return adminUser?.authorizedMvnos?.includes('all') === true;
  }, [adminUser]);

  const filterData = useMemo(() => {
    const startDateParam = searchParams.get('startDate');
    let startDate;
    if (startDateParam != null) {
      startDate = DateTime.fromISO(startDateParam);
    }

    const endDateParam = searchParams.get('endDate');
    let endDate;
    if (endDateParam != null) {
      endDate = DateTime.fromISO(endDateParam);
    }

    return {
      customerName: searchParams.get('customerName'),
      customerPhoneNumber: searchParams
        .get('customerPhoneNumber')
        ?.replaceAll('-', ''),
      planName: searchParams.get('planName'),
      mno: searchParams.get('mno'),
      mvno: searchParams.get('mvno'),
      dateStandard: searchParams.get('dateStandard') ?? 'createdAt',
      orderDateTimeFrom: startDate?.toISODate() ?? DEFAULT_DATE_FOR_SEARCH.from,
      orderDateTimeTo: endDate?.toISODate() ?? DEFAULT_DATE_FOR_SEARCH.to,
      status: initStatus || searchParams.get('status'),
      registerType: searchParams.get('registerType'),
      memo: searchParams.get('memo'),
      orderTypes,
      autoActivateStates,
      hasUsim: searchParams.get('hasUsim'),
      isEsim: searchParams.get('isEsim'),
      willPurchaseUsim: searchParams.get('willPurchaseUsim'),
      birthday: searchParams.get('birthday') || undefined,
      유심도착후_진행되지않은일자:
        searchParams.get('유심도착후_진행되지않은일자'),
      orderHandlerId: paramsToNumber(searchParams.get('orderHandlerId')),
      hasOrderHandler: paramsToBoolean(searchParams.get('hasOrderHandler')),
    };
  }, [searchParams, orderTypes, initStatus, autoActivateStates]);

  const fetchOrders = useCallback(async () => {
    setLoading(true);

    let page = 0;
    const pageParams = parseInt(searchParams.get('page') ?? '');
    if (!isNaN(pageParams)) page = pageParams;

    let pageSize = 25;
    const pageSizeParams = parseInt(searchParams.get('pageSize') ?? '');
    if (!isNaN(pageSizeParams)) pageSize = pageSizeParams;

    const sortField = searchParams.get('sortField') ?? 'createdAt';
    const sortDirection = searchParams.get('sortDirection');
    let sort: 'asc' | 'desc' | null = 'desc';
    if (sortDirection === 'asc' || sortDirection === 'desc') {
      sort = sortDirection;
    }

    const orderList = await handleApi(
      () =>
        orderApiService.getList(
          filterData,
          page,
          pageSize,
          sortField,
          sort ?? undefined,
        ),
      navigate,
    );

    setLoading(false);
    setInitialized(true);

    if (!orderList) return;

    setTotalCount(orderList.totalCount);
    setOrders(orderList.list);
  }, [searchParams, filterData]);

  useEffect(() => {
    return () => {
      setInitialized(false);
    };
  }, [navigation]);

  useEffect(() => {
    if (!initialized) return;
    pbl('pageview', 'none', navigation, {
      eventProperties: {
        total_count: totalCount,
      },
    });
    return () => {
      setInitialized(false);
    };
  }, [initialized]);

  useEffect(() => {
    try {
      let parse = JSON.parse(
        localStorage.getItem('columnVisibilityModel') || '',
      ) as GridColumnVisibilityModel;
      setCurrentGridColumnVisibilityModel(parse);
    } catch (error) {
      console.error('json parse error-orderlist', error);
    }
  }, []);

  useEffect(() => {
    fetchOrders();
  }, [searchParams, orderTypes, initStatus, autoActivateStates]);

  useEffect(() => {
    const startDateParam = searchParams.get('startDate');
    if (startDateParam != null) setStartDate(DateTime.fromISO(startDateParam));

    const endDateParam = searchParams.get('endDate');
    if (endDateParam != null) setEndDate(DateTime.fromISO(endDateParam));

    setDateStandard(searchParams.get('dateStandard') ?? 'createdAt');
    setCustomerName(searchParams.get('customerName') ?? '');
    setCustomerPhoneNumber(
      searchParams.get('customerPhoneNumber')?.replaceAll('-', '') ?? '',
    );
    setPlanName(searchParams.get('planName') ?? '');
    setMno(searchParams.get('mno') ?? '');
    setRegisterType(searchParams.get('registerType') ?? '');
    setHasUsim(searchParams.get('hasUsim') ?? '');
    setIsEsim(searchParams.get('isEsim') ?? undefined);
    setWillPurchaseUsim(searchParams.get('willPurchaseUsim') ?? undefined);
    setMvno(searchParams.get('mvno') ?? '');
    set유심도착후_진행되지않은일자(
      searchParams.get('유심도착후_진행되지않은일자') ?? undefined,
    );
    setBirthday(searchParams.get('birthday') ?? '');
    setMemo(searchParams.get('memo') ?? '');
    setIsChangePlanRequest(searchParams.get('isChangePlanRequest') === 'true');
    setOrderHandlerId(stringToNumber(searchParams.get('orderHandlerId')));
    setHasOrderHandler(stringToBoolean(searchParams.get('hasOrderHandler')));

    const status = searchParams.get('status');
    if (status) {
      const newStatus = statusList.find((s: OrderStatus) => s.key === status);
      if (newStatus) {
        setStatus(newStatus);
      }
    }

    const pageParams = searchParams.get('page');
    const page = parseInt(pageParams ?? '');
    if (!isNaN(page)) {
      setPage(page);
    }

    const pageSizeParams = parseInt(searchParams.get('pageSize') ?? '');
    if (!isNaN(pageSizeParams)) {
      setPageSize(pageSizeParams);
    }

    const sortField = searchParams.get('sortField');
    const sortDirection = searchParams.get('sortDirection');
    if (sortField) {
      let sort: 'asc' | 'desc' | null = null;
      if (sortDirection === 'asc' || sortDirection === 'desc')
        sort = sortDirection;
      setSortModel([{ field: sortField, sort }]);
    }
  }, [searchParams, statusList]);

  const updateSearchParams = (data: {
    [key: string]: string | undefined | null;
  }) => {
    for (const [key, value] of Object.entries(data)) {
      if (value == null) {
        searchParams.delete(key);
      } else {
        searchParams.set(key, value);
      }
    }
    searchParams.set('version', `${Date.now()}`);

    setSearchParams(searchParams);
  };

  const getSearchParamsData = () => {
    return {
      startDate: startDate?.toSQLDate(),
      endDate: endDate?.toSQLDate(),
      dateStandard,
      customerName,
      customerPhoneNumber,
      planName,
      mno,
      status: status?.key,
      registerType,
      memo,
      isChangePlanRequest: isChangePlanRequest?.toString(),
      hasUsim,
      birthday,
      isEsim,
      willPurchaseUsim,
      mvno,
      유심도착후_진행되지않은일자,
      orderHandlerId: orderHandlerId?.toString(),
      hasOrderHandler: hasOrderHandler?.toString(),
    };
  };

  const onClickSearch = () => {
    const searchSectionData = getSearchParamsData();
    updateSearchParams(searchSectionData);
    pblSearchOrderFilter(searchSectionData);
  };

  const onSearch = () => {
    const searchSectionData = getSearchParamsData();
    updateSearchParams(searchSectionData);
  };

  const onClickReset = () => {
    navigate(`${window.location.pathname}?version=${Date.now()}`);
    setDateStandard('createdAt');
    setStartDate(null);
    setEndDate(null);
    setCustomerName('');
    setCustomerPhoneNumber('');
    setPlanName('');
    setMno('');
    setStatus(null);
    setRegisterType('');
    setHasUsim('');
    setIsEsim(undefined);
    setWillPurchaseUsim(undefined);
    setMvno('');
    set유심도착후_진행되지않은일자(undefined);
    setMemo('');
    setBirthday('');
    setIsChangePlanRequest(false);
    setOrderHandlerId(null);
    setHasOrderHandler(null);
    pblResetSearchOrderFilter();
  };

  const onStartDateChange = (date: DateTime | null | undefined) => {
    if (date == null) return;

    setStartDate(date);
  };

  const onEndDateChange = (date: DateTime | null | undefined) => {
    if (date == null) return;
    setEndDate(date);
  };

  const onCustomerPhoneNumberChange = (phoneNumber: string) => {
    let number = phoneNumber.replaceAll('-', '');
    if (number.startsWith('10')) number.replace(/10/g, '010');
    setCustomerPhoneNumber(number);
  };

  const onPageChange = (page: number) => {
    updateSearchParams({ page: page.toString() });
  };

  const onPageSizeChange = (pageSize: number) => {
    updateSearchParams({ pageSize: pageSize.toString() });
  };

  const onIsChangePlanRequestChange = (data: boolean | null) => {
    if (data == null) return;
    setIsChangePlanRequest(data);
  };

  const onSortModelChange = (newModel: GridSortModel) => {
    if (newModel.length <= 0) return;

    let field = newModel[0].field;
    if (field === 'isDuplicate') {
      field = 'duplicate';
    }

    updateSearchParams({
      sortField: field,
      sortDirection: newModel[0].sort?.toString(),
    });
  };

  const [showExcelDownloadPopup, setShowExcelDownloadPopup] = useState(false);
  const [showUinExcelDownloadPopup, setShowUinExcelDownloadPopup] =
    useState(false);

  const onClickExcelDownload = () => {
    setShowExcelDownloadPopup(true);
    pbl('click', 'button', 'here', {
      object: {
        id: 'export_excel',
        section: 'action_menu',
      },
      eventProperties: {
        filterData,
        order_count: totalCount,
      },
    });
  };

  const onClickUinplusExcelDownload = () => {
    setShowUinExcelDownloadPopup(true);
    pbl('click', 'button', 'here', {
      object: {
        id: 'export_excel_for_uinplus',
        section: 'action_menu',
      },
      eventProperties: {
        filterData,
        order_count: totalCount,
      },
    });
  };

  const [
    showUsimOrDeliveryExcelUploadPopup,
    setShowUsimOrDeliveryExcelUploadPopup,
  ] = useState(false);

  const onClickUsimOrDeliveryExcelUpload = () => {
    setShowUsimOrDeliveryExcelUploadPopup(true);
    pbl('click', 'button', 'here', {
      object: {
        id: 'upload_usim_or_delivery_excel',
        section: 'action_menu',
      },
    });
  };

  const showSelectionMenu = useMemo(() => {
    return selectedIds.length > 0;
  }, [selectedIds]);

  const [showBulkStatusChangePopup, setShowBulkStatusChangePopup] =
    useState(false);

  const onClickBulkStatusChange = () => {
    setShowBulkStatusChangePopup(true);
    pbl('click', 'button', 'here', {
      object: {
        id: 'change_bulk_status',
        section: 'action_menu',
      },
      eventProperties: {
        orderCount: selectedIds.length,
      },
    });
  };

  const onBulkChangeFinish = () => {
    setSelectedIds([]);
    fetchOrders();
  };

  const [promiseArguments, setPromiseArguments] = useState<any>(null);

  const processMemoUpdate = (newRow: GridRowModel, oldRow: GridRowModel) =>
    new Promise<GridRowModel>((resolve, reject) => {
      if (newRow.memo !== oldRow.memo) {
        setPromiseArguments({ resolve, reject, newRow, oldRow });
      } else {
        resolve(oldRow);
      }
    });

  const handleMemoUpdateError = (error: Error) => {
    console.error(error);
  };

  const renderConfirmDialog = () => {
    if (!promiseArguments) {
      return null;
    }

    return (
      <Dialog open={!!promiseArguments}>
        <DialogTitle>메모 추가</DialogTitle>
        <DialogContent>메모를 추가하시겠습니까?</DialogContent>
        <DialogActions>
          <Button onClick={onEditMemoCancel}>취소</Button>
          <Button onClick={onEditMemoConfirm}>추가하기</Button>
        </DialogActions>
      </Dialog>
    );
  };

  const onEditMemoCancel = () => {
    const { newRow, resolve } = promiseArguments;
    resolve(newRow);
    setPromiseArguments(null);
  };

  const onEditMemoConfirm = async () => {
    const { newRow, oldRow, reject, resolve } = promiseArguments;

    try {
      setLoading(true);

      const orders = await handleApi(
        () =>
          orderApiService.updateOrderStatus(
            [newRow.id],
            undefined,
            undefined,
            newRow.memo.trim(),
          ),
        navigate,
        (err) => {
          alert(err);
        },
      );

      if (orders != null && orders.totalCount === 1) {
        alert('메모가 추가되었습니다.');
        resolve(orders.list[0]);
      } else {
        reject(oldRow);
      }
    } catch (err) {
      console.log(err);
      reject(oldRow);
    } finally {
      setPromiseArguments(null);
      setLoading(false);
    }
  };

  const [
    currentGridColumnVisibilityModel,
    setCurrentGridColumnVisibilityModel,
  ] = useState<GridColumnVisibilityModel | undefined>(undefined);

  const onColumnVisibilityChangeModel = (params: GridColumnVisibilityModel) => {
    if (Object.keys(params).length === 0) {
      setCurrentGridColumnVisibilityModel((s) => ({}));
    } else {
      setCurrentGridColumnVisibilityModel((s) => ({
        ...(s ? s : initialVisibilityModel),
        ...params,
      }));
    }
  };

  useEffect(() => {
    try {
      localStorage.setItem(
        'columnVisibilityModel',
        JSON.stringify(
          currentGridColumnVisibilityModel || initialVisibilityModel,
        ),
      );
    } catch (error) {
      console.log('storage error-orderlist', error);
    }
  }, [currentGridColumnVisibilityModel]);

  /**
   * NOTE: 나에게 할당하기
   */
  const {
    handleSelfAssign,
    showOverWriteModal,
    showHandlAssignToast,
    closeOverWriteModal,
    closeHandleAssignToast,
  } = useSelfAssign();

  const orderIds = selectedIds.map((id) => Number(id));

  const [selectedValue, setSelectedValue] = useState('null');

  const handleSelectedValue = (selectedOrderHandler: string) => {
    const value = selectedOrderHandler === 'null' ? null : selectedOrderHandler;
    handleSelfAssignType(value);
    setSelectedValue(selectedOrderHandler);
  };

  const handleConfirmSelfAssign = () => {
    pbl('click', 'modal_button', 'here', {
      object: {
        id: 'assign_handler_change',
        section: 'action_menu',
      },
      eventProperties: {
        orderCount: orderIds.length,
      },
    });
    handleSelfAssign.mutate({
      orderIds,
      allowOverwrite: true,
    });
    closeOverWriteModal();
  };

  const handleCancelSelfAssign = () => {
    pbl('click', 'modal_button', 'here', {
      object: {
        id: 'assign_handler_change_cancel',
        section: 'action_menu',
      },
      eventProperties: {
        orderCount: orderIds.length,
      },
    });
    closeOverWriteModal();
  };

  const handleSelfAssignType = (hasOrderHandler: string | null) => {
    const orderHandlerId =
      hasOrderHandler === 'true' ? user?.id.toString() : null;
    setHasOrderHandler(stringToBoolean(hasOrderHandler));
    setOrderHandlerId(stringToNumber(orderHandlerId));
    const searchSectionData = getSearchParamsData();
    updateSearchParams({
      ...searchSectionData,
      orderHandlerId,
      hasOrderHandler,
    });
  };

  const handleClickSelfAssign = () => {
    pbl('click', 'button', 'here', {
      object: {
        id: 'assign_handler',
        section: 'action_menu',
      },
      eventProperties: {
        orderCount: orderIds.length,
      },
    });
    handleSelfAssign.mutate(
      {
        orderIds,
        allowOverwrite: false,
      },
      {
        onSuccess: () => {
          // TODO: useQuery의 invalidateQueries를 사용해서 새로고침을 하도록 변경
          onSearch();
        },
      },
    );
  };

  const gridColumns = showActivateState
    ? moyoOnlyColumnVisibilityModel
      ? moyoActivateStateColumn
      : activateStateColumn
    : OrderTableColumns;

  const selectedOrders = useMemo(
    () => orders.filter((order) => selectedIds.includes(order.id)),
    [orders, selectedIds],
  );

  const userMvno = useMemo(() => getUserMvno(), [getUserMvno]);

  return (
    <>
      <Container sx={{ display: 'grid', mt: 3 }} maxWidth="xl">
        <Typography variant="h5" fontWeight={'bold'}>
          {title}
        </Typography>
        <OrderSearchSection
          dateStandard={dateStandard}
          onDateStandardChanged={setDateStandard}
          startDate={startDate}
          onStartDateChanged={onStartDateChange}
          endDate={endDate}
          onEndDateChanged={onEndDateChange}
          customerName={customerName}
          onCustomerNameChanged={setCustomerName}
          customerPhoneNumber={customerPhoneNumber}
          onCustomerPhoneNumberChanged={onCustomerPhoneNumberChange}
          planName={planName}
          onPlanNameChanged={setPlanName}
          mno={mno}
          onMnoChanged={setMno}
          mvno={mvno ?? userMvno}
          onMvnoChanged={setMvno}
          status={status}
          onStatusChanged={setStatus}
          statusList={statusList.filter((s) =>
            orderTypes?.includes(s.orderType),
          )}
          registerType={registerType}
          onRegisterTypeChanged={setRegisterType}
          birthday={birthday}
          onBirthdayChanged={setBirthday}
          onChange유심도착후_진행되지않은일자={set유심도착후_진행되지않은일자}
          유심도착후_진행되지않은일자={유심도착후_진행되지않은일자}
          hasUsim={hasUsim}
          isEsim={isEsim}
          willPurchaseUsim={willPurchaseUsim}
          onHasUsimChanged={(value) => {
            console.log('value :', value);
            if (value === 'eSIM') {
              setIsEsim('true');
              setWillPurchaseUsim(undefined);
              setHasUsim('');
            } else if (value === 'willPurchaseUsim') {
              setIsEsim(undefined);
              setWillPurchaseUsim('true');
              setHasUsim('');
            } else if (value === 'true') {
              setIsEsim(undefined);
              setWillPurchaseUsim(undefined);
              setHasUsim('true');
            } else if (value === 'false') {
              setIsEsim('false');
              setWillPurchaseUsim('false');
              setHasUsim('false');
            } else {
              setIsEsim(undefined);
              setWillPurchaseUsim(undefined);
              setHasUsim('');
            }
          }}
          memo={memo}
          isChangePlanRequest={isChangePlanRequest}
          onMemoChanged={setMemo}
          onSearch={onClickSearch}
          onIsChangePlanRequestChange={onIsChangePlanRequestChange}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mt: 2,
            mb: 1,
          }}
        >
          <Button onClick={onClickSearch} variant="contained" sx={{ mr: 1 }}>
            검색
          </Button>
          <Button onClick={onClickReset} variant="outlined">
            검색 초기화
          </Button>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h5" mt={3}>
            온라인 가입 신청서
          </Typography>
          <Box
            sx={{
              display: 'inline-block',
            }}
          >
            {showSelectionMenu ? (
              <ButtonGroup size="small" variant="outlined">
                <Button
                  size="small"
                  variant="outlined"
                  onClick={handleClickSelfAssign}
                >
                  나에게 할당하기
                </Button>
                <Button onClick={onClickBulkStatusChange}>
                  상태값 일괄 변경하기
                </Button>
              </ButtonGroup>
            ) : (
              <>
                {isSuperAdmin && (
                  <Button onClick={onClickUinplusExcelDownload}>
                    유인플러스용 엑셀 추출하기
                  </Button>
                )}
                {isExcelPermission && (
                  <Button onClick={onClickExcelDownload}>
                    엑셀로 추출하기
                  </Button>
                )}
                {isExcelPermission && (
                  <Button onClick={onClickUsimOrDeliveryExcelUpload}>
                    유심 정보/택배 송장 업로드하기
                  </Button>
                )}
              </>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'start',
          }}
        >
          <Select
            size="small"
            value={selectedValue}
            displayEmpty={false}
            onChange={(event) => {
              const value = event.target.value;
              handleSelectedValue(value);
              pblAssigneeFilter(value);
            }}
          >
            <MenuItem value="null">전체</MenuItem>
            <MenuItem value="false">담당자 없는 건</MenuItem>
            <MenuItem value="true">나에게 배정된 건</MenuItem>
          </Select>
          <Typography variant="subtitle2" mt={1}>
            총 {totalCount}건
          </Typography>
        </Box>
        <DataGrid
          key={`${showActivateState}-${moyoOnlyColumnVisibilityModel}`}
          localeText={{
            toolbarColumns: '열 수정하기',
            toolbarFilters: '필터',
          }}
          slots={{ toolbar: OrderDataGridToolbar }}
          sx={{
            mt: 3,
            '& .row-duplicate': {
              bgcolor: '#fff5f5',
            },
            '& .MuiDataGrid-columnSeparator': {
              visibility: 'visible',
            },
          }}
          rows={orders}
          columns={gridColumns}
          autoHeight
          rowCount={totalCount}
          pagination
          paginationMode="server"
          paginationModel={{ page, pageSize }}
          onPaginationModelChange={(newModel) => {
            pblDatagridPagination(newModel, page, pageSize, {
              object: {
                section: 'order_list',
              },
            });
            onPageChange(newModel.page);
            onPageSizeChange(newModel.pageSize);
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={onSortModelChange}
          checkboxSelection
          onRowSelectionModelChange={(newSelectionModel) => {
            pblDatagridCheckbox(selectedIds, newSelectionModel, {
              object: {
                section: 'order_list',
              },
            });
            setSelectedIds(newSelectionModel);
          }}
          rowSelectionModel={selectedIds}
          processRowUpdate={processMemoUpdate}
          onProcessRowUpdateError={handleMemoUpdateError}
          initialState={{
            columns: {
              columnVisibilityModel: {
                신청일: true,
              },
            },
          }}
          onColumnVisibilityModelChange={onColumnVisibilityChangeModel}
          columnVisibilityModel={currentGridColumnVisibilityModel}
        />
      </Container>
      <UsimOrDeliveryExcelUploadPopup
        open={showUsimOrDeliveryExcelUploadPopup}
        onClose={() => setShowUsimOrDeliveryExcelUploadPopup(false)}
        onSuccess={() => fetchOrders()}
      />
      <BulkStatusChangePopup
        mvno={mvno ?? ''}
        user={user?.username}
        orders={selectedOrders}
        open={showBulkStatusChangePopup}
        onClose={() => setShowBulkStatusChangePopup(false)}
        onSuccess={onBulkChangeFinish}
      />
      <ExcelDownloadPopup
        filterData={filterData}
        open={showExcelDownloadPopup}
        onClose={() => setShowExcelDownloadPopup(false)}
        totalCount={totalCount}
      />
      {isSuperAdmin && (
        //유인플러스 엑셀 다운로드 팝업
        <UinExcelDownloadPopup
          filterData={filterData}
          open={showUinExcelDownloadPopup}
          onClose={() => {
            setShowUinExcelDownloadPopup(false);
          }}
        />
      )}
      {renderConfirmDialog()}
      <SimpleConfirmModal
        show={showOverWriteModal}
        onImpression={() => {
          pbl('impression', 'modal', 'here', {
            object: {
              id: 'assign_handler_change',
              section: 'action_menu',
            },
            eventProperties: {
              orderCount: orderIds.length,
            },
          });
        }}
        title="이미 배정된 담당자가 있어요"
        description={`확인을 누르시면 ${
          user?.username ?? '담당자'
        }님으로 담당자가 변경돼요.`}
        cancelText="취소"
        onHide={handleCancelSelfAssign}
        onConfirm={handleConfirmSelfAssign}
      />
      <Snackbar
        open={showHandlAssignToast}
        autoHideDuration={5000}
        onClose={closeHandleAssignToast}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          severity="success"
          variant="filled"
          onClose={closeHandleAssignToast}
        >
          {selectedIds.length}건이 나에게 배정되었어요.
        </Alert>
      </Snackbar>
    </>
  );
}
