import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import MvnoInfoItem from '../Common/MvnoInfoItem';
import type {
  MnoOperationPolicyByMoyoList,
  OperationPolicy,
} from '../../types/mobile-plan-operator-admin-types';
import { MvnoInfoItemBody, MvnoInfoItemRow } from '../Common/MvnoInfoItemFrame';
import { Mno } from '../../../../entity/mno';
import { convertStringToMno } from '../../../../common/order-util';
import { comma } from '../../../../common/string_util';

const MvnoInfoOperationPolicyDetailSection: FC<{
  mno: Mno;
  mnoOperationPolicyByMoyo: OperationPolicy;
}> = ({ mno, mnoOperationPolicyByMoyo }) => {
  const title = convertStringToMno(mno);

  return (
    <MvnoInfoItemBody gap={3}>
      <Box>
        <Typography variant="body1" color="text.primary" fontWeight={700}>
          {title}망
        </Typography>
      </Box>
      <MvnoInfoItemRow gap={3}>
        <MvnoInfoItem
          fallback={{
            isActive: mnoOperationPolicyByMoyo.postActivation == null,
            text: '입력 필요',
            color: 'error',
          }}
          title="신규가입 개통 방식"
          content={
            mnoOperationPolicyByMoyo.postActivation ? '후개통' : '선개통'
          }
        />
        <MvnoInfoItem
          fallback={{
            isActive: mnoOperationPolicyByMoyo.dailyDiscount == null,
            text: '입력 필요',
            color: 'error',
          }}
          title="요금 할인기간 계산 방법"
          content={mnoOperationPolicyByMoyo.dailyDiscount ? '일할' : '월할'}
        />
        <MvnoInfoItem
          fallback={{
            isActive: mnoOperationPolicyByMoyo.allowPaymentByOther == null,
            text: '입력 필요',
            color: 'error',
          }}
          title="타인납부"
          content={
            mnoOperationPolicyByMoyo.allowPaymentByOther ? '지원' : '미지원'
          }
        />
      </MvnoInfoItemRow>
      <MvnoInfoItemRow gap={3}>
        <MvnoInfoItem
          fallback={{
            isActive:
              !mnoOperationPolicyByMoyo.registerChangeFee ||
              mnoOperationPolicyByMoyo.registerChangeFee === 0,
            text: '없음',
          }}
          title="번호이동 수수료"
          content={`${comma(mnoOperationPolicyByMoyo.registerChangeFee)}원`}
        />
        <MvnoInfoItem
          fallback={{
            isActive:
              !mnoOperationPolicyByMoyo.billingDateList?.card.billingDay,
            text: '입력 필요',
            color: 'error',
          }}
          title="카드 결제일"
          content={
            mnoOperationPolicyByMoyo.billingDateList?.card.billingDay === 99
              ? '말일'
              : `매월 ${mnoOperationPolicyByMoyo.billingDateList?.card.billingDay}일`
          }
        />
        <MvnoInfoItem
          fallback={{
            isActive:
              !mnoOperationPolicyByMoyo.billingDateList?.account.billingDay,
            text: '입력 필요',
            color: 'error',
          }}
          title="자동이체 결제일"
          content={
            mnoOperationPolicyByMoyo.billingDateList?.account.billingDay === 99
              ? '말일'
              : `매월 ${mnoOperationPolicyByMoyo.billingDateList?.account.billingDay}일`
          }
        />
      </MvnoInfoItemRow>
    </MvnoInfoItemBody>
  );
};

const MvnoInfoOperationPolicyByMoyoDetail: FC<MnoOperationPolicyByMoyoList> = (
  mnoOperationPolicyByMoyoList,
) => {
  return (
    <MvnoInfoItemBody gap={6}>
      {Object.entries(mnoOperationPolicyByMoyoList).map(
        ([key, mnoOperationPolicyByMoyo]) => (
          <Box key={key}>
            <MvnoInfoOperationPolicyDetailSection
              key={key}
              mno={key as Mno}
              mnoOperationPolicyByMoyo={mnoOperationPolicyByMoyo}
            />
          </Box>
        ),
      )}
    </MvnoInfoItemBody>
  );
};

export default MvnoInfoOperationPolicyByMoyoDetail;
