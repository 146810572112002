export const isNumber = (value?: string | number | null) =>
  typeof value === 'number' || /^\d+$/.test(value || '');

export function assert(
  condition: unknown,
  error: Error | string = new Error(),
): asserts condition {
  if (!condition) {
    if (typeof error === 'string') {
      throw new Error(error);
    } else {
      throw error;
    }
  }
}
