import { Alert, Typography } from '@mui/material';
import { FC } from 'react';

export const DangerCallout: FC = () => {
  return (
    <Alert
      severity="error"
      style={{
        alignItems: 'center',
        background: '#D32F2F',
        color: '#fff',
        marginTop: '15px',
      }}
      sx={{
        '.css-1ytlwq5-MuiAlert-icon': {
          color: '#fff',
        },
      }}
    >
      <Typography marginLeft={'15px'} fontSize={14} fontWeight={400}>
        <li>가격이 모요Only 기준에 맞지 않아요 </li>
      </Typography>
    </Alert>
  );
};
