import styled from '@emotion/styled';
import { ListItemButton as MaterialListItemButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Button = styled(MaterialListItemButton)(() => ({
  padding: '20px 26px',
  height: '56px',
  fontWeight: 'bold',
}));

export default function PrivacyPolicyButton() {
  const navigate = useNavigate();
  return (
    <Button
      onClick={() => {
        navigate('/privacy-policy');
      }}
    >
      개인정보처리방침
    </Button>
  );
}
