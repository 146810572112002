import { Container, Typography } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import internetCommonConsultApiService from '../../../../api/internet-consult';
import { handleApiV2 } from '../../../../common/http_util';
import { useLoading } from '../../../../context/LoadingContext';
import {
  GetInternetIptvBundlePlanConsultsResponse,
  GetInternetPlanConsultsResponse,
} from '../../../../entity/internet-plan/response/internet-plan-consults-response';
import {
  useSearchPagination,
  useSearchParamsData,
} from '../../../SearchData/hooks';
import useInternetConsultSearch from '../../hooks/useInternetConsultSearch';
import InternetConsultTable from '../InternetConsultListTable/InternetConsultListTable';
import InternetConsultTableTab from '../InternetConsultListTable/InternetConsultListTableTab';
import InternetConsultListSearch from './InternetConsultListSearch';

const InternetConsultList: FC = () => {
  const navigate = useNavigate();
  const { paginationHandler } = useSearchPagination();
  const { filterData } = useSearchParamsData();
  const {
    searchHandler,
    searchState,
    searchParams,
    handleClickReset,
    handleClickSearch,
  } = useInternetConsultSearch();
  const { setLoading } = useLoading();
  const [consultsResponse, setConsultsResponse] =
    useState<GetInternetPlanConsultsResponse>({
      totalCount: 0,
      items: [],
    });
  const [iptvBundleConsultsResponse, setIptvBundleConsultsResponse] =
    useState<GetInternetIptvBundlePlanConsultsResponse>({
      totalCount: 0,
      items: [],
    });

  const consultList = useMemo(
    () =>
      searchState.iptvBundle
        ? iptvBundleConsultsResponse.items
        : consultsResponse.items,
    [searchState.iptvBundle, iptvBundleConsultsResponse, consultsResponse],
  );
  const totalCount = useMemo(
    () =>
      searchState.iptvBundle
        ? iptvBundleConsultsResponse.totalCount
        : consultsResponse.totalCount,
    [searchState.iptvBundle, iptvBundleConsultsResponse, consultsResponse],
  );

  useEffect(() => {
    searchHandler.setSearchStateWithParams(searchParams);
  }, []);

  useEffect(() => {
    fetchOrders();
  }, [searchParams]);

  const fetchOrders = async () => {
    try {
      setLoading(true);
      const { page, pageSize, sortField, sort } =
        paginationHandler.getPaginationDataWithParams(searchParams);
      const {
        dateStandard,
        orderDateTimeFrom,
        orderDateTimeTo,
        internetStatus,
        customerName,
        customerPhoneNumber,
      } = filterData;

      const data = [
        dateStandard,
        orderDateTimeFrom?.toString(),
        orderDateTimeTo?.toString(),
        internetStatus,
        customerName,
        customerPhoneNumber,
        undefined,
        page + 1,
        pageSize,
      ] as const;

      const consultsPromise = handleApiV2(
        () =>
          internetCommonConsultApiService
            .iptvBundle(false)
            .getConsults(...data),
        navigate,
      );

      const iptvBundleConsultsPromise = handleApiV2(
        () =>
          internetCommonConsultApiService.iptvBundle(true).getConsults(...data),
        navigate,
      );

      const [_consultsResponse, _iptvBundleConsultsResponse] =
        await Promise.all([consultsPromise, iptvBundleConsultsPromise]);

      _consultsResponse && setConsultsResponse(_consultsResponse);
      _iptvBundleConsultsResponse &&
        setIptvBundleConsultsResponse(_iptvBundleConsultsResponse);
    } catch (_) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container sx={{ display: 'grid', mt: 3 }} maxWidth="xl">
      <Typography variant="h5" fontWeight={'bold'}>
        인터넷 정찰제 접수
      </Typography>
      <InternetConsultListSearch
        onClickSearch={handleClickSearch}
        onClickReset={handleClickReset}
      />
      <InternetConsultTableTab
        internetCount={consultsResponse.totalCount}
        internetIptvCount={iptvBundleConsultsResponse.totalCount}
      />
      <InternetConsultTable
        iptvBundle={!!searchState.iptvBundle}
        totalCount={totalCount}
        list={consultList}
      />
    </Container>
  );
};

export default InternetConsultList;
