const jwtParse = (jwt: string): { [key: string]: unknown } => {
  const payload = jwt.split('.')[1];
  if (payload == null) {
    return {};
  }

  const base64 = payload.replace(/-/g, '+').replace(/_/g, '/');
  return JSON.parse(decodeURIComponent(escape(atob(base64)))) as {
    [key: string]: unknown;
  };
};

export default jwtParse;
