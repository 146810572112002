import React, { ChangeEvent, FC, useState } from 'react';
import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { CreateMoyoPlanTypes } from '../type';

interface Props {
  isDone: boolean;
}

export const PlanDataInputs: FC<Props> = ({ isDone }) => {
  const [isUnlimitedCall, setIsUnlimitedCall] = useState(false);
  const [isUnlimitedText, setIsUnlimitedText] = useState(false);
  const { control, setValue, watch } = useFormContext<CreateMoyoPlanTypes>();

  const UnlimitedValue = 9999;
  return (
    <FormControl fullWidth sx={{ gap: '12px' }}>
      <>
        <Typography variant="subtitle1" m={0}>
          요금제 스펙
        </Typography>
        <Grid container gap={'32px'}>
          <Grid>
            <Typography component={'span'} variant="body1" m={0}>
              통화 무제한
            </Typography>
            <Controller
              control={control}
              name="call"
              render={({ field }) => (
                <Switch
                  {...field}
                  checked={isUnlimitedCall}
                  disabled={isDone}
                  onChange={(event) => {
                    setValue('call', 9999);
                    setIsUnlimitedCall(event.target.checked);
                  }}
                />
              )}
            />
          </Grid>
          <Grid>
            <Typography component={'span'} variant="body1" m={0}>
              문자 무제한
            </Typography>
            <Controller
              control={control}
              name="textMessage"
              render={({ field }) => (
                <Switch
                  {...field}
                  checked={isUnlimitedText}
                  disabled={isDone}
                  onChange={(event) => {
                    setValue('textMessage', 9999);
                    setIsUnlimitedText(event.target.checked);
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
        <Controller
          control={control}
          name="call"
          render={({ field }) => (
            <TextField
              disabled={isUnlimitedCall || isDone}
              InputLabelProps={{
                shrink:
                  !Number.isNaN(watch('call')) && watch('call') !== null
                    ? true
                    : false,
              }}
              required
              {...field}
              type="number"
              onWheel={(event) => (event.target as HTMLElement).blur()}
              fullWidth
              label="통화(분)"
              value={isUnlimitedCall ? UnlimitedValue : field.value}
              onChange={(event) => field.onChange(parseInt(event.target.value))}
            />
          )}
        />
        <Controller
          control={control}
          name="textMessage"
          render={({ field }) => (
            <TextField
              disabled={isUnlimitedText || isDone}
              InputLabelProps={{
                shrink:
                  !Number.isNaN(watch('textMessage')) &&
                  watch('textMessage') !== null
                    ? true
                    : false,
              }}
              required
              {...field}
              onWheel={(event) => (event.target as HTMLElement).blur()}
              type="number"
              fullWidth
              label="문자(건)"
              value={isUnlimitedText ? UnlimitedValue : field.value}
              onChange={(event) => field.onChange(parseInt(event.target.value))}
            />
          )}
        />
        <Controller
          control={control}
          name="dataSpeed"
          render={({ field }) => (
            <TextField
              {...field}
              onWheel={(event) => (event.target as HTMLElement).blur()}
              type="number"
              fullWidth
              disabled={isDone}
              label="데이터(GB)"
              onChange={(event) => field.onChange(parseInt(event.target.value))}
            />
          )}
        />
        <Controller
          control={control}
          name="dataSpeed2"
          render={({ field }) => (
            <TextField
              {...field}
              onWheel={(event) => (event.target as HTMLElement).blur()}
              type="number"
              fullWidth
              disabled={isDone}
              label="일 추가 데이터(GB)"
              onChange={(event) => field.onChange(parseInt(event.target.value))}
            />
          )}
        />
        <Controller
          control={control}
          name="throttledSpeed"
          render={({ field }) => (
            <TextField
              {...field}
              onWheel={(event) => (event.target as HTMLElement).blur()}
              type="number"
              fullWidth
              disabled={isDone}
              label="소진시 속도(Mbps)"
              onChange={(event) => {
                field.onChange(parseInt(event.target.value));
              }}
            />
          )}
        />
      </>
    </FormControl>
  );
};
