import {
  Button,
  colors,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useCallback, useEffect } from 'react';
import pbl from '../../../pbl/pbl-service/pbl-service';

type PopupProps = {
  logParams: {
    stageName: string;
    mvno: string;
    user: string;
    partners_activation_stage: string;
  };
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
};

export function BuyingUsimPopup({
  logParams,
  open,
  onClose,
  onSubmit,
}: PopupProps) {
  useEffect(() => {
    if (open) {
      pbl('impression', 'modal', 'here', {
        object: {
          id: 'dont_do_partners_activation_popup',
          section: 'partners_activation',
        },
        eventProperties: {
          ...logParams,
        },
      });
    }
  }, [logParams, open]);

  const handleForceSubmitClick = useCallback(async () => {
    pbl('click', 'modal_button', 'here', {
      object: {
        id: 'dont_do_partners_activation_popup_force_submit',
        section: 'partners_activation',
      },
      eventProperties: { ...logParams },
    });

    await onSubmit();
    onClose();
  }, [logParams, onSubmit, onClose]);
  const handleCancelClick = useCallback(async () => {
    pbl('click', 'modal_button', 'here', {
      object: {
        id: 'dont_do_partners_activation_popup_cancel',
        section: 'partners_activation',
      },
      eventProperties: { ...logParams },
    });
    onClose();
  }, [logParams, onClose]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        이 주문은 상담사분들의 작업을
        <br />
        권장하지 않아요
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: '16px' }}>
          유심 구매 중인 주문이에요. 고객님이 유심을 사오신 후 개통을 요청하면 -
          {'>'} 모요 시스템이 자동처리 해드려요.
          <br />
          <br />
          유심 구매 예정에 대해{' '}
          <a
            href="https://moyoplan.notion.site/9853780ad0cf43809c3b973d8c1d8ab4?pvs=4"
            target="_blank"
            rel="noreferrer"
          >
            더 알아보기
          </a>
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ marginBottom: '12px', marginRight: '24px' }}>
        <Button
          color="error"
          onClick={handleForceSubmitClick}
          style={{ color: colors.grey[400] }}
        >
          그래도 진행할래요
        </Button>
        <Button onClick={handleCancelClick}>알겠어요</Button>
      </DialogActions>
    </Dialog>
  );
}
