import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useCallback, useEffect, useMemo } from 'react';
import { API_ERROR_CODE } from '../../../common/api';
import { PARTNERS_ADMIN_API_ERROR_CODE } from '../../../entity/api';
import pbl from '../../../pbl/pbl-service/pbl-service';

type PopupProps = {
  logParams: {
    stageName: string;
    mvno: string;
    user: string;
    partners_activation_stage: string;
    error?: string;
    section: string;
  };
  open: boolean;
  onClose: () => void;
  errorMessage: string;
  errorCode?: PARTNERS_ADMIN_API_ERROR_CODE;
};

export function CannotSendToMnoPopup({
  logParams,
  open,
  onClose,
  errorMessage,
  errorCode,
}: PopupProps) {
  useEffect(() => {
    if (open) {
      const { section, ...params } = logParams;
      pbl('impression', 'modal', 'here', {
        object: {
          id: 'cannot_send_to_mno_popup',
          section,
        },
        eventProperties: {
          ...params,
          errorCode,
        },
      });
    }
  }, [logParams, open]);

  const handleCancelClick = useCallback(async () => {
    pbl('click', 'modal_button', 'here', {
      object: {
        id: 'cannot_send_to_mno_popup_cancel',
        section: 'partners_activation',
      },
      eventProperties: { ...logParams, errorCode },
    });
    onClose();
  }, [logParams, onClose]);

  const contents = useMemo(() => {
    switch (errorCode) {
      case API_ERROR_CODE.LG_MNO_SERVER_ERROR:
        return <></>;
      default:
        return (
          <>
            사유 : {errorMessage}
            <br />
            <br />
            MNO 서버 오류라 모요도 해결이 불가능해요.
            <br />
            MNO 서버가 정상적으로 돌아올 때까지 기다렸다가
            <br />
            재시도해주세요.
          </>
        );
    }
  }, [errorCode, errorMessage]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>전산에 보낼 수 없어요</DialogTitle>
      <DialogContent>
        <DialogContentText
          sx={{ mb: '16px' }}
          style={{ overflowWrap: 'anywhere' }}
        >
          {contents}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ marginBottom: '12px', marginRight: '24px' }}>
        <Button onClick={handleCancelClick}>확인</Button>
      </DialogActions>
    </Dialog>
  );
}
