import { Button } from '@mui/material';
import { FC, ReactNode } from 'react';
import SwitchEditableMvnoInfoHeader from './SwitchEditableMvnoInfoHeader';

interface Props {
  title: string;
  subTitle?: string;
  children: ReactNode;
  enableEdit?: boolean;
  onClickEdit?: () => void;
}

const SwitchEditableMvnoInfoDetail: FC<Props> = ({
  title,
  subTitle,
  children,
  enableEdit,
  onClickEdit,
}) => {
  const onClickEditHandler = () => {
    onClickEdit?.();
  };

  return (
    <>
      <SwitchEditableMvnoInfoHeader
        title={title}
        subTitle={subTitle}
        actionArea={
          enableEdit ? (
            <Button
              variant="outlined"
              color="inherit"
              onClick={onClickEditHandler}
            >
              수정하기
            </Button>
          ) : undefined
        }
      />
      {children}
    </>
  );
};

export default SwitchEditableMvnoInfoDetail;
