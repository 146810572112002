import axios from 'axios';
import { s3api } from '../common/http_util';
import { ApiResponse } from '../entity/api';
import { env } from '../utils/env';

const fileService = {
  // 사용 안하고 있음
  upload: (file: File, path: string) => {
    return fileService.uploads([file], path);
  },

  uploads: (files: File[], path: string) => {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('files', file);
    });
    formData.append('path', path);

    return s3api.post<ApiResponse<{ paths: string[] }>>('/image', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  getImageUrl: (cloudeFrontPath: string) => {
    const { pathname } = new URL(cloudeFrontPath);
    return `${env('REACT_APP_API_URL')}/api/v1/moyo/aws/s3/image${pathname}`;
  },

  getFileNameFromUUIDPath: (path: string) => {
    const splitedFileUrl = path.split('/');
    const splitUuid = splitedFileUrl[splitedFileUrl.length - 1].split(
      /[a-z,0-9]{8}-[a-z,0-9]{4}-[a-z,0-9]{4}-[a-z,0-9]{4}-[a-z,0-9]{12}/,
    );
    const fileName = splitUuid?.[1];
    return fileName || splitedFileUrl[splitedFileUrl.length - 1];
  },

  download: async (link: string) => {
    const response = await axios.get(link);
    const blob = response.data.blob();
    const type = response.headers['content-type'];
    const fileBlob = new Blob([blob], { type });
    const fileName = fileService.getFileNameFromUUIDPath(link);

    const url = URL.createObjectURL(fileBlob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName || 'download';
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener('click', clickHandler);
      }, 150);
    };
    a.addEventListener('click', clickHandler, false);
    a.click();
  },
};

export default fileService;
