import { useMemo } from 'react';
import { useAuth } from '../context/UserContext';

export function useRole() {
  const { roles, user } = useAuth();

  // NOTE: 1차원적인 role에 대한 처리
  const isMoyoRole = useMemo(() => user?.company?.id === 1, [user]);
  const isSuperAdmin = roles.includes('ROLE_SUPER_ADMIN');
  const isAdmin = roles.includes('ROLE_ADMIN');

  // NOTE: 파생된 롤 값에 대한 처리
  const isAdminAccount = isAdmin || isSuperAdmin;

  return {
    roles,
    isMoyoRole,
    isAdmin,
    isSuperAdmin,
    isAdminAccount,
  };
}
