export function formatCardNumber(value: string) {
  if (!value) return value;

  const cardNumber = value.replace(/\D/g, '');

  const cardNumberLength = cardNumber.length;

  if (cardNumberLength < 5) return cardNumber;

  if (cardNumberLength < 9) {
    return `${cardNumber.slice(0, 4)}-${cardNumber.slice(4)}`;
  }

  if (cardNumberLength < 13) {
    return `${cardNumber.slice(0, 4)}-${cardNumber.slice(
      4,
      8,
    )}-${cardNumber.slice(8)}`;
  }

  return `${cardNumber.slice(0, 4)}-${cardNumber.slice(
    4,
    8,
  )}-${cardNumber.slice(8, 12)}-${cardNumber.slice(12)}`;
}

export const krCurrencyFormatter = new Intl.NumberFormat('ko-KR', {
  style: 'currency',
  currency: 'KRW',
});

export const numberCommaFormatter = (value?: number): string =>
  value !== undefined ? new Intl.NumberFormat().format(value) : '';

export const feeStringFormatter = (
  fee?: number,
  month?: number,
  originalFee?: number,
): string => {
  const feeString = fee ? `${numberCommaFormatter(fee)}원` : '';
  const monthString = month ? `${numberCommaFormatter(month)}개월 후` : '';
  const originalFeeString = originalFee
    ? `${numberCommaFormatter(originalFee)}원`
    : '';
  return `${feeString} ${monthString} ${originalFeeString}`;
};
