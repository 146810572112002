import { Box, Stack, Switch, TextField, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { BasicInfoDto } from '../../types/mobile-plan-operator-admin-types';
import { validate } from '../../utils/validate';
import { HTTPS_PROTOCOL_PREFIX } from '../../constants';
import { MvnoInfoItemBody, MvnoInfoItemRow } from '../Common/MvnoInfoItemFrame';

const MvnoInfoBasicEdit: FC<BasicInfoDto> = (data) => {
  const [isPcUrlSame, setIsPcUrlSame] = useState(
    data.pcHomepageUrl === data.mobileHomepageUrl,
  );
  const {
    control,
    register,
    setValue,
    getValues,
    reset,
    watch,
    formState: { errors },
  } = useFormContext<BasicInfoDto>();

  const initialize = (_basicInfoDto: BasicInfoDto) => {
    Object.entries(data).forEach(([key, value]) => {
      let _value = value;
      if (key === 'pcHomepageUrl' || key === 'mobileHomepageUrl') {
        _value = _value?.replace(HTTPS_PROTOCOL_PREFIX, '');
      }
      setValue(key as keyof BasicInfoDto, _value);
    });
  };

  const handleChangeIsSamePcLink = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setIsPcUrlSame(event.target.checked);
  };

  useEffect(() => {
    initialize(data);
    return () => {
      reset();
    };
  }, []);

  useEffect(() => {
    if (isPcUrlSame) {
      setValue('mobileHomepageUrl', getValues('pcHomepageUrl'));
    }
  }, [watch('pcHomepageUrl'), isPcUrlSame]);

  return (
    <>
      <MvnoInfoItemBody gap={7}>
        <MvnoInfoItemRow gap={3}>
          <Controller
            control={control}
            name="businessName"
            defaultValue={data.businessName}
            render={({ field }) => (
              <TextField
                InputLabelProps={{
                  required: false,
                }}
                required
                {...field}
                fullWidth
                label="법인명"
              />
            )}
          />
          <Controller
            control={control}
            name="businessRegistrationNumber"
            defaultValue={data.businessRegistrationNumber}
            render={({ field }) => (
              <TextField
                InputLabelProps={{
                  required: false,
                }}
                required
                {...field}
                fullWidth
                label="사업자등록번호"
              />
            )}
          />
        </MvnoInfoItemRow>
        <Box>
          <Controller
            control={control}
            name="address"
            defaultValue={data.address}
            render={({ field }) => (
              <TextField
                InputLabelProps={{
                  required: false,
                }}
                required
                {...field}
                fullWidth
                label="회사 주소"
              />
            )}
          />
        </Box>
        <MvnoInfoItemRow gap={3}>
          <Controller
            control={control}
            name="pcHomepageUrl"
            defaultValue={data.pcHomepageUrl}
            render={() => (
              <TextField
                required
                InputLabelProps={{
                  required: false,
                }}
                {...register('pcHomepageUrl', {
                  validate: {
                    isUrl: (value) => validate.validateUrlURLFormat(value),
                  },
                  onBlur: (event) => {
                    setValue('pcHomepageUrl', event.target.value, {
                      shouldValidate: true,
                    });
                  },
                })}
                helperText={errors.pcHomepageUrl?.message}
                error={!!errors.pcHomepageUrl?.message}
                InputProps={{
                  startAdornment: (
                    <Typography variant="body1" style={{ marginRight: '8px' }}>
                      {HTTPS_PROTOCOL_PREFIX}
                    </Typography>
                  ),
                }}
                fullWidth
                placeholder="예: moyoplan.com"
                label="통신사 홈페이지 (PC)"
              />
            )}
          />
          <Controller
            control={control}
            name="mobileHomepageUrl"
            defaultValue={data.mobileHomepageUrl}
            render={() => (
              <TextField
                {...register('mobileHomepageUrl', {
                  validate: {
                    isUrl: (value) => validate.validateUrlURLFormat(value),
                  },
                  onBlur: (event) => {
                    setValue('mobileHomepageUrl', event.target.value, {
                      shouldValidate: true,
                    });
                  },
                })}
                InputProps={{
                  startAdornment: (
                    <Typography variant="body1" style={{ marginRight: '8px' }}>
                      {HTTPS_PROTOCOL_PREFIX}
                    </Typography>
                  ),
                }}
                helperText={errors.mobileHomepageUrl?.message}
                error={!!errors.mobileHomepageUrl?.message}
                fullWidth
                disabled={isPcUrlSame}
                placeholder="예: moyoplan.com"
                label="통신사 홈페이지 (모바일)"
              />
            )}
          />
        </MvnoInfoItemRow>
      </MvnoInfoItemBody>
      <Box sx={{ marginTop: '10px' }}>
        <Stack direction={'row'} alignItems={'center'} justifyContent={'end'}>
          <Typography>PC 링크와 동일</Typography>
          <Switch
            defaultChecked={isPcUrlSame}
            value={isPcUrlSame}
            onChange={handleChangeIsSamePcLink}
          />
        </Stack>
      </Box>
    </>
  );
};

export default MvnoInfoBasicEdit;
