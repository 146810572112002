import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { NetworkList } from '../../../../entity/network';
import { CreateMoyoPlanTypes } from '../../type';

interface Props {
  isDone: boolean;
}

export const NetworkSelect: FC<Props> = ({ isDone }) => {
  const { control, register } = useFormContext<CreateMoyoPlanTypes>();

  return (
    <div>
      <Typography variant="subtitle1" m={0}>
        네트워크
      </Typography>
      <FormControl sx={{ mt: 1, mb: 1, minWidth: 150 }}>
        <InputLabel>종류</InputLabel>
        <Controller
          control={control}
          name="network"
          rules={{ required: true }}
          render={({ field }) => (
            <Select disabled={isDone} required {...field}>
              {NetworkList.map((group, index) => (
                <MenuItem key={index} value={group.value}>
                  {group.value}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </FormControl>
    </div>
  );
};
