import { orderApiService } from '../../api/order';
import { CookieConstants } from '../../common/constants';
import { getCookie } from '../../common/cookie';
import { isPartnersActivationAvailable } from '../../components/OrderDetail/OrderDetailOneTong/is-partners-activation-available';
import { MobilePlanOrder, OrderStatus } from '../../entity/order';
import pbl from '../pbl-service/pbl-service';
import { OrderDetailNavigationType } from '../pbl-types/pbl-navigation-types';

let localStatusList: OrderStatus[] | undefined;

// NOTE: 서버에서 텍스트로만 내려와서 이후 수정은 필요할 듯
const orderStatusToStatusKey = async (status: string) => {
  if (!localStatusList) {
    const res = await orderApiService.getOrderStatusOptions();
    localStatusList = res.data.result?.statusList ?? [];
  }
  return (
    localStatusList.find((orderStatus) => orderStatus.name === status)?.key ??
    status
  );
};

// - order_id
// - created_at
// - mvno
// - mno
// - plan_id
// - plan_name
// - register_type
// - order_type
// - status
// - sim_type
// - auto_activate_state
// - is_support_self_activate: 이게 파통 노출 여부랑 다른지 확인 필요 (다르다면 별도로 기록 필요)
//     - is_partners_activation_available
// - partners_activation_stage: 현재 파통 어떤 단계에 있는지
// - has_past_orders: 이전 신청내역 보기 버튼 노출 여부
// - has_change_plan: 요금제 변경 승인 UI 노출 여부
export const pblPageviewOrderDetail = async (
  navigationType: OrderDetailNavigationType,
  order: MobilePlanOrder,
) => {
  pbl('pageview', 'none', navigationType, {
    eventProperties: {
      orderId: order.id,
      createdAt: order.createdAt,
      mvno: order.mvno,
      mno: order.mno,
      planId: order.planMeta?.id,
      planName: order.planMeta?.name,
      registerType: order.registerType,
      orderType: order.orderType,
      status: await orderStatusToStatusKey(order.status),
      autoActivateState: order.autoActivateState,
      isSupportSelfActivate: order.isSupportSelfActivate,
      isPartnersActivationAvailable: isPartnersActivationAvailable(order),
      hasUsim: order.detail?.hasUsim,
      isNfcUsim: order.detail?.isNfcUsim,
      isEsim: order.isEsim,
      willPurchaseUsim: order.willPurchaseUsim,
    },
  });
};
