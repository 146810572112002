import { useFormContext } from 'react-hook-form';
import { TableCell, TableRow, TextField } from '@mui/material';

import { MobilePlanOrder } from '../../../entity/order';

export function WishNumber() {
  const { register } = useFormContext<MobilePlanOrder>();

  return (
    <TableRow>
      <TableCell>희망번호 1</TableCell>
      <TableCell>
        <TextField {...register('detail.wishNumber1')} size="small" />
      </TableCell>
      <TableCell>희망번호 2</TableCell>
      <TableCell>
        <TextField {...register('detail.wishNumber2')} size="small" />
      </TableCell>
    </TableRow>
  );
}
