import generateSnakeCase from '../../common/generate-snake-case';

// - 시간
//     - client_access_time: 유저의 접속 시간
// - 유저정보
//     - user_id: 로그인 유저 id
//     - user_agent: 접속유저 정보
//     - ip: 접속 유저 ip --
//     - device: 웹 / 앱(ios, and) 와 같은 디바이스 정보
//         - Web (Desktop), Web (Mobile), Android, iOS
//     - partners_version: 모요 파트너스 버전
//     - user_properties: 유저 프로퍼티
//         -

// ### 성민 추가

// - user_name: 로그인 유저 이름
// - partner_company_id: 파트너 조직 id
// - partner_company_name: 파트너 조직 이름 (e.g. 이지모바일)

interface AdminUserInfo {
  id: number;
  name: string;
  adminUserId: string;
  partnerCompanyId: number;
  authorities: string; // "ROLE_OPERATOR,ROLE_EXCEL_DOWNLOAD,ROLE_EDIT_ORDER,ROLE_ADMIN,ROLE_SUPER_ADMIN,ROLE_EDIT_ROLE",
}

export const createPblUserProperties = (
  adminUserInfo?: AdminUserInfo,
  userProperties?: Record<string, unknown>,
): Record<string, unknown> => {
  const userAgent = window.navigator.userAgent;
  const _userProperties = {
    partnerCompanyId: adminUserInfo?.partnerCompanyId,
    adminUserId: adminUserInfo?.adminUserId,
    userName: adminUserInfo?.name,
    userId: adminUserInfo?.id.toString(),
    authorities: adminUserInfo?.authorities,
    userAgent,
    ...userProperties,
  };

  return generateSnakeCase(_userProperties) as Record<string, unknown>;
};
