import { useNavigate } from 'react-router-dom';

import { orderApiService } from '../../../api/order';
import { handleApi } from '../../../common/http_util';
import { PlanMetaHistory } from '../../../entity/plan';
import pbl from '../../../pbl/pbl-service/pbl-service';
import { BaseSelectPlanPopup } from './BaseSelectPlanPopup';

type SelectPlanPopupProps = {
  orderId: string;
  open: boolean;
  onClose: () => void;
  onSubmit?: () => void;
  mvno: string;
  originalPlanName: string;
  changePlan?: PlanMetaHistory;
};

export function SelectPlanPopup({
  orderId,
  open,
  onClose,
  onSubmit,
  mvno,
  originalPlanName,
  changePlan,
}: SelectPlanPopupProps) {
  const navigate = useNavigate();

  const updateOrderPlan = async (
    planId: number,
    planName: string,
    mno: string,
  ) => {
    try {
      await handleApi(
        () =>
          orderApiService.updateOrder(
            orderId,
            planId,
            planName,
            mno,
            `${originalPlanName}에서 ${planName}(으)로 변경됨`,
          ),
        navigate,
      );

      alert('요금제 변경이 완료되었습니다.');

      if (onSubmit) onSubmit();
    } catch (err) {
      console.log(err);
      alert('문제가 발생했습니다. 잠시 후 다시 시도해주세요.');
    }
  };

  const onCloseHandler = () => {
    onClose();
  };

  return (
    <BaseSelectPlanPopup
      orderId={orderId}
      open={open}
      onClose={onCloseHandler}
      onSubmit={updateOrderPlan}
      mvno={mvno}
      changePlan={changePlan}
    />
  );
}
