export const isInValidHttpUrl = (str?: string | null): boolean => {
  if (!str) return true;

  let url;

  try {
    url = new URL(str);
  } catch (e) {
    console.log(e);
    return true;
  }

  return url.protocol !== 'http:' && url.protocol !== 'https:';
};

export const isInValidHttpUrlWithRegex = (str?: string | null): boolean => {
  if (str === null || str === undefined || str === '') return true;

  const urlRegex = /^(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(:\d+)?(\/[^\s]*)?$/;
  return urlRegex.test(str);
};
