import { useCallback, useEffect, useMemo, useState } from 'react';
import { Alert, Box, Button, Chip, Grid, Typography } from '@mui/material';
import {
  ReservationStatus,
  SpecialGroupNameSelectList,
} from '../../../entity/plan';

import { planApiService } from '../../../api/plan';

import { useNavigate } from 'react-router-dom';

import Snackbar from '@mui/material/Snackbar';
import { ExposeConfirmDialog } from '../../PlanListPage/ExposeConfirmDialog';
import { DateTime } from 'luxon';
import { isMoyoAdmin } from '../../../common/moyoActivationCollection';
import { useOptions } from '../../../context/OptionsContext';
import { mapPlanDetailToCreatePlanDto } from '../../../helpers/plan.helper';

import { useLoading } from '../../../context/LoadingContext';
import DisplaySettingDialog from '../Dialog/DisplaySettingDialog';
import { useModal } from '../../../hooks/useModal';
import DeleteSettingDialog from '../Dialog/DeleteSettingDialog';
import DeleteReserveDialog from '../Dialog/DeleteReserveDialog';
import DeleteConfirmDialog from '../Dialog/DeleteConfirmDialog';
import ConfirmDialog from '../Dialog/ConfirmDialog';
import PermissionRender from '../../../shared/components/PermissionRender/PermissionRender';
import useAlert from '../../../common/useAlert';
import { env } from '../../../utils/env';
import { PlanV2 } from '../../../entity/plan-v2';

type PlanDetailHeaderProps = {
  plan: PlanV2;
  parameter?: string;
  onRefresh: () => void;
  setter: React.Dispatch<React.SetStateAction<boolean>>;
};
const PlanDetailHeader = ({
  plan,
  parameter,
  onRefresh,
  setter,
}: PlanDetailHeaderProps) => {
  const navigate = useNavigate();
  const { openModal, closeModal } = useModal();
  const { adminUser } = useOptions();
  const alert = useAlert();
  const { meta: planMeta, adminPlanMeta } = plan;

  const { setLoading } = useLoading();
  const [snackBarState, setSnackBarState] = useState({
    open: false,
    massage: '',
  });

  const handleEditPlan = async () => {
    if (adminPlanMeta.reservationStatus === ReservationStatus.CHANGE_RESERVED) {
      await alert.open({
        title: '수정 예약이 있어요',
        content:
          '정보 수정이 필요하다면, 수정 예약을 취소한 후 다시 시도해 주세요.',
      });
      return;
    }
    navigate(`/plan-edit?planId=${planId}`);
  };

  const openMoyoPage = useCallback(() => {
    window.open(`${env('REACT_APP_API_URL')}/plans/${planId}`);
  }, [plan]);

  const validateRequestName = (requestName: string) => {
    if (requestName === 'cancelReserve') {
      window.alert('종료 예약을 취소했어요');
    }
  };

  const onReliveClick = async (requestName: string) => {
    try {
      await planApiService.restorePlan(planId);
      validateRequestName(requestName);
    } catch (error) {
      window.alert('에러가 발생했습니다');
    }
    window.location.reload();
  };

  let defaultButtonList = (
    <Grid container gap={'16px'}>
      <Button variant={'text'} onClick={openMoyoPage}>
        모요 링크 확인
      </Button>
      {parameter === 'moyoonly' && planMeta.name?.includes('모요only') && (
        <Button
          variant={'contained'}
          color="error"
          onClick={() => setter(true)}
        >
          종료 요청
        </Button>
      )}
    </Grid>
  );

  const planId = Number(planMeta?.id);
  // header UI 관련
  const [text, setText] = useState('');
  const [background, setBackground] = useState<
    | 'success'
    | 'error'
    | 'default'
    | 'warning'
    | 'primary'
    | 'secondary'
    | 'info'
  >('success');

  const [reserveText, setReserveText] = useState('');
  const [reserveBackground, setReserveBackground] = useState<
    | 'success'
    | 'error'
    | 'default'
    | 'warning'
    | 'primary'
    | 'secondary'
    | 'info'
  >('success');

  const isReserve = useMemo(() => {
    if (adminPlanMeta.reservationStatus && adminPlanMeta.reserveTime) {
      return true;
    }

    return false;
  }, [adminPlanMeta.reservationStatus, adminPlanMeta.reserveTime]);

  const [buttonList, setButtonList] = useState(<></>);

  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useState(false);

  const [openRemoveAlert, setOpenRemoveAlert] = useState(false);
  const [openRemoveReserveAlert, setOpenRemoveReserveAlert] = useState(false);

  const handleDelete = useCallback(async () => {
    try {
      setLoading(true);
      await planApiService.deletePlan(planId);
      closeModal();
      onRefresh();
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, [closeModal, onRefresh, planId, setLoading]);

  const handleRemovePlan = useCallback(async () => {
    if (adminPlanMeta.reservationStatus === ReservationStatus.CHANGE_RESERVED) {
      await alert.open({
        title: '수정 예약이 있어 요금제 노출을 종료할 수 없어요.',
        content: '수정 예약을 취소한 후, 노출을 종료해 주세요.',
      });
      return;
    }

    if (adminPlanMeta.reservationStatus === ReservationStatus.CLOSE_RESERVED) {
      await alert.open({
        title: '노출 종료 예약이 있어요',
        content:
          '지금 노출을 종료하고 싶다면, 예약을 취소한 후 다시 시도 해주세요.',
      });
      return;
    }

    openModal(
      <DeleteSettingDialog
        open
        onClose={closeModal}
        onDelete={handleDelete}
        onReserve={() => {
          openModal(
            <DeleteReserveDialog
              planIdList={[planId]}
              open
              onSuccess={(message: string) => {
                setOpenRemoveReserveAlert(true);
                setSnackBarState({
                  open: true,
                  massage: message,
                });

                setTimeout(() => {
                  setSnackBarState({
                    open: false,
                    massage: '',
                  });
                }, 2000);

                onRefresh();
              }}
              onClose={closeModal}
            />,
          );
        }}
      />,
    );
  }, [
    closeModal,
    handleDelete,
    onRefresh,
    openModal,
    adminPlanMeta.reservationStatus,
    planId,
  ]);

  const handleDisplay = useCallback(async () => {
    try {
      setLoading(true);
      await planApiService.restorePlan(planMeta.id);
      closeModal();
      onRefresh();
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, [closeModal, onRefresh, planMeta.id, setLoading]);

  const handleDisplayPlan = useCallback(async () => {
    if (adminPlanMeta.reservationStatus === ReservationStatus.OPEN_RESERVED) {
      alert.open({
        title: '노출 예약이 있어요',
        content: '지금 노출하고 싶다면, 예약을 취소한 후 다시 시도해 주세요.',
      });
      return;
    }
    openModal(
      <DisplaySettingDialog
        open
        onClose={closeModal}
        onDisplay={handleDisplay}
        onReserve={() => {
          openModal(
            <ExposeConfirmDialog
              title="요금제 노출 예약"
              open
              onClose={closeModal}
              onExpose={async (exposeDate) => {
                const isNow = exposeDate?.equals(
                  DateTime.now().startOf('minute'),
                );
                if (isNow) {
                  await planApiService.restorePlan(planMeta.id);
                } else {
                  if (!exposeDate) {
                    return;
                  }

                  const createPlanDto = mapPlanDetailToCreatePlanDto(plan);
                  createPlanDto.reserveTime = exposeDate.toFormat(
                    "yyyy-MM-dd'T'HH:mm:ss",
                  );

                  await planApiService.putPlan(planId, createPlanDto);
                }

                setSnackBarState({
                  open: true,
                  massage: '요금제 노출을 설정했어요',
                });

                setTimeout(() => {
                  setSnackBarState({
                    open: false,
                    massage: '',
                  });
                }, 2000);

                closeModal();
                onRefresh();
              }}
            />,
          );
        }}
      />,
    );
  }, [closeModal, handleDisplay, onRefresh, openModal, plan, planId]);

  const updateReservationChip = () => {
    if (adminPlanMeta.reservationStatus === ReservationStatus.OPEN_RESERVED) {
      setReserveText(
        `노출예약: ${DateTime.fromISO(adminPlanMeta.reserveTime || '').toFormat(
          'yy-MM-dd HH:mm',
        )}`,
      );
      setReserveBackground('success');
      return;
    }

    if (adminPlanMeta.reservationStatus === ReservationStatus.CLOSE_RESERVED) {
      setReserveText(
        `종료예약: ${DateTime.fromISO(adminPlanMeta.reserveTime || '').toFormat(
          'yy-MM-dd HH:mm',
        )}`,
      );
      setReserveBackground('warning');
      return;
    }

    if (adminPlanMeta.reservationStatus === ReservationStatus.CHANGE_RESERVED) {
      setReserveText(
        `수정예약: ${DateTime.fromISO(adminPlanMeta.reserveTime || '').toFormat(
          'yy-MM-dd HH:mm',
        )}`,
      );
      setReserveBackground('secondary');

      return;
    }
  };
  const updateStateChips = () => {
    if (adminPlanMeta.display) {
      setText('노출중');
      setBackground('info');
    } else {
      setText('미노출');
      setBackground('default');
    }

    updateReservationChip();
  };

  useEffect(() => {
    updateStateChips();
  }, [plan]);

  return (
    <>
      {openRemoveAlert && (
        <Alert
          severity="success"
          onClose={() => {
            setOpenRemoveAlert(false);
            window.location.reload();
          }}
          style={{ marginTop: '10px' }}
        >
          요금제가 종료되었습니다.
        </Alert>
      )}

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '32px',
        }}
      >
        <Grid>
          <Grid sx={{ display: 'flex' }}>
            <Grid container direction="column">
              {isMoyoAdmin(adminUser) && (
                <Grid
                  sx={{
                    display: 'inline-flex',
                    mb: 1,
                    gap: 0.5,
                    alignItems: 'center',
                  }}
                >
                  <Typography variant="body1">
                    {planMeta?.mvno || '없음'} |
                  </Typography>

                  <Typography variant="body1">
                    {plan.mobilePlanOperatorDto.planManagementType}
                  </Typography>
                </Grid>
              )}

              <Grid
                container
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: '8px',
                  flexDirection: { xs: 'column', sm: 'row' },
                }}
              >
                <Typography
                  variant="h5"
                  sx={{ maxWidth: '600px', wordBreak: 'break-word' }}
                >
                  [{planId}]&nbsp;{planMeta.name}
                </Typography>

                <div
                  style={{
                    display: 'flex',
                    gap: '8px',
                  }}
                >
                  <Chip size="small" color={background} label={text} />
                  {isReserve && (
                    <Chip
                      size="small"
                      variant="outlined"
                      color={reserveBackground}
                      label={reserveText}
                    />
                  )}
                </div>
              </Grid>
              <Grid
                sx={{
                  display: 'inline-flex',
                  mt: 1,
                  gap: 0.5,
                  alignItems: 'center',
                }}
              >
                <Typography variant="body1">
                  {SpecialGroupNameSelectList.find(
                    (group) => group.key === planMeta.specialPlanCategory,
                  )?.value ?? '일반 요금제'}{' '}
                  &nbsp;|&nbsp;
                </Typography>
                <Typography variant="body1">
                  {planMeta.mno}망 &nbsp;|&nbsp;
                </Typography>

                <Typography variant="body1"> {planMeta.net}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid sx={{ display: 'inline-flex', gap: 2 }}>
          {/* 수정가능 - 어드민인데 manual & 파트너스인데 manual */}
          <PermissionRender
            moyoAdmin={plan.mobilePlanOperatorDto.planManagementType !== 'API'}
            permission={{
              requiredPermissions: ['PARTNERS_MOBILE_PLAN_ORDER_MANAGE'],
            }}
            planManagementMethodList={['MANUAL']}
          >
            <Button
              color="inherit"
              variant="outlined"
              onClick={handleEditPlan}
              sx={{ height: '36px', minWidth: '89px', whiteSpace: 'nowrap' }}
            >
              정보 수정
            </Button>
          </PermissionRender>

          {/* 노출조작가능 - 어드민 전부노출 or 파트너스 매뉴얼 일때만 노출 */}
          <PermissionRender
            moyoAdmin
            permission={{
              requiredPermissions: ['PARTNERS_MOBILE_PLAN_ORDER_MANAGE'],
            }}
            planManagementMethodList={['SCRAPING', 'MANUAL']}
          >
            <>
              {adminPlanMeta.display ? (
                <Button
                  color="error"
                  variant="outlined"
                  onClick={handleRemovePlan}
                  sx={{
                    height: '36px',
                    minWidth: '89px',
                    whiteSpace: 'nowrap',
                  }}
                >
                  노출 종료
                </Button>
              ) : (
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={handleDisplayPlan}
                  sx={{
                    height: '36px',
                    minWidth: '89px',
                    whiteSpace: 'nowrap',
                  }}
                >
                  노출 설정
                </Button>
              )}
            </>
          </PermissionRender>
        </Grid>

        {/* TODO - 하단으로 이동 */}
        {/* <Grid>{buttonList}</Grid> */}
      </Box>

      {/* useModal로 대체 start */}
      <DeleteConfirmDialog
        planIdList={[planId]}
        open={isRemoveDialogOpen}
        onSuccess={() => {
          setOpenRemoveAlert(true);
          onRefresh();
        }}
        onClose={() => {
          setIsRemoveDialogOpen(false);
        }}
      />

      <Snackbar
        open={snackBarState.open}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        message={snackBarState.massage}
      />
      {/* useModal로 대체 end*/}
    </>
  );
};

export default PlanDetailHeader;
