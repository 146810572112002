import { GridColDef } from '@mui/x-data-grid';
import { DateTime } from 'luxon';

import { dateFormatter } from '../../utils/gridFormatter';

import * as GridPreset from '../../utils/gridPreset';

export const InternetOrderTableColumns: GridColDef[] = [
  {
    ...GridPreset.createdAt_신청일,
    width: 250,
    valueFormatter: dateFormatter(DateTime.DATETIME_SHORT_WITH_SECONDS),
  },
  { ...GridPreset.status_상태, width: 180 },
  {
    ...GridPreset.registerType_신청유형,
    headerName: '유형',
    width: 180,
  },
  GridPreset.customerName_고객명,
  GridPreset.customerPhoneNumber_신청전화번호,
  GridPreset.company_통신사,
  GridPreset.internetPlanName_인터넷요금제,
  { ...GridPreset.planName_요금제, width: 190 },
  GridPreset.address_주소,
];
