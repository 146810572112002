import { ReleaseNoteItem } from './releaseNotes';
import ReleaseStatusType from './ReleaseStatusType';

const Release_2_4_0: ReleaseNoteItem = {
  version: '2.4.0',
  date: '2023-07-24',
  title: '통신사별 요금제 공통 정보 편집 기능 제거',
  details: [
    {
      title: '통신사별 요금제 공통 정보 편집 기능 제거',
      contents: [
        {
          content: (
            <>
              요금제 관리 상세 화면에서 통신사와 통신망 별로 공통 관리할 수 있는
              아래 정보는 편집할 수 없도록 변경되었어요.
              <br />
              <ul>
                <li>유심</li>
                <li>NFC 유심</li>
                <li>
                  <del>통신사 약정</del>
                </li>
                <li>셀프 개통</li>
                <li>해외 로밍</li>
                <li>소액 결제</li>
              </ul>
              공통 정보의 수정이 필요한 경우 왼쪽 문의하기 메뉴를 통해
              요청해주세요.
            </>
          ),
          status: ReleaseStatusType.DEPRECATED,
        },
      ],
    },
  ],
};

export default Release_2_4_0;
