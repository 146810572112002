import { FC } from 'react';
import SwitchEditableMvnoInfo from '../../SwitchEditableMvnoInfo/SwitchEditableMvnoInfo';
import MvnoInfoCustomerCenterDetail from './MvnoInfoCustomerCenterDetail';
import MvnoInfoCustomerCenterEdit from './MvnoInfoCustomerCenterEdit';
import { CustomerCenterInfo } from '../../types/mobile-plan-operator-admin-types';
import mvnoInfoApiService, {
  MvnoInfoPutFunction,
} from '../../api/mvno-info-api-service';
import { useMobilePlanOperatorAdmin } from '../../hooks/useMobilePlanOperatorAdmin';
import { useCheckRole } from '../../../../router/useCheckRole';
import { useRole } from '../../../../router/useRole';

interface Props {
  customerCenterInfo: CustomerCenterInfo;
  mobilePlanOperatorId: number;
}

const MvnoInfoCustomerCenter: FC<Props> = ({
  customerCenterInfo,
  mobilePlanOperatorId,
}) => {
  const { update } = useMobilePlanOperatorAdmin(mobilePlanOperatorId);
  const { checkRole } = useCheckRole();
  const { isMoyoRole } = useRole();

  const put: MvnoInfoPutFunction<CustomerCenterInfo> = async (
    data: CustomerCenterInfo,
  ) => {
    const response = await mvnoInfoApiService.putCustomerCenter(
      mobilePlanOperatorId,
      data,
    );
    if (response.data.result) {
      update({
        customerServiceContactList:
          response.data.result.customerServiceContactList,
        operatingHoursInfoList: response.data.result.operatingHoursInfoList,
      });
    }
    return response;
  };

  const enableEdit = isMoyoRole ? true : checkRole(['ROLE_ADMIN']);

  return (
    <SwitchEditableMvnoInfo
      title="고객센터 정보"
      detail={
        <MvnoInfoCustomerCenterDetail customerCenterInfo={customerCenterInfo} />
      }
      edit={
        <MvnoInfoCustomerCenterEdit customerCenterInfo={customerCenterInfo} />
      }
      put={put}
      enableEdit={enableEdit}
    />
  );
};

export default MvnoInfoCustomerCenter;
