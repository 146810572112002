import { Paper, TableBody, TableContainer, Typography } from '@mui/material';
import { MobilePlanOrder } from '../../../entity/order';
import { VerticalColorTable } from '../../../shared/components/coloredTable';
import {
  CertificationInfo,
  ChangeCertification,
  ColumnGroupDefinition,
  CustomerNameAndRegisterType,
  DeliveryInfo,
  HasUsimAndType,
  LicenseInfo,
  PhoneInfo,
  UsimModelAndNumber,
  WishNumber,
} from '../components';

interface CustomerInfoSectionProps {
  order?: MobilePlanOrder;
}

export function CustomerInfoSection({ order }: CustomerInfoSectionProps) {
  if (!order) {
    return null;
  }

  return (
    <>
      <Typography variant="h6" my={2}>
        고객 정보
      </Typography>
      <TableContainer component={Paper} elevation={5}>
        <VerticalColorTable>
          <ColumnGroupDefinition />
          <TableBody>
            <CustomerNameAndRegisterType order={order} />
            <PhoneInfo order={order} />
            <CertificationInfo order={order} />
            <LicenseInfo order={order} />
            <ChangeCertification order={order} />
            <HasUsimAndType order={order} />
            <UsimModelAndNumber order={order} />
            <DeliveryInfo />
            <WishNumber />
          </TableBody>
        </VerticalColorTable>
      </TableContainer>
    </>
  );
}
