import { useNavigate } from 'react-router-dom';
import { handleApiV2 } from '../../common/http_util';
import { useInternetOptions } from '../../context/InternetOptionsContext';
import InternetCommonHistoryCreateSection from './InternetCommonHistoryCreateSection';
import internetCommonConsultApiService from '../../api/internet-consult';
import { Box, Typography } from '@mui/material';
import pbl from '../../pbl/pbl-service/pbl-service';

interface Props {
  consultId: number;
  iptvBundle?: boolean;
  afterSubmitProcess?: () => void;
}

export default function InternetConsultHistoryCreateSection({
  consultId,
  iptvBundle = false,
  afterSubmitProcess,
}: Props) {
  const navigate = useNavigate();
  const { statusList } = useInternetOptions();

  const onSubmitHandler = async (
    status: string,
    memo: string,
    statusDetail?: string,
  ) => {
    try {
      pbl('click', 'button', 'here', {
        object: {
          id: 'add_internet_order_history',
          section: 'internet_order_history',
        },
        eventProperties: {
          consultId,
          iptvBundle,
          status,
          memo,
          statusDetail,
        },
      });
      await handleApiV2(
        () =>
          internetCommonConsultApiService
            .iptvBundle(iptvBundle)
            .patchConsultStatus(consultId, status, memo, statusDetail),
        navigate,
      );

      afterSubmitProcess?.();
    } catch (error) {
      alert(error);
    }
  };

  return (
    <>
      <Box
        my={2}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h6">상담 이력</Typography>
      </Box>
      <InternetCommonHistoryCreateSection
        onSubmit={onSubmitHandler}
        statusList={statusList}
      />
    </>
  );
}
