import { FC } from 'react';
import SwitchEditableMvnoInfo from '../../SwitchEditableMvnoInfo/SwitchEditableMvnoInfo';
import MvnoInfoBasicDetail from './MvnoInfoBasicDetail';
import MvnoInfoBasicEdit from './MvnoInfoBasicEdit';
import { BasicInfoDto } from '../../types/mobile-plan-operator-admin-types';
import mvnoInfoApiService, {
  MvnoInfoPutFunction,
} from '../../api/mvno-info-api-service';
import { useMobilePlanOperatorAdmin } from '../../hooks/useMobilePlanOperatorAdmin';
import { HTTPS_PROTOCOL_PREFIX } from '../../constants';
import { useCheckRole } from '../../../../router/useCheckRole';
import { useRole } from '../../../../router/useRole';

interface Props {
  basicInfo: BasicInfoDto;
  mobilePlanOperatorId: number;
}

const MvnoInfoBasic: FC<Props> = ({ basicInfo, mobilePlanOperatorId }) => {
  const { update } = useMobilePlanOperatorAdmin(mobilePlanOperatorId);
  const { checkRole } = useCheckRole();
  const { isMoyoRole } = useRole();

  const put: MvnoInfoPutFunction<BasicInfoDto> = async (data: BasicInfoDto) => {
    const pcHomepageUrl = `${HTTPS_PROTOCOL_PREFIX}${data.pcHomepageUrl}`;
    const mobileHoempageUrl = data.mobileHomepageUrl
      ? `${HTTPS_PROTOCOL_PREFIX}${data.mobileHomepageUrl}`
      : '';

    const requestBody = {
      ...data,
      pcHomepageUrl: pcHomepageUrl,
      mobileHomepageUrl: mobileHoempageUrl,
    };

    const response = await mvnoInfoApiService.putBasicInfo(
      mobilePlanOperatorId,
      requestBody,
    );
    if (response.data.result) {
      update({
        basicInfo: response.data.result,
      });
    }
    return response;
  };

  const enableEdit = isMoyoRole ? true : checkRole(['ROLE_ADMIN']);

  return (
    <SwitchEditableMvnoInfo
      title="기본 정보"
      detail={<MvnoInfoBasicDetail {...basicInfo} />}
      edit={<MvnoInfoBasicEdit {...basicInfo} />}
      put={put}
      enableEdit={enableEdit}
    />
  );
};

export default MvnoInfoBasic;
