import { Typography } from '@mui/material';

import { makeTetheringDesc } from '../../../helpers/plan.helper';
import MvnoInfoItem from '../../MvnoInfoPage/Section/Common/MvnoInfoItem';
import {
  MvnoInfoItemBody,
  MvnoInfoItemRow,
} from '../../MvnoInfoPage/Section/Common/MvnoInfoItemFrame';
import { PlanMetaV2 } from '../../../entity/plan-v2';

type PlanDetailInfoComponentProps = {
  planMeta: PlanMetaV2;
};

const AdditionalInfoComponent = ({
  planMeta,
}: PlanDetailInfoComponentProps) => {
  return (
    <>
      <MvnoInfoItemBody gap={4}>
        <Typography variant="h6">부가 정보</Typography>
        <MvnoInfoItemRow numberOfRow={3} gap={3}>
          <MvnoInfoItem
            title="데이터 쉐어링"
            content={planMeta.dataSharingSupported ? '가능' : '불가능'}
          />
          <MvnoInfoItem
            title="인터넷 결합"
            content={planMeta.internetCombineSupported ? '가능' : '불가능'}
          />
        </MvnoInfoItemRow>
        <MvnoInfoItemRow numberOfRow={3} gap={3}>
          <MvnoInfoItem
            title="부가통화"
            content={
              planMeta.additionalCall === 0
                ? '통화량에서 차감'
                : planMeta.additionalCall === -1
                ? '미제공'
                : planMeta.additionalCall === null
                ? '통신사에 문의'
                : `${planMeta.additionalCall}분`
            }
          />
          {/* {planDetail.tethering
              ? planDetail?.tetheringDataString
                ? `월 ${planDetail.tetheringDataString}`
                : planDetail.tetheringDescription
              : '제공안함'} */}
          {/* TODO - fe 임시문구 처리 be에 변경요청함 
            https://www.notion.so/moyoplan/QA-137c3ed13bfb8002ae83ff906dd3735b?pvs=4#13cc3ed13bfb804e8c4ce3c87f0ae3f4
            */}
          <MvnoInfoItem
            title="핫스팟 (테더링)"
            content={makeTetheringDesc({
              tethering: planMeta.tethering,
            })}
          />
        </MvnoInfoItemRow>
      </MvnoInfoItemBody>
    </>
  );
};

export default AdditionalInfoComponent;
