import { FC, useEffect } from 'react';
import {
  UsimInfo,
  UsimInfoDetails,
} from '../../types/mobile-plan-operator-admin-types';
import { Box, Typography } from '@mui/material';
import { Mno } from '../../../../entity/mno';
import { convertStringToMno } from '../../../../common/order-util';
import { useFormContext } from 'react-hook-form';
import { MvnoInfoDeliveryFeeStatus } from './MvnoInfoDeliveryFeeStatus';
import { MvnoInfoItemBody, MvnoInfoItemRow } from '../Common/MvnoInfoItemFrame';

const MnoInfoUsimSupportEdit: FC<{
  mno: Mno;
  usimInfoDetails: UsimInfoDetails;
}> = ({ mno, usimInfoDetails }) => {
  const title = convertStringToMno(mno);

  return (
    <MvnoInfoItemBody gap={3}>
      <Box>
        <Typography variant="body1" color="text.primary" fontWeight={700}>
          {title}망
        </Typography>
      </Box>
      <MvnoInfoItemRow gap={3}>
        <MvnoInfoDeliveryFeeStatus
          simFeeStatusKey="usimDelivery"
          label="일반 유심 배송"
          defaultValue={{
            fee: usimInfoDetails.usimDeliveryFee,
            feeStatus: usimInfoDetails.usimDeliveryFeeStatus,
          }}
          mno={mno}
        />
        <MvnoInfoDeliveryFeeStatus
          simFeeStatusKey="nfcUsimDelivery"
          label="NFC 유심 배송"
          defaultValue={{
            fee: usimInfoDetails.nfcUsimDeliveryFee,
            feeStatus: usimInfoDetails.nfcUsimDeliveryFeeStatus,
          }}
          mno={mno}
        />
        <MvnoInfoDeliveryFeeStatus
          simFeeStatusKey="esim"
          label="eSIM 지원"
          defaultValue={{
            fee: usimInfoDetails.esimFee,
            feeStatus: usimInfoDetails.esimFeeStatus,
          }}
          mno={mno}
        />
      </MvnoInfoItemRow>
    </MvnoInfoItemBody>
  );
};

const MvnoInfoUsimSupportEdit: FC<UsimInfo> = (usimInfo) => {
  const { setValue, reset } = useFormContext<UsimInfo>();

  useEffect(() => {
    initialize(usimInfo);
    return () => {
      reset();
    };
  }, []);

  const initialize = (_usimInfo: UsimInfo) => {
    Object.entries(_usimInfo).forEach(([usimInfoKey, usimInfoDetails]) => {
      Object.entries(usimInfoDetails).forEach(([usimInfoDetailsKey, value]) => {
        setValue(
          `${usimInfoKey as keyof UsimInfo}.${
            usimInfoDetailsKey as keyof UsimInfoDetails
          }`,
          value,
        );
      });
    });
  };

  return (
    <MvnoInfoItemBody gap={6}>
      {Object.entries(usimInfo).map(([key, usimInfoDetails]) => (
        <MnoInfoUsimSupportEdit
          usimInfoDetails={usimInfoDetails}
          key={key}
          mno={key as Mno}
        />
      ))}
    </MvnoInfoItemBody>
  );
};

export default MvnoInfoUsimSupportEdit;
