import { FC, forwardRef, useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  Snackbar,
  styled,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { TransitionProps } from '@mui/material/transitions';

import { ThemeControlProvider } from '../../../context/ThemeControlContext';
import { usePortal, usePortalController } from '../../../common/usePortal';

const _Box = styled(Box)(({ theme }) => ({
  color: theme.palette.primary.dark,
  whiteSpace: 'pre-wrap',
}));

const SlideUpTransition = forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
  ) => {
    return <Slide direction="up" ref={ref} {...props} />;
  },
);

interface ToastAlarmTalkSendProps {
  userName: string;
  message: string;
}

const ToastAlarmTalkSend: FC<ToastAlarmTalkSendProps> = ({
  userName,
  message,
}) => {
  const { close, dismiss } = usePortalController<boolean>();
  const [openAlarmTalkToast, setOpenAlarmTalkToast] = useState(true);
  const [showAlarmTalkMessage, setShowAlarmTalkMessage] = useState(false);

  useEffect(() => {
    if (!openAlarmTalkToast && !showAlarmTalkMessage) {
      close();
      dismiss();
    }
  }, [openAlarmTalkToast, showAlarmTalkMessage]);

  return (
    <>
      <Snackbar
        open={openAlarmTalkToast}
        autoHideDuration={7000}
        onClose={(_, reason) => {
          if (reason === 'timeout') {
            setOpenAlarmTalkToast(false);
          }
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        TransitionComponent={SlideUpTransition}
      >
        <Alert
          severity="success"
          sx={{ width: '480px' }}
          variant="filled"
          action={
            <Box
              style={{
                display: 'flex',
                gap: 8,
                alignItems: 'center',
                marginTop: -4,
              }}
            >
              <Button
                color="inherit"
                size="small"
                onClick={() => {
                  setShowAlarmTalkMessage(true);
                }}
              >
                알림톡 내용 보기
              </Button>
              <IconButton
                color="inherit"
                onClick={() => setOpenAlarmTalkToast(false)}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
          }
        >
          {userName}님에게 알림톡이 발송되었어요
        </Alert>
      </Snackbar>
      <Dialog open={showAlarmTalkMessage}>
        <DialogTitle>이렇게 알림톡을 보냈어요</DialogTitle>
        <DialogContent>
          알림톡 내용 :<br />
          <br />
          <_Box>{message}</_Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowAlarmTalkMessage(false);
            }}
          >
            확인
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const useToastAlarmTalkSend = () => {
  const portal = usePortal<boolean>();

  const open = async (userName: string, message: string) => {
    const result = await portal.open(
      <ThemeControlProvider>
        <ToastAlarmTalkSend userName={userName} message={message} />
      </ThemeControlProvider>,
    );

    portal.dispose();

    return result;
  };

  return { open };
};
