import { AxiosResponse } from 'axios';
import { companyOrderApi } from '../common/http_util';
import { ApiResponse } from '../entity/api';
import { CompanyInfo } from '../entity/company';

class CompanyApiService {
  getInfo(): Promise<AxiosResponse<ApiResponse<CompanyInfo>>> {
    return companyOrderApi.get('');
  }
}

export const companyApiService = new CompanyApiService();
