import { ChangeEvent, forwardRef, HTMLAttributes } from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

interface Props
  extends Omit<
    HTMLAttributes<HTMLInputElement | HTMLTextAreaElement>,
    'defaultValue'
  > {
  name?: string;
}

const NumericFormatComponent = forwardRef<NumericFormatProps, Props>(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...field } = props;

    return (
      <NumericFormat
        {...field}
        getInputRef={ref}
        onValueChange={(values, sourceInfo) => {
          const sourceEvent = sourceInfo.event as ChangeEvent<HTMLInputElement>;
          const event = {
            ...sourceEvent,
            target: {
              ...sourceEvent?.target,
              value: values.value,
            },
          };
          onChange?.(event);
        }}
        allowNegative={false}
        thousandSeparator
        valueIsNumericString
      />
    );
  },
);

export default NumericFormatComponent;
