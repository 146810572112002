import { Chip } from '@mui/material';
import { FC } from 'react';
import { getActivationStateLabelText } from '../../domains/activations/states/services';
import { MoyoActivationState } from '../../domains/activations/states/enum';

interface AutoActivateStateChipProps {
  state: MoyoActivationState;
  size?: 'small' | 'medium';
  sx?: Parameters<typeof Chip>['0']['sx'];
  moyoOnlyView?: boolean;
}

type MuiChipColor =
  | 'default'
  | 'primary'
  | 'secondary'
  | 'error'
  | 'info'
  | 'success'
  | 'warning';

const colorMap: Record<MoyoActivationState, MuiChipColor> = {
  검증_완료: 'success',
  검증_대기: 'warning',
  검증_진행중: 'primary',
  개통_시도_완료: 'success',
  개통_대기: 'warning',
  개통_진행중: 'primary',
  처리_완료: 'success',
  배송_대상: 'secondary',
  배송_처리_완료: 'success',
  KT_바로배송중: 'primary',
  유심구매중: 'primary',
  처리_전: 'warning',
  원통_대기: 'warning',
  KT_바로배송_검증완료_개통불가능: 'success',
  KT_바로배송_검증완료_개통가능: 'success',
};

export const isProcessingActivationState = (state?: MoyoActivationState) => {
  return (
    !!state && !!getActivationStateLabelText({ state, isMoyoAuthority: false })
  );
};

const AutoActivateStateChip: FC<AutoActivateStateChipProps> = (props) => {
  if (!props.moyoOnlyView) {
    return <ActivationStateChipWithPartnersAuthority {...props} />;
  }
  return <ActivationStateChipWithMoyoAuthority {...props} />;
};

function ActivationStateChipWithMoyoAuthority({
  state,
  size,
  sx,
}: AutoActivateStateChipProps) {
  const labelText = getActivationStateLabelText({
    state,
    isMoyoAuthority: true,
  });

  return (
    <Chip
      sx={sx}
      size={size}
      label={labelText ?? '미대상'}
      color={colorMap[state] ?? 'default'}
      variant="filled"
    />
  );
}

function ActivationStateChipWithPartnersAuthority(
  props: AutoActivateStateChipProps,
) {
  const labelText = getActivationStateLabelText({
    state: props.state,
    isMoyoAuthority: false,
  });
  if (!labelText) {
    return <></>;
  }
  return <Chip {...props} label={labelText} color="primary" />;
}

export default AutoActivateStateChip;
