const entriesToRecord = <
  Key extends number | string,
  Value extends number | string,
>(
  entries: IterableIterator<[Key, Value]>,
): Record<Key, Value> => {
  const record: Record<Key, Value> = {} as Record<Key, Value>;
  for (const [key, value] of entries) {
    record[key] = value;
  }

  return record;
};

const isEmpty = (target: object): boolean => {
  return Object.keys(target).length === 0;
};

export const pblQueryParamsService = (locationSearch: string) => {
  const queryParamsAsRecord = entriesToRecord(
    new URLSearchParams(locationSearch).entries(),
  );
  return isEmpty(queryParamsAsRecord) ? undefined : queryParamsAsRecord;
};
