import {
  Button,
  MenuItem,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { Box } from '@mui/system';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { orderApiService } from '../../../api/order';
import { handleApi } from '../../../common/http_util';
import { OrderStatus } from '../../../entity/order';
import { useMoyoProcessingDialog } from '../popup/MoyoProcessingDialog';
import { AlarmTalkButton } from '../AlarmTalk/AlarmTalkButton';
import { useToastAlarmTalkSend } from '../AlarmTalk/ToastAlarmTalkSend';
import pbl from '../../../pbl/pbl-service/pbl-service';

const CANCEL_MESSAGE =
  '취소를 하면 고객에 대한 모든 정보가 사라지니 주의해주세요!\n정보가 삭제되는 것에 대해서 고객에게도 꼭 안내해주세요.';

type OrderHistoryCreateProps = {
  orderId: string;
  onSubmit?: () => void;
  statusList: Array<OrderStatus>;
  isMoyoProcessing?: boolean;
  userName?: string;
};

const colGroupDefinition = (
  <colgroup>
    <col style={{ width: '20%' }} />
    <col style={{ width: '20%' }} />
    <col style={{ width: '60%' }} />
  </colgroup>
);

export default function OrderHistoryCreate({
  orderId,
  onSubmit,
  statusList,
  isMoyoProcessing,
  userName,
}: OrderHistoryCreateProps) {
  const navigate = useNavigate();

  const [status, setStatus] = useState<OrderStatus>();
  const [statusDetailList, setStatusDetailList] = useState<Array<string>>([]);
  const [statusDetail, setStatusDetail] = useState<string>('none');
  const [memo, setMemo] = useState<string>('');
  const [sendAlarmTalk, setSendAlarmTalk] = useState<boolean>(true);
  const moyoProcessingDialog = useMoyoProcessingDialog();
  const toastAlarmTalkSend = useToastAlarmTalkSend();

  const onStatusChanged = (value: OrderStatus | string) => {
    const newStatus = statusList.find((s: OrderStatus) => s.key === value);
    setStatus(newStatus);
  };

  useEffect(() => {
    if (status) {
      setStatusDetail('none');
      setStatusDetailList(status.details);
    }
  }, [status]);

  const onClickSubmit = useCallback(async () => {
    pbl('click', 'button', 'here', {
      object: {
        id: 'add_order_history',
        section: 'order_history',
      },
      eventProperties: {
        orderId,
        newStatus: status?.key,
        memo,
        newStatusDetail: statusDetail === 'none' ? undefined : statusDetail,
        sendAlimtalk: sendAlarmTalk,
      },
    });
    if (isMoyoProcessing) {
      const rs = await moyoProcessingDialog.open('order_history');
      if (!rs) {
        return;
      }
    }

    if (statusDetailList.length > 0 && statusDetail === 'none') {
      alert('상세 이유를 선택해주세요');
      return;
    }

    try {
      const template = sendAlarmTalk
        ? await handleApi(
            () => orderApiService.getAlarmTalkTemplate(orderId, status?.key),
            navigate,
            (err) => {
              alert(err);
            },
          )
        : '';
      await handleApi(
        () =>
          orderApiService.updateOrderStatus(
            [orderId],
            status?.key,
            statusDetail === 'none' ? undefined : statusDetail,
            memo,
            sendAlarmTalk,
          ),
        navigate,
        (err) => {
          alert(err);
        },
      );

      if (onSubmit) await onSubmit();

      if (template) {
        toastAlarmTalkSend.open(userName || '고객', template);
      }

      reset();
    } catch (err) {
      console.log(err);
    } finally {
      setStatusDetail('none');
    }
  }, [
    isMoyoProcessing,
    status,
    statusDetailList,
    statusDetail,
    navigate,
    onSubmit,
    orderId,
    memo,
    sendAlarmTalk,
  ]);

  const reset = () => {
    setStatus(undefined);
    setStatusDetail('none');
    setStatusDetailList([]);
    setMemo('');
  };

  return (
    <>
      <TableContainer component={Paper} elevation={5}>
        <Table
          sx={{
            td: {
              backgroundColor: 'transparent',
              fontWeight: 'normal',
            },
          }}
        >
          {colGroupDefinition}
          <TableHead>
            <TableRow>
              <TableCell>상태</TableCell>
              <TableCell>상세 사유</TableCell>
              <TableCell>상담 메모</TableCell>
              <TableCell style={{ fontSize: 12 }}>알림톡 발송</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <Select
                  id={'select_status'}
                  value={status?.key ?? 'none'}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (
                      (value === 'APPLICATION_CANCELED' ||
                        value === 'ACTIVATION_CANCELED') &&
                      !window.confirm(CANCEL_MESSAGE)
                    ) {
                      return;
                    }
                    onStatusChanged(value);
                  }}
                  fullWidth
                >
                  <MenuItem value={'none'} disabled>
                    상태값을 선택해주세요
                  </MenuItem>
                  {statusList.map((status) => (
                    <MenuItem key={status.key} value={status.key}>
                      {status.name}
                    </MenuItem>
                  ))}
                </Select>
              </TableCell>
              <TableCell>
                {statusDetailList.length > 0 && (
                  <Select
                    id={'select_status_detail'}
                    value={statusDetail}
                    onChange={(e) => setStatusDetail(e.target.value)}
                    fullWidth
                  >
                    <MenuItem value={'none'} disabled>
                      상세 상태값을 선택해주세요
                    </MenuItem>
                    {statusDetailList.map((detail) => (
                      <MenuItem key={detail} value={detail}>
                        {detail}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </TableCell>
              <TableCell>
                <TextField
                  multiline
                  rows={2}
                  value={memo}
                  onChange={(e) => setMemo(e.target.value)}
                  fullWidth
                  inputProps={{
                    maxLength: 300,
                  }}
                />
              </TableCell>
              <TableCell>
                <Switch
                  checked={sendAlarmTalk}
                  onChange={(e) => setSendAlarmTalk(e.target.checked)}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        display="flex"
        justifyContent="center"
        marginTop={3}
        position="relative"
      >
        <Button variant="contained" onClick={onClickSubmit}>
          상담 등록하기
        </Button>
        <Box position="absolute" right={0}>
          <AlarmTalkButton userName={userName} orderId={orderId} />
        </Box>
      </Box>
    </>
  );
}
