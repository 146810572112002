import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useRole } from '../../../router/useRole';

import CopyButton from '../../../shared/components/copyButton';

export function MoyoLinkCopy() {
  const { orderId } = useParams();
  const { isMoyoRole } = useRole();

  if (!(orderId && isMoyoRole)) return <></>;
  return (
    <Box
      sx={{
        display: 'flex',
        gap: 2,
      }}
    >
      <div>
        <text>개통요청 링크</text>
        <CopyButton
          actionName="request_activation_link"
          copyText={`https://www.moyoplan.com/mypage/orders/${orderId}/requestActivation`}
          style={{ marginLeft: '10px', color: 'CaptionText' }}
          size="small"
        />
      </div>
      <div>
        <text>원통 링크</text>
        <CopyButton
          actionName="one_tong_link"
          copyText={`https://www.moyoplan.com/mypage/orders/${orderId}/requestSelfActivation`}
          style={{ marginLeft: '10px', color: 'CaptionText' }}
          size="small"
        />
      </div>
    </Box>
  );
}
