import { ReactNode } from 'react';
import { BootstrapDialog, BootstrapDialogTitle } from './Dialog';
import { Button, DialogActions, DialogContent } from '@mui/material';

type ConfirmDialogProps = {
  title: string;
  description: ReactNode;
  open: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  onCancel?: () => void;
  confirmText?: string;
};
const ConfirmDialog = ({
  title,
  description,
  open,
  onClose,
  onCancel,
  onConfirm,
  confirmText = '확인',
}: ConfirmDialogProps) => {
  const handleConfirm = onConfirm ?? onClose;

  return (
    <BootstrapDialog
      aria-labelledby="customized-dialog-title"
      open={open}
      onClose={onClose}
    >
      <BootstrapDialogTitle id="customized-dialog-title">
        {title}
      </BootstrapDialogTitle>
      <DialogContent style={{ width: '300px', paddingBottom: '20px' }}>
        {description}
      </DialogContent>
      <DialogActions
        style={{ justifyContent: 'flex-end', padding: '8px', gap: '4px' }}
      >
        {onCancel && (
          <Button variant={'text'} onClick={onCancel}>
            닫기
          </Button>
        )}
        <Button variant={'contained'} onClick={handleConfirm}>
          {confirmText}
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};

export default ConfirmDialog;
