import { assert } from '../../utils/validator';

interface MetadataForDownloading {
  data: BlobPart[];
  contentType: string;
  filename: string;
}

const downloadBlobViaAnchorTag = (blob: Blob, name: string) => {
  const anchor = document.createElement('a');
  anchor.href = URL.createObjectURL(blob);
  anchor.target = '_self';
  anchor.download = name;
  anchor.click();
  window.URL.revokeObjectURL(anchor.href);
};

export const download = (metadataForDownloading: MetadataForDownloading) => {
  const blob = new Blob(metadataForDownloading.data, {
    type: metadataForDownloading.contentType,
  });
  downloadBlobViaAnchorTag(blob, metadataForDownloading.filename);
};

const getFileName = (contentDisposition: string) => {
  // attachment;filename=orders.xlsx와 같은 string을 처리한다.
  const fileName = contentDisposition
    .split(';')
    .filter((el) => el.indexOf('filename') > -1)
    .map((ele) => ele.replace(/"/g, '').split('=')[1]);
  return fileName[0];
};

export const makeMetadataForDownloading = async (
  response: Response,
): Promise<MetadataForDownloading> => {
  const contentType = response.headers.get('Content-Type');
  assert(contentType, 'Content-Type is not provided');

  const contentDiposition = response.headers.get('Content-Disposition');
  assert(contentDiposition, 'Content-Disposition is not provided');

  const responseBody = await response.blob();
  return {
    data: [responseBody],
    contentType: contentType,
    filename: getFileName(decodeURIComponent(contentDiposition)),
  };
};
