import { AxiosResponse } from 'axios';
import { assert } from '../../utils/validator';

function convertAxiosDataToBlob<T>(data: T, contentType: string) {
  if (contentType === 'application/json') {
    return new Blob([JSON.stringify(data)], { type: contentType });
  }

  return new Blob([data as BlobPart], { type: contentType });
}

export function convertAxiosResponseToFetchResponse<T>(
  axiosResponse: AxiosResponse<T>,
): Response {
  const { status, statusText, headers, data } = axiosResponse;

  const headersObj = new Headers();
  Object.entries(headers).forEach(([key, value]) => {
    headersObj.append(key, value as string);
  });

  const contentType = headersObj.get('Content-Type');
  assert(contentType, 'Content-Type is not provided');

  const response = new Response(convertAxiosDataToBlob(data, contentType), {
    status,
    statusText,
    headers: headersObj,
  });

  return response;
}
