import { ReleaseNoteItem } from './releaseNotes';
import ReleaseStatusType from './ReleaseStatusType';

const Release_2_2_0: ReleaseNoteItem = {
  version: '2.2.0',
  date: '2023-06-01',
  title: '문의하기 기능 추가',
  details: [
    {
      title: '문의하기 기능 추가',
      contents: [
        {
          content: (
            <>
              채널톡을 통해 모요 CX팀에 바로 문의 할 수 있는 기능이
              <br />
              추가되었습니다.
              <br />
              <br />
              좌측 하단 사용자 프로필 {'>'} 문의하기를 통해 이용하실 수
              있습니다.
            </>
          ),
          status: ReleaseStatusType.NEW,
        },
      ],
    },
  ],
};

export default Release_2_2_0;
