import { useAuth } from '../context/UserContext';
import { Role } from '../types/AuthType';

export function useCheckRole() {
  const { roles } = useAuth();

  const checkRole = (requiredRoles: Role[]) => {
    if (requiredRoles.length > 0) {
      if (!requiredRoles.some((role) => roles.includes(role))) {
        return false;
      }
    }

    return true;
  };

  return { checkRole };
}
