import { Controller, useFormContext } from 'react-hook-form';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TableCell,
  TableRow,
} from '@mui/material';

import { MobilePlanOrder } from '../../../entity/order';

interface PayerProps {
  order: MobilePlanOrder;
}

export function Payer({ order }: PayerProps) {
  const { control } = useFormContext<MobilePlanOrder>();

  return (
    <TableRow>
      <TableCell>납부대상자</TableCell>
      <TableCell>
        <FormControl>
          <Controller
            name="detail.payerChecked"
            control={control}
            defaultValue={!!order?.detail?.payerChecked}
            render={({ field }) => (
              <RadioGroup {...field} row>
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="본인"
                />
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="타인"
                />
              </RadioGroup>
            )}
          />
        </FormControl>
      </TableCell>
    </TableRow>
  );
}
