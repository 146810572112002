import { Box, Button, Typography } from '@mui/material';
import pbl from '../../../pbl/pbl-service/pbl-service';

interface TitleProps {
  title: string;
  onCancel: () => void;
}

export function Title({ title, onCancel }: TitleProps) {
  const onCancelHandler = () => {
    onCancel();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography variant="h5" my={3}>
        {title}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          gap: 1,
        }}
      >
        <Button variant="outlined" color="warning" onClick={onCancelHandler}>
          취소
        </Button>
        <Button type="submit" variant="contained">
          저장
        </Button>
      </Box>
    </Box>
  );
}
