import { AxiosResponse } from 'axios';
import { moyoApi } from '../../../common/http_util';
import {
  BasicInfoDto,
  CustomerCenterInfo,
  CustomerServiceContactList,
  ExceedRateList,
  MnoOperationPolicyByMoyoList,
  MnoOperationPolicyByOperatorList,
  MobilePlanOperatorAdminDto,
  OperatingHoursInfoList,
  UsimInfo,
} from '../types/mobile-plan-operator-admin-types';
import { ApiResponse } from '../../../entity/api';

export type MvnoInfoPutFunction<T> = (
  data: T,
) => Promise<AxiosResponse<ApiResponse<T>>>;

class MvnoInfoApiService {
  getMobilePlanOrderator(
    mobilePlanOperatorId: number,
  ): Promise<AxiosResponse<ApiResponse<MobilePlanOperatorAdminDto>>> {
    return moyoApi.get(`/mobile-plan-operator/${mobilePlanOperatorId}`);
  }

  putBasicInfo(
    mobilePlanOperatorId: number,
    baseInfo: BasicInfoDto,
  ): Promise<AxiosResponse<ApiResponse<BasicInfoDto>>> {
    return moyoApi.put(
      `/mobile-plan-operator/${mobilePlanOperatorId}/basic-info`,
      baseInfo,
    );
  }

  private _putOperatingHours(
    mobilePlanOperatorId: number,
    operatingHoursInfoList: OperatingHoursInfoList,
  ): Promise<AxiosResponse<ApiResponse<OperatingHoursInfoList>>> {
    return moyoApi.put(
      `/mobile-plan-operator/${mobilePlanOperatorId}/operating-hours`,
      operatingHoursInfoList,
    );
  }

  private _putCustomerServiceContact(
    mobilePlanOperatorId: number,
    customerServiceContactList: CustomerServiceContactList,
  ): Promise<AxiosResponse<ApiResponse<CustomerServiceContactList>>> {
    return moyoApi.put(
      `/mobile-plan-operator/${mobilePlanOperatorId}/customer-service-contact`,
      customerServiceContactList,
    );
  }

  async putCustomerCenter(
    mobilePlanOperatorId: number,
    customerCenterInfo: CustomerCenterInfo,
  ): Promise<AxiosResponse<ApiResponse<CustomerCenterInfo>>> {
    const [operatingHoursInfoListResponse, customerServiceContactListResponse] =
      await Promise.all([
        this._putOperatingHours(
          mobilePlanOperatorId,
          customerCenterInfo.operatingHoursInfoList,
        ),
        this._putCustomerServiceContact(
          mobilePlanOperatorId,
          customerCenterInfo.customerServiceContactList,
        ),
      ]);
    if (!customerServiceContactListResponse.data.result) {
      return {
        ...operatingHoursInfoListResponse,
        data: {
          ...operatingHoursInfoListResponse.data,
          result: undefined,
        },
      };
    }
    if (!operatingHoursInfoListResponse.data.result) {
      return {
        ...operatingHoursInfoListResponse,
        data: {
          ...operatingHoursInfoListResponse.data,
          result: undefined,
        },
      };
    }

    return {
      ...operatingHoursInfoListResponse,
      data: {
        ...operatingHoursInfoListResponse.data,
        result: {
          customerServiceContactList:
            customerServiceContactListResponse.data.result,
          operatingHoursInfoList: operatingHoursInfoListResponse.data.result,
        },
      },
    };
  }

  putUsimInfo(
    mobilePlanOperatorId: number,
    usimInfo: UsimInfo,
  ): Promise<AxiosResponse<ApiResponse<UsimInfo>>> {
    return moyoApi.put(
      `/mobile-plan-operator/${mobilePlanOperatorId}/usim-info`,
      usimInfo,
    );
  }

  putMnoOperationPolicyByOperatorList(
    mobilePlanOperatorId: number,
    mnoOperationPolicyByOperatorList: MnoOperationPolicyByOperatorList,
  ): Promise<AxiosResponse<ApiResponse<MnoOperationPolicyByOperatorList>>> {
    return moyoApi.put(
      `/mobile-plan-operator/${mobilePlanOperatorId}/mno-operation-policy-by-operator`,
      mnoOperationPolicyByOperatorList,
    );
  }

  putMnoOperationPolicyByMoyoList(
    mobilePlanOperatorId: number,
    mnoOperationPolicyByMoyoList: MnoOperationPolicyByMoyoList,
  ): Promise<AxiosResponse<ApiResponse<MnoOperationPolicyByMoyoList>>> {
    return moyoApi.put(
      `/mobile-plan-operator/${mobilePlanOperatorId}/mno-operation-policy-by-moyo`,
      mnoOperationPolicyByMoyoList,
    );
  }

  putExceedRate(
    mobilePlanOperatorId: number,
    exceedRate: ExceedRateList,
  ): Promise<AxiosResponse<ApiResponse<ExceedRateList>>> {
    return moyoApi.put(
      `/mobile-plan-operator/${mobilePlanOperatorId}/exceed-rate`,
      exceedRate,
    );
  }
}

const mvnoInfoApiService = new MvnoInfoApiService();

export default mvnoInfoApiService;
