import { Dialog, DialogTitle, styled } from '@mui/material';

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: '10px 25px',
    width: '444px',
  },
  '& .MuiDialogActions-root': {
    padding: '30px 30px',
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
}

export const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, pb: 2, pt: 2 }} {...other}>
      {children}
    </DialogTitle>
  );
};
