import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';

import { ColumnGroupDefinition } from './ColumnGroupDefinition';
import { MobilePlanOrder } from '../../../entity/order';

type Props = {
  order: MobilePlanOrder;
};

export function OrderUsimDeliverySection({ order }: Props) {
  if (!order.usimDelivery) return null;

  return (
    <>
      <Typography variant="h6" my={2}>
        (모요) 유심 배송 정보
      </Typography>
      <TableContainer component={Paper} elevation={5}>
        <Table>
          {ColumnGroupDefinition}
          <TableBody>
            <TableRow>
              <TableCell>배송 상태</TableCell>
              <TableCell colSpan={3}>{order.usimDelivery.status}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>택배사</TableCell>
              <TableCell>{order.usimDelivery.deliveryCompany}</TableCell>
              <TableCell>송장 번호</TableCell>
              <TableCell>{order.usimDelivery.deliveryTrackingNumber}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
