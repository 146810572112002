import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { orderApiService } from '../../api/order';
import { useLoading } from '../../context/LoadingContext';
import pbl from '../../pbl/pbl-service/pbl-service';
import { download, makeMetadataForDownloading } from '../../domains/file';
import { convertAxiosResponseToFetchResponse } from '../../converters/network/response';

type Props = {
  filterData?: any;
  open: boolean;
  onClose: () => void;
};

export default function UinExcelDownloadPopup({
  filterData,
  open,
  onClose,
}: Props) {
  const { setLoading } = useLoading();

  const onClickDownload = async () => {
    pbl('click', 'modal_button', 'here', {
      object: {
        id: 'export_excel_for_uinplus',
        section: 'action_menu',
      },
      eventProperties: {
        filterData,
      },
    });

    setLoading(true);
    try {
      const res = await orderApiService.exportExcelForUinplus(filterData);
      download(
        await makeMetadataForDownloading(
          convertAxiosResponseToFetchResponse(res),
        ),
      );
    } catch (err) {
      alert('엑셀 파일 다운로드에 실패했습니다. 잠시 후 다시 시도해주세요.');
    } finally {
      setLoading(false);
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>엑셀로 추출하기</DialogTitle>
      <DialogContent>
        <DialogContentText>
          유인 플러스에 보낼 주문을 엑셀로 추출합니다.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClickDownload}>추출하기</Button>
      </DialogActions>
    </Dialog>
  );
}
