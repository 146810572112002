import { useFormContext } from 'react-hook-form';
import { TableCell, TableRow, TextField } from '@mui/material';

import { MobilePlanOrder } from '../../../entity/order';

export function FeeInfo() {
  const { register } = useFormContext<MobilePlanOrder>();

  return (
    <TableRow>
      <TableCell>위약금</TableCell>
      <TableCell>
        <TextField
          aria-readonly
          disabled
          {...register('detail.penaltyFee')}
          type="number"
        />
      </TableCell>
      <TableCell>잔여 할부금</TableCell>
      <TableCell>
        <TextField
          aria-readonly
          disabled
          {...register('detail.installmentFee')}
          type="number"
        />
      </TableCell>
    </TableRow>
  );
}
