import {
  Box,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { ColumnGroupDefinition } from './ColumnGroupDefinition';
import {
  convertStringToPaymentMethod,
  convertStringToPaymentNotiChannel,
} from '../../../common/order-util';
import { comma } from '../../../common/string_util';
import { MobilePlanOrder } from '../../../entity/order';
import { VerticalColorTable } from '../../../shared/components/coloredTable';
import CopyButton from '../../../shared/components/copyButton';
import { PayerIdCardButton } from './PayerIdCardbutton';

type Props = {
  order: MobilePlanOrder;
};

export function OrderPaymentInfoSection({ order }: Props) {
  if (!order.detail) return null;

  return (
    <>
      <Typography variant="h6" my={2}>
        요금 납부 정보
      </Typography>
      <TableContainer component={Paper} elevation={5}>
        <VerticalColorTable>
          {ColumnGroupDefinition}
          <TableBody>
            <TableRow>
              <TableCell>수령인 이름</TableCell>
              <TableCell>
                {order.detail.recipientName}{' '}
                {order.detail.recipientName && (
                  <CopyButton
                    actionName="recipientName"
                    copyText={order.detail.recipientName}
                    style={{ marginLeft: '10px' }}
                  />
                )}
              </TableCell>
              <TableCell>수령인 연락처</TableCell>
              <TableCell>
                {order.detail.recipientPhoneNumber?.replace(
                  /(\d{3})(\d{4})(\d{4})/,
                  '$1-$2-$3',
                )}
                {order.detail.recipientPhoneNumber && (
                  <CopyButton
                    actionName="recipientPhoneNumber"
                    copyText={order.detail.recipientPhoneNumber?.replace(
                      /(\d{3})(\d{4})(\d{4})/,
                      '$1-$2-$3',
                    )}
                    style={{ marginLeft: '10px' }}
                  />
                )}
              </TableCell>
            </TableRow>

            {/*납부자 관련 */}
            {order.detail.payerName ? (
              <>
                <TableRow>
                  <TableCell>납부자 이름</TableCell>
                  <TableCell>
                    {order.detail.payerName}
                    {order.detail.payerIdCardImageUrl && (
                      <PayerIdCardButton
                        src={order.detail.payerIdCardImageUrl}
                      />
                    )}
                    {order.detail.payerName && (
                      <CopyButton
                        actionName="payerName"
                        copyText={order.detail.payerName}
                        style={{ marginLeft: '10px' }}
                      />
                    )}
                  </TableCell>
                  <TableCell>납부자 생년월일</TableCell>
                  <TableCell>
                    {order.detail.payerBirthday}{' '}
                    {order.detail.payerBirthday && (
                      <CopyButton
                        actionName="payerBirthday"
                        copyText={order.detail.payerBirthday}
                        style={{ marginLeft: '10px' }}
                      />
                    )}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>납부자 연락처</TableCell>
                  <TableCell>
                    {order.detail.payerPhoneNumber?.replace(
                      /(\d{3})(\d{4})(\d{4})/,
                      '$1-$2-$3',
                    )}
                    {order.detail.payerPhoneNumber && (
                      <CopyButton
                        actionName="payerPhoneNumber"
                        copyText={order.detail.payerPhoneNumber?.replace(
                          /(\d{3})(\d{4})(\d{4})/,
                          '$1-$2-$3',
                        )}
                        style={{ marginLeft: '10px' }}
                      />
                    )}
                  </TableCell>
                  <TableCell>신청자와의 관계</TableCell>
                  <TableCell>{order.detail.payerRelationship}</TableCell>
                </TableRow>
              </>
            ) : (
              <></>
            )}

            <TableRow>
              <TableCell>이메일</TableCell>
              <TableCell>
                {order.detail.email}{' '}
                {order.detail.email && (
                  <CopyButton
                    actionName="email"
                    copyText={order.detail.email}
                    style={{ marginLeft: '10px' }}
                  />
                )}
              </TableCell>
              <TableCell>주소</TableCell>
              <TableCell
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Box display={'flex'} flexDirection={'row'}>
                  <Typography fontSize={14} mt={0.5}>
                    {order.detail.addressLevel1}
                  </Typography>
                  <CopyButton
                    actionName="addressLevel1"
                    copyText={`${order.detail.addressLevel1}`}
                    style={{ marginLeft: '10px' }}
                  />
                </Box>
                <Box display={'flex'} flexDirection={'row'}>
                  <Typography fontSize={14} mt={0.5}>
                    {order.detail.addressLevel2}
                  </Typography>
                  <CopyButton
                    actionName="addressLevel2"
                    copyText={`${order.detail.addressLevel2}`}
                    style={{ marginLeft: '10px' }}
                  />
                </Box>
                {`(${order.detail.postalCode})`}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>청구서</TableCell>
              <TableCell>
                {convertStringToPaymentNotiChannel(
                  order.detail.paymentNotiChannel,
                )}
              </TableCell>
              <TableCell>자동 납부 방법</TableCell>
              <TableCell>
                {convertStringToPaymentMethod(order.detail.paymentMethod)}
              </TableCell>
            </TableRow>
            {order.detail.paymentMethod === 'account' && (
              <TableRow>
                <TableCell>은행</TableCell>
                <TableCell>
                  {order.detail.bank}{' '}
                  <CopyButton
                    actionName="bank"
                    copyText={order.detail.bank ?? ''}
                    style={{ marginLeft: '10px' }}
                  />
                </TableCell>
                <TableCell>계좌번호</TableCell>
                <TableCell>
                  {order.detail.accountNumber}{' '}
                  <CopyButton
                    actionName="accountNumber"
                    copyText={`${order?.detail?.accountNumber}`}
                    style={{ marginLeft: '10px' }}
                  />
                </TableCell>
              </TableRow>
            )}
            {order.detail.paymentMethod === 'card' && (
              <TableRow>
                <TableCell>카드번호</TableCell>
                <TableCell
                  sx={{
                    display: 'flex',
                  }}
                >
                  <Typography fontSize={14} mt={0.5}>
                    {order.detail.cardCompany} {order.detail.cardNumber}
                  </Typography>
                  <CopyButton
                    actionName="cardNumber"
                    copyText={`${order?.detail?.cardNumber?.replaceAll(
                      '-',
                      '',
                    )}`}
                    style={{ marginLeft: '10px' }}
                  />
                </TableCell>
                <TableCell>유효기간</TableCell>
                <TableCell>
                  {`${order.detail.cardExpiresMonth}/${order.detail.cardExpiresYear}`}
                  <CopyButton
                    actionName="cardExpiresMonth"
                    copyText={`${order.detail.cardExpiresMonth}/${order.detail.cardExpiresYear}`}
                    style={{ marginLeft: '10px' }}
                  />
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell>위약금</TableCell>
              <TableCell>{comma(order.detail.penaltyFee)}원</TableCell>
              <TableCell>잔여 할부금</TableCell>
              <TableCell>{comma(order.detail.installmentFee)}원</TableCell>
            </TableRow>
          </TableBody>
        </VerticalColorTable>
      </TableContainer>
    </>
  );
}
