import { QueryClient, useQuery, useSuspenseQuery } from '@tanstack/react-query';
import mvnoInfoApiService from '../api/mvno-info-api-service';
import {
  MobilePlanOperatorAdminDto,
  PlanManagementMethodType,
} from '../types/mobile-plan-operator-admin-types';

export const queryClient = new QueryClient();

type ToUnion<T> = {
  [K in keyof T]: { [P in K]: T[K] };
}[keyof T];

export function useMobilePlanOperatorAdmin(mobilePlanOperatorId: number) {
  const { data } = useQuery({
    queryKey: ['mvnoInfoApiService', mobilePlanOperatorId],
    queryFn: async () => {
      const result = (
        await mvnoInfoApiService.getMobilePlanOrderator(mobilePlanOperatorId)
      ).data.result;
      queryClient.setQueryData(
        ['mvnoInfoApiService', mobilePlanOperatorId],
        result,
      );
      return result;
    },
  });

  const update = (_data: ToUnion<MobilePlanOperatorAdminDto>) => {
    queryClient.setQueryData(['mvnoInfoApiService', mobilePlanOperatorId], {
      ...data,
      ..._data,
    });
  };

  return { data, update };
}
