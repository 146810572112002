import { GridSortModel } from '@mui/x-data-grid';
import { MobilePlanOrder } from '../../../entity/order';

export interface PaginationState {
  orders: MobilePlanOrder[];
  totalCount: number;
  page: number;
  pageSize: number;
  sortModel: GridSortModel;
}

export const initialPaginationState: PaginationState = {
  orders: [],
  totalCount: 0,
  page: 0,
  pageSize: 25,
  sortModel: [],
};
