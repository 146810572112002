import { useMemo } from 'react';
import { Box, Divider, Link, Typography } from '@mui/material';

import { ReservationStatus } from '../../../entity/plan';

import { planApiService } from '../../../api/plan';

import { useLoading } from '../../../context/LoadingContext';

import React from 'react';
import {
  DeleteReserveBlock,
  ChangeReserveBlock,
  SummaryBlock,
  ReadyReserveBlock,
} from '../styled';
import { CheckCircleOutline, WarningAmber } from '@mui/icons-material';
import { DateTime } from 'luxon';
import { getPlanStatus } from '../../../helpers/plan.helper';
import { useModal } from '../../../hooks/useModal';
import ConfirmDialog from '../Dialog/ConfirmDialog';
import MvnoInfoItem from '../../MvnoInfoPage/Section/Common/MvnoInfoItem';
import {
  MvnoInfoItemBody,
  MvnoInfoItemRow,
} from '../../MvnoInfoPage/Section/Common/MvnoInfoItemFrame';
import { useThemeControl } from '../../../context/ThemeControlContext';
import { AdminPlanMeta, PlanMetaV2 } from '../../../entity/plan-v2';

type PlanBasicInfoComponentProps = {
  planMeta: PlanMetaV2;
  adminPlanMeta: AdminPlanMeta;
  onRefresh: () => void;
};

const PlanBasicInfoComponent = ({
  planMeta,
  adminPlanMeta,
  onRefresh,
}: PlanBasicInfoComponentProps) => {
  const { setLoading } = useLoading();
  const themeControl = useThemeControl();
  const { openModal, closeModal } = useModal();

  const isFreeAmount = (amount: number) => {
    return amount === 9999 || amount === 999;
  };

  const planSummaryInfo = useMemo(
    () => [
      {
        label: '월 데이터 제공량',
        value: isFreeAmount(planMeta.mobileData || 0)
          ? '무제한'
          : planMeta.mobileDataStr || '없음',
      },
      {
        label: '일 데이터 제공량',
        value: isFreeAmount(planMeta.mobileData2 || 0)
          ? '무제한'
          : planMeta.mobileData2Str || '없음',
      },
      {
        label: '소진 시 속도',
        value:
          Math.floor(planMeta.speedWhenExhausted || 0) !== 0
            ? planMeta.mobileDataDateExhaustedDescription
            : '없음',
      },
      {
        label: '문자 제공량',
        value: planMeta.mobileMessage
          ? isFreeAmount(planMeta.mobileMessage)
            ? '무제한'
            : `${planMeta.mobileMessage}개`
          : '없음',
      },
      {
        label: '통화 제공량',
        value: planMeta.mobileVoice
          ? isFreeAmount(planMeta.mobileVoice)
            ? '무제한'
            : `${planMeta.mobileVoice} 분`
          : '없음',
      },
    ],
    [
      planMeta.mobileData,
      planMeta.mobileData2,
      planMeta.mobileData2Str,
      planMeta.mobileDataDateExhaustedDescription,
      planMeta.mobileDataStr,
      planMeta.mobileMessage,
      planMeta.mobileVoice,
      planMeta.speedWhenExhausted,
    ],
  );

  const handleCancelReserve = async () => {
    let reserveCancelType = '';

    if (adminPlanMeta.state === 'DELETE_RESERVED') {
      reserveCancelType = '종료';
    }

    if (adminPlanMeta.reservationStatus === ReservationStatus.OPEN_RESERVED) {
      reserveCancelType = '노출';
    }

    if (adminPlanMeta.reservationStatus === ReservationStatus.CHANGE_RESERVED) {
      reserveCancelType = '수정';
    }

    openModal(
      <ConfirmDialog
        open
        title={`${reserveCancelType}예약을 취소할까요?`}
        description={`취소한 이후에는 복구할 수 없어요.`}
        onClose={closeModal}
        onCancel={closeModal}
        confirmText="예약 취소"
        onConfirm={async () => {
          if (!adminPlanMeta.reserveId) {
            return;
          }

          try {
            setLoading(true);
            await planApiService.deletePlanReserveEdit(adminPlanMeta.reserveId);
            onRefresh();
            closeModal();
          } catch (error) {
            throw error;
          } finally {
            setLoading(false);
          }
        }}
      />,
    );
  };

  const getReserveType = () => {
    if (adminPlanMeta.reservationStatus === ReservationStatus.OPEN_RESERVED) {
      return {
        text: '노출예약',
        color: 'success',
      };
    }

    return getPlanStatus(adminPlanMeta.state);
  };

  return (
    <Box mt={3}>
      {adminPlanMeta.reservationStatus === ReservationStatus.OPEN_RESERVED && (
        <ReadyReserveBlock>
          <Box sx={{ padding: '2px 12px 7px 0' }}>
            <CheckCircleOutline sx={{ fontSize: '20px' }} />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '4px',
              width: '100%',
            }}
          >
            <Typography variant="subtitle2">
              요금제 {getReserveType()?.text}
            </Typography>
            <Typography variant="subtitle2" sx={{ whiteSpace: 'nowrap' }}>
              {DateTime.fromISO(adminPlanMeta.reserveTime || '').toFormat(
                'yy-MM-dd HH:mm',
              )}{' '}
              요금제 {getReserveType()?.text}
            </Typography>
          </Box>

          <Typography
            sx={{
              whiteSpace: 'nowrap',
              padding: '4px 5px',
              cursor: 'pointer',
            }}
            variant="subtitle2"
            onClick={handleCancelReserve}
          >
            예약 취소하기
          </Typography>
        </ReadyReserveBlock>
      )}

      {adminPlanMeta.reservationStatus ===
        ReservationStatus.CHANGE_RESERVED && (
        <ChangeReserveBlock>
          <Box sx={{ padding: '2px 12px 7px 0' }}>
            <CheckCircleOutline sx={{ fontSize: '20px' }} />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '4px',
              width: '100%',
            }}
          >
            <Typography variant="subtitle2">
              요금제 {getReserveType()?.text}
            </Typography>
            <Typography variant="subtitle2" sx={{ whiteSpace: 'nowrap' }}>
              {DateTime.fromISO(adminPlanMeta.reserveTime || '').toFormat(
                'yy-MM-dd HH:mm',
              )}{' '}
              요금제 {getReserveType()?.text}
            </Typography>
          </Box>

          <Typography
            sx={{
              whiteSpace: 'nowrap',
              padding: '4px 5px',
              cursor: 'pointer',
            }}
            variant="subtitle2"
            onClick={handleCancelReserve}
          >
            예약 취소하기
          </Typography>
        </ChangeReserveBlock>
      )}

      {adminPlanMeta.reservationStatus === ReservationStatus.CLOSE_RESERVED && (
        <DeleteReserveBlock>
          <Box sx={{ padding: '2px 12px 7px 0' }}>
            <WarningAmber sx={{ color: 'warning.main', fontSize: '20px' }} />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '4px',
              width: '100%',
              color: '#663C00',
            }}
          >
            <Typography variant="subtitle2">요금제 종료 예약</Typography>
            <Typography variant="subtitle2" sx={{ whiteSpace: 'nowrap' }}>
              {DateTime.fromISO(adminPlanMeta.reserveTime || '').toFormat(
                'yy-MM-dd HH:mm',
              )}{' '}
              요금제 종료 예약
            </Typography>
          </Box>

          <Typography
            sx={{
              whiteSpace: 'nowrap',
              color: '#663C00',
              padding: '4px 5px',
              cursor: 'pointer',
            }}
            variant="subtitle2"
            onClick={handleCancelReserve}
          >
            예약 취소하기
          </Typography>
        </DeleteReserveBlock>
      )}

      <MvnoInfoItemBody gap={4}>
        <SummaryBlock
          sx={{
            backgroundColor: themeControl.theme.palette.neutral[2],
            border: `1px solid ${themeControl.theme.palette.neutral[3]}`,
          }}
        >
          {planSummaryInfo.map((item, index) => (
            <React.Fragment key={index}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                  width: '120px',
                }}
              >
                <Typography variant="caption" color="text.secondary">
                  {item.label}
                </Typography>
                <Typography variant="body1">{item.value}</Typography>
              </Box>
              {index < 4 && (
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ height: '40px', margin: 'auto 0' }}
                />
              )}
            </React.Fragment>
          ))}
        </SummaryBlock>
        <MvnoInfoItemRow gap={3}>
          <MvnoInfoItem
            title="자사몰 신청 링크 (PC)"
            content={
              planMeta.signupUrl ? (
                <Link
                  href={planMeta.signupUrl}
                  target="_blank"
                  rel="noreferrer"
                  underline="none"
                >
                  <Typography variant="body1" sx={{ wordWrap: 'break-word' }}>
                    {planMeta.signupUrl}
                  </Typography>
                </Link>
              ) : (
                <Typography variant="body1" color="error">
                  입력 필요
                </Typography>
              )
            }
          />
          <MvnoInfoItem
            title="자사몰 신청 링크 (모바일)"
            content={
              planMeta.mobileSignupUrl ? (
                <Link
                  href={planMeta.mobileSignupUrl}
                  target="_blank"
                  rel="noreferrer"
                  underline="none"
                >
                  <Typography variant="body1" sx={{ wordWrap: 'break-word' }}>
                    {planMeta.mobileSignupUrl}
                  </Typography>
                </Link>
              ) : (
                <Typography
                  variant="body1"
                  color="error"
                  sx={{ wordBreak: 'break-word' }}
                >
                  입력 필요
                </Typography>
              )
            }
          />
        </MvnoInfoItemRow>
      </MvnoInfoItemBody>
    </Box>
  );
};

export default PlanBasicInfoComponent;
