import styled from '@emotion/styled';
import { Logout } from '@mui/icons-material';
import { Button, CircularProgress } from '@mui/material';
import { useEffect } from 'react';
import pbl from '../../../pbl/pbl-service/pbl-service';

interface Props {
  loading: boolean;
  onClick: () => void;
  loggingParams: Record<string, string | undefined> & { section?: string };
}

export function SendToMnoButton({ loading, onClick, loggingParams }: Props) {
  useEffect(() => {
    const { section, ...params } = loggingParams;
    pbl('impression', 'button', 'here', {
      object: {
        id: 'send_to_mno_button',
        name: '전산에 보내기 버튼',
        section: section,
      },
      eventProperties: {
        ...params,
      },
    });
  }, []);

  return (
    <ActionButtonHug variant="contained" disabled={loading} onClick={onClick}>
      {loading ? (
        <CircularProgress size={16} color="inherit" />
      ) : (
        '전산에 보내기'
      )}
      <SendIcon />
    </ActionButtonHug>
  );
}

const ActionButtonHug = styled(Button)`
  min-width: 130px;
  height: 36px;
  padding: 6px 10px 6px 13.5px;
  margin-top: 16px;
`;

const SendIcon = styled(Logout)`
  width: 18px;
  height: 18px;
  margin-left: 8px;
`;
