import { Grid, TextField, Typography } from '@mui/material';

import { useSearchSection } from '../hooks';

export function CustomerPhoneNumberSection() {
  const { searchState, searchHandler } = useSearchSection();

  const { customerPhoneNumber } = searchState;
  const { setCustomerPhoneNumber } = searchHandler;

  return (
    <Grid item xs={12} md={6}>
      <Typography mb={1}>전화번호</Typography>
      <TextField
        label=" - 없이 전화번호를 입력해주세요 ( ex: 01012345678 )"
        value={customerPhoneNumber}
        onChange={(event) => setCustomerPhoneNumber(event.target.value)}
        size="small"
        fullWidth
      />
    </Grid>
  );
}
