import { useFormContext } from 'react-hook-form';
import { TableCell, TableRow, TextField } from '@mui/material';

import { MobilePlanOrder } from '../../../entity/order';

interface EmailAndAddressProps {
  openAddressPopup: () => void;
}

const postalCodeErrorMessage = '우편번호를 입력해 주세요.';
const addressLevel1ErrorMessage = '주소를 입력해 주세요.';

export function EmailAndAddress({ openAddressPopup }: EmailAndAddressProps) {
  const {
    register,
    formState: { errors },
  } = useFormContext<MobilePlanOrder>();

  return (
    <TableRow>
      <TableCell>이메일</TableCell>
      <TableCell>
        <TextField
          {...register('detail.email', {
            required: false,
          })}
          size="small"
        />
      </TableCell>
      <TableCell>주소</TableCell>
      <TableCell>
        <TextField
          {...register('detail.postalCode', {
            required: postalCodeErrorMessage,
          })}
          size="small"
          onClick={openAddressPopup}
          InputProps={{
            readOnly: true,
          }}
          error={!!errors.detail?.postalCode}
          helperText={errors.detail?.postalCode?.message}
        />
        <TextField
          {...register('detail.addressLevel1', {
            required: addressLevel1ErrorMessage,
          })}
          sx={{
            width: '100%',
            mt: 1,
          }}
          size="small"
          onClick={openAddressPopup}
          InputProps={{
            readOnly: true,
          }}
          error={!!errors.detail?.addressLevel1}
          helperText={errors.detail?.addressLevel1?.message}
        />
        <TextField
          {...register('detail.addressLevel2')}
          sx={{
            width: '100%',
            mt: 1,
          }}
          size="small"
        />
      </TableCell>
    </TableRow>
  );
}
