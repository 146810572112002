import { getCookie, setCookie } from '../../common/cookie';
import { generateUUID } from '../../common/string_util';

const PBL_DEVICE_ID_KEY = 'pbl_device_id';

const getPblDeviceId = () => {
  const pblDeviceId = getCookie(PBL_DEVICE_ID_KEY) ?? createPblDeviceId();
  return pblDeviceId;
};

const createPblDeviceId = () => {
  const deviceId = generateUUID();
  setCookie(PBL_DEVICE_ID_KEY, deviceId);
  return deviceId;
};

export default getPblDeviceId;
