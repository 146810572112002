import { Button, Grid, Paper, styled, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { NestedValue, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { authApiService } from '../../api/auth';
import { CookieConstants } from '../../common/constants';
import { setCookie, setToken } from '../../common/cookie';
import { useAuth } from '../../context/UserContext';
import { useSecureSession } from '../../hooks/useSecureSession';

const ImageBox = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  img: {
    width: '50%',
    mixBlendMode: theme.palette.mode === 'light' ? 'unset' : 'plus-lighter',
  },
}));

export default function LoginPage() {
  const state = useLocation();
  const redirectUrl = new URLSearchParams(state.search).get('redirect-url');
  const { setUserInfo } = useAuth();
  const { register, handleSubmit } = useForm<{
    username: NestedValue<String>;
    password: NestedValue<String>;
  }>();
  const navigate = useNavigate();
  const { initializeSession } = useSecureSession();

  const handleLogin = async (data: any) => {
    try {
      // Status List 제거
      setCookie(CookieConstants.STATUS_OPTIONS, '');
      setCookie(CookieConstants.INTERNET_STATUS_OPTIONS, '');

      const res = await authApiService.login(data.username, data.password);

      const user = res.data.result;

      if (user) {
        const expires = new Date();
        expires.setTime(expires.getTime() + 12 * 60 * 60 * 1000);

        setToken(user.token, {
          path: '/',
          expires,
        });
        await setUserInfo({
          id: user.id,
          username: user.username,
        });
        initializeSession();

        navigate(decodeURIComponent(redirectUrl ?? '/'));
      }
    } catch (err: any) {
      const errorMessage =
        err?.response?.data?.error ?? '알 수 없는 오류가 발생했습니다.';
      alert(errorMessage);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <Grid
          container
          sx={{
            justifyContent: 'center',
          }}
        >
          <Grid item xs={10} md={8} lg={6} xl={5}>
            <Paper
              component="form"
              elevation={5}
              sx={{
                padding: '28px',
              }}
              onSubmit={handleSubmit(handleLogin)}
            >
              <ImageBox>
                <img src="/moyo_partners.png" alt="모요 파트너스 로고" />
              </ImageBox>
              <TextField
                {...register('username', {
                  required: true,
                })}
                fullWidth
                id="username"
                label="사용자 이름"
                variant="outlined"
                margin="normal"
              />
              <TextField
                {...register('password', {
                  required: true,
                })}
                fullWidth
                id="password"
                label="비밀번호"
                variant="outlined"
                margin="normal"
                type="password"
              />
              <Button
                sx={{ mt: 1 }}
                fullWidth
                variant="contained"
                size="large"
                type="submit"
              >
                로그인
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
