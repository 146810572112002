import { Typography } from '@mui/material';

import {
  BasicInfoSection,
  HTTPS_PROTOCOL_PREFIX,
} from './components/BasicInfoSection';
import { Header, PageContainer } from './styled';
import { PlanSpecificSection } from './components/PlanSpecificSection';
import { PlanInfoSection } from './components/PlanInfoSection';
import { AdditionalInfoSection } from './components/AdditionalInfoSection';

import { useForm, FormProvider } from 'react-hook-form';
import { ICreatePlanDto } from '../../entity/plan';
import { planApiService } from '../../api/plan';

import { useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import { getISOSimpleFormat } from '../../utils/dateFormatter';

import { AddBottomButtonGroup } from './components/AddBottomButtonGroup';
import { EditBottomButtonGroup } from './components/EditBottomButtonGroup';
import { useLoading } from '../../context/LoadingContext';
import { useEffect } from 'react';

export default function PlanEdit({
  isEdit,
  planId,
  displayPlan,
  createPlanDto,
}: {
  isEdit: boolean;
  planId?: number;
  displayPlan: boolean;
  createPlanDto: ICreatePlanDto;
}) {
  const navigate = useNavigate();
  const { setLoading } = useLoading();

  const methods = useForm<ICreatePlanDto>({
    defaultValues: createPlanDto,
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
  });
  const formType = isEdit ? '수정' : '등록';

  const {
    handleSubmit,
    trigger,
    formState: { isDirty, isValid },
  } = methods;

  useEffect(() => {
    if (isEdit) trigger();
  }, []);

  const formatPlanData = (data: ICreatePlanDto, date?: DateTime | null) => ({
    ...data,
    basicData: data.basicData
      ? Number(data.basicData.toString().replace(/,/g, ''))
      : 0,
    dailyData: data.dailyData
      ? Number(data.dailyData.toString().replace(/,/g, ''))
      : 0,
    discountFee: data.discountFee
      ? Number(data.discountFee.toString().replace(/,/g, ''))
      : 0,
    discountPeriod: data.discountPeriod,
    originalFee: data.originalFee
      ? Number(data.originalFee.toString().replace(/,/g, ''))
      : 0,
    reserveTime: date ? getISOSimpleFormat(date) : '',
    additionalCall:
      data.additionalCall === null ? null : Number(data.additionalCall),

    signupUrl: `${HTTPS_PROTOCOL_PREFIX}${data.signupUrl}`,
    mobileSignupUrl: `${HTTPS_PROTOCOL_PREFIX}${data.mobileSignupUrl}`,
  });

  const handlePlanAction = async (
    action: (data: ICreatePlanDto) => Promise<any>,
    date?: DateTime | null,
  ) => {
    handleSubmit(async (data: ICreatePlanDto) => {
      try {
        setLoading(true);
        const formattedData = formatPlanData(data, date);
        const response = await action(formattedData);

        const planId = response?.data?.result?.id;
        const errorMessage = response?.response?.data?.error;

        if (errorMessage) {
          alert(errorMessage);
          return;
        }

        if (planId) {
          navigate(`/plans/${planId}`);
        }
      } catch (error) {
        alert(error);
        throw error;
      } finally {
        setLoading(false);
      }
    })();
  };

  const handleConfirmOnlyRegister = () => {
    handlePlanAction(planApiService.createPlan);
  };

  const handleConfirmExpose = (date: DateTime | null) => {
    handlePlanAction(planApiService.createPlan, date);
  };

  const handleEditPlan = () => {
    handlePlanAction((data) => planApiService.putPlan(Number(planId), data));
  };

  const handleReserveEditPlan = (date: DateTime | null) => {
    handlePlanAction(
      (data) => planApiService.putPlan(Number(planId), data),
      date,
    );
  };

  return (
    <PageContainer>
      <Header>
        <Typography variant="h4">요금제 {formType}</Typography>
      </Header>

      <FormProvider {...methods}>
        {/* TODO - 각 form에 required 추가 */}
        <form noValidate>
          {/* 기본 정보 */}
          <BasicInfoSection />
          {/* 요금제 스펙  */}
          <PlanSpecificSection isEdit={isEdit} />
          {/* 요금 정보  */}
          <PlanInfoSection
            isEdit={isEdit}
            initialAgreementPeriod={createPlanDto.agreementPeriod}
            initialDiscountFee={createPlanDto.discountFee}
            initialDiscountPeriod={createPlanDto.discountPeriod}
            initialOriginalFee={createPlanDto.originalFee}
          />
          {/* 부가 정보 */}

          <AdditionalInfoSection />

          {/* 하단 버튼 */}
          {isEdit ? (
            <EditBottomButtonGroup
              onReserve={(date: DateTime | null) => {
                handleReserveEditPlan(date);
              }}
              onValidate={trigger}
              onOk={handleEditPlan}
              displayPlan={displayPlan}
              isDirty={isDirty}
              isValid={isValid}
            />
          ) : (
            <AddBottomButtonGroup
              onOnlyRegister={handleConfirmOnlyRegister}
              onValidate={trigger}
              onExpose={(date: DateTime | null) => {
                handleConfirmExpose(date);
              }}
            />
          )}
        </form>
      </FormProvider>
    </PageContainer>
  );
}
