export const flattenObject = (obj: any, roots = [], sep = '.'): any =>
  Object.keys(obj).reduce(
    (memo, prop) =>
      Object.assign(
        {},
        memo,
        Object.prototype.toString.call(obj[prop]) === '[object Object]'
          ? // @ts-ignore
            flattenObject(obj[prop], roots.concat([prop]), sep)
          : // @ts-ignore
            { [roots.concat([prop]).join(sep)]: obj[prop] },
      ),
    {},
  );
