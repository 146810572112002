import { companyApiService } from '../api/company';
import { planApiService } from '../api/plan';

// 궁극적으로는 api 로직을 공통화하고 이 파일을 없앱시다..
export const fetchCompanyInfo = async () => {
  const response = await companyApiService.getInfo();

  return response.data.result;
};

// 궁극적으로는 api 로직을 공통화하고 이 파일을 없앱시다..
export const updatePlanCommonInfo = async (
  mvno: string,
  mno: string,
  data: any,
) => {
  const response = await planApiService.editPlanCommonInfo(mvno, mno, data);

  return response.data.result;
};
