import { AxiosResponse } from 'axios';
import { getToken } from '../common/cookie';
import { authApi } from '../common/http_util';
import { ApiResponse } from '../entity/api';
import { UserDto } from '../entity/user';
import { Permission, Role } from '../types/AuthType';

export interface UserMe {
  id: number;
  name: string;
  adminUserId: string;
  partnerCompanyId: number;
  roles: Role[];
  permissions: Permission[];
}

class AuthApiService {
  login(
    username: string,
    password: string,
  ): Promise<AxiosResponse<ApiResponse<UserDto>>> {
    return authApi.post(
      '/api/partners-admin/users/login',
      { username, password },
      { withCredentials: true },
    );
  }
  resetPassword(username: string, password: string, newPassword: string) {
    const token = getToken();

    return authApi.put(
      '/api/partners-admin/users/password',
      {
        username,
        password,
        newPassword,
      },
      {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }

  me() {
    const token = getToken();

    return authApi.get<ApiResponse<UserMe>>('/api/partners-admin/users/me', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  getMembers() {
    const token = getToken();

    return authApi.get<ApiResponse<{ members: UserMe[] }>>(
      '/api/partners-admin/users',
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }

  /**
   * 유저 계정생성
   * @param name
   * @param password
   * @param userId
   */
  createMember(name: string, password: string, userId: string) {
    const token = getToken();
    return authApi.post(
      '/api/partners-admin/users/register',
      {
        name,
        password,
        userId,
      },
      {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }

  updatePermission(adminUserId: string, permissions: Permission[]) {
    const token = getToken();

    return authApi.put(
      '/api/partners-admin/users/update',
      {
        adminUserId,
        permissions,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }

  deleteMember(id: string) {
    const token = getToken();
    return authApi.delete(`/api/partners-admin/users/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}

export const authApiService = new AuthApiService();
