import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import {
  createTheme,
  PaletteMode,
  Theme,
  ThemeOptions,
  ThemeProvider,
} from '@mui/material';
import { grey } from '@mui/material/colors';

const neutral = {
  1: '#FFFFFF',
  2: '#FAFAFA',
  3: '#F5F5F5',
  4: '#F0F0F0',
  5: '#D9D9D9',
  6: '#BFBFBF',
  7: '#8C8C8C',
  8: '#595959',
  9: '#434343',
  10: '#262626',
  11: '#1F1F1F',
  12: '#141414',
  13: '#000000',
};

const darkNeutral = {
  1: neutral[13],
  2: neutral[12],
  3: neutral[11],
  4: neutral[10],
  5: neutral[9],
  6: neutral[8],
  7: neutral[7],
  8: neutral[6],
  9: neutral[5],
  10: neutral[4],
  11: neutral[3],
  12: neutral[2],
  13: neutral[1],
};

declare module '@mui/material/styles' {
  interface Palette {
    neutral: typeof neutral;
  }
}

const getDesignTokens = (mode: PaletteMode): ThemeOptions => ({
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          background: {
            default: grey[50],
          },
        }
      : {
          background: {
            default: 'rgb(18, 18, 18)',
          },
        }),
    ...(mode === 'light'
      ? {
          neutral: { ...neutral },
        }
      : {
          neutral: { ...darkNeutral },
        }),
  },
});

interface ThemeContext {
  theme: Theme;
  onChangeTheme: () => void;
}
const ThemeControlContext = createContext<ThemeContext>({
  theme: createTheme(getDesignTokens('light')),
  onChangeTheme: () => {},
});

interface ThemeControlProviderProps {
  children: ReactNode;
}

const ThemeControlProvider: FC<ThemeControlProviderProps> = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState<boolean>(
    localStorage.getItem('moyo_partners_theme') === 'dark',
  );
  const theme = useMemo(() => {
    return createTheme(getDesignTokens(isDarkMode ? 'dark' : 'light'));
  }, [isDarkMode]);

  const onChangeTheme = useCallback(() => {
    setIsDarkMode((rs) => {
      try {
        localStorage.setItem('moyo_partners_theme', !rs ? 'dark' : 'light');
      } catch (error) {
        console.log('storage error-onChangeTheme', error);
      }
      return !rs;
    });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <ThemeControlContext.Provider
        value={{
          theme,
          onChangeTheme,
        }}
      >
        {children}
      </ThemeControlContext.Provider>
    </ThemeProvider>
  );
};

const useThemeControl = () => {
  return useContext(ThemeControlContext);
};

export { ThemeControlProvider, useThemeControl };
