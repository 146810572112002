import { Box, Button, TextField } from '@mui/material';
import { FormEvent, useState } from 'react';

interface Props {
  onSubmit: (
    id: string,
    password: string,
    name: string,
  ) => void | Promise<void>;
}

export const AccountCreatingForm = ({ onSubmit: onSubmitOuter }: Props) => {
  const [loading, setLoading] = useState(false);
  const [isInvalidPasswordCheck, setIsInvalidPasswordCheck] =
    useState<boolean>(false);

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const target = event.target as typeof event.target & {
      id: { value: string };
      name: { value: string };
      password: { value: string };
      password_check: { value: string };
    };

    setIsInvalidPasswordCheck(false);

    try {
      guard(
        () => target.password.value === target.password_check.value,
        () => Error('invalid password_check'),
      );

      await onSubmitOuter(
        target.id.value,
        target.password.value,
        target.name.value,
      );

      alert(target.name.value + '님의 계정이 생성되었습니다');
    } catch (error) {
      if (error instanceof Error) {
        switch (error.message) {
          case 'invalid password_check':
            setIsInvalidPasswordCheck(true);
            break;
        }
        return;
      }

      // TODO: handle api error
      /**
       *  만약 axios를 쓴다면
       *  if (error instanceof AxiosError) { ... }
       *  혹은 network error에 대한 error class가 있다면
       *  if (error instanceof SomeNetworkError) { ... }
       */
    } finally {
      setLoading(false);
    }
  };
  return (
    <Box
      component="form"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        '& > div': { marginBottom: '12px' },
        margin: 'auto',
        minWidth: '640px',
      }}
      onSubmit={onSubmit}
    >
      <TextField id="id" label="아이디" required />
      <TextField id="name" label="이름" required />
      <TextField id="password" type="password" label="비밀번호" required />
      <TextField
        id="password_check"
        type="password"
        label="비밀번호 확인"
        required
        helperText={
          isInvalidPasswordCheck ? '비밀번호와 일치하지 않습니다.' : ''
        }
      />
      <Button type="submit" variant="contained" disabled={loading}>
        생성
      </Button>
    </Box>
  );
};

const guard = (
  fn: (...args: unknown[]) => boolean,
  throwError: () => Error,
): void => {
  if (!fn()) {
    throw throwError();
  }
};
