import { ReactNode } from 'react';
import Release_2_1_4 from './release-2.1.4';
import Release_2_1_5_egmobile from './release-2.1.5-egmobile';
import Release_2_1_5_ins from './release-2.1.5-ins';
import Release_2_1_6 from './release-2.1.6';
import Release_2_1_7_marvelring from './release-2.1.7-marvelring';
import ReleaseStatusType from './ReleaseStatusType';
import Release_2_1_8 from './release-2.1.8';
import Release_2_2_0 from './release-2.2.0';
import Release_2_2_1 from './release-2.2.1';
import Release_2_2_2 from './release-2.2.2';
import Release_2_3_0 from './release-2.3.0';
import Release_2_4_0 from './release-2.4.0';
import Release_2_5_0 from './release-2.5.0';
import Release_2_6_0 from './release-2.6.0';
import Release_2_7_0 from './release-2.7.0';
import Release_2_8_0 from './release-2.8.0';
import Release_2_9_0 from './release-2.9.0';
import Release_2_9_1 from './release-2.9.1';

interface ReleaseNoteDetail {
  title: string;
  contents: ReleaseNoteDetailContent[];
}

interface ReleaseNoteDetailContent {
  content: ReactNode;
  status: ReleaseStatusType;
}

interface ReleaseNoteItem {
  version: string; // major.minor.hotfix
  date: string; // yyyy-mm-dd
  title: string;
  authorizedMvnos?: string[];
  details: ReleaseNoteDetail[];
}

type ReleaseNoteList = ReleaseNoteItem[];

const ReleaseNotes: ReleaseNoteList = [
  Release_2_9_1,
  Release_2_9_0,
  Release_2_8_0,
  Release_2_7_0,
  Release_2_6_0,
  Release_2_5_0,
  Release_2_4_0,
  Release_2_3_0,
  Release_2_2_2,
  Release_2_2_1,
  Release_2_2_0,
  Release_2_1_8,
  Release_2_1_7_marvelring,
  Release_2_1_6,
  Release_2_1_5_ins,
  Release_2_1_5_egmobile,
  Release_2_1_4,
];

export default ReleaseNotes;

export { ReleaseStatusType as ReleaseStatus };

export type { ReleaseNoteItem };
