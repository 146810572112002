import { Box, Button, TextField, Typography } from '@mui/material';

import { MobilePlanOrder } from '../../../entity/order';
import { OrderHistoryList } from './OrderHistoryList';
import OrderHistoryCreate from './OrderHistoryCreate';
import { useOptions } from '../../../context/OptionsContext';
import { useMemo } from 'react';
import { isProcessingActivationState } from '../../../shared/components/autoActivateStateChip';
import { MoyoLinkCopy } from './MoyoLinkCopy';

interface OrderHistorySectionProps {
  orderId?: string;
  order?: MobilePlanOrder;
  orderNumber: string;
  onHistorySubmit: () => void;
  onClickSaveNumber: () => void;
  onChageOrderNumber: (value: string) => void;
}

export function OrderHistorySection({
  orderId,
  order,
  orderNumber,
  onHistorySubmit,
  onClickSaveNumber,
  onChageOrderNumber,
}: OrderHistorySectionProps) {
  const { statusList } = useOptions();

  const statusSelectList = useMemo(() => {
    return statusList.filter((s) => s.orderType === order?.orderType);
  }, [statusList, order]);

  return (
    <>
      <Box
        my={2}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h6">상담 이력</Typography>
        <MoyoLinkCopy />
      </Box>
      {order && order.registerType === 'new' && (
        <Box sx={{ my: 3 }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              mt: 3,
            }}
          >
            <Typography marginRight={1}>
              (신규 가입 시) 개통 번호 입력 :
            </Typography>
            <TextField
              size="small"
              sx={{
                mr: 3,
                width: '300px',
              }}
              placeholder="숫자만 입력(예: 01012341234)"
              type="number"
              value={orderNumber}
              onChange={(e) => onChageOrderNumber(e.target.value)}
            />
            <Button variant="contained" onClick={onClickSaveNumber}>
              저장
            </Button>
          </Box>
          <Typography variant="caption">
            개통 번호 입력 시 자동으로 "선개통"으로 상태가 변경됩니다.
          </Typography>
        </Box>
      )}
      {orderId && (
        <OrderHistoryCreate
          orderId={orderId}
          userName={order?.customerName}
          onSubmit={onHistorySubmit}
          statusList={statusSelectList}
          isMoyoProcessing={isProcessingActivationState(
            order?.autoActivateState,
          )}
        />
      )}
      <OrderHistoryList historyList={order?.historyList} />
    </>
  );
}
