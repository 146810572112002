export function ColumnGroupDefinition() {
  return (
    <colgroup>
      <col style={{ width: '15%' }} />
      <col style={{ width: '35%' }} />
      <col style={{ width: '15%' }} />
      <col style={{ width: '35%' }} />
    </colgroup>
  );
}
