import { AxiosResponse } from 'axios';
import { internetOrderApi } from '../common/http_util';
import { ApiResponse } from '../entity/api';
import qs from 'query-string';
import {
  InternetOrder,
  InternetOrderListDto,
  InternetOrderStatusOptions,
} from '../entity/internet-order';

class InternetOrderApiService {
  getList(
    data: any,
    page: number,
    size: number,
    sortField?: string,
    sortDirection?: string,
  ): Promise<AxiosResponse<ApiResponse<InternetOrderListDto>>> {
    let queryParams: any = {
      page,
      size,
    };

    if (sortField && sortDirection) {
      queryParams = {
        ...queryParams,
        sort: `${sortField},${sortDirection}`,
      };
    }

    return internetOrderApi.post(`list?${qs.stringify(queryParams)}`, data);
  }

  getOrderStatusOptions(): Promise<
    AxiosResponse<ApiResponse<InternetOrderStatusOptions>>
  > {
    return internetOrderApi.get('/status');
  }

  getOrder(
    orderId: string,
  ): Promise<AxiosResponse<ApiResponse<InternetOrder>>> {
    return internetOrderApi.get(`/detail/${orderId}`);
  }

  updateOrderStatus(
    orderId: string,
    status?: string,
    statusDetail?: string,
    memo?: string,
  ): Promise<AxiosResponse<ApiResponse<InternetOrder>>> {
    return internetOrderApi.put('/status', {
      id: orderId,
      status,
      statusDetail,
      memo,
    });
  }

  exportToExcel(data: any): Promise<AxiosResponse<any>> {
    return internetOrderApi.post(`export/excel`, data, {
      responseType: 'arraybuffer',
    });
  }
}

export const internetOrderApiService = new InternetOrderApiService();
