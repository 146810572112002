import styled from '@emotion/styled';
import { Box, Divider, Typography } from '@mui/material';

export const PageContainer = styled(Box)({
  width: '100%',
  padding: 40,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '40px',
});

export const BodyContainer = styled(Box)({
  width: '860px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '40px',
});

export const Header = styled(Box)({
  width: '100%',
  padding: '16px 0',
});

export const Section = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  width: '860px',
});

export const SectionHeader = styled(Box)<{ flexDirection?: 'row' | 'column' }>(
  ({ flexDirection }) => ({
    display: 'flex',
    flexDirection: flexDirection ?? 'column',
    justifyContent: 'space-between',
    gap: '8px',
    padding: '16px 0',
    width: '100%',
  }),
);

export const SectionTitle = styled(Typography)({
  padding: '0',
});

export const FormContent = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '56px',
});

export const InputRow = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'start',
  gap: 32,
});

export const SwitchRow = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: 1,
  width: '100%',
});

export const SectionDivider = styled(Divider)({
  marginTop: 20,
});

export const InputWithToggleBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
  flex: '1 0 0',
});

export const BottomButtonRow = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  minWidth: '860px',
  gap: '8px',
  marginTop: '56px',
});
