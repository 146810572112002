import { pblPageviewEventPropertiesProcessor } from './pbl-pageview-event-properties-process';
import { pblPageviewNavigationProcessor } from './pbl-pageview-navigation-process';

export const getNavigationWithProcess = (
  navigation: string,
  category: string,
  eventProperties?: Record<string, unknown>,
) => {
  if (navigation === 'here') {
    return pblPageviewNavigationProcessor.getHereNavigation() ?? navigation;
  }
  if (category === 'pageview') {
    pblPageviewNavigationProcessor.updatePreviousNavigation();
    pblPageviewEventPropertiesProcessor.updateCurrentPageviewEventProperties(
      eventProperties,
    );
    pblPageviewNavigationProcessor.updatePageviewUuid();
    pblPageviewNavigationProcessor.updateNavigation(navigation);
  }
  return navigation;
};
