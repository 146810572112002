import { Controller, useFormContext } from 'react-hook-form';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  TableCell,
  TableRow,
} from '@mui/material';

import { MobilePlanOrder } from '../../../entity/order';

interface PaymentInfoProps {
  order: MobilePlanOrder;
}

const paymentNotiChannelErrorMessage = '청구서 수신 방법을 선택해 주세요.';
const paymentMethodErrorMessage = '자동 납부 방법을 선택해 주세요.';

export function PaymentInfo({ order }: PaymentInfoProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext<MobilePlanOrder>();

  return (
    <TableRow>
      <TableCell>청구서</TableCell>
      <TableCell>
        <FormControl>
          <Controller
            name="detail.paymentNotiChannel"
            rules={{ required: paymentNotiChannelErrorMessage }}
            control={control}
            defaultValue={order.detail?.paymentNotiChannel ?? ''}
            render={({ field }) => (
              <RadioGroup {...field} row>
                <FormControlLabel
                  value="sms"
                  control={<Radio />}
                  label="문자"
                />
                <FormControlLabel
                  value="email"
                  control={<Radio />}
                  label="이메일"
                />
                <FormControlLabel
                  value="mail"
                  control={<Radio />}
                  label="우편"
                />
              </RadioGroup>
            )}
          />
        </FormControl>
        <FormHelperText error={!!errors.detail?.paymentNotiChannel}>
          {errors.detail?.paymentNotiChannel?.message}
        </FormHelperText>
      </TableCell>
      <TableCell>자동 납부 방법</TableCell>
      <TableCell>
        <FormControl>
          <Controller
            name="detail.paymentMethod"
            rules={{ required: paymentMethodErrorMessage }}
            control={control}
            defaultValue={order.detail?.paymentMethod ?? ''}
            render={({ field }) => (
              <RadioGroup {...field} row>
                <FormControlLabel
                  value="account"
                  control={<Radio />}
                  label="계좌"
                />
                <FormControlLabel
                  value="card"
                  control={<Radio />}
                  label="카드"
                />
              </RadioGroup>
            )}
          />
        </FormControl>
        <FormHelperText error={!!errors.detail?.paymentMethod}>
          {errors.detail?.paymentMethod?.message}
        </FormHelperText>
      </TableCell>
    </TableRow>
  );
}
