import { AxiosResponse } from 'axios';
import { orderApi } from '../common/http_util';
import { ApiResponse } from '../entity/api';

class AlarmTalkService {
  getAlarmTalkTemplate(
    orderId: string,
    reason: string,
    detailReason?: string[],
  ): Promise<AxiosResponse<ApiResponse<string>>> {
    return orderApi.post(`/alarm-talk/template`, {
      orderId,
      reason,
      detailReason: detailReason?.join(','),
    });
  }

  sendAlarmTalk(
    orderId: string,
    reason: string,
    detailReason?: string[],
  ): Promise<AxiosResponse<ApiResponse<boolean>>> {
    return orderApi.post(`/alarm-talk`, {
      orderId,
      reason,
      detailReason: detailReason?.join(','),
    });
  }
}

export const alarmTalkService = new AlarmTalkService();
