import { Controller, useFormContext } from 'react-hook-form';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TableCell,
  TableRow,
  TextField,
} from '@mui/material';

import { MobilePlanOrder } from '../../../entity/order';

interface ChangeCertificationProps {
  order: MobilePlanOrder;
}

export function ChangeCertification({ order }: ChangeCertificationProps) {
  const { register, control, watch } = useFormContext<MobilePlanOrder>();
  const watchRegisterType = watch('registerType');

  if (watchRegisterType !== 'change') {
    return null;
  }

  return (
    <TableRow>
      <TableCell>번호이동 인증 수단</TableCell>
      <TableCell>
        <FormControl>
          <Controller
            name="detail.changeCertBillingMethod"
            control={control}
            defaultValue={order.detail?.changeCertBillingMethod ?? ''}
            render={({ field }) => (
              <RadioGroup {...field} row>
                <FormControlLabel
                  value="account"
                  control={<Radio />}
                  label="계좌"
                />
                <FormControlLabel
                  value="card"
                  control={<Radio />}
                  label="카드"
                />
                <FormControlLabel
                  value="giro"
                  control={<Radio />}
                  label="지로"
                />
                <FormControlLabel
                  value="serial"
                  control={<Radio />}
                  label="단말기"
                />
              </RadioGroup>
            )}
          />
        </FormControl>
      </TableCell>
      <TableCell>번호이동 인증 정보</TableCell>
      <TableCell>
        <TextField {...register('detail.changeCertBillingInfo')} size="small" />
      </TableCell>
    </TableRow>
  );
}
