import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import MvnoInfoSignupSupport from './Section/MvnoInfoSignupSupport/MvnoInfoSignupSupport';
import MvnoInfoBasic from './Section/MvnoInfoBasic/MvnoInfoBasic';
import MvnoInfoCustomerCenter from './Section/MvnoInfoCustomerCenter/MvnoInfoCustomerCenter';
import MvnoInfoOperationPolicyByMoyo from './Section/MvnoInfoOperationPolicyByMoyo/MvnoInfoOperationPolicyByMoyo';
import MvnoInfoMnoOperationPolicy from './Section/MvnoInfoMnoOperationPolicy/MvnoInfoMnoOperationPolicy';
import MvnoInfoUsimSupport from './Section/MvnoInfoUsimSupport/MvnoInfoUsimSupport';
import MvnoInfoExceedRate from './Section/MvnoInfoExceedRate/MvnoInfoExceedRate';
import {
  queryClient,
  useMobilePlanOperatorAdmin,
} from './hooks/useMobilePlanOperatorAdmin';
import { QueryClientProvider } from '@tanstack/react-query';
import { useAuth } from '../../context/UserContext';
import { useParams } from 'react-router-dom';
import { env } from '../../utils/env';

const WithDivider = ({ children }: { children: ReactNode }) => {
  const items = React.Children.toArray(children);
  return (
    <>
      {items.map((item, index) => (
        <React.Fragment key={index}>
          {item}
          {index < items.length - 1 && <Divider />}
        </React.Fragment>
      ))}
    </>
  );
};

const MvnoInfo: FC<{ operationId: number }> = ({ operationId }) => {
  const { data } = useMobilePlanOperatorAdmin(operationId);

  const handleMvnoPageOpen = () => {
    const openUrl = env('REACT_APP_BASE_URL');
    window.open(
      openUrl +
        `/mvnos/redirect-by-id/${data?.basicInfo.mobilePlanOperatorMetaId}`,
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 5,
        padding: 5,
      }}
    >
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Typography variant="h4" my={2}>
          통신사 정보
        </Typography>
        <Button onClick={handleMvnoPageOpen} variant="text" size="large">
          모요 화면 보기
        </Button>
      </Box>
      {data && (
        <Box
          sx={{
            width: '860px',
            minWidth: 860,
            margin: 'auto',
          }}
        >
          <MvnoInfoSignupSupport
            applicationMethodList={data.applicationMethodList}
            isMoyoPartner={data.operatorStatus.isMoyoPartner}
            brandName={data.basicInfo.brandName}
            mobilePlanOperatorMetaId={data.basicInfo.mobilePlanOperatorMetaId}
            logoUrl={data.basicInfo.logoUrl}
          />
          <Stack flexDirection={'column'} gap={7}>
            <WithDivider>
              <MvnoInfoBasic
                basicInfo={data.basicInfo}
                mobilePlanOperatorId={data.basicInfo.mobilePlanOperatorMetaId}
              />
              <MvnoInfoCustomerCenter
                mobilePlanOperatorId={data.basicInfo.mobilePlanOperatorMetaId}
                customerCenterInfo={{
                  customerServiceContactList: data.customerServiceContactList,
                  operatingHoursInfoList: data.operatingHoursInfoList,
                }}
              />
              {data.mnoOperationPolicyByMoyoList && (
                <MvnoInfoOperationPolicyByMoyo
                  mnoOperationPolicyByMoyoList={
                    data.mnoOperationPolicyByMoyoList
                  }
                  mobilePlanOperatorId={data.basicInfo.mobilePlanOperatorMetaId}
                />
              )}
              <MvnoInfoMnoOperationPolicy
                mnoOperationPolicyByOperatorList={
                  data.mnoOperationPolicyByOperatorList
                }
                mobilePlanOperatorId={data.basicInfo.mobilePlanOperatorMetaId}
              />
              <MvnoInfoUsimSupport
                usimInfo={data.usimInfoList}
                mobilePlanOperatorId={data.basicInfo.mobilePlanOperatorMetaId}
              />
              <MvnoInfoExceedRate
                exceedRate={data.exceedRateList}
                mobilePlanOperatorId={data.basicInfo.mobilePlanOperatorMetaId}
              />
            </WithDivider>
          </Stack>
        </Box>
      )}
    </Box>
  );
};

const MvnoInfoPage: FC = () => {
  const { user } = useAuth();
  const { operationId: operationIdParams } = useParams();
  const operationId = operationIdParams ? parseInt(operationIdParams) : null;
  const userOperatorId = user?.company?.mobilePlanOperatorId;

  return (
    <QueryClientProvider client={queryClient}>
      {operationId ? (
        <MvnoInfo operationId={operationId} />
      ) : userOperatorId ? (
        <MvnoInfo operationId={userOperatorId} />
      ) : (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {
            // 계정에 mobilePlanOperatorId가 없는 경우에 렌더링 됩니다
          }
          <Typography variant="body1">
            페이지 접근에 실패 했습니다. 모요에 문의해주세요
          </Typography>
        </Box>
      )}
    </QueryClientProvider>
  );
};

export default MvnoInfoPage;
