import { Button } from '@mui/material';
import { FC, useState } from 'react';
import ImageDialog from '../../../shared/components/ImageDialog';

interface PayerIdCardButtonProps {
  src: string;
}

export const PayerIdCardButton: FC<PayerIdCardButtonProps> = ({ src }) => {
  const [openImgDialog, setOpenImgDialog] = useState(false);
  return (
    <>
      <Button
        sx={{ marginLeft: 1, verticalAlign: 'baseline' }}
        size="small"
        onClick={() => setOpenImgDialog(true)}
      >
        신분증 확인
      </Button>
      <ImageDialog
        fit
        download
        src={src}
        open={openImgDialog}
        onClose={() => setOpenImgDialog(false)}
      />
    </>
  );
};
