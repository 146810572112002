export enum MoyoActivationState {
  검증_완료 = '검증_완료',
  검증_대기 = '검증_대기',
  검증_진행중 = '검증_진행중',
  개통_시도_완료 = '개통_시도_완료',
  개통_대기 = '개통_대기',
  개통_진행중 = '개통_진행중',
  처리_완료 = '처리_완료',
  배송_대상 = '배송_대상',
  처리_전 = '처리_전',
  유심구매중 = '유심구매중',
  배송_처리_완료 = '배송_처리_완료',
  KT_바로배송중 = 'KT_바로배송중',
  원통_대기 = '원통_대기',
  KT_바로배송_검증완료_개통불가능 = 'KT_바로배송_검증완료_개통불가능',
  KT_바로배송_검증완료_개통가능 = 'KT_바로배송_검증완료_개통가능',
}
