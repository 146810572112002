import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import {
  FormContent,
  InputRow,
  InputWithToggleBox,
  Section,
  SectionHeader,
  SectionTitle,
} from '../styled';
import styled from '@emotion/styled';
import { DataSpeedUnit, ICreatePlanDto } from '../../../entity/plan';
import { Controller, useFormContext } from 'react-hook-form';
import { useEffect, useState } from 'react';

enum SupportType {
  '제공' = '제공',
  '미제공' = '미제공',
  '통신사에 문의' = '통신사에 문의',
}

export const AdditionalInfoSection = () => {
  const {
    control,
    register,
    setValue,
    getValues,
    watch,
    formState: { errors, isDirty },
  } = useFormContext<ICreatePlanDto>();
  const [showAdditionalCallInput, setShowAdditionalCallInput] = useState(
    Number(getValues('additionalCall')) > 0,
  );
  const [showTetheringInfo, setShowTetheringInfo] = useState(
    getValues('tetheringStatus') === 'PROVIDED',
  );

  const handleChangeAdditionalCallType = (e: SelectChangeEvent<string>) => {
    const value = e.target.value;

    if (value === SupportType.제공) {
      setShowAdditionalCallInput(true);
      setValue('additionalCall', 0, {
        shouldDirty: true,
      });
    } else if (value === SupportType.미제공) {
      setShowAdditionalCallInput(false);
      setValue('additionalCall', -1, {
        shouldDirty: true,
      });
    } else {
      setShowAdditionalCallInput(false);
      setValue('additionalCall', null, {
        shouldDirty: true,
      });
    }
  };

  useEffect(() => {
    if (!isDirty) return;
    const tetheringStatus = watch('tetheringStatus');
    switch (tetheringStatus) {
      case 'CONTACT_MOBILE_PLAN_OPERATOR':
        setShowTetheringInfo(false);
        setValue('tetheringData', undefined, {
          shouldDirty: true,
        });
        setValue('tetheringDataUnit', undefined, {
          shouldDirty: true,
        });
        setValue('tetheringDailyData', undefined, {
          shouldDirty: true,
        });
        setValue('tetheringDailyDataUnit', undefined, {
          shouldDirty: true,
        });
        break;
      case 'NOT_PROVIDED':
        setShowTetheringInfo(false);
        setValue('tetheringData', undefined, {
          shouldDirty: true,
        });
        setValue('tetheringDataUnit', undefined, {
          shouldDirty: true,
        });
        setValue('tetheringDailyData', undefined, {
          shouldDirty: true,
        });
        setValue('tetheringDailyDataUnit', undefined, {
          shouldDirty: true,
        });
        break;
      case 'PROVIDED':
        setShowTetheringInfo(true);
        const tetheringData = getValues('tetheringData') ?? 0;
        const tetheringDailyData = getValues('tetheringDailyData') ?? 0;
        setValue('tetheringData', tetheringData > 0 ? tetheringData : 0, {
          shouldDirty: true,
        });
        setValue(
          'tetheringDailyData',
          tetheringDailyData > 0 ? tetheringDailyData : 0,
          {
            shouldDirty: true,
          },
        );
        break;
    }
  }, [watch('tetheringStatus'), isDirty]);

  const additionalCall = watch('additionalCall');

  return (
    <Section>
      <SectionHeader mt={6}>
        <SectionTitle variant="h5">부가 정보</SectionTitle>
      </SectionHeader>

      <FormContent>
        <InputRow>
          <FormControl style={{ width: '265.3px' }}>
            <ButtonGroupForm>
              <Typography variant="body2" color="text.secondary">
                데이터 쉐어링
              </Typography>
              <Controller
                name="dataSharingSupported"
                control={control}
                rules={{
                  validate: (value) =>
                    value !== null || '데이터 쉐어링 여부를 선택해주세요',
                }}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <ToggleButtonGroup
                      value={field.value}
                      exclusive
                      fullWidth
                      onChange={(_, value) => {
                        field.onChange(value);
                        setValue('dataSharingSupported', value, {
                          shouldValidate: true,
                          shouldDirty: true,
                        });
                      }}
                      aria-labelledby="toggle-label-1"
                    >
                      <ToggleButton color="primary" value={true}>
                        가능
                      </ToggleButton>
                      <ToggleButton color="primary" value={false}>
                        불가능
                      </ToggleButton>
                    </ToggleButtonGroup>
                    {error && (
                      <Typography
                        color="error"
                        variant="caption"
                        sx={{ mt: -1 }}
                      >
                        {error.message}
                      </Typography>
                    )}
                  </>
                )}
              />
            </ButtonGroupForm>
          </FormControl>
          {/* TODO - 통신사 + 망단위에서 받는 정보로 제거 */}
          {/* <FormControl fullWidth>
            <ButtonGroupForm>
              <label>휴대폰 소액 결제</label>
              <Controller
                name="microPaymentSupported"
                control={control}
                rules={{
                  validate: (value) =>
                    value !== null || '휴대폰 소액 결제 여부를 선택해주세요',
                }}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <ToggleButtonGroup
                      value={field.value}
                      exclusive
                      fullWidth
                      onChange={(_, value) => field.onChange(value)}
                      aria-labelledby="toggle-label-1"
                    >
                      <ToggleButton color="primary" value={true}>
                        가능
                      </ToggleButton>
                      <ToggleButton color="primary" value={false}>
                        불가능
                      </ToggleButton>
                    </ToggleButtonGroup>
                    {error && (
                      <Typography
                        color="error"
                        variant="caption"
                        sx={{ mt: -1 }}
                      >
                        {error.message}
                      </Typography>
                    )}
                  </>
                )}
              />
            </ButtonGroupForm>
          </FormControl> */}
          <FormControl style={{ width: '265.3px' }}>
            <ButtonGroupForm>
              <Typography variant="body2" color="text.secondary">
                인터넷 결합
              </Typography>
              <Controller
                name="internetCombineSupported"
                control={control}
                rules={{
                  validate: (value) =>
                    value !== null || '인터넷 결합 여부를 선택해주세요',
                }}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <ToggleButtonGroup
                      value={field.value}
                      exclusive
                      fullWidth
                      onChange={(_, value) => {
                        field.onChange(value);
                        setValue('internetCombineSupported', value, {
                          shouldValidate: true,
                          shouldDirty: true,
                        });
                      }}
                      aria-labelledby="toggle-label-1"
                    >
                      <ToggleButton color="primary" value={true}>
                        가능
                      </ToggleButton>
                      <ToggleButton color="primary" value={false}>
                        불가능
                      </ToggleButton>
                    </ToggleButtonGroup>
                    {error && (
                      <Typography
                        color="error"
                        variant="caption"
                        sx={{ mt: -1 }}
                      >
                        {error.message}
                      </Typography>
                    )}
                  </>
                )}
              />
            </ButtonGroupForm>
          </FormControl>
        </InputRow>

        <InputRow>
          <FormControl fullWidth style={{ width: '265.3px' }}>
            <InputLabel id="demo-simple-select-label">부가통화</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select-label"
              label="부가통화"
              style={{ width: '265.3px' }}
              onChange={handleChangeAdditionalCallType}
              value={
                additionalCall === null
                  ? SupportType['통신사에 문의']
                  : additionalCall === -1
                  ? SupportType.미제공
                  : SupportType.제공
              }
            >
              <MenuItem value={SupportType.제공}>{SupportType.제공}</MenuItem>
              <MenuItem value={SupportType.미제공}>
                {SupportType.미제공}
              </MenuItem>
              <MenuItem value={SupportType['통신사에 문의']}>
                {SupportType['통신사에 문의']}
              </MenuItem>
            </Select>
          </FormControl>

          <Controller
            name="tetheringStatus"
            control={control}
            key={watch('tetheringStatus')}
            render={({ field }) => (
              <FormControl fullWidth style={{ width: '265.3px' }}>
                <InputLabel id="tethering-select-label">
                  핫스팟 (테더링)
                </InputLabel>
                <Select
                  labelId="tethering-select-label"
                  id="tethering-select"
                  label="핫스팟 (테더링)"
                  {...field}
                >
                  <MenuItem value={'PROVIDED'}>{SupportType.제공}</MenuItem>
                  <MenuItem value={'NOT_PROVIDED'}>
                    {SupportType.미제공}
                  </MenuItem>
                  <MenuItem value={'CONTACT_MOBILE_PLAN_OPERATOR'}>
                    {SupportType['통신사에 문의']}
                  </MenuItem>
                </Select>
              </FormControl>
            )}
          />
        </InputRow>
        {/* 조건에따라 render */}
        {(showAdditionalCallInput || showTetheringInfo) && (
          <InputRow style={{ marginTop: '-46px' }}>
            <FormControl
              style={{
                visibility: showAdditionalCallInput ? 'visible' : 'hidden',
                width: '265.3px',
              }}
              fullWidth
            >
              <TextField
                {...register('additionalCall', {
                  required: showAdditionalCallInput
                    ? '부가통화는 필수입니다'
                    : false,
                })}
                id="additionalCall"
                placeholder="입력"
                type="number"
                fullWidth
                value={watch('additionalCall') || ''}
                error={!!errors.additionalCall}
                helperText={errors.additionalCall?.message}
                InputProps={{
                  inputProps: {
                    min: 0,
                    onKeyDown: (e) => e.key === '-' && e.preventDefault(),
                  },
                  endAdornment: (
                    <Typography
                      variant="body1"
                      style={{
                        whiteSpace: 'nowrap',
                      }}
                    >
                      분
                    </Typography>
                  ),
                }}
              />
            </FormControl>

            <FormControl
              style={{
                visibility: showTetheringInfo ? 'visible' : 'hidden',
                width: '265.3px',
              }}
              fullWidth
            >
              <InputWithToggleBox>
                <TextField
                  id="tetheringData"
                  type="number"
                  placeholder="입력"
                  fullWidth
                  required={!showTetheringInfo}
                  error={!!errors.tetheringData}
                  {...register('tetheringData')}
                  helperText={errors.tetheringData?.message}
                  value={watch('tetheringData') || ''}
                  InputProps={{
                    inputProps: {
                      min: 0,
                      onKeyDown: (e) => e.key === '-' && e.preventDefault(),
                    },
                    startAdornment: (
                      <Typography
                        variant="body1"
                        style={{
                          whiteSpace: 'nowrap',
                        }}
                      >
                        매달&nbsp;
                      </Typography>
                    ),
                  }}
                />

                <Controller
                  name="tetheringDataUnit"
                  control={control}
                  render={({ field }) => (
                    <ToggleButtonGroup
                      {...field}
                      exclusive
                      aria-label="text alignment"
                    >
                      <ToggleButton
                        value={DataSpeedUnit.GB}
                        aria-label="centered"
                      >
                        GB
                      </ToggleButton>
                      <ToggleButton
                        value={DataSpeedUnit.MB}
                        aria-label="left aligned"
                      >
                        MB
                      </ToggleButton>
                    </ToggleButtonGroup>
                  )}
                />
              </InputWithToggleBox>
            </FormControl>
          </InputRow>
        )}

        {showTetheringInfo && (
          <InputRow style={{ marginTop: '-46px' }}>
            <FormControl
              style={{
                visibility: 'hidden',
                width: '265.3px',
              }}
              fullWidth
            ></FormControl>

            <FormControl
              style={{
                visibility: showTetheringInfo ? 'visible' : 'hidden',
                width: '265.3px',
              }}
              fullWidth
            >
              <InputWithToggleBox>
                <TextField
                  id="tetheringDailyData"
                  type="number"
                  placeholder="입력"
                  fullWidth
                  {...register('tetheringDailyData')}
                  required={!showTetheringInfo}
                  error={!!errors.tetheringDailyData}
                  value={watch('tetheringDailyData') || ''}
                  helperText={errors.tetheringDailyData?.message}
                  InputProps={{
                    inputProps: {
                      min: 0,
                      onKeyDown: (e) => e.key === '-' && e.preventDefault(),
                    },
                    startAdornment: (
                      <Typography
                        variant="body1"
                        style={{
                          whiteSpace: 'nowrap',
                        }}
                      >
                        매일&nbsp;
                      </Typography>
                    ),
                  }}
                />

                <Controller
                  name="tetheringDailyDataUnit"
                  control={control}
                  render={({ field }) => (
                    <ToggleButtonGroup
                      {...field}
                      exclusive
                      aria-label="text alignment"
                    >
                      <ToggleButton
                        value={DataSpeedUnit.GB}
                        aria-label="centered"
                      >
                        GB
                      </ToggleButton>
                      <ToggleButton
                        value={DataSpeedUnit.MB}
                        aria-label="left aligned"
                      >
                        MB
                      </ToggleButton>
                    </ToggleButtonGroup>
                  )}
                />
              </InputWithToggleBox>
            </FormControl>
          </InputRow>
        )}

        {/* TODO: 추구 기능구현 */}
        {/* <InputRow>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              안내사항 (선택)
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select-label"
              label="안내사항 (선택)"
            ></Select>
          </FormControl>
        </InputRow> */}
      </FormContent>
    </Section>
  );
};

const ButtonGroupForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;

  label {
    color: rgba(0, 0, 0, 0.6);
    font-size: 12px;

    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0.15px;
  }
`;
