import generateSnakeCase from '../../common/generate-snake-case';

export const createPblSuperProperties = (
  superProperties?: Record<string, unknown>,
): Record<string, unknown> => {
  const _superProperties = {
    ...superProperties,
  };
  return generateSnakeCase(_superProperties) as Record<string, unknown>;
};
