import { useEffect, useState } from 'react';
import { UserMe, authApiService } from '../../api/auth';
import { Box, Button, Divider, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import { useLoading } from '../../context/LoadingContext';
import AccountDeletingModal from './AccountDeletingModal';
import pbl from '../../pbl/pbl-service/pbl-service';

const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: '이름',
    width: 360,
  },
];

const AccountDeletingPage = () => {
  const [pageSize, setPageSize] = useState<number>(5);
  const [page, setPage] = useState<number>(0);
  const [selectedIds, setSelectedIds] = useState<GridRowSelectionModel>([]);
  const [selectedUsers, setSelectedUsers] = useState<UserMe[]>([]);
  const [openAuthDeletingModal, setOpenAuthDeletingModal] =
    useState<boolean>(false);
  const [members, setMembers] = useState<UserMe[]>([]);
  const { setLoading } = useLoading();

  useEffect(() => {
    pbl('pageview', 'none', 'account_delete');
  }, []);

  useEffect(() => {
    const loadMembers = async () => {
      setLoading(true);
      try {
        const getMemberService = await authApiService.getMembers();
        setMembers(getMemberService.data.result?.members || []);
      } finally {
        setLoading(false);
      }
    };
    loadMembers();
  }, []);

  const onOpenAuthDeletingModalHandler = () => {
    setOpenAuthDeletingModal(true);
    setSelectedUsers(
      members.filter((member) =>
        selectedIds.find((selectedId) => selectedId === member.id),
      ),
    );
  };
  const onCloseAccountDeletingModalHandler = () => {
    setOpenAuthDeletingModal(false);
  };
  const onDidChangeAccountDeletingHandler = () => {
    const updatedMembers = members.filter(
      (member) => !selectedIds.includes(member.id),
    );
    setMembers(updatedMembers);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <div style={{ minWidth: '640px', margin: 'auto' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h5" my={2}>
            계정 삭제
          </Typography>
          <Button
            variant="outlined"
            disabled={selectedIds.length === 0}
            onClick={onOpenAuthDeletingModalHandler}
          >
            삭제
            {selectedIds.length > 0 ? `(${selectedIds.length}명)` : undefined}
          </Button>
        </Box>
        <Divider sx={{ marginBottom: 2 }} />
        <DataGrid
          sx={{
            '& .MuiDataGrid-columnSeparator': {
              visibility: 'visible',
            },
          }}
          paginationMode="client"
          autoHeight
          pagination
          pageSizeOptions={[5, 10, 30]}
          rows={members}
          columns={columns}
          checkboxSelection
          paginationModel={{ page, pageSize }}
          onPaginationModelChange={(newModel) => {
            setPage(newModel.page);
            setPageSize(newModel.pageSize);
          }}
          onRowSelectionModelChange={setSelectedIds}
        />
      </div>

      <AccountDeletingModal
        open={openAuthDeletingModal}
        onClose={onCloseAccountDeletingModalHandler}
        onDidChange={onDidChangeAccountDeletingHandler}
        members={selectedUsers}
      />
    </div>
  );
};

export default AccountDeletingPage;
