import './App.scss';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter } from 'react-router-dom';
import { UserContextProvider } from './context/UserContext';
import AuthErrorHandler from './components/AuthErrorHandler';
import { OptionsContextProvider } from './context/OptionsContext';
import { LoadingProvider } from './context/LoadingContext';
import { InternetOptionsContextProvider } from './context/InternetOptionsContext';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import DrawerNavigation from './components/DrawerNavigation/DrawerNavigation';
import { ThemeControlProvider } from './context/ThemeControlContext';
import { Paper, Box } from '@mui/material';
import RouteExcept from './components/RouteExcept';
import initDatadogRum from './config/datadog';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Suspense } from 'react';
import { RouteList } from './router/RouteList';
import { ModalProvider } from './hooks/useModal';
import Spinner from './modules/Spinner';

const queryClient = new QueryClient();

initDatadogRum();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <UserContextProvider>
          <LoadingProvider>
            <ModalProvider>
              <BrowserRouter>
                <OptionsContextProvider>
                  <InternetOptionsContextProvider>
                    <AuthErrorHandler>
                      <ThemeControlProvider>
                        <Box sx={{ display: 'flex' }}>
                          <RouteExcept pathnames={['login', 'privacy-policy']}>
                            <Suspense fallback={null}>
                              <DrawerNavigation key="force" />
                            </Suspense>
                          </RouteExcept>
                          <Paper
                            sx={{
                              borderRadius: 0,
                              minHeight: '100vh',
                              flexGrow: 1,
                              paddingBottom: 4,
                            }}
                          >
                            <Suspense fallback={<Spinner show />}>
                              <RouteList />
                            </Suspense>
                          </Paper>
                        </Box>
                      </ThemeControlProvider>
                    </AuthErrorHandler>
                  </InternetOptionsContextProvider>
                </OptionsContextProvider>
              </BrowserRouter>
            </ModalProvider>
          </LoadingProvider>
        </UserContextProvider>
      </LocalizationProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
