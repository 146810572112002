const Mvnos = Object.freeze({
  KCTV: { value: 'KCTV', name: 'KCTV' },
  KG모바일: { value: 'KG모바일', name: 'KG모바일' },
  kt엠모바일: { value: 'ktmmobile', name: 'KT엠모바일' },
  KT스카이라이프: { value: 'KTskylife', name: 'KT스카이라이프' },
  sk7모바일: { value: 'sk7mobile', name: 'SK7모바일' },
  U알뜰모바일: { value: 'U+알뜰모바일', name: 'U+알뜰모바일' },
  고고모바일: { value: '고고모바일', name: '고고모바일' },
  드림모바일: { value: '드림모바일', name: '드림모바일' },
  리브엠: { value: 'liivm', name: '리브엠' },
  마이월드: { value: '마이월드', name: '마이월드' },
  밸류컴: { value: 'valuecomm', name: '밸류컴' },
  마블링: { value: 'marvelring', name: '마블링' },
  모나: { value: '모나', name: '모나' },
  찬스모바일: { value: '찬스모바일', name: '찬스모바일' },
  모빙: { value: 'mobing', name: '모빙' },
  사람과연결: { value: '사람과연결', name: '사람과연결' },
  서경모바일: { value: '서경모바일', name: '서경모바일' },
  슈가모바일: { value: 'sugarmobile', name: '슈가모바일' },
  스노우맨: { value: 'snowman', name: '스노우맨' },
  스마텔: { value: 'smartel', name: '스마텔' },
  아이즈모바일: { value: '아이즈모바일', name: '아이즈모바일' },
  에스원안심모바일: { value: '에스원안심모바일', name: '에스원 안심모바일' },
  에이모바일: { value: 'amobile', name: '에이모바일' },
  여유알뜰폰: { value: 'yeoyou', name: '여유알뜰폰' },
  위너스텔: { value: '위너스텔', name: '위너스텔' },
  이야기모바일: { value: '이야기모바일', name: '이야기모바일' },
  이지모바일: { value: '이지모바일', name: '이지모바일' },
  인스모바일: { value: 'insmobile', name: '인스모바일' },
  티플러스: { value: 'tplus', name: '티플러스' },
  프리티: { value: '프리티', name: '프리티' },
  핀다이렉트: { value: '핀다이렉트', name: '핀다이렉트' },
  헬로모바일: { value: '헬로모바일', name: '헬로모바일' },
  에르엘: { value: '에르엘', name: '에르엘' },
  쉐이크모바일: { value: '쉐이크모바일', name: '쉐이크모바일' },
  오파스모바일: { value: '오파스모바일', name: '오파스모바일' },
  한패스모바일: { value: '한패스모바일', name: '한패스모바일' },
  lgu: { value: 'lgu', name: 'lgu' },
  SKT: { value: 'skt', name: 'skt' },
});

export const MvnosWithAll = Object.freeze({
  ALL: { value: '', name: '통신사 전체' },
  ...Mvnos,
});
