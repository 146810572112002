import styled from '@emotion/styled';
import { Box, Divider, Typography } from '@mui/material';

export const PageContainer = styled(Box)({
  padding: 40,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '40px',
});

export const Header = styled(Box)({
  width: '100%',
  padding: '16px 0',
  display: 'flex',
  justifyContent: 'space-between',
});

export const Section = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  width: 860,
});

export const SectionHeader = styled(Box)<{ flexDirection?: 'row' | 'column' }>(
  ({ flexDirection }) => ({
    display: 'flex',
    flexDirection: flexDirection ?? 'column',
    justifyContent: 'space-between',
    gap: '8px',
    padding: '16px 0',
    width: '100%',
  }),
);

export const SectionTitle = styled(Typography)({
  padding: '0',
});

export const FormContent = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '56px',
});

export const InputRow = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'start',
  gap: 32,
});

export const SwitchRow = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: 1,
  width: '100%',
});

export const SectionDivider = styled(Divider)({
  marginTop: 20,
});

export const InputWithToggleBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
  flex: '1 0 0',
});

export const BottomButtonRow = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  minWidth: '860px',
  gap: '8px',
  marginTop: '14px',
});

export const SummaryBlock = styled(Box)`
  display: flex;
  padding: 16px 32px;
  align-items: center;
  gap: 24px;
  border-radius: 16px;
`;

export const DeleteReserveBlock = styled.div`
  display: flex;
  padding: 6px var(--2, 16px);
  align-items: flex-start;
  align-self: stretch;
  border-radius: var(--borderRadius, 4px);
  background: var(--_components-alert-warning-background, #fff4e5);
  margin-bottom: 24px;
`;

export const ChangeReserveBlock = styled.div`
  display: flex;
  color: var(--_components-alert-info-color, #014361);
  padding: 6px var(--2, 16px);
  align-items: flex-start;
  align-self: stretch;
  margin-bottom: 24px;
  border-radius: var(--borderRadius, 4px);
  background: var(--_components-alert-info-background, #e5f6fd);
`;

export const ReadyReserveBlock = styled.div`
  display: flex;
  color: var(--_components-alert-success-color, #1e4620);
  padding: 6px var(--2, 16px);
  align-items: flex-start;
  align-self: stretch;
  margin-bottom: 24px;
  border-radius: var(--borderRadius, 4px);
  background: var(--_components-alert-success-background, #edf7ed);
`;
