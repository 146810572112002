import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Divider } from '@mui/material';

import { orderApiService } from '../../api/order';
import { useLoading } from '../../context/LoadingContext';
import { handleApi } from '../../common/http_util';
import { MobilePlanOrder } from '../../entity/order';
import { DeliveryInfo } from '../../entity/delivery';
import { isProcessingActivationState } from '../../shared/components/autoActivateStateChip';
import useMoyoActiveCollection from '../../common/moyoActivationCollection';
import OrderDetailOneTong from './OrderDetailOneTong';
import { OrderPlanInfoSection } from './components/OrderPlanInfoSection';
import { OrderApplicationInfoSection } from './components/OrderApplicationInfoSection';
import { OrderUsimDeliverySection } from './components/OrderUsimDeliverySection';
import { OrderPaymentInfoSection } from './components/OrderPaymentInfoSection';
import { OrderAgreementsSection } from './components/OrderAgreementsSection';
import { OrderBasicInfoSection } from './components/OrderBasicInfoSection';
import { OrderDeliveryInfoSection } from './components/OrderDeliveryInfoSection';
import { OrderESimInfoSection } from './components/OrderESimInfoSection';
import { RegistAutoActivate } from './components/RegistAutoActivate';
import { useMoyoProcessingDialog } from './popup/MoyoProcessingDialog';
import { OrderHistorySection } from './OrderHistorySection/OrderHistorySection';
import pbl from '../../pbl/pbl-service/pbl-service';
import { pblPageviewOrderDetail } from '../../pbl/pbl-common-service/pbl-pageview-order-detail';

export function OrderDetail() {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const { setLoading } = useLoading();

  const [order, setOrder] = useState<MobilePlanOrder>();
  const [deliveryInfo, setDeliveryInfo] = useState<DeliveryInfo>();
  const moyoProcessingDialog = useMoyoProcessingDialog();

  const { isSelfMoyoActive: showActivateState } = useMoyoActiveCollection();

  const [orderNumber, setOrderNumber] = useState('');

  useEffect(() => {
    if (!order) return;
    const navigationType =
      order.orderType === 'call' ? 'call_order_detail' : 'order_detail';
    pblPageviewOrderDetail(navigationType, order);
  }, [order]);

  const fetchOrder = useCallback(async () => {
    setLoading(true);

    if (!orderId) return;

    try {
      const order = await handleApi(
        () => orderApiService.getOrder(orderId),
        navigate,
        (error) => {
          alert(error);
        },
      );

      if (!order) return;
      const deliveryInfo = await handleApi(
        () => orderApiService.getDelivery(orderId),
        navigate,
        (error) => {
          alert(error);
        },
      );

      setOrder(order);
      setOrderNumber(order.orderPhoneNumber);
      setDeliveryInfo(deliveryInfo);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [orderId, navigate, setLoading]);

  const handleHistorySubmit = async () => {
    await fetchOrder();

    if (window.opener) {
      window.opener.location.reload();
    }
  };

  useEffect(() => {
    fetchOrder();
  }, [orderId, fetchOrder]);

  const handleEditAutoActivate = async () => {
    const _isMoyoProcessing = isProcessingActivationState(
      order?.autoActivateState,
    );
    pbl('click', 'button', 'here', {
      object: {
        id: 'edit',
        section: 'top_menu',
      },
      eventProperties: {
        isMoyoProcessing: _isMoyoProcessing,
      },
    });
    if (_isMoyoProcessing) {
      const rs = await moyoProcessingDialog.open('edit');
      if (!rs) return;
    }
    navigate(`/orders/${orderId}/edit`);
  };

  const handleRestoreAutoActivate = async () => {
    try {
      if (!orderId) return;
      pbl('click', 'button', 'here', {
        object: {
          id: 'restore',
          section: 'top_menu',
        },
      });
      await handleApi(
        () => orderApiService.restoreOrder(Number(orderId)),
        navigate,
        (err) => {
          alert(err);
        },
      );

      if (window.opener) {
        window.opener.location.reload();
      }
    } catch (err) {
      alert(err);
      console.log(err);
    }
  };

  const handleSaveNumber = useCallback(async () => {
    if (!orderId) return;

    const match = /\d*/g;
    if (orderNumber.length !== 11 || !orderNumber.match(match)) {
      alert('올바른 번호를 입력해주세요');
      return;
    }
    pbl('click', 'button', 'here', {
      object: {
        id: 'add_new_number',
        section: 'order_history',
      },
      eventProperties: {
        orderId,
        orderNumber,
      },
    });
    try {
      await handleApi(
        () => orderApiService.updateOrderPhoneNumber(orderId, orderNumber),
        navigate,
        (err) => {
          alert(err);
        },
      );

      fetchOrder();

      if (window.opener) {
        window.opener.location.reload();
      }
    } catch (err) {
      alert(err);
      console.log(err);
    }
  }, [orderNumber, orderId, fetchOrder, navigate]);

  return (
    <>
      <Container
        sx={{
          width: {
            xs: '100%',
            lg: '75%',
          },
          paddingBottom: '64px',
        }}
      >
        {orderId && showActivateState && order && (
          <OrderDetailOneTong order={order} />
        )}

        <RegistAutoActivate
          title="간편 신청 접수"
          order={order}
          onClickEdit={handleEditAutoActivate}
          onClickRestore={handleRestoreAutoActivate}
        />

        <Divider />

        {order && (
          <>
            <OrderBasicInfoSection order={order} />
            <OrderPlanInfoSection order={order} onOrderUpdated={fetchOrder} />
            <OrderApplicationInfoSection order={order} />
            <OrderESimInfoSection order={order} />
            <OrderUsimDeliverySection order={order} />
            <OrderPaymentInfoSection order={order} />
            <OrderDeliveryInfoSection deliveryInfo={deliveryInfo} />
            <OrderAgreementsSection order={order} />
          </>
        )}

        <OrderHistorySection
          orderId={orderId}
          order={order}
          orderNumber={orderNumber}
          onChageOrderNumber={setOrderNumber}
          onHistorySubmit={handleHistorySubmit}
          onClickSaveNumber={handleSaveNumber}
        />
      </Container>
    </>
  );
}
