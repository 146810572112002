import { DateTime } from 'luxon';

export const dateToLocaleString = (date?: number) =>
  date ? new Date(date).toLocaleString() : undefined;

export const dateToLocaleStringSplitDateAndTime = (date?: number) => {
  if (!date) return undefined;
  const dateObject = new Date(date);
  const dateString = dateObject.toLocaleDateString('ko-KR', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
  const timeString = dateObject.toLocaleTimeString('ko-KR', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });
  return { dateString, timeString };
};

/**
 * 모요 서버에서 사용하는 iso 포맷으로 변환
 * @param date DateTime (luxon)
 * @returns dateTime -> 2023-11-30T16:33:22
 */
export const getISOSimpleFormat = (date?: DateTime) => {
  return date?.toFormat("yyyy-MM-dd'T'HH:mm:ss") ?? '';
};

/**
 * @param dateString yyyy.MM.dd HH:mm:ss 형식의 문열
 * @returns DateTime (luxon)
 */
export const getDateTimeFromString = (dateString?: string) => {
  if (!dateString) return null;
  const [days, time] = dateString.split(' ');
  const year = days.split('.')[0] ?? '';
  const month = days.split('.')[1] ?? '';
  const day = days.split('.')[2] ?? '';
  const hour = time.split(':')[0] ?? '';
  const minute = time.split(':')[1] ?? '';
  const second = time.split(':')[2] ?? '';
  return DateTime.fromObject({
    year: Number(year),
    month: Number(month),
    day: Number(day),
    hour: Number(hour),
    minute: Number(minute),
    second: Number(second),
  });
};
