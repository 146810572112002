import { ReleaseNoteItem } from './releaseNotes';
import ReleaseStatusType from './ReleaseStatusType';

const Release_2_1_4: ReleaseNoteItem = {
  version: '2.1.4',
  date: '2023-03-24',
  title: '릴리즈 노트가 추가',
  details: [
    {
      title: '릴리즈 노트가 추가',
      contents: [
        {
          content: `모요 파트너스 서비스의 기능에 대한 업데이트 내용을 공지할 수 있는
Release Note 기능이 추가 되었어요.`,
          status: ReleaseStatusType.NEW,
        },
      ],
    },
  ],
};

export default Release_2_1_4;
