import { GridColDef } from '@mui/x-data-grid';

import {
  dateFormatter,
  orderPhoneNumberFormatter,
  phoneNumberFormatter,
} from './gridFormatter';
import {
  autoActivateStateRenderCell,
  hasUsimRenderCell,
  isConfirmStateRenderCell,
} from './gridRenderer';
import { DateTime } from 'luxon';

/**
 * @description
 * GridColDef와 관련된 여러 기본 값들을 설정해둔 상태로 반환합니다.
 * 기본적인 이름은 field_headerName 으로 지어져 있으며,
 * 필요한 값 만큼 재설정 하거나 추가로 설정할 수 있습니다.
 */

export function gridColumn(columnsDef: GridColDef): GridColDef {
  return {
    headerAlign: 'center',
    align: 'center',
    ...columnsDef,
  };
}

export const createdAt_신청일: GridColDef = gridColumn({
  field: 'createdAt',
  headerName: '신청일',
  width: 175,
  valueFormatter: dateFormatter(),
});

export const applicationStartedTs_자사몰신청일: GridColDef = gridColumn({
  field: 'applicationStartedTs',
  headerName: '신청일',
  width: 175,
  valueFormatter: dateFormatter(),
});

export const status_상태: GridColDef = gridColumn({
  field: 'status',
  headerName: '상태',
  width: 100,
});

export const deliveryStatus_배송상태: GridColDef = gridColumn({
  field: 'deliveryStatus',
  headerName: '배송상태',
  width: 120,
});

export const id_상세: GridColDef = gridColumn({
  field: 'id',
  headerName: '상세',
  type: 'actions',
  width: 50,
});

export const customerName_고객명: GridColDef = gridColumn({
  field: 'customerName',
  headerName: '고객명',
  width: 70,
});

export const customerPhoneNumber_신청전화번호: GridColDef = gridColumn({
  field: 'customerPhoneNumber',
  headerName: '신청 전화번호',
  width: 120,
  valueFormatter: phoneNumberFormatter,
});

export const orderPhoneNumber_개통전화번호: GridColDef = gridColumn({
  field: 'orderPhoneNumber',
  headerName: '개통 전화번호',
  width: 120,
  valueFormatter: orderPhoneNumberFormatter,
});

export const mvno_통신사: GridColDef = gridColumn({
  field: 'mvno',
  headerName: '통신사',
  width: 180,
});

export const planName_요금제: GridColDef = gridColumn({
  field: 'planName',
  headerName: '요금제',
  width: 300,
});

export const birthDay_생년월일: GridColDef = gridColumn({
  field: 'birthday',
  headerName: '생년월일',
  width: 75,
});

export const registerType_신청유형: GridColDef = gridColumn({
  field: 'registerType',
  headerName: '신청유형',
  width: 75,
});

export const hasUsim_유심보유: GridColDef = gridColumn({
  field: 'hasUsim',
  headerName: '유심보유',
  width: 65,
  renderCell: hasUsimRenderCell,
});

export const mno_통신망: GridColDef = gridColumn({
  field: 'mno',
  headerName: '통신망',
  width: 55,
});

export const memo_메모: GridColDef = gridColumn({
  field: 'memo',
  align: 'left',
  headerName: '최신 메모',
  minWidth: 360,
  editable: true,
});

export const completedAt_개통완료일: GridColDef = gridColumn({
  field: 'completedAt',
  headerName: '개통완료일',
  width: 120,
  valueFormatter: dateFormatter(),
});

export const requestedAt_개통요청일: GridColDef = gridColumn({
  field: 'requestedAt',
  headerName: '개통요청일',
  width: 120,
  valueFormatter: dateFormatter(DateTime.DATE_SHORT),
});

export const updatedAt_수정일: GridColDef = gridColumn({
  field: 'updatedAt',
  headerName: '수정일',
  width: 200,
  valueFormatter: dateFormatter(),
});

export const isDuplicate_기접수여부: GridColDef = gridColumn({
  field: 'isDuplicate',
  headerName: '기접수 여부',
  align: 'left',
  type: 'boolean',
});

export const autoActivateState_모요처리상태: GridColDef = gridColumn({
  field: 'autoActivateState',
  headerName: '모요 처리상태',
  width: 120,
  renderCell: autoActivateStateRenderCell(),
});

export const autoActivateStateValue_처리상태: GridColDef = gridColumn({
  field: 'autoActivateStateValue',
  headerName: '처리상태',
  width: 120,
  renderCell: autoActivateStateRenderCell(true),
});

export const company_통신사: GridColDef = gridColumn({
  field: 'company',
  headerName: '통신사',
  width: 180,
});

export const company_인터넷제공자: GridColDef = gridColumn({
  field: 'internetProviderName',
  headerName: '인터넷 제공자',
  width: 180,
});

export const internetPlanName_인터넷요금제: GridColDef = gridColumn({
  field: 'internetPlanName',
  headerAlign: 'center',
  headerName: '인터넷 요금제',
  align: 'center',
  width: 190,
});

export const address_주소: GridColDef = gridColumn({
  field: 'address',
  headerName: '주소',
  width: 200,
});

export const payerName: GridColDef = gridColumn({
  field: 'payerName',
  headerName: '타인납부여부',
  type: 'boolean',
});

export const isConfirmed_상태: GridColDef = gridColumn({
  field: 'isConfirmed',
  headerName: '상태',
  width: 120,
  type: 'boolean',
  renderCell: isConfirmStateRenderCell,
});

export const mvnoOrderId_자사몰주문번호: GridColDef = gridColumn({
  field: 'mvnoOrderId',
  headerName: '주문번호',
  width: 145,
});

export const source_자사몰채널: GridColDef = gridColumn({
  field: 'source',
  headerName: '채널',
  width: 80,
});

export const agency_개통대리점: GridColDef = gridColumn({
  field: 'agencyName',
  headerName: '개통대리점',
  width: 90,
});

export const orderHandlerName_개통담당자: GridColDef = gridColumn({
  field: 'orderHandlerName',
  headerName: '개통담당자',
  width: 120,
});
