import { ReleaseNoteItem } from './releaseNotes';
import ReleaseStatusType from './ReleaseStatusType';

const Release_2_1_6: ReleaseNoteItem = {
  version: '2.1.6',
  date: '2023-03-31',
  title: '엑셀로 추출하기 기능 개선',
  details: [
    {
      title: '엑셀로 추출하기 기능 개선',
      contents: [
        {
          content: (
            <>- 추출된 주문 엑셀 항목에 주민등록번호 뒷자리가 추가되었습니다.</>
          ),
          status: ReleaseStatusType.CHANGE,
        },
      ],
    },
  ],
};

export default Release_2_1_6;
