import { Dialog } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { orderApiService } from '../../../api/order';
import { handleApi } from '../../../common/http_util';
import { useLoading } from '../../../context/LoadingContext';
import { MobilePlanOrder, OrderStatus } from '../../../entity/order';
import { IBehaviorLogOptions } from '../../../pbl/pbl-service/create-pbl-service';
import { CheckOrdersMoyoIsHandling } from './CheckOrdersMoyoIsHandling';
import { SelectStatusToApply } from './SelectStatusToApply';
import { getActivationStateLabelText } from '../../../domains/activations/states/services';

type PopupProps = {
  mvno: string;
  user?: string;
  orders: Array<MobilePlanOrder>;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
};

function InnerBulkStatusChangePopup({
  mvno,
  user,
  orders,
  open,
  onClose,
  onSuccess,
}: PopupProps) {
  const [step, setStep] = useState<
    '상태값_선택' | '건드리면_안되는_주문이_있는지_확인'
  >('상태값_선택');

  const 건드리면_안되는_주문들_id = useMemo(() => {
    return orders
      .filter((order) => {
        if (!order.autoActivateState) {
          return false;
        }
        return (
          getActivationStateLabelText({
            state: order.autoActivateState,
            isMoyoAuthority: false,
          }) != null
        );
      })
      .map((order) => order.id);
  }, [orders]);

  const 문제없는_주문들_id = useMemo(() => {
    return orders
      .filter((order) => {
        if (!order.autoActivateState) {
          return true;
        }
        return (
          getActivationStateLabelText({
            state: order.autoActivateState,
            isMoyoAuthority: false,
          }) == null
        );
      })
      .map((order) => order.id);
  }, [orders]);

  const [status, setStatus] = useState<OrderStatus | null>(null);
  const [sendAlarm, setSendAlarm] = useState<boolean>(false);
  const [memo, setMemo] = useState<string | undefined>();

  const commonLogParams = useMemo(() => {
    return {
      mvno,
      user,
      orderCount: orders.length,
      orderCountMoyoIsHandling: 건드리면_안되는_주문들_id.length,
      status: status?.key,
      memo,
      sendAlimtalk: sendAlarm,
    } as IBehaviorLogOptions;
  }, [
    memo,
    mvno,
    orders.length,
    sendAlarm,
    status?.key,
    user,
    건드리면_안되는_주문들_id.length,
  ]);

  const navigate = useNavigate();
  const { loading, setLoading } = useLoading();

  const closePopup = useCallback(() => {
    onClose();
  }, [onClose]);

  const submitStatusChangeToServer = useCallback(
    async (orderIds: number[]) => {
      try {
        setLoading(true);
        const changedOrders = await handleApi(
          () =>
            orderApiService.updateOrderStatus(
              orderIds.map((id) => id.toString()),
              status?.key,
              undefined,
              memo,
              sendAlarm,
            ),
          navigate,
          (err) => {
            alert(err);
          },
        );

        if (changedOrders != null) {
          alert('상태값 변경이 완료되었습니다.');
          closePopup();
          onSuccess();
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    },
    [setLoading, navigate, status?.key, memo, sendAlarm, closePopup, onSuccess],
  );

  const handleSubmitSelectedStatus = useCallback(() => {
    if (
      !['APPLICATION_CANCELED', 'APPLICATION_REJECTED'].includes(
        status?.key ?? '',
      ) &&
      건드리면_안되는_주문들_id.length > 0
    ) {
      setStep('건드리면_안되는_주문이_있는지_확인');
    } else {
      submitStatusChangeToServer([
        ...문제없는_주문들_id,
        ...건드리면_안되는_주문들_id,
      ]);
    }
  }, [
    status?.key,
    건드리면_안되는_주문들_id,
    submitStatusChangeToServer,
    문제없는_주문들_id,
  ]);

  const handleSubmitAll = useCallback(() => {
    submitStatusChangeToServer([
      ...문제없는_주문들_id,
      ...건드리면_안되는_주문들_id,
    ]);
  }, [
    submitStatusChangeToServer,
    문제없는_주문들_id,
    건드리면_안되는_주문들_id,
  ]);

  const handleExcludeOrdersMoyoIsHandling = useCallback(() => {
    submitStatusChangeToServer(문제없는_주문들_id);
  }, [submitStatusChangeToServer, 문제없는_주문들_id]);

  const content = useMemo(() => {
    switch (step) {
      case '상태값_선택':
        return (
          <SelectStatusToApply
            loading={loading}
            logParams={commonLogParams}
            isSendAlimtalk={sendAlarm}
            onChangeSendAlimtalk={setSendAlarm}
            memo={memo}
            onChangeMemo={setMemo}
            statusToApply={status}
            onSelectStatusToApply={setStatus}
            onSubmit={handleSubmitSelectedStatus}
          />
        );
      case '건드리면_안되는_주문이_있는지_확인':
        return (
          <CheckOrdersMoyoIsHandling
            loading={loading}
            logParams={commonLogParams}
            orderCountMoyoIsHandling={건드리면_안되는_주문들_id.length}
            onClickSubmitAll={handleSubmitAll}
            onClickExcludeOrdersMoyoIsHandling={
              handleExcludeOrdersMoyoIsHandling
            }
          />
        );
    }
  }, [
    commonLogParams,
    handleExcludeOrdersMoyoIsHandling,
    handleSubmitAll,
    handleSubmitSelectedStatus,
    loading,
    memo,
    sendAlarm,
    status,
    step,
    건드리면_안되는_주문들_id,
  ]);

  return (
    <Dialog open={open} onClose={closePopup}>
      {content}
    </Dialog>
  );
}

export default function BulkStatusChangePopup({ open, ...props }: PopupProps) {
  if (open) {
    return <InnerBulkStatusChangePopup {...props} open />;
  } else {
    return null;
  }
}
