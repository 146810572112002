import { Paper, TableBody, TableContainer, Typography } from '@mui/material';

import { MobilePlanOrder } from '../../../entity/order';
import { VerticalColorTable } from '../../../shared/components/coloredTable';
import {
  AccountInfo,
  CardInfo,
  ColumnGroupDefinition,
  EmailAndAddress,
  FeeInfo,
  Payer,
  PayerChecked,
  PaymentInfo,
  RecipientInfo,
} from '../components';

interface PaymentInfoSectionProps {
  order?: MobilePlanOrder;
  openAddressPopup: () => void;
}

export function PaymentInfoSection({
  order,
  openAddressPopup,
}: PaymentInfoSectionProps) {
  if (!order) {
    return <></>;
  }

  return (
    <>
      <Typography variant="h6" my={2}>
        요금 납부 정보
      </Typography>
      <TableContainer component={Paper} elevation={5}>
        <VerticalColorTable>
          <ColumnGroupDefinition />
          <TableBody>
            <RecipientInfo order={order} />
            <Payer order={order} />
            <PayerChecked order={order} />
            <EmailAndAddress openAddressPopup={openAddressPopup} />
            <PaymentInfo order={order} />
            <AccountInfo order={order} />
            <CardInfo order={order} />
            <FeeInfo />
          </TableBody>
        </VerticalColorTable>
      </TableContainer>
    </>
  );
}
