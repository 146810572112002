import { Controller, useFormContext } from 'react-hook-form';
import { CreateMoyoPlanTypes } from '../../type';
import { FormControl, TextField } from '@mui/material';

interface Props {
  isDone: boolean;
}

function PlanName({ isDone }: Props) {
  const { control } = useFormContext<CreateMoyoPlanTypes>();
  return (
    <FormControl fullWidth>
      <Controller
        control={control}
        defaultValue={''}
        name="name"
        render={({ field }) => (
          <TextField
            disabled={isDone}
            {...field}
            required
            type="text"
            fullWidth
            label="요금제 이름"
          />
        )}
      />
    </FormControl>
  );
}

export default PlanName;
