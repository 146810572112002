import { env } from '../../utils/env';

const eventTrackerIds = [env('REACT_APP_EVENT_TRACKER_ID')].filter((it) => it);

const sendToEventTracker = (type: string, data: object) => {
  eventTrackerIds.forEach((key) => {
    // @ts-ignore
    window?.chrome?.runtime?.sendMessage(
      key,
      {
        type,
        data,
      },
      (response: any) => {},
    );
  });
};

export const sendPblToEventTracker = (data: object) => {
  sendToEventTracker('ubl', data);
};
