import { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

export default function RouteExcept({
  pathnames,
  children,
}: {
  pathnames: string[];
  children: ReactNode;
}) {
  const location = useLocation();
  const matched = pathnames.some((pathname) =>
    location.pathname.includes(pathname),
  );

  return matched ? null : <>{children}</>;
}
