import { ReleaseNoteItem } from './releaseNotes';
import ReleaseStatusType from './ReleaseStatusType';

const Release_2_1_8: ReleaseNoteItem = {
  version: '2.1.8',
  date: '2023-04-10',
  title: '기본 탭 순서 변경',
  details: [
    {
      title: '기본 탭 순서 변경',
      contents: [
        {
          content: <>- 간편신청 접수탭이 기본 탭으로 변경되었습니다.</>,
          status: ReleaseStatusType.CHANGE,
        },
      ],
    },
  ],
};

export default Release_2_1_8;
