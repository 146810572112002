import { useFormContext } from 'react-hook-form';
import { TableCell, TableRow, TextField } from '@mui/material';

import { MobilePlanOrder } from '../../../entity/order';

const recipientPhoneNumberErrorMessage = '수령인 연락처를 입력해 주세요.';

interface PhoneInfoProps {
  order: MobilePlanOrder;
}

export function RecipientInfo({ order }: PhoneInfoProps) {
  const {
    register,
    formState: { errors },
  } = useFormContext<MobilePlanOrder>();

  return (
    <TableRow>
      <TableCell>수령인 이름</TableCell>
      <TableCell>
        <TextField
          {...register('detail.recipientName', {
            required: false,
          })}
          size="small"
        />
      </TableCell>
      <TableCell>수령인 연락처</TableCell>
      <TableCell>
        <TextField
          {...register('detail.recipientPhoneNumber', {
            required:
              order.isEsim || order.willPurchaseUsim || order.detail?.hasUsim
                ? false
                : recipientPhoneNumberErrorMessage,
          })}
          size="small"
          error={!!errors.detail?.recipientPhoneNumber}
          helperText={errors.detail?.recipientPhoneNumber?.message}
        />
      </TableCell>
    </TableRow>
  );
}
