import { FC, useEffect, useMemo } from 'react';
import OrderListPage from '.';
import useMoyoActiveCollection from '../../common/moyoActivationCollection';
import pbl from '../../pbl/pbl-service/pbl-service';

const AllOrderListPage: FC = () => {
  const orderTypes = useMemo(() => ['register', 'self', 'outlink_self'], []);
  const { isSelfMoyoActive: showActivateState } = useMoyoActiveCollection();

  return (
    <OrderListPage
      navigation="orders_all"
      title="전체 신청"
      orderTypes={orderTypes}
      showActivateState={showActivateState}
    />
  );
};

export default AllOrderListPage;
