import {
  DataSpeedUnit,
  ICreatePlanDto,
  QosUnit,
  Tethering,
} from '../entity/plan';
import { PlanV2 } from '../entity/plan-v2';

export function getAdditionalCallOption(
  additionalCall: number | null,
): 'CONTACT_CARRIER' | 'NONE' | 'OFFER' | 'DEDUCTION' {
  if (additionalCall === null) {
    return 'CONTACT_CARRIER';
  } else if (additionalCall < 0) {
    return 'NONE';
  } else if (additionalCall > 0) {
    return 'OFFER';
  } else {
    return 'DEDUCTION';
  }
}

export function mapPlanDetailToCreatePlanDto(plan: PlanV2): ICreatePlanDto {
  const planMeta = plan.meta;

  return {
    // 기본정보
    mobilePlanOperatorId: planMeta.mobilePlanOperatorId || 0,
    name: planMeta.name,
    code: planMeta.code || '',
    signupUrl: planMeta.signupUrl,
    mobileSignupUrl: planMeta.mobileSignupUrl || '',
    reserveTime: '', // PlanDetail에 해당하는 필드가 없음

    // 요금제 스펙
    specialGroupName: planMeta.specialPlanCategory || 'none',
    network: planMeta.net,
    mno: planMeta.mno,
    basicData: planMeta.mobileData || 0,
    basicDataUnit: extractUnit(planMeta.mobileDataStr || ''),
    dailyData: planMeta.mobileData2 || 0,
    dailyDataUnit: extractUnit(planMeta.mobileData2Str || DataSpeedUnit.GB),
    qos:
      extractNumbers(planMeta?.mobileDataDateExhaustedDescription || '0') || 0,
    qosUnit: extractUnit(
      planMeta?.mobileDataDateExhaustedDescription || QosUnit.KBPS,
    ),
    message: planMeta.mobileMessage,
    voice: planMeta.mobileVoice,

    // 요금 정보
    agreementPeriod: planMeta.agreementPeriod ?? null,
    discountFee: planMeta.fee || 0,
    originalFee: planMeta.할인끝난후_금액 || null,
    discountPeriod: planMeta.할인적용_개월수 || 0,

    // 부가정보
    dataSharingSupported: planMeta.dataSharingSupported,
    microPaymentSupported: plan.mnoOperationPolicyByOperator.microPayment,
    internetCombineSupported: planMeta.internetCombineSupported,
    additionalCall: planMeta.additionalCall || null,

    tetheringData: plan?.meta.tethering.tetheringData,
    tetheringDataUnit: planMeta.tethering.tetheringDataUnit,

    tetheringStatus: planMeta.tethering.tetheringStatus,
    //TODO - 조회량 추가
    tetheringDailyData: planMeta.tethering.tetheringDailyData,
    tetheringDailyDataUnit: planMeta.tethering.tetheringDailyDataUnit,
  };
}

function extractUnit(str: string): string {
  const match = str.match(/[^0-9.]+/);
  if (match) {
    return match[0].trim();
  }
  return '';
}

function extractNumbers(str: string): number {
  return Number(str.replace(/\D/g, '') || '0');
}

export function getPlanStatus(state: string): {
  text: string;
  color: 'error' | 'warning' | 'default' | 'success' | 'secondary';
} {
  if (state === 'READY') {
    return {
      text: '진행대기',
      color: 'warning',
    };
  }

  if (state === 'CREATE_RESERVED') {
    return {
      text: '노출예약',
      color: 'success',
    };
  }

  if (state === 'CHANGE_RESERVED') {
    return {
      text: '수정예약',
      color: 'secondary',
    };
  }

  if (state === 'DELETE_RESERVED') {
    return {
      text: '종료예약',
      color: 'warning',
    };
  }

  if (state === 'CONFIRMED') {
    return {
      text: '진행중',
      color: 'success',
    };
  }

  if (state === 'DELETED') {
    return {
      text: '종료',
      color: 'error',
    };
  }

  return {
    text: '진행중',
    color: 'success',
  };
}

export const isExposeReserved = (display: boolean, reserveTime: string) =>
  !display && reserveTime && reserveTime !== '';

export function getPlanStatusInfo(
  state: string,
  display?: boolean,
  reserveTime?: string,
): {
  text: string;
  color: 'error' | 'warning' | 'default' | 'success' | 'secondary';
} {
  if (isExposeReserved(display || false, reserveTime || '')) {
    return {
      text: '노출예약',
      color: 'success',
    };
  }

  if (state === 'READY') {
    return {
      text: '진행대기',
      color: 'warning',
    };
  }

  if (state === 'CHANGE_RESERVED') {
    return {
      text: '수정예약',
      color: 'secondary',
    };
  }

  if (state === 'DELETE_RESERVED') {
    return {
      text: '종료예약',
      color: 'warning',
    };
  }

  if (state === 'CONFIRMED') {
    return {
      text: '진행중',
      color: 'success',
    };
  }

  if (state === 'DELETED') {
    return {
      text: '종료',
      color: 'error',
    };
  }

  return {
    text: '진행중',
    color: 'success',
  };
}

export function makeTetheringDesc({ tethering }: { tethering: Tethering }) {
  const {
    tetheringData = 0,
    tetheringDataUnit = 'GB',
    tetheringDailyData = 0,
    tetheringDailyDataUnit = 'GB',
    tetheringStatus,
  } = tethering;

  if (tetheringStatus === 'NOT_PROVIDED') {
    return '제공안함';
  }

  if (tetheringStatus === 'PROVIDED') {
    if (tetheringData > 0 && tetheringDailyData > 0) {
      return `월 ${tetheringData}${tetheringDataUnit} + 일 ${tetheringDailyData}${tetheringDailyDataUnit} 이용 가능`;
    }
    if (tetheringData > 0) {
      return `월 ${tetheringData}${tetheringDataUnit} 이용 가능`;
    }
    if (tetheringDailyData > 0) {
      return `일 ${tetheringDailyData}${tetheringDailyDataUnit} 이용 가능`;
    }
  }

  return '통신사에 문의';
}
