import { AxiosResponse } from 'axios';
import { activationApi, orderApi } from '../common/http_util';
import { ApiResponse } from '../entity/api';

export interface NewPhoneNumberItem {
  number: string;
  encryptedPhoneNumber?: string; // KT는 암호화된 전화번호도 필요
  해지일자: string; // YYYYMMDD
}
export interface NewPhoneNumber {
  newPhoneNumberList: NewPhoneNumberItem[];
  expireDateTime: string;
}
export interface SelfActivationStageItem {
  apiPath: string;
  name: string;
  subStages: string[];
  pollingPath?: string;
}

export interface SelfActivationStage {
  stages: SelfActivationStageItem[];
}

export interface SelfActivationStageStatus {
  previousList: SelfActivationStageInfo[];
  currentStage: SelfActivationStageInfo;
  failReason: string;
  isFailed: false;
  canSendToMno: boolean;
}

export interface SelfActivationStageInfo {
  name: string;
  memo?: string;
  type?: 'ERROR' | 'INFO' | 'WARNING';
}

export interface ActivationOneTongSendRs {
  message?: string;
}

interface ActivationCheckRs {
  status: 'REQUEST' | 'INPROGRESS' | 'FINISHED' | 'FAILED';
}

interface OneTongStageResponse<T> extends ApiResponse<T> {
  canSendToMno?: boolean;
}

class OrderDetailOneTongApi {
  /**
   * 파트너스 개통 4~5단계 가져오기
   * @param orderId
   */
  getSelfActivationStage(
    orderId: string,
  ): Promise<AxiosResponse<ApiResponse<SelfActivationStage>>> {
    return activationApi.get(`/stage?orderId=${orderId}`);
  }

  postSelfActivationStageStatus(
    orderId: string,
  ): Promise<AxiosResponse<ApiResponse<SelfActivationStageStatus>>> {
    return activationApi.post(`/status`, { orderId });
  }

  deleteSelfActivationStageStatus(
    orderId: string,
  ): Promise<AxiosResponse<ApiResponse<SelfActivationStage>>> {
    return activationApi.delete(`/status/orderId/${orderId}`);
  }

  postSelfActivationOneTong(
    endPoint: 'activation' | 'order',
    apiPath: string,
    orderId: string,
    from: string,
    data?: any,
  ): Promise<AxiosResponse<OneTongStageResponse<ActivationOneTongSendRs>>> {
    if (endPoint === 'order') {
      return orderApi.post(apiPath, { orderId, from, ...data });
    }
    return activationApi.post(apiPath, { orderId, from, ...data });
  }

  /**
   * 개통 결과 확인
   * @param orderId
   * @param url
   */
  postValidationMoveActivate(
    orderId: string,
    url: string,
  ): Promise<AxiosResponse<OneTongStageResponse<ActivationCheckRs>>> {
    return activationApi.post<OneTongStageResponse<ActivationCheckRs>>(url, {
      orderId,
      from: '파트너스',
    });
  }

  /**
   * 개통 결과 확인
   * @param orderId
   */
  postSendOrderToMno(
    orderId: number,
  ): Promise<AxiosResponse<OneTongStageResponse<ActivationCheckRs>>> {
    return activationApi.post<OneTongStageResponse<ActivationCheckRs>>(
      `/online-application/${orderId}`,
      {
        orderId,
        from: '파트너스',
      },
    );
  }

  /**
   * 번호패턴 조회
   * @param last4Number
   * @param orderId
   */
  getNewPhoneNumber = (
    last4Number: string,
    orderId: number,
  ): Promise<AxiosResponse<ApiResponse<NewPhoneNumber>>> => {
    const size = 20;
    return activationApi.get<ApiResponse<NewPhoneNumber>>(`new/phone-number`, {
      params: {
        last4Number,
        orderId,
        size,
      },
    });
  };
}

export default new OrderDetailOneTongApi();
