import styled from '@emotion/styled';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import {
  Alert,
  Breadcrumbs,
  Button,
  Chip,
  CircularProgress,
  Paper,
  Dialog,
  styled as muiStyled,
  DialogTitle,
} from '@mui/material';
import { observer } from 'mobx-react';
import { FC, useEffect, useState } from 'react';
import orderDetailOneTongApi from '../../../api/orderDetailOneTongApi';
import { useAuth } from '../../../context/UserContext';
import { MobilePlanOrder } from '../../../entity/order';
import pbl from '../../../pbl/pbl-service/pbl-service';
import { BuyingUsimPopup } from './BuyingUsimPopup';
import { CannotSendToMnoPopup } from './CannotSendToMnoPopup';
import { FailedToSendToMnoPopup } from './FailedToSendToMnoPopup';
import OneTongStage from './OneTongStore';
import PhoneNumberSelector from './PhoneNumberSelector';
import { SendToMnoButton } from './SendToMnoButton';

const Item = muiStyled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: 20,
}));

const ItemFrame = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const ItemFrameFull = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`;

const ActionButton = styled(Button)`
  width: 100px;
  min-width: 100px;
  height: 36px;
`;

const OnetongProcessBreadcrumbs = styled(Breadcrumbs)`
  display: 'inline-block';
  ol {
    height: 100%;
  }
`;

const Badge = styled.span`
  display: inline-block;
  text-align: center;
  box-sizing: border-box;
  padding: 0px 6px;
  color: rgb(255, 255, 255);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #1976d2;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 26px;
`;

const StatusIconFrame = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  width: 150px;
`;

const OneTongContentFrame = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
`;
interface StatusIconProps {
  title: string;
  status?: 'error' | 'success';
  index: number;
}

const StatusIcon: FC<StatusIconProps> = ({ status, index, title }) => {
  if (status === 'error') {
    return (
      <StatusIconFrame>
        <ErrorIcon style={{ fill: '#D32F2F' }} />
        <span style={{ color: '#D32F2F' }}>{title}</span>
      </StatusIconFrame>
    );
  }
  if (status === 'success') {
    return (
      <StatusIconFrame>
        <CheckCircleIcon style={{ fill: '#2E7D32' }} />
        <span style={{ color: '#2E7D32' }}>{title}</span>
      </StatusIconFrame>
    );
  }
  return (
    <StatusIconFrame>
      <Badge>{index}</Badge>
      <span>{title}</span>
    </StatusIconFrame>
  );
};

interface OrderDetailOneTongStepItemProps {
  index: number;
  oneTongStage: OneTongStage;
  order: MobilePlanOrder;
}

const PHONE_NUMBER_SELECTOR_STAGE = '번호 예약';

const OrderDetailOneTongStepItem: FC<OrderDetailOneTongStepItemProps> =
  observer(({ index, oneTongStage, order }) => {
    const [isCannotSendToMnoPopupOpen, setIsCannotSendToMnoPopupOpen] =
      useState(false);
    const [isFailedToSendToMnoPopupOpen, setIsFailedToSendToMnoPopupOpen] =
      useState(false);
    const [failedToSendToMnoReason, setFailedToSendToMnoReason] = useState<
      string | undefined
    >();
    const [isBuyingUsimPopupOpen, setIsBuyingUsimPopupOpen] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [encryptedPhoneNumber, setEncryptedPhoneNumber] = useState<
      string | undefined
    >(undefined);
    const [enableOrderPhoneNumber, setEnableOrderPhoneNumber] =
      useState<boolean>(!!order.orderPhoneNumber);
    const { getUserMvno, user } = useAuth();
    const [isShowPhoneNumberEmptyAlert, setIsShowPhoneNumberEmptyAlert] =
      useState(false);

    const isPhoneNumberEmpty = phoneNumber.length === 0;
    const isPhoneNumberSelectorShow =
      oneTongStage.name === PHONE_NUMBER_SELECTOR_STAGE &&
      oneTongStage.isActive;

    useEffect(() => {
      return () => {
        oneTongStage.clear();
      };
    }, []);

    useEffect(() => {
      if (oneTongStage.status === 'error') {
        setEnableOrderPhoneNumber(false);
      }
    }, [oneTongStage.status]);

    const commonLogParams = {
      mvno: getUserMvno(),
      user: user?.username ?? '',
      partners_activation_stage: oneTongStage.stageId,
      stageName: oneTongStage.name,
    };

    const onSuccessHandler = (info?: string) => {
      pbl('server', 'success', 'here', {
        object: {
          id: oneTongStage.stageId,
          name: oneTongStage.name,
          section: 'partners_activation',
          position: index - 1,
        },
        eventProperties: {
          message: info,
        },
      });
    };

    const onFailHandler = (errorInfo?: string, errorCode?: string) => {
      pbl('server', 'fail', 'here', {
        object: {
          id: oneTongStage.stageId,
          name: oneTongStage.name,
          section: 'partners_activation',
          position: index - 1,
        },
        eventProperties: {
          message: errorInfo,
          errorCode,
        },
      });
    };

    const requestButtonAction = async () => {
      setLoading(true);
      try {
        // TODO: 점차 activation으로 쏘는게 제거될 예정
        const endPoint =
          order.mno === 'SKT' && order.mvno === '스마텔'
            ? 'order'
            : 'activation';
        // TODO KT는 phoneNumber 말고 encrypted도 내려줘야 함
        if (oneTongStage.name === PHONE_NUMBER_SELECTOR_STAGE) {
          await oneTongStage.request(
            endPoint,
            { phoneNumber, encryptedPhoneNumber },
            onSuccessHandler,
            onFailHandler,
          );
        } else {
          await oneTongStage.request(
            endPoint,
            undefined,
            onSuccessHandler,
            onFailHandler,
          );
        }
      } finally {
        setLoading(false);
      }
    };

    const onClickStartHandler = async () => {
      pbl('click', 'button', 'here', {
        object: {
          id: oneTongStage.stageId,
          name: oneTongStage.name,
          section: 'partners_activation',
          position: index - 1,
        },
      });

      if (isPhoneNumberSelectorShow && isPhoneNumberEmpty) {
        setIsShowPhoneNumberEmptyAlert(true);
        return;
      }

      if (order.autoActivateState === '유심구매중') {
        setIsBuyingUsimPopupOpen(true);
        return;
      }

      await requestButtonAction();
    };

    const sendOrderToMno = async () => {
      setLoading(true);
      try {
        const response = await orderDetailOneTongApi.postSendOrderToMno(
          order.id,
        );
        if (response.status !== 200) {
          throw new Error(response.statusText);
        }
        if (response.data.resultType !== 'SUCCESS') {
          throw new Error(`${response.data.error}`);
        }
        pbl('server', 'success', 'here', {
          object: {
            id: 'send_to_mno_success',
            name: '온라인 서식 전송 성공',
            section: 'partners_activation',
          },
          eventProperties: {
            ...commonLogParams,
          },
        });
        window.alert('전산에 보내는 데 성공했어요');
        window.location.reload();
      } catch (error) {
        const errorMessage =
          error instanceof Error ? error.message : JSON.stringify(error);

        pbl('server', 'fail', 'here', {
          object: {
            id: 'send_to_mno_fail',
            name: '온라인 서식 전송 실패',
            section: 'partners_activation',
            position: index - 1,
          },
          eventProperties: {
            ...commonLogParams,
            error,
          },
        });
        setFailedToSendToMnoReason(errorMessage);
        setIsFailedToSendToMnoPopupOpen(true);
      } finally {
        setLoading(false);
      }
    };

    const onClickSendToMno = async () => {
      pbl('click', 'button', 'here', {
        object: {
          id: 'send_to_mno_button',
          name: '전산에 보내기 버튼',
          section: 'partners_activation',
          position: index - 1,
        },
        eventProperties: {
          ...commonLogParams,
          error: oneTongStage.errorInfo,
          canSendToMno: oneTongStage.canSendToMno,
        },
      });

      if (!oneTongStage.canSendToMno) {
        setIsCannotSendToMnoPopupOpen(true);
      } else {
        await sendOrderToMno();
      }
    };

    return (
      <Item>
        <ItemFrame>
          <div>
            <StatusIcon
              status={oneTongStage.status}
              index={index}
              title={oneTongStage.name}
            />
          </div>
          <OneTongContentFrame>
            <OnetongProcessBreadcrumbs separator="›" aria-label="breadcrumb">
              {oneTongStage.subStages.map((stage) => (
                <Chip key={stage} size="small" label={stage} />
              ))}
            </OnetongProcessBreadcrumbs>
            {oneTongStage.status === 'error' ? (
              <ActionButton
                variant="outlined"
                disabled={loading}
                onClick={onClickStartHandler}
              >
                {loading ? (
                  <CircularProgress size={16} color="inherit" />
                ) : (
                  '다시하기'
                )}
              </ActionButton>
            ) : oneTongStage.status === 'success' ? (
              <></>
            ) : (
              <ActionButton
                variant="contained"
                disabled={!oneTongStage.isActive || loading}
                onClick={onClickStartHandler}
              >
                {loading ? (
                  <CircularProgress size={16} color="inherit" />
                ) : (
                  '시작하기'
                )}
              </ActionButton>
            )}
          </OneTongContentFrame>
        </ItemFrame>
        {isPhoneNumberSelectorShow && (
          <div style={{ marginTop: 16 }}>
            <PhoneNumberSelector
              mvno={order.mvno}
              wishNumber1={order.detail?.wishNumber1}
              wishNumber2={order.detail?.wishNumber2}
              orderPhoneNumber={order.orderPhoneNumber}
              enableOrderPhoneNumber={enableOrderPhoneNumber}
              onChange={(_phoneNumber, _encryptedPhoneNumber) => {
                setPhoneNumber(_phoneNumber);
                setEncryptedPhoneNumber(_encryptedPhoneNumber);
              }}
              orderId={order.id}
            />
          </div>
        )}
        {oneTongStage.status === 'error' && (
          <ItemFrameFull>
            <Alert
              style={{ marginTop: 16, display: 'inline-flex', minWidth: 320 }}
              severity="error"
            >
              {oneTongStage.errorInfo || '알 수 없는 에러가 발생하였습니다'}
            </Alert>
            <SendToMnoButton
              loading={loading}
              onClick={onClickSendToMno}
              loggingParams={{
                ...commonLogParams,
                section: 'partners_activation',
                canSendToMno: oneTongStage.canSendToMno?.toString(),
              }}
            />
          </ItemFrameFull>
        )}
        {oneTongStage.info && (
          <Alert
            style={{ marginTop: 16, display: 'inline-flex', minWidth: 320 }}
            severity="info"
          >
            {oneTongStage.info}
          </Alert>
        )}
        <CannotSendToMnoPopup
          logParams={{
            ...commonLogParams,
            error: oneTongStage.errorInfo,
            section: 'partners_activation',
          }}
          open={isCannotSendToMnoPopupOpen}
          onClose={() => setIsCannotSendToMnoPopupOpen(false)}
          errorCode={oneTongStage.errorCode}
          errorMessage={
            oneTongStage.errorInfo ?? '알 수 없는 이유로 에러가 발생했어요'
          }
        />
        <FailedToSendToMnoPopup
          logParams={{
            ...commonLogParams,
            error: failedToSendToMnoReason,
            section: 'partners_activation',
          }}
          open={isFailedToSendToMnoPopupOpen}
          onClose={() => setIsFailedToSendToMnoPopupOpen(false)}
          errorMessage={failedToSendToMnoReason}
        />
        <BuyingUsimPopup
          logParams={commonLogParams}
          open={isBuyingUsimPopupOpen}
          onClose={() => setIsBuyingUsimPopupOpen(false)}
          onSubmit={requestButtonAction}
        />
        <Dialog
          open={isShowPhoneNumberEmptyAlert}
          onClose={(event, reason) => {
            if (reason === 'backdropClick') {
              return;
            }
            setIsShowPhoneNumberEmptyAlert(false);
          }}
          disableEscapeKeyDown
        >
          <DialogTitle>개통 번호를 입력해주세요.</DialogTitle>
          <Button
            onClick={() => {
              setIsShowPhoneNumberEmptyAlert(false);
            }}
          >
            닫기
          </Button>
        </Dialog>
      </Item>
    );
  });

export default OrderDetailOneTongStepItem;
