import { isInValidHttpUrlWithRegex } from '../../../common/url_util';

const validateIncludeHttps = (value: string | null) => {
  return value?.includes('https://') || value?.includes('http://');
};

const validateUrlURLFormat = (
  value: string | null,
  message: string | undefined = '입력한 주소가 맞는지 확인해 주세요.',
) => {
  if (validateIncludeHttps(value)) {
    return 'https://와 http://는 빼고 입력해 주세요';
  }

  return isInValidHttpUrlWithRegex(value) || message;
};

const isGreaterThan = (value: number | null, threshold: number): boolean => {
  if (value == null) return false;
  return value > threshold;
};

const isLessThan = (value: number | null, threshold: number): boolean => {
  if (value == null) return false;
  return value < threshold;
};

const isEqualTo = (value: number | null, threshold: number): boolean => {
  if (value == null) return false;
  return value === threshold;
};

export const validate = {
  validateUrlURLFormat,
  isGreaterThan,
  isLessThan,
  isEqualTo,
};
