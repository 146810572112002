import { Controller, useFormContext } from 'react-hook-form';
import {
  FormControl,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField,
} from '@mui/material';

import { MobilePlanOrder } from '../../../entity/order';
import { bankList } from '../../../common/order-util';

interface AccountInfoProps {
  order: MobilePlanOrder;
}

export function AccountInfo({ order }: AccountInfoProps) {
  const { control, register, watch } = useFormContext<MobilePlanOrder>();
  const watchPaymentMethod = watch('detail.paymentMethod');

  if (watchPaymentMethod !== 'account') {
    return null;
  }

  return (
    <TableRow>
      <TableCell>은행</TableCell>
      <TableCell>
        <FormControl>
          <Controller
            name="detail.bank"
            control={control}
            defaultValue={order?.detail?.bank ?? ''}
            render={({ field }) => (
              <Select {...field} size="small">
                {bankList.map((bank) => (
                  <MenuItem value={bank.value} key={bank.value}>
                    {bank.inputLabel}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
      </TableCell>
      <TableCell>계좌번호</TableCell>
      <TableCell>
        <TextField
          {...register('detail.accountNumber')}
          sx={{
            width: '100%',
          }}
          size="small"
        />
      </TableCell>
    </TableRow>
  );
}
