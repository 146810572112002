import { Grid, MenuItem, Select, Typography } from '@mui/material';

import { OrderStatus } from '../../../entity/order';
import { useSearchSection } from '../hooks';
import { useOptions } from '../../../context/OptionsContext';
import { useInternetOptions } from '../../../context/InternetOptionsContext';
import { InternetOrderStatus } from '../../../entity/internet-order';

export function InternetStatusSection() {
  const { statusList } = useInternetOptions();
  const { searchState, searchHandler } = useSearchSection();

  // TODO : statusList.filter((s) => orderTypes?.includes(s.orderType))
  // status list가 이렇게 동작하는데 이 부분을 반영해주기
  const { internetStatus } = searchState;
  const { setInternetStatus } = searchHandler;

  const onStatusCheck = (value: InternetOrderStatus | string) => {
    const newStatus = statusList.find(
      (s: InternetOrderStatus) => s.key === value,
    );
    setInternetStatus(newStatus ?? null);
  };

  return (
    <Grid item xs={12} md={6}>
      <Typography mb={1}>상태값</Typography>
      <Select
        fullWidth
        defaultValue="all"
        value={internetStatus?.key ?? 'all'}
        onChange={(event) => onStatusCheck(event.target.value)}
        size="small"
      >
        <MenuItem key={'all'} value="all">
          전체
        </MenuItem>
        {statusList.map((status) => (
          <MenuItem key={status.key} value={status.key}>
            {status.name}
          </MenuItem>
        ))}
      </Select>
    </Grid>
  );
}
