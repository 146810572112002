import { generateUUID } from '../../common/string_util';
import { getAdminUserInfoFromJwtToken } from '../admin-user-info-service';
import UBLSchema from '../pbl-interface';
import { PblCategoryTypes } from '../pbl-types/pbl-category-types';
import { PblNavigationTypes } from '../pbl-types/pbl-navigation-types';
import { PblObjectTypes } from '../pbl-types/pbl-object-types';
import getPblDeviceId from './pbl-device-id-service';
import { createPblEventProperties } from './pbl-event-property-service';
import { getNavigationWithProcess } from './pbl-navigation-service';
import { pblPageviewNavigationProcessor } from './pbl-pageview-navigation-process';
import { createPblSuperProperties } from './pbl-super-property-service';
import { createPblUserProperties } from './pbl-user-property-service';

export interface IBehaviorLogObject {
  id?: string;
  section?: string;
  name?: string;
  url?: string;
  position?: number;
}

export interface IBehaviorLogOptions {
  object?: IBehaviorLogObject;
  userProperties?: Record<string, unknown>;
  eventProperties?: Record<string, unknown>;
  superProperties?: Record<string, unknown>;
}

const createPblService = (
  category: PblCategoryTypes,
  objectType: PblObjectTypes,
  navigation: PblNavigationTypes,
  options?: IBehaviorLogOptions,
): UBLSchema => {
  const adminUserInfo = getAdminUserInfoFromJwtToken();
  const processedNavigation = getNavigationWithProcess(
    navigation,
    category,
    options?.eventProperties,
  );
  const previousNavigation =
    pblPageviewNavigationProcessor.getPreviousNavigation();
  const userProperties = createPblUserProperties(
    adminUserInfo,
    options?.userProperties,
  );
  const eventProperties = createPblEventProperties(
    category,
    options?.eventProperties,
  );
  const superProperties = createPblSuperProperties(options?.superProperties);
  const deviceId = getPblDeviceId();
  const timestamp = Date.now();
  const eventId = generateUUID();

  return {
    category,
    objectType,
    navigation: processedNavigation,
    objectId: options?.object?.id,
    objectName: options?.object?.name,
    objectSection: options?.object?.section,
    objectPosition: options?.object?.position,
    objectUrl: options?.object?.url,
    userProperties,
    eventProperties,
    timestamp,
    eventId,
    deviceId,
    // appVersion: '',
    previousNavigation,
    superProperties,
    userId: adminUserInfo?.id.toString(),
    pathUrl: window.location.href,
  };
};

export default createPblService;
