import React, { FC, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import { PlanMetaAdmin } from '../../entity/plan';
import { CalculatorFeeButton } from '../NewMoyoOnlyPage/components/FeeInput/CalculatorFeeButton';
import { AlertInfo } from '../NewMoyoOnlyPage/components/FeeInput/AlertInfo';
import { DangerCallout } from '../NewMoyoOnlyPage/components/FeeInput/DangerCallout';
import { Controller, useFormContext } from 'react-hook-form';
import changeEnteredNum from '../NewMoyoOnlyPage/hook/inputComma';
import { PlanMetaV2 } from '../../entity/plan-v2';

export interface RequestChangeMoyoOnlyTypes {
  fee: number;
  feeAfterDiscount: number | undefined;
  promotionPeriod: number | undefined;
  status?: string;
}

interface MoyoOnlyRequestPopupProps {
  open: boolean;
  onClose: () => void;
  onSuccess: (plan: PlanMetaAdmin) => void;
  plans: PlanMetaAdmin;
  guardRailPlan: PlanMetaV2 | undefined;
  isNotConfirm: boolean;
  title: string;
  description: string;
  guardRailFee: number;
}

const MoyoOnlyRequestPopup: FC<MoyoOnlyRequestPopupProps> = (props) => {
  const {
    open,
    onClose,
    onSuccess,
    plans,
    guardRailFee,
    title,
    description,
    isNotConfirm,
    guardRailPlan,
  } = props;
  const { control, getValues, reset, watch } = useFormContext<PlanMetaAdmin>();
  const [calculatorFee, setCalculatorFee] = useState<number>(0);

  const ValidateFee = (
    fee: number,
    feeAfterDiscount: number,
    promotionPeriod: number,
  ) => {
    if (promotionPeriod < 12) {
      return setCalculatorFee(
        fee * promotionPeriod + feeAfterDiscount * (12 - promotionPeriod),
      );
    } else if (promotionPeriod >= 12) {
      return setCalculatorFee(fee * 12);
    }
  };

  const planFee = Number(getValues('fee')?.toString().replace(',', ''));
  const planFeeAfter = Number(
    getValues('feeAfterDiscount')?.toString().replace(',', ''),
  );
  const planPromotion = Number(
    getValues('promotionPeriod')?.toString().replace(',', ''),
  );

  return (
    <Dialog maxWidth="lg" open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '12px',
          marginTop: '12px',
        }}
      >
        <DialogContentText style={{ paddingRight: '200px' }}>
          {plans?.name} {description}
        </DialogContentText>
        {guardRailFee !== 0 && (
          <AlertInfo
            planId={guardRailPlan?.id}
            planName={guardRailPlan?.name}
            fee={guardRailFee}
          />
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
          }}
        >
          <Controller
            control={control}
            name="fee"
            render={({ field }) => (
              <TextField
                {...field}
                required
                onWheel={(event) => (event.target as HTMLElement).blur()}
                inputMode="numeric"
                onChange={(event) => {
                  field.onChange(changeEnteredNum(event));
                }}
                label="할인시 가격"
              />
            )}
          />
          <Controller
            control={control}
            name="feeAfterDiscount"
            render={({ field }) => (
              <TextField
                {...field}
                required
                onWheel={(event) => (event.target as HTMLElement).blur()}
                inputMode="numeric"
                onChange={(event) => {
                  field.onChange(changeEnteredNum(event));
                }}
                label="할인종료 후 가격"
              />
            )}
          />
          <Controller
            control={control}
            name="promotionPeriod"
            render={({ field }) => (
              <TextField
                {...field}
                required
                onWheel={(event) => (event.target as HTMLElement).blur()}
                inputMode="numeric"
                onChange={(event) => {
                  field.onChange(changeEnteredNum(event));
                }}
                label="할인기간"
                placeholder="개월 수를 입력해주세요."
              />
            )}
          />
        </div>
        <CalculatorFeeButton
          onValidationClick={() =>
            //0으로 무조건 있음
            ValidateFee(planFee, planFeeAfter, planPromotion)
          }
          fee={calculatorFee}
          isDisabled={
            watch('fee') === undefined ||
            watch('feeAfterDiscount') === undefined ||
            watch('promotionPeriod') === undefined
          }
        />
        {isNotConfirm && <DangerCallout />}
      </DialogContent>
      <DialogActions>
        <Button
          size="medium"
          onClick={() => {
            onClose();
            setCalculatorFee(0);
          }}
        >
          닫기
        </Button>
        <Button
          size="medium"
          onClick={() => {
            onSuccess(getValues());
            setCalculatorFee(0);
          }}
          style={{ marginLeft: 0 }}
        >
          확인
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MoyoOnlyRequestPopup;
