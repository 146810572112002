import { FC } from 'react';
import { UsimInfo } from '../../../types/mobile-plan-operator-admin-types';
import { Mno } from '../../../../../entity/mno';
import { SimFeeStatusKeyType } from '../MvnoInfoDeliveryFeeStatus';
import { useFormContext } from 'react-hook-form';
import { MvnoInfoDeliveryFee } from './MvnoInfoDeliveryFee';

interface Props {
  simFeeStatusKey: SimFeeStatusKeyType;
  defaultValue: number | null;
  mno: Mno;
}

export const ConditionalRenderByFeeStatus: FC<Props> = ({
  simFeeStatusKey,
  mno,
  defaultValue,
}) => {
  const { watch } = useFormContext<UsimInfo>();
  const deliveryFeeStatusKey = `${mno}.${simFeeStatusKey}FeeStatus` as const;
  const deliveryFeeStatus = watch(deliveryFeeStatusKey);

  if (deliveryFeeStatus === 'PAID') {
    return (
      <MvnoInfoDeliveryFee
        deliveryFeeKey={`${simFeeStatusKey}Fee`}
        mno={mno}
        defaultValue={defaultValue}
      />
    );
  }
  return null;
};
