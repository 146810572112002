import { FC, useEffect } from 'react';
import {
  CustomerServiceContactList,
  OperatingHoursInfoList,
  CustomerServiceContact,
  OperatingHoursInfo,
  CustomerCenterInfo,
} from '../../types/mobile-plan-operator-admin-types';
import { Controller, useFormContext } from 'react-hook-form';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { Mno } from '../../../../entity/mno';
import { convertStringToMno } from '../../../../common/order-util';
import TimePickerWithDayjs from '../../../../shared/components/custom-mui/TimePickerWithDayjs';
import { MvnoInfoItemBody, MvnoInfoItemRow } from '../Common/MvnoInfoItemFrame';

const closeDayOptions = [
  {
    label: '공휴일',
    value: '공휴일',
  },
  {
    label: '일요일',
    value: '일',
  },
  {
    label: '토요일',
    value: '토',
  },
  {
    label: '금요일',
    value: '금',
  },
  {
    label: '목요일',
    value: '목',
  },
  {
    label: '수요일',
    value: '수',
  },
  {
    label: '화요일',
    value: '화',
  },
  {
    label: '월요일',
    value: '월',
  },
];

const MnoInfoCustomerCenterEdit: FC<{
  mno: Mno;
  customerServiceContact?: CustomerServiceContact;
  operatingHoursInfo?: OperatingHoursInfo;
}> = ({ mno, customerServiceContact, operatingHoursInfo }) => {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext<CustomerCenterInfo>();
  const title = convertStringToMno(mno);

  return (
    <MvnoInfoItemBody gap={3}>
      <Box>
        <Stack
          alignItems={'center'}
          justifyContent={'space-between'}
          flexDirection={'row'}
        >
          <Typography variant="body1" color="text.primary" fontWeight={700}>
            {title}망
          </Typography>
          <Controller
            control={control}
            name={`operatingHoursInfoList.${mno}.lunchBreakEnabled`}
            defaultValue={operatingHoursInfo?.lunchBreakEnabled}
            render={({ field }) => (
              <ToggleButtonGroup
                exclusive
                size="small"
                {...field}
                onChange={(event, value) => {
                  field.onChange(value);
                }}
                aria-label="break-time"
              >
                <ToggleButton value={true}>점심 휴식</ToggleButton>
                <ToggleButton value={false}>휴식 안함</ToggleButton>
              </ToggleButtonGroup>
            )}
          />
        </Stack>
      </Box>
      <MvnoInfoItemBody gap={6}>
        <MvnoInfoItemRow gap={3} numberOfRow={3}>
          <Controller
            control={control}
            name={`customerServiceContactList.${mno}.phoneNumber`}
            rules={{ required: '고객센터 전화번호를 입력해 주세요.' }}
            defaultValue={customerServiceContact?.phoneNumber}
            render={({ field }) => (
              <TextField
                error={!!errors.customerServiceContactList?.[mno]?.phoneNumber}
                helperText={
                  errors.customerServiceContactList?.[mno]?.phoneNumber?.message
                }
                {...field}
                sx={{ width: '265px' }}
                label="고객센터 전화번호"
              />
            )}
          />
        </MvnoInfoItemRow>
        <MvnoInfoItemRow numberOfRow={3} gap={3}>
          <Box
            sx={{
              display: 'flex',
              gap: '8px',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Controller
              control={control}
              name={`operatingHoursInfoList.${mno}.openTime`}
              defaultValue={operatingHoursInfo?.openTime}
              render={({ field }) => (
                <TimePickerWithDayjs label="운영시간" {...field} />
              )}
            />
            <Controller
              control={control}
              name={`operatingHoursInfoList.${mno}.closeTime`}
              render={({ field }) => (
                <TimePickerWithDayjs label="영업 종료" {...field} />
              )}
            />
          </Box>
          {watch(`operatingHoursInfoList.${mno}.lunchBreakEnabled`) && (
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Controller
                control={control}
                name={`operatingHoursInfoList.${mno}.lunchStartTime`}
                render={({ field }) => (
                  <TimePickerWithDayjs label="점심시간" {...field} />
                )}
              />
              <Controller
                control={control}
                name={`operatingHoursInfoList.${mno}.lunchEndTime`}
                render={({ field }) => (
                  <TimePickerWithDayjs label="점심 종료" {...field} />
                )}
              />
            </Box>
          )}
          <Controller
            control={control}
            name={`operatingHoursInfoList.${mno}.closedDays`}
            defaultValue={operatingHoursInfo?.closedDays}
            render={({ field }) => {
              return (
                <FormControl fullWidth>
                  <InputLabel id="closeDays-select-label">휴무일</InputLabel>
                  <Select
                    multiple
                    value={
                      field.value
                        ? field.value.split(',').map((item) => item.trim())
                        : []
                    }
                    label="휴무일"
                    onChange={(event) => {
                      const value = event.target.value as string[];
                      // NOTE: 항상 선택의 순서를 보장하기 위함
                      const resultValue = closeDayOptions
                        .filter((option) => value.includes(option.value))
                        .map((option) => option.value)
                        .reverse();
                      field.onChange(resultValue.join(', '));
                    }}
                    renderValue={(selected) =>
                      selected
                        .map((value) => value.replace('요일', ''))
                        .join(', ')
                    }
                  >
                    {closeDayOptions.map(({ label, value }) => (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              );
            }}
          />
        </MvnoInfoItemRow>
      </MvnoInfoItemBody>
    </MvnoInfoItemBody>
  );
};

const MvnoInfoCustomerCenterEdit: FC<{
  customerCenterInfo: CustomerCenterInfo;
}> = ({ customerCenterInfo }) => {
  const { setValue, reset } = useFormContext<CustomerCenterInfo>();
  useEffect(() => {
    initializeCustomerServiceContactList(
      customerCenterInfo.customerServiceContactList,
    );
    initializeOperatingHoursInfoList(customerCenterInfo.operatingHoursInfoList);
    return () => {
      reset();
    };
  }, []);

  const initializeCustomerServiceContactList = (
    _customerServiceContactList: CustomerServiceContactList,
  ) => {
    Object.entries(_customerServiceContactList).forEach(
      ([customerServiceContactListKey, customerCenterInfo]) => {
        Object.entries(customerCenterInfo).forEach(
          ([customerCenterInfoKey, value]) => {
            setValue(
              `customerServiceContactList.${
                customerServiceContactListKey as keyof CustomerServiceContactList
              }.${customerCenterInfoKey as keyof CustomerServiceContact}`,
              value,
            );
          },
        );
      },
    );
  };

  const initializeOperatingHoursInfoList = (
    _operatingHoursInfoList: OperatingHoursInfoList,
  ) => {
    Object.entries(_operatingHoursInfoList).forEach(
      ([customerServiceContactListKey, customerCenterInfo]) => {
        Object.entries(customerCenterInfo).forEach(
          ([operatingHoursInfoKey, value]) => {
            setValue(
              `operatingHoursInfoList.${
                customerServiceContactListKey as keyof OperatingHoursInfoList
              }.${operatingHoursInfoKey as keyof OperatingHoursInfo}`,
              value,
            );
          },
        );
      },
    );
  };

  return (
    <MvnoInfoItemBody gap={6}>
      {Object.values(['LGU', 'KT', 'SKT']).map((mno) => {
        const customerServiceContact =
          customerCenterInfo.customerServiceContactList[mno as Mno];
        const operatingHoursInfo =
          customerCenterInfo.operatingHoursInfoList[mno as Mno];
        if (!customerServiceContact && !operatingHoursInfo) {
          return <></>;
        }
        return (
          <MnoInfoCustomerCenterEdit
            key={mno}
            mno={mno as Mno}
            customerServiceContact={customerServiceContact}
            operatingHoursInfo={operatingHoursInfo}
          />
        );
      })}
    </MvnoInfoItemBody>
  );
};

export default MvnoInfoCustomerCenterEdit;
