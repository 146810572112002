import { Switch, TextField, Typography } from '@mui/material';
import {
  FormContent,
  InputRow,
  Section,
  SectionDivider,
  SectionTitle,
  SwitchRow,
} from '../styled';
import { useFormContext } from 'react-hook-form';
import { ICreatePlanDto } from '../../../entity/plan';
import { useState } from 'react';

export const HTTPS_PROTOCOL_PREFIX = 'https://';

export const BasicInfoSection = () => {
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<ICreatePlanDto>();

  const [isSamePcLink, setIsSamePcLink] = useState(false);

  const handleChangeIsSamePcLink = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setIsSamePcLink(event.target.checked);
    if (event.target.checked) {
      const signupUrlValue = (
        document.getElementById('pc-link') as HTMLInputElement
      ).value;

      setValue('mobileSignupUrl', signupUrlValue, {
        shouldValidate: true,
      });
    }
  };

  const signupUrl = watch('signupUrl')?.replace(HTTPS_PROTOCOL_PREFIX, '');
  const mobileSignupUrl = watch('mobileSignupUrl')?.replace(
    HTTPS_PROTOCOL_PREFIX,
    '',
  );

  return (
    <Section>
      <SectionTitle variant="h5" mb={4}>
        기본 정보
      </SectionTitle>
      <FormContent>
        <InputRow>
          <TextField
            {...register('name', {
              required: '요금제 이름은 필수입니다',
              validate: (value) => {
                if (value.length > 100) {
                  return '요금제 이름은 100자를 넘을 수 없어요.';
                }
                return true;
              },
            })}
            id="plan-name"
            label="요금제 이름"
            error={!!errors.name}
            helperText={errors.name?.message}
            placeholder="입력"
            fullWidth
            InputLabelProps={{ shrink: true }}
            InputProps={{ 'aria-required': true }}
            onChange={(e) => {
              let value = e.target.value;
              setValue('name', value, {
                shouldValidate: true,
                shouldDirty: true,
              });
            }}
          />
          <TextField
            {...register('code', {
              required: '요금제 코드는 필수입니다',
              validate: (value) => {
                const englishNumberRegex = /^[A-Za-z0-9]+$/;
                if (!englishNumberRegex.test(value)) {
                  return '영어와 숫자만 입력 가능합니다.';
                }
                if (value.length > 32) {
                  return '요금제 코드는 32자를 넘을 수 없어요.';
                }
                return true;
              },
            })}
            id="code"
            label="요금제 코드"
            error={!!errors.code}
            helperText={errors.code?.message}
            placeholder="입력"
            fullWidth
            onKeyDown={(e) => {
              const englishNumberRegex = /^[A-Za-z0-9]*$/;
              if (!englishNumberRegex.test(e.key)) {
                e.preventDefault();
              }
            }}
            InputLabelProps={{ shrink: true }}
            InputProps={{ 'aria-required': true }}
            onChange={(e) => {
              let value = e.target.value.replace(/\s/g, '').toUpperCase();
              setValue('code', value, {
                shouldValidate: true,
                shouldDirty: true,
              });
            }}
          />
        </InputRow>
        <InputRow>
          <TextField
            {...register('signupUrl', {
              required: '입력한 주소가 맞는지 확인해 주세요.',
            })}
            error={!!errors.signupUrl}
            helperText={errors.signupUrl?.message}
            id="pc-link"
            label="자사몰 신청 링크 (PC)"
            placeholder="예: moyoplan.com"
            fullWidth
            value={signupUrl}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              'aria-required': true,
              startAdornment: (
                <Typography variant="body1">
                  {HTTPS_PROTOCOL_PREFIX}&nbsp;
                </Typography>
              ),
            }}
            onChange={(e) => {
              setValue('signupUrl', e.target.value, {
                shouldValidate: true,
                shouldDirty: true,
              });
            }}
          />
          <TextField
            {...register('mobileSignupUrl', {
              required: '입력한 주소가 맞는지 확인해 주세요.',
            })}
            error={!!errors.mobileSignupUrl}
            helperText={errors.mobileSignupUrl?.message}
            id="mobile-link"
            label="자사몰 신청 링크 (모바일)"
            placeholder="예: m.moyoplan.com"
            fullWidth
            value={mobileSignupUrl}
            disabled={isSamePcLink}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              'aria-required': true,
              startAdornment: (
                <Typography variant="body1">
                  {HTTPS_PROTOCOL_PREFIX}&nbsp;
                </Typography>
              ),
            }}
            onBlur={(e) => {
              setValue('mobileSignupUrl', e.target.value, {
                shouldValidate: true,
                shouldDirty: true,
              });
            }}
          />
        </InputRow>
      </FormContent>
      <SwitchRow>
        <Typography>PC 링크와 동일</Typography>
        <Switch checked={isSamePcLink} onChange={handleChangeIsSamePcLink} />
      </SwitchRow>
      <SectionDivider
        orientation="horizontal"
        flexItem
        style={{ marginTop: '24px' }}
      />
    </Section>
  );
};
