import {
  Box,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';

import { ColumnGroupDefinition } from './ColumnGroupDefinition';
import { convertStringToRegisterType } from '../../../common/order-util';
import { MobilePlanOrder } from '../../../entity/order';
import { VerticalColorTable } from '../../../shared/components/coloredTable';
import { BeforeOrderInfoButton } from './BeforeOrderInfoButton';
import { dateUtils } from '../../../utils';

const OrderTypeMap: { [key: string]: string } = {
  self: '셀프개통',
  register: '간편신청',
  outlink_self: '셀프개통 접수',
  call: '전화상담',
  none: '-',
};

type Props = {
  order: MobilePlanOrder;
};

export function OrderBasicInfoSection({ order }: Props) {
  const applicationDate = order.createdAt;

  return (
    <>
      <Box
        sx={{
          my: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'start',
            gap: 2,
          }}
        >
          <Typography variant="h6">접수 정보</Typography>
        </Box>
        <BeforeOrderInfoButton order={order} />
      </Box>
      <TableContainer component={Paper} elevation={5}>
        <VerticalColorTable>
          {ColumnGroupDefinition}
          <TableBody>
            <TableRow>
              <TableCell>신청자 이름</TableCell>
              <TableCell>{order.customerName}</TableCell>
              <TableCell>가입 유형</TableCell>
              <TableCell>
                {convertStringToRegisterType(order.registerType)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>신청 일자</TableCell>
              <TableCell>
                {dateUtils.dateToLocaleString(applicationDate)}
              </TableCell>
              <TableCell>접수 상태</TableCell>
              <TableCell>{order.status}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>개통 번호</TableCell>
              <TableCell>{order.orderPhoneNumber}</TableCell>
              <TableCell>접수 종류</TableCell>
              <TableCell>{OrderTypeMap[order.orderType || 'none']}</TableCell>
            </TableRow>
            {order.partnerOrderId && order.mvno === 'KT 스카이라이프' ? (
              <TableRow>
                <TableCell>접수 번호</TableCell>
                <TableCell>{order.partnerOrderId}</TableCell>
              </TableRow>
            ) : (
              <></>
            )}
          </TableBody>
        </VerticalColorTable>
      </TableContainer>
    </>
  );
}
