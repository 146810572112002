export const objectDiff = (x: Record<string, any>, y: Record<string, any>) => {
  const diff: Record<string, any> = {};
  for (const key in x) {
    if (typeof x[key] === 'object' && typeof y[key] === 'object') {
      const nestedDiff = objectDiff(x[key], y[key]);
      if (Object.keys(nestedDiff).length > 0) {
        diff[key] = nestedDiff;
      }
    } else if (x[key] !== y[key] && !(!x[key] && !y[key])) {
      // 둘다 null or undefined일 때는 다르다고 판단하면 안됨
      diff[key] = y[key];
    }
  }
  return diff;
};
