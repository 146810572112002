import { BootstrapDialog, BootstrapDialogTitle } from './Dialog';
import { Button, DialogActions, DialogContent } from '@mui/material';

import { Box } from '@mui/system';

type DisplayPlanDialogProps = {
  open: boolean;
  onReserve: () => void;
  onClose: () => void;
  onDisplay: () => void;
};

const DisplayPlanDialog = ({
  open,
  onReserve,
  onClose,
  onDisplay,
}: DisplayPlanDialogProps) => {
  return (
    <BootstrapDialog
      aria-labelledby="customized-dialog-title"
      open={open}
      onClose={onClose}
      style={{ padding: `8px` }}
    >
      <BootstrapDialogTitle id="customized-dialog-title">
        요금제 등록하고 노출하기
      </BootstrapDialogTitle>
      <DialogContent>
        <Box width={'100%'} pt={0} pb={2}>
          요금제를 등록하고 모요에 노출시킬까요?
        </Box>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'flex-end', padding: `8px` }}>
        <Button color={'info'} variant={'text'} onClick={onReserve}>
          노출 예약
        </Button>
        <Button color={'info'} variant={'contained'} onClick={onDisplay}>
          지금 노출
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};

export default DisplayPlanDialog;
