import { createContext, useContext, useEffect, useState } from 'react';
import { authApiService } from '../api/auth';
import { companyApiService } from '../api/company';
import { CookieConstants } from '../common/constants';
import { getJWTInfo, getToken } from '../common/cookie';
import { setCookie } from '../common/cookie';
import { CompanyInfo } from '../entity/company';
import { User } from '../entity/user';
import { Permission, Role } from '../types/AuthType';
import { planApiService } from '../api/plan';
import { IMobileOperatorMeta } from '../entity/plan';

type UserContextType = {
  user: User | null;
  planOperatorMeta: IMobileOperatorMeta | undefined;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  setUserInfo: (user: any) => User | Promise<User>;
  permissions: Permission[];
  roles: Role[];
};

const UserContext = createContext<UserContextType>({
  user: null,
  planOperatorMeta: undefined,
  setUser: () => {},
  setUserInfo: (): User | Promise<User> => {
    return {
      id: -1,
      adminUserId: '',
      username: '',
      company: undefined,
      roles: undefined,
    };
  },
  permissions: [],
  roles: [],
});

const STORAGE_PARTNERS_ADMIN_USER = '_moyo_partners_admin_user';

const getInitialUser = () => {
  try {
    return localStorage.getItem(STORAGE_PARTNERS_ADMIN_USER)
      ? (JSON.parse(
          localStorage.getItem(STORAGE_PARTNERS_ADMIN_USER) || '',
        ) as User)
      : null;
  } catch (error) {
    console.error('json parse error-orderlist', error);
    return null;
  }
};

export function UserContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [user, setUser] = useState<User | null>(getInitialUser());
  const [planOperatorMeta, setPlanOperatorMeta] = useState<
    IMobileOperatorMeta | undefined
  >(undefined);

  const roles = user?.roles ?? [];
  const permissions = user?.permissions ?? [];

  const setUserInfo = async (newUser: User) => {
    return await initUserInfo(newUser);
  };

  const initUserInfo = async (user: User) => {
    const userJWTInfo = getJWTInfo(getToken());

    const jwtRoles = userJWTInfo.role.split(',') as Role[];
    const jwtPermissions = userJWTInfo.permissions.split(',') as Permission[];

    const companyInfoResult = await companyApiService.getInfo();
    const userData = {
      ...user,
      roles: jwtRoles,
      permissions: jwtPermissions,
      company: companyInfoResult.data.result,
    };
    setUser(userData);
    return userData;
  };

  const initUserPlanOperator = async (mobilePlanOperatorId: number) => {
    const planOperatorResult = await planApiService.getPlanOperatorMeta(
      mobilePlanOperatorId,
    );

    setPlanOperatorMeta(planOperatorResult?.data?.result ?? undefined);
    return planOperatorResult.data.result;
  };

  useEffect(() => {
    if (!user) return;
    initUserInfo(user);
  }, []);

  useEffect(() => {
    if (!user) return;

    if (
      user?.company?.mobilePlanOperatorId &&
      user?.company?.mobilePlanOperatorId !== 0
    ) {
      initUserPlanOperator(user?.company?.mobilePlanOperatorId);
    }
  }, [user]);

  useEffect(() => {
    try {
      localStorage.setItem(STORAGE_PARTNERS_ADMIN_USER, JSON.stringify(user));
    } catch (error) {
      console.log('storage error-useContext', error);
    }
  }, [user]);

  const value = {
    user,
    setUser,
    setUserInfo,
    planOperatorMeta,
    // NOTE: 새로 정의된 인증 인가 관련된 타입
    roles,
    permissions,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

export function useAuth() {
  const context = useContext(UserContext);
  const { user, setUser } = context;

  // NOTE: me API를 통해 사용자의 역할과 권한을 업데이트하는 함수
  const updateRoleAndPermission = async () => {
    if (!user) return;
    const userMe = await authApiService.me();

    const userData = {
      ...user,
      roles: userMe.data.result?.roles ?? [],
      permissions: userMe.data.result?.permissions ?? [],
    };
    setUser(userData);
    return userData;
  };

  const logoutUser = () => {
    setUser(null);
    localStorage.removeItem(STORAGE_PARTNERS_ADMIN_USER);
    setCookie(CookieConstants.ACCESS_TOKEN, '', {
      path: '/',
    });
  };

  const setUserCompanyInfo = (company: CompanyInfo) => {
    if (!user) return;
    setUser({ ...user, company });
  };

  const getUserMvno = () => {
    return user?.company?.mvnos?.[0] ?? '';
  };

  return {
    ...context,
    logoutUser,
    getUserMvno,
    setUserCompanyInfo,
    updateRoleAndPermission,
  };
}
