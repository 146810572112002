import {
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress as MaterialCircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  styled,
  Typography,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { usePortal, usePortalController } from '../../../common/usePortal';
import { ThemeControlProvider } from '../../../context/ThemeControlContext';
import LoadingButton from '@mui/lab/LoadingButton';
import { alarmTalkService } from '../../../api/alarmTalkService';
import pbl from '../../../pbl/pbl-service/pbl-service';

const CircularProgress = styled(MaterialCircularProgress)`
  position: absolute;
  left: calc(50% - 20px);
  top: calc(50% - 20px);
`;

interface FormFieldValue {
  licenseIssuedInfo: boolean;
  paymentInfo: boolean;
  usimInfo: boolean;
  currentPhoneInfo: boolean;
  certBillingInfo: boolean;
}

export type AlarmTalkType =
  | '개통불가 사유 정정 요청'
  | '3개월 내 번호이동'
  | 'NFC 유심 결제 요청'
  | '명의불일치'
  | '타인납부'
  | '개통 예정'
  | '중복신청'
  | '14일 이내 개통 불가'
  | '사용 불가 유심'
  | '미납'
  | '개통 불가 (기타 사유)';

interface AlarmTalkSendModalProps {
  orderId: string;
  type: AlarmTalkType;
}

const AlarmTalkSendModal: FC<AlarmTalkSendModalProps> = ({ orderId, type }) => {
  const { close, dismiss } = usePortalController<string>();
  const [open, setOpen] = useState(true);
  const form = useForm<FormFieldValue>();
  const { control, watch } = form;
  const [alarmTalkMessage, setAlarmTalkMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingTemplate, setLoadingTemplate] = useState(false);
  const onlyMessagePreview = type !== '개통불가 사유 정정 요청';
  const [disabledSend, setDisabledSend] = useState(false);

  useEffect(() => {
    if (open) return;
    onCloseDialogHandler();
  }, [open]);

  useEffect(() => {
    (async () => {
      try {
        setLoadingTemplate(true);
        const detailReasons: string[] = [];
        if (watch('licenseIssuedInfo')) {
          detailReasons.push('신분증 정보');
        }
        if (watch('paymentInfo')) {
          detailReasons.push('청구 납부 정보');
        }
        if (watch('usimInfo')) {
          detailReasons.push('유심번호');
        }
        if (watch('currentPhoneInfo')) {
          detailReasons.push('현재 통신사');
        }
        if (watch('certBillingInfo')) {
          detailReasons.push('번호이동 인증 수단');
        }

        const result = await alarmTalkService.getAlarmTalkTemplate(
          orderId,
          type,
          detailReasons,
        );

        // 체크 옵션이 필요한 경우에는 버튼 활성화가 있어야됨
        if (!onlyMessagePreview && !detailReasons.length) {
          setDisabledSend(true);
          setAlarmTalkMessage('');
        } else {
          setDisabledSend(false);
          setAlarmTalkMessage(result.data.result || '');
        }
      } finally {
        setLoadingTemplate(false);
      }
    })();
  }, [
    onlyMessagePreview,
    watch('licenseIssuedInfo'),
    watch('paymentInfo'),
    watch('usimInfo'),
    watch('currentPhoneInfo'),
    watch('certBillingInfo'),
  ]);

  const onCloseDialogHandler = () => {
    setTimeout(() => {
      dismiss();
    }, 500);
  };

  const onClickSend = async () => {
    setLoading(true);
    try {
      const detailReasons: string[] = [];
      if (watch('licenseIssuedInfo')) {
        detailReasons.push('신분증 정보');
      }
      if (watch('paymentInfo')) {
        detailReasons.push('청구 납부 정보');
      }
      if (watch('usimInfo')) {
        detailReasons.push('유심번호');
      }
      if (watch('currentPhoneInfo')) {
        detailReasons.push('현재 통신사');
      }
      if (watch('certBillingInfo')) {
        detailReasons.push('번호이동 인증 수단');
      }
      pbl('click', 'modal_button', 'here', {
        object: {
          id: 'send_alimtalk',
          section: 'order_history',
        },
        eventProperties: {
          reason: type,
          detailReasons,
        },
      });
      await alarmTalkService.sendAlarmTalk(orderId, type, detailReasons);

      close(alarmTalkMessage);
    } catch (_: any) {
      alert('알림톡을 보내는데 실패했습니다.');
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };

  const onClickCancel = () => {
    pbl('click', 'modal_button', 'here', {
      object: {
        id: 'send_alimtalk_cancel',
        section: 'order_history',
      },
      eventProperties: {
        reason: type,
      },
    });
    setOpen(false);
    close();
  };

  return (
    <Dialog open={open} fullWidth={!onlyMessagePreview}>
      <DialogTitle>
        <Typography variant="h5">{type} 안내를 보낼게요</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={onlyMessagePreview ? 1 : 2}>
          {!onlyMessagePreview && (
            <Grid item xs={6}>
              <Typography variant="h6">
                어떤 내용을 포함해서 보낼까요?
              </Typography>
              <FormProvider {...form}>
                <FormGroup>
                  <FormControl>
                    <Controller
                      name="licenseIssuedInfo"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          {...field}
                          label="신분증 정보"
                          control={<Checkbox />}
                        />
                      )}
                    />
                  </FormControl>

                  <FormControl>
                    <Controller
                      name="paymentInfo"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          {...field}
                          label="청구 납부 정보"
                          control={<Checkbox />}
                        />
                      )}
                    />
                  </FormControl>

                  <FormControl>
                    <Controller
                      name="usimInfo"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          {...field}
                          label="유심번호"
                          control={<Checkbox />}
                        />
                      )}
                    />
                  </FormControl>

                  <FormControl>
                    <Controller
                      name="currentPhoneInfo"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          {...field}
                          label="현재 통신사"
                          control={<Checkbox />}
                        />
                      )}
                    />
                  </FormControl>

                  <FormControl>
                    <Controller
                      name="certBillingInfo"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          {...field}
                          label="번호이동 인증 수단"
                          control={<Checkbox />}
                        />
                      )}
                    />
                  </FormControl>
                </FormGroup>
              </FormProvider>
            </Grid>
          )}
          <Grid item xs={onlyMessagePreview ? 12 : 6}>
            <Card
              sx={{
                borderRadius: '20px',
                boxShadow: '0px 12px 12px rgba(0, 0, 0, 0.15)',
                position: 'relative',
              }}
            >
              <CardContent sx={{ height: '388px', overflow: 'auto' }}>
                <Typography
                  variant="subtitle2"
                  fontWeight={700}
                  color="primary.dark"
                >
                  알림톡 미리보기
                </Typography>
                <br />
                <Typography whiteSpace="pre-wrap" variant="body2">
                  {loadingTemplate ? (
                    <CircularProgress />
                  ) : (
                    <>{alarmTalkMessage}</>
                  )}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" disabled={loading} onClick={onClickCancel}>
          취소
        </Button>
        <LoadingButton
          variant="contained"
          loading={loading}
          disabled={disabledSend}
          onClick={onClickSend}
        >
          보내기
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export const useAlarmTalkSendModal = () => {
  const portal = usePortal<string>();

  const open = {
    개통사유불가: (orderId: string) => {
      return portal.open(
        <ThemeControlProvider>
          <AlarmTalkSendModal
            orderId={orderId}
            type="개통불가 사유 정정 요청"
          />
        </ThemeControlProvider>,
      );
    },
    번호이동3개월제한: (orderId: string) => {
      return portal.open(
        <ThemeControlProvider>
          <AlarmTalkSendModal orderId={orderId} type="3개월 내 번호이동" />
        </ThemeControlProvider>,
      );
    },
    사용불가유심: (orderId: string) => {
      return portal.open(
        <ThemeControlProvider>
          <AlarmTalkSendModal orderId={orderId} type="사용 불가 유심" />
        </ThemeControlProvider>,
      );
    },
    NFC유심결제요청: (orderId: string) => {
      return portal.open(
        <ThemeControlProvider>
          <AlarmTalkSendModal orderId={orderId} type="NFC 유심 결제 요청" />
        </ThemeControlProvider>,
      );
    },
    타인납부: (orderId: string) => {
      return portal.open(
        <ThemeControlProvider>
          <AlarmTalkSendModal orderId={orderId} type="타인납부" />
        </ThemeControlProvider>,
      );
    },
    명의불일치: (orderId: string) => {
      return portal.open(
        <ThemeControlProvider>
          <AlarmTalkSendModal orderId={orderId} type="명의불일치" />
        </ThemeControlProvider>,
      );
    },
    개통예정: (orderId: string) => {
      return portal.open(
        <ThemeControlProvider>
          <AlarmTalkSendModal orderId={orderId} type="개통 예정" />
        </ThemeControlProvider>,
      );
    },
    중복신청: (orderId: string) => {
      return portal.open(
        <ThemeControlProvider>
          <AlarmTalkSendModal orderId={orderId} type="중복신청" />
        </ThemeControlProvider>,
      );
    },
    '14일이내개통불가': (orderId: string) => {
      return portal.open(
        <ThemeControlProvider>
          <AlarmTalkSendModal orderId={orderId} type="14일 이내 개통 불가" />
        </ThemeControlProvider>,
      );
    },
    개통불가기타사유: (orderId: string) => {
      return portal.open(
        <ThemeControlProvider>
          <AlarmTalkSendModal orderId={orderId} type="개통 불가 (기타 사유)" />
        </ThemeControlProvider>,
      );
    },
    미납: (orderId: string) => {
      return portal.open(
        <ThemeControlProvider>
          <AlarmTalkSendModal orderId={orderId} type="미납" />
        </ThemeControlProvider>,
      );
    },
  };

  return { open };
};
