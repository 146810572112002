/**
 *
 * @param includeQuestionMark 물음표를 붙여서 리턴할지 여부
 * @returns redirect-url 쿼리스트링을 반환
 */

export default function getRedirectQueryString({
  redirectUrl,
  includeQuestionMark = false,
}: {
  redirectUrl: string;
  includeQuestionMark?: boolean;
}) {
  const params = new URLSearchParams();
  if (redirectUrl !== '/') {
    params.set('redirect-url', redirectUrl);
  }

  return includeQuestionMark ? `?${params.toString()}` : params.toString();
}
