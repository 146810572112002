import { Controller, useFormContext } from 'react-hook-form';
import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField,
} from '@mui/material';

import { MobilePlanOrder } from '../../../entity/order';

interface PayerCheckedProps {
  order?: MobilePlanOrder;
}

const payerNameErrorMessage = '납부자 이름을 입력해 주세요.';
const payerBirthdayErrorMessage = '납부자 생년월일을 입력해 주세요.';
const payerPhoneNumberErrorMessage = '납부자 연락처를 입력해 주세요.';
const payerRelationshipErrorMessage = '신청자와의 관계를 선택해 주세요.';

export function PayerChecked({ order }: PayerCheckedProps) {
  const {
    control,
    register,
    watch,
    formState: { errors },
  } = useFormContext<MobilePlanOrder>();
  const watchPayerChecked = watch('detail.payerChecked');

  if (!watchPayerChecked) {
    return null;
  }

  return (
    <>
      <TableRow>
        <TableCell>납부자 이름</TableCell>
        <TableCell>
          <TextField
            {...register('detail.payerName', {
              required: payerNameErrorMessage,
            })}
            size="small"
            error={!!errors?.detail?.payerName}
            helperText={errors?.detail?.payerName?.message}
          />
        </TableCell>
        <TableCell>납부자 생년월일</TableCell>
        <TableCell>
          <TextField
            {...register('detail.payerBirthday', {
              required: payerBirthdayErrorMessage,
            })}
            size="small"
            error={!!errors?.detail?.payerBirthday}
            helperText={errors?.detail?.payerBirthday?.message}
          />
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell>납부자 연락처</TableCell>
        <TableCell>
          <TextField
            {...register('detail.payerPhoneNumber', {
              required: payerPhoneNumberErrorMessage,
            })}
            size="small"
            error={!!errors?.detail?.payerPhoneNumber}
            helperText={errors?.detail?.payerPhoneNumber?.message}
          />
        </TableCell>
        <TableCell>신청자와의 관계</TableCell>
        <TableCell>
          <FormControl>
            <Controller
              name="detail.payerRelationship"
              control={control}
              rules={{ required: payerRelationshipErrorMessage }}
              defaultValue={order?.detail?.payerRelationship ?? ''}
              render={({ field }) => (
                <Select {...field} required size="small">
                  <MenuItem value="부모">부모</MenuItem>
                  <MenuItem value="배우자">배우자</MenuItem>
                  <MenuItem value="자녀">자녀</MenuItem>
                  <MenuItem value="기타">기타</MenuItem>
                </Select>
              )}
            />
          </FormControl>
          <FormHelperText error={!!errors?.detail?.payerRelationship}>
            {errors?.detail?.payerRelationship?.message}
          </FormHelperText>
        </TableCell>
      </TableRow>
    </>
  );
}
