import {
  Button,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { InternetOrderStatus } from '../../entity/internet-order';

interface Props {
  statusList: InternetOrderStatus[];
  onSubmit: (
    status: string,
    memo: string,
    statusDetail?: string,
  ) => void | Promise<void>;
}

const colGroupDefinition = (
  <colgroup>
    <col style={{ width: '20%' }} />
    <col style={{ width: '20%' }} />
    <col style={{ width: '60%' }} />
  </colgroup>
);

export default function InternetCommonHistoryCreateSection({
  statusList,
  onSubmit,
}: Props) {
  const [status, setStatus] = useState<InternetOrderStatus>();
  const [statusDetailList, setStatusDetailList] = useState<Array<string>>([]);
  const [statusDetail, setStatusDetail] = useState<string>('none');
  const [memo, setMemo] = useState<string>('');

  const onStatusChanged = (value: InternetOrderStatus | string) => {
    const newStatus = statusList.find(
      (s: InternetOrderStatus) => s.key === value,
    );
    setStatus(newStatus);
  };

  useEffect(() => {
    if (status) {
      setStatusDetail('none');
      setStatusDetailList(status.details);
    }
  }, [status]);

  const onClickSubmit = async () => {
    if (!status?.key) return;
    if (statusDetailList.length > 0 && statusDetail === 'none') {
      alert('상세 이유를 선택해주세요');
      return;
    }

    try {
      await onSubmit(
        status.key,
        memo,
        statusDetail === 'none' ? undefined : statusDetail,
      );
      reset();
    } catch (err) {
      console.log(err);
    }
  };

  const reset = () => {
    // init state
    setStatus(undefined);
    setStatusDetail('none');
    setStatusDetailList([]);
    setMemo('');
  };

  return (
    <>
      <TableContainer component={Paper} elevation={5}>
        <Table
          sx={{
            td: {
              backgroundColor: 'transparent',
              fontWeight: 'normal',
            },
          }}
        >
          {colGroupDefinition}
          <TableHead>
            <TableRow>
              <TableCell>상태</TableCell>
              <TableCell>상세 사유</TableCell>
              <TableCell>상담 메모</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <Select
                  id={'select_status'}
                  value={status?.key ?? 'none'}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (
                      (value === 'APPLICATION_CANCELED' ||
                        value === 'ACTIVATION_CANCELED') &&
                      !window.confirm(
                        '취소를 하면 고객에 대한 모든 정보가 사라지니 주의해주세요!\n정보가 삭제되는 것에 대해서 고객에게도 꼭 안내해주세요.',
                      )
                    ) {
                      return;
                    }
                    onStatusChanged(value);
                  }}
                  fullWidth
                >
                  <MenuItem value={'none'} disabled>
                    상태값을 선택해주세요
                  </MenuItem>
                  {statusList.map((status) => (
                    <MenuItem key={status.key} value={status.key}>
                      {status.name}
                    </MenuItem>
                  ))}
                </Select>
              </TableCell>
              <TableCell>
                {statusDetailList.length > 0 && (
                  <Select
                    id={'select_status_detail'}
                    value={statusDetail}
                    onChange={(e) => setStatusDetail(e.target.value)}
                    fullWidth
                  >
                    <MenuItem value={'none'} disabled>
                      상세 상태값을 선택해주세요
                    </MenuItem>
                    {statusDetailList.map((detail) => (
                      <MenuItem key={detail} value={detail}>
                        {detail}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </TableCell>
              <TableCell>
                <TextField
                  multiline
                  rows={2}
                  value={memo}
                  onChange={(e) => setMemo(e.target.value)}
                  fullWidth
                  inputProps={{
                    maxLength: 100,
                  }}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          mt: 3,
        }}
      >
        <Button variant="contained" onClick={onClickSubmit}>
          상담 등록하기
        </Button>
      </Box>
    </>
  );
}
