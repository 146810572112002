import { Grid, MenuItem, Select, Typography } from '@mui/material';

import { useSearchSection } from '../hooks';

export function RegisterTypeSection() {
  const { searchState, searchHandler } = useSearchSection();

  const { registerType } = searchState;
  const { setRegisterType } = searchHandler;

  return (
    <Grid item xs={12} md={6}>
      <Typography mb={1}>신청 유형</Typography>
      <Select
        fullWidth
        defaultValue=""
        value={registerType}
        onChange={(event) => setRegisterType(event.target.value)}
        size="small"
        displayEmpty
      >
        <MenuItem key={'all'} value="">
          전체
        </MenuItem>
        <MenuItem key={'change'} value="change">
          번호이동
        </MenuItem>
        <MenuItem key={'new'} value="new">
          신규가입
        </MenuItem>
      </Select>
    </Grid>
  );
}
