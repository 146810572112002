import { Grid } from '@mui/material';

import {
  DateSection,
  CustomerNameSection,
  BirthdaySection,
  CustomerPhoneNumberSection,
  PlanNameSection,
  StatusSection,
  MnoSection,
  RegisterTypeSection,
  MemoSection,
  HasUsimSection,
  UsimAfterDeliverySection,
  CurrentMnoSection,
} from '../SearchData/components';
import { InternetStatusSection } from './components/InternetStatusSection';

/**
 * @description
 * SearchSection의 UI표시 및 순서를 담당하는 템플릿으로 해당 섹션의 사용여부를 사용처에서 결정하면 된다
 */
export function SearchSectionTemplate({
  dateSection = false,
  customerNameSection = false,
  birthdaySection = false,
  customerPhoneNumberSection = false,
  planNameSection = false,
  statusSection = false,
  internetStatusSection = false,
  mnoSection = false,
  registerTypeSection = false,
  memoSection = false,
  hasUsimSection = false,
  usimAfterDeliverySection = false,
  beforeMnoSection = false,
}) {
  return (
    <Grid container rowSpacing={2} columnSpacing={6} mt={2}>
      {dateSection && <DateSection />}
      {customerNameSection && <CustomerNameSection />}
      {birthdaySection && <BirthdaySection />}
      {customerPhoneNumberSection && <CustomerPhoneNumberSection />}
      {planNameSection && <PlanNameSection />}
      {statusSection && <StatusSection />}
      {internetStatusSection && <InternetStatusSection />}
      {mnoSection && <MnoSection />}
      {registerTypeSection && <RegisterTypeSection />}
      {memoSection && <MemoSection />}
      {hasUsimSection && <HasUsimSection />}
      {usimAfterDeliverySection && <UsimAfterDeliverySection />}
      {beforeMnoSection && <CurrentMnoSection />}
    </Grid>
  );
}
