import { ChangeEvent } from 'react';

const changeEnteredNum = (
  e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
) => {
  const value: string = e.target.value;
  const removedCommaValue: number = Number(value.replaceAll(',', ''));
  return removedCommaValue.toLocaleString();
};

export default changeEnteredNum;
