import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { orderApiService } from '../../../api/order';
import { useAuth } from '../../../context/UserContext';
import { OrderHandleAssignDto } from '../../../entity/order';

export function useSelfAssign() {
  const { user } = useAuth();

  const [showOverWriteModal, setShowOverWriteModal] = useState(false);
  const [showHandlAssignToast, setShowHandlAssignToast] = useState(false);

  const handleSelfAssign = useMutation({
    mutationFn: (assignEntity: OrderHandleAssignDto) =>
      orderApiService.updateSelfAssign(assignEntity),
    onSuccess: async (response) => {
      if (response.data.errorCode === 'ALREADY_ASSIGNED') {
        setShowOverWriteModal(true);
        return;
      }

      if (response.data.resultType === 'SUCCESS') {
        setShowHandlAssignToast(true);

        // NOTE: 정상 업데이트 후에 해당 id들의 메모에 담당자를 넣어준다.
        const resultOrderIds = response.data.result?.orderIds;
        if (!resultOrderIds) return;
        const userName = user?.username ?? '';
        await orderApiService.updateOrderStatus(
          resultOrderIds.map((id) => id.toString()),
          undefined,
          undefined,
          `[배정된 담당자]\n${userName}`,
          false,
        );
      }
    },
  });

  const handleConfirmSelfAssign = (orderIds: number[]) => {
    handleSelfAssign.mutate({
      orderIds,
      allowOverwrite: true,
    });
    closeOverWriteModal();
  };

  const closeOverWriteModal = () => {
    setShowOverWriteModal(false);
  };

  const closeHandleAssignToast = () => {
    setShowHandlAssignToast(false);
  };

  return {
    handleSelfAssign,
    showOverWriteModal,
    showHandlAssignToast,
    closeOverWriteModal,
    closeHandleAssignToast,
    handleConfirmSelfAssign,
  };
}
