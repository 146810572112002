import React, { FC, useEffect, useState } from 'react';
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';

interface FeeGuardRailProps {
  open: boolean;
  title: string;
  description: string;
}

const FeeGuardRailDialog: FC<FeeGuardRailProps> = ({
  open,
  title,
  description,
}) => {
  return (
    <Dialog maxWidth="xs" open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <CircularProgress size={20} />
        <DialogContentText>
          <Typography color={'rgba(0,0,0,0.87)'} variant="body1">
            {description}
          </Typography>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default FeeGuardRailDialog;
